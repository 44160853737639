import { process } from "@progress/kendo-data-query"
import {
  Grid,
  GridCell,
  GridColumn as Column,
  GridRowClickEvent,
  GridNoRecords,
  GridToolbar,
} from "@progress/kendo-react-grid"
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl"
import { ExcelExport } from "@progress/kendo-react-excel-export"
import { Tooltip } from "@progress/kendo-react-tooltip"
import "@progress/kendo-theme-bootstrap/dist/all.css"
import SwitchCell from "./SwitchCell"
import CheckboxCell from "./CheckboxCell"
import BooleanCell from "./CustomFilterCell"
import { useHistory } from "react-router-dom"
import ActionMenu from "./ActionMenu"
import IconMenu from "./IconMenu"
import commonStyles from "../../styles/Pages/common/styles"
import moment from "moment"
import _ from "lodash"
import React from "react"
import { Button, Typography, Popover, ListItem } from "@material-ui/core"
import * as IconsGallery from "@iconsGallery"
import styled from "styled-components"
import ShowCircleBasedOnProp from "./ShowCircleBasedOnProp"
import { useTranslation } from "react-i18next"
let locale = { es: "es-ES", fr: "fr-FR", ar: "ar-AR" }

const { CheckCircleIcon, Slash } = IconsGallery || {}

const TemplateTableUIGrid = styled(Grid)`
  &.k-grid {
    background-color: #ffffff;
    box-shadow: 1px 2px 3px #0000002b;
  }
   &.k-grid tr.k-state-selected > td{
    background-color: #F9F9FC;
  }
 .k-checkbox:checked{
    background-color: var(--primaryColor);
    border-Color: var(--primaryColor);
  }
  .k-grid-table {
    width: 100% !important
  }
  .k-grid-header table {
     width: 100% !important
  }
  .k-header {
    background: #eee;

    .k-link {
      color: #000000;
      font-weight: 500;
      padding: 15px 12px !important;
    }
  }
  .k-filter-row {
    background: #ffffff;

    .k-dropdown .k-dropdown-wrap {
      background: white;
      border: none;
    }
    .k-button {
      background: white;
      border: none;
    }

    .k-i-filter::before {
      color: #353535;
    }
    .k-textbox {
      border-top: none;
      border-left: none;
      border-right: none;
      background: transparent;
    }
  }

  &.k-grid td {
    border-bottom: 1px solid #e0e0e0;
  }
  .k-filter-row th {
    padding: 16px 8px;
  }
  .k-master-row,
  .k-pager-wrap {
    background: transparent !important;
  }
  .k-pager-numbers .k-link {
    color:  #000;
  }
  .k-pager-numbers .k-link.k-state-selected {
    color: var(--primaryColor);
    background-color: #f9f9fc;
    border-color: var(--primaryColor) !important;
    box-shadow: none !important;
    }
   .k-pager-numbers .k-link:hover {
    color: #000 !important ;
    background-color: #f9f9fc !important;
  }
  .k-pager-numbers .k-link:active {
    color: var(--primaryColor) !important ;
    background-color: #f9f9fc !important;
  }
  .k-pager-numbers .k-link:focus, .k-pager-numbers .k-link.k-state-focus {
    box-shadow: none !important;
  }
  .k-checkbox {
    border: 2px solid #8c8c8c;
  }
  .bRDGfF {
    border-bottom: 1px solid #fefefe52;
  }

  .k-grid tbody td {
  }
  &.k-grid td {
    padding:${(props) =>
      props.BreadcrumbsValue2 === "Tax" ? "1rem 10px" : "0.4rem 10px"};
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.k-grid th {
    padding: 0.4rem 0.4rem;
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
   .k-grid-header thead tr:first-child th{ 
    background-color: #f9f9fc;
  }
  &.k-grid .k-filter-row th:empty {
    padding: 0 !important;
  }
  &.k-grid td,
  .k-grid .k-grid-content,
  .k-grid .k-grid-header-locked,
  .k-grid .k-grid-content-locked {
    border-color: transparent;
    border-bottom: 1px solid #e8e8e8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .selected {
    margin-bottom: 10px,
    margin-left: 5px,
  }
.k-dropdown-operator {
    width: auto;
    display: none;
}
.k-icon-button, .k-button.k-button-icon {
    width: calc(0.2em + 0.2rem );
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.1rem;
}
.k-pager-info.k-label{
  display: block
}
.k-checkbox{
  margin-bottom: 6px;
  margin-left: 6px;
}
.k-pager-nav.k-link{
  color: #000;
}
.k-dropdown {
  background-color: #f9f9fc;
}
.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm{
   color:  #000;
   place-self: flex-start;
   position: absolute;
   top: 40%;
   right: 5px;
}
.k-grid th.k-state-selected, .k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
      background-color: var(--primaryColor);
}
.k-grid-header{
  padding: 0px
}
`

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
)

const DashboardMaterialDataGrid = ({
  isInlineEditAllowed = false,
  gridState,
  columnData,
  pathProps,
  rowData,
  setRowData,
  setGridState,
  itemChange,
  setDefaultValue,
  headerSelectionChange,
  isLookup,
  selectionChange,
  onDiscard = () => {},
  onEdit = (e) => {
    console.log("onEdit --> ", e)
  },
  onDelete = () => {},
  onSave = () => {},
  switchHandler,
  pageData,
  actionMenu,
  additionalActions = () => {},
  reDirectButton,
  reDirectTo,
  number_of_items,
  pageChangeEvent,
  gridNoRecordsText = "No records found",
  loader,
  onRowClick = () => null,
  otherProps = {},
  menutype = "",
  buttonCount = 4,
  ShowCheckBox = true,
  checkBoxWidth = "50px",
  hideCheckboxAll = false,
  isOnRowClick = true,
  pageable = true,
  BreadcrumbsValue2 = "",
  setAnchorEl = () => null,
  anchorEl = false,
  InputData = "",
  setInputData = () => null,
  id = "",
  open = false,
  handleRowClick = () => null,
  tabIndex = "",
  mainStateDashboard = {},
  tenantId = "",
  isLoading = false,
}) => {
  const { t } = useTranslation()
  let history = useHistory()
  const commonClasses = commonStyles()
  const gridRowsStyle = `tr.k-master-row k-alt {background:transparent;}`

  let lang = localStorage.getItem("i18nextLng")
  let message = {}
  message[localStorage.getItem("lang")] = {
    grid: {
      pagerItemsPerPage: t("pagerItemsPerPage"),
      pagerOf: t("pagerOf"),
      pagerItems: t("pagerItems"),
      pagerInfo: t("pagerInfo"),
    },
  }
  loadMessages(message[lang], locale[lang])

  const dropDownFilterChange = (value, field) => {
    const newFilterObj = {
      logic: "and",
      filters: [],
    }
    const newGridState = { ...gridState }
    if (newGridState.dataState.filter === null) {
      newGridState.dataState.filter = newFilterObj
    }
    const newFilter = [
      {
        field: field,
        operator: "eq",
        value: value,
      },
    ]
    const filterIndex =
      newGridState.dataState &&
      newGridState.dataState.filter &&
      newGridState.dataState.filter.filters &&
      newGridState.dataState.filter.filters.findIndex(
        (filter) => filter.field === field
      )
    if (value !== "All") {
      filterIndex !== -1
        ? newGridState.dataState.filter.filters.length !== 0
          ? newGridState.dataState.filter.filters.splice(
              filterIndex,
              1,
              newFilter[0]
            )
          : (newGridState.filter.filters = newFilter[0])
        : newGridState.dataState.filter
        ? newGridState.dataState.filter.filters.push(newFilter[0])
        : (newGridState.dataState.filter["filters"] = newFilter)
    } else {
      newGridState.dataState.filter.filters.splice(filterIndex, 1)
    }
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }

  const onClearButtonClick = (field) => {
    const newGridState = { ...gridState }
    const filterIndex =
      newGridState.dataState &&
      newGridState.dataState.filter &&
      newGridState.dataState.filter.filters.findIndex(
        (filter) => filter.field === field
      )
    newGridState.dataState.filter.filters.splice(filterIndex, 1)
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }
  const handleKeyPress = (e) => {
    if (e.which === 32) {
      e.preventDefault()
    }
  }

  const gridWidth = 900

  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage
  }

  const _export = React.useRef(null)
  const excelExport = () => {
    if (_export.current !== null) {
      console.log(
        rowData.data,
        _.filter(columnData, _.matches({ show: true })),
        "wwwwwwwww"
      )
      _export.current.save(
        rowData.data,
        _.filter(columnData, _.matches({ show: true }))
      )
    }
  }

  return (
    <div className="cls-main-global-table-kit">
      {loader && loadingPanel}
      <style type="text/css">{gridRowsStyle}</style>
      <ExcelExport ref={_export} />
      <LocalizationProvider language={locale[lang]}>
        <IntlProvider locale={lang}>
          <TemplateTableUIGrid
            pageable={
              pageable ? { buttonCount: buttonCount, pageSizes: true } : false
            }
            filterable
            sortable
            style={{
              height: "auto",
              width: "100% !important",
              stripeRows: "false",
            }}
            {...rowData}
            {...gridState.dataState}
            onDataStateChange={(e) => {
              setGridState({ dataState: e.dataState })
            }}
            onSortChange={({ sort }) => {
              setGridState((c) => ({
                dataState: { ...c.dataState, sort, skip: 0 },
              }))
            }}
            editField="inEdit"
            selectedField="selected"
            onSelectionChange={(event) => selectionChange(event)}
            onHeaderSelectionChange={(event) => headerSelectionChange(event)}
            onItemChange={(event) => itemChange(event)}
            total={number_of_items}
            // className="ecom-table-ui"
            className={hideCheckboxAll ? commonClasses.hideCheckboxAll : ""}
            GridLinesVisibility
            onRowClick={(event) => {
              hideCheckboxAll && selectionChange(event)
              isOnRowClick && _.isFunction(onRowClick) && onRowClick()
            }}
            {...otherProps}
            BreadcrumbsValue2={BreadcrumbsValue2}
          >
            <GridNoRecords>
              {isLoading ? (
                <IconsGallery.DotLoading style={{ height: 20 }} />
              ) : (
                gridNoRecordsText && gridNoRecordsText
              )}
            </GridNoRecords>

            {ShowCheckBox && isInlineEditAllowed === true && (
              <Column
                field="selected"
                width={checkBoxWidth}
                filterable={false}
                headerSelectionValue={
                  !_.isEmpty(_.get(rowData, "data", [])) &&
                  _.filter(_.get(rowData, "data", []), (x) =>
                    _.get(x, "selected")
                  ).length === _.get(rowData, "data", []).length
                }
              />
            )}
            {columnData &&
              columnData.map((val, index) => {
                return (
                  val.show && (
                    <Column
                      field={val.field && val.field}
                      title={t(val?.title)}
                      editable={val.editable && val.editable}
                      sortable={val.sortable && val.sortable}
                      editor={val.editor && val.editor}
                      filterable={val.filter && val.filter}
                      filter={val.filterType && val.filterType}
                      onKeyPress={(e) => handleKeyPress(e)}
                      filterCell={
                        val.filterType === "boolean" &&
                        val.customFilter === true
                          ? (props) => (
                              <BooleanCell
                                data={val.filterData && val.filterData}
                                onKeyPress={(e) => handleKeyPress(e)}
                                value={
                                  gridState.dataState.filter
                                    ? gridState.dataState.filter.filters[
                                        gridState.dataState.filter.filters.findIndex(
                                          (filter) => filter.field === val.field
                                        )
                                      ]
                                    : { value: "All" }
                                }
                                dropdownChange={dropDownFilterChange}
                                onClearButtonClick={onClearButtonClick}
                                field={val.field}
                              />
                            )
                          : null
                      }
                      // width={val.min_width && val.min_width}
                      width={setPercentage(val.min_width)}
                      cell={
                        val.cell && val.switchCell
                          ? (props) => (
                              <SwitchCell
                                dataItem={props.dataItem}
                                value={props.dataItem[props.field]}
                                renderBox={props.dataItem[props.field]}
                                onChange={(event) => {
                                  console.log("event------------------>", event)
                                  switchHandler(event, props)
                                }}
                              />
                            )
                          : val.checkbox
                          ? (props) => (
                              <CheckboxCell
                                dataItem={props.dataItem}
                                value={props.dataItem[props.field]}
                                renderText={props.dataItem[props.field]}
                                onClick={(event) => {
                                  setDefaultValue(event, props)
                                }}
                              />
                            )
                          : val.showCircleColor
                          ? (props) => (
                              <ShowCircleBasedOnProp showColor={"red"} />
                            )
                          : val.renderDate
                          ? (props) => (
                              <td>
                                <p>
                                  {moment(props.dataItem[props.field]).format(
                                    "MMM DD, YYYY"
                                  )}
                                </p>
                              </td>
                            )
                          : val.button
                          ? (props) => (
                              <td>
                                <Button
                                  variant="outlined"
                                  className={
                                    commonClasses.defaultOutlinedButton
                                  }
                                  onClick={() => {
                                    reDirectTo(props.dataItem)
                                  }}
                                  style={{
                                    marginBottom: "0px",
                                    marginTop: "0px",
                                    color: "#1565C0",
                                  }}
                                >
                                  <IconsGallery.Map size="15" /> &ensp;List
                                </Button>
                              </td>
                            )
                          : val.text
                          ? (props) => (
                              <td>
                                {props.dataItem[props.field] ? "Yes" : "No"}
                              </td>
                            )
                          : val.verified
                          ? (props) => (
                              <td style={{ padding: "10px" }}>
                                {props.dataItem[props.field] ? (
                                  <CheckCircleIcon
                                    style={{ width: "20px", color: "#4CAF50" }}
                                  />
                                ) : (
                                  <Slash
                                    style={{ width: "20px", color: "gray" }}
                                  />
                                )}
                              </td>
                            )
                          : val.isSubfield
                          ? (props) => (
                              <td style={{ padding: "15px 10px" }}>
                                <Typography style={{ fontSize: "14px" }}>
                                  {props.dataItem[props.field]}
                                </Typography>
                                <Typography
                                  style={{ fontSize: "12px", color: "gray" }}
                                >
                                  {props.dataItem[val.subfield]}
                                </Typography>
                              </td>
                            )
                          : val.feildCircle
                          ? (props) => (
                              <td>
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {_.isArray(
                                    props.dataItem[val.iteratorName]
                                  ) ? (
                                    props.dataItem[val.iteratorName].map(
                                      (val, index) => {
                                        return (
                                          <div
                                            className={
                                              commonClasses.feildCircleStyle
                                            }
                                          >
                                            {val?.name === undefined
                                              ? val
                                              : val?.name}
                                          </div>
                                        )
                                      }
                                    )
                                  ) : (
                                    <div
                                      className={
                                        _.isEmpty(
                                          props.dataItem[val.iteratorName]
                                        )
                                          ? ""
                                          : commonClasses.feildCircleStyle
                                      }
                                    >
                                      {props.dataItem[val.iteratorName]}
                                    </div>
                                  )}
                                </div>
                              </td>
                            )
                          : val.circleStatus
                          ? (props) => (
                              <td>
                                <div
                                  style={{ display: "flex", flexWrap: "wrap" }}
                                >
                                  {props.dataItem[val?.field] ? (
                                    <div
                                      className={commonClasses.circleActive}
                                      style={{ marginTop: "2px" }}
                                    ></div>
                                  ) : (
                                    <div
                                      className={commonClasses.circleInactive}
                                    ></div>
                                  )}
                                </div>
                              </td>
                            )
                          : null
                      }
                    />
                  )
                )
              })}
            {!isLookup ? (
              actionMenu ? (
                <Column
                  title={t("Actions")}
                  width={setPercentage(10)}
                  resizable={true}
                  filterable={false}
                  sortable={false}
                  cell={(props) => (
                    <td>
                      <ListItem
                        style={{ cursor: "pointer" }}
                        onClick={() => handleRowClick(props)}
                      >
                        <IconsGallery.Eye
                          size={20}
                          style={{
                            color: "var(--primaryColor)",
                            marginRight: "10px",
                          }}
                        />
                      </ListItem>
                    </td>
                  )}
                />
              ) : (
                <Column
                  title={t("Actions")}
                  width={setPercentage(10)}
                  filterable={false}
                  sortable={false}
                  cell={(props) => <td>test</td>}
                />
              )
            ) : null}
          </TemplateTableUIGrid>
        </IntlProvider>
      </LocalizationProvider>
    </div>
  )
}
export { DashboardMaterialDataGrid }
export default DashboardMaterialDataGrid
