import React from "react"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Card,
  CardContent,
  CardActions,
  Button,
  Typography,
  Grid,
  Paper,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@material-ui/core"
import { useHistory } from "react-router-dom"
import * as Features from "@features"
import * as Adm from "@adm"
import {
  CreateOutlinedIcon,
  BorderColorOutlinedIcon,
  Edit,
  Slash,
} from "@iconsGallery"
import { defaultCreateForm, defaultEditForm, editListForm } from "./fixture"
import { useAddAddressContentStyles } from "./styles"
import styled from "styled-components"

const PaperMainWrapper = styled(Paper)`
  background-color: transparent;
  border-radius: 8px;
  box-shadow: none;
  max-width: 320px;
  width: 25%;
  margin-bottom: 10px;
`
const ButtonGroup = styled(Grid)`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 20px;
`
const AddressCardWrapper = styled(Card)`
  width: 100%;
  max-width: 520px;
  min-width: 300px;
  height: 220px;
  position: relative;
`
const FooterKit = styled(Grid)`
  display: flex;
  justify-content: space-between;
  position: absolute;
  bottom: 12px;
  width: calc(100% - 20px);
  align-items: center;
`
const FooterButton = styled(Grid)`
  display: flex;
`
const ButtonFlag = styled(Typography)`
  background: #1565c0;
  padding: 5px 13px;
  border-radius: 20px;
  color: white;
  text-transform: uppercase;
  height: 30px;
  font-size: 12px;
  &.gapr {
    margin-right: 10px;
  }
`
const DefaultTag = styled(Typography)`
  text-transform: uppercase;
  color: #757575;
  &.setdefaultui {
    color: #1565c0;
  }
`
const CardContentWrapper = styled(CardContent)`
  padding: 12px;
`
const DialogWrapper = styled(Dialog)`
  .base-form-checkbox {
    display: inline-flex !important;
  }
`

const useStyles = makeStyles({
  root: {
    width: "100%",
    maxWidth: "320px",
    height: 200,
    marginRight: "10px",
  },
  bullet: {
    display: "inline-block",
    margin: "0 2px",
    transform: "scale(0.8)",
  },
  btn: {
    color: "#8a8a8ac2",
  },
  whitebtn: {
    color: "white",
    background: "#0058e9",
    borderRadius: "50px",
    "&:hover": {
      background: "#0058e9",
    },
  },
  title: {
    fontSize: 14,
    color: "black",
    fontWeight: "bold",
  },
  pos: {
    color: "black",
  },
  paper: {
    boxShadow: "none",
  },
  rightPaper: {
    boxShadow: "none",
    float: "right",
  },
  btnPaper: {
    boxShadow: "none",
    float: "right",
  },
})

function OverlaySample(props) {
  const { cardData, editAddress } = props
  const classes = useAddAddressContentStyles()
  const dataViewList = editListForm(cardData)
  const [formList, setFormList] = React.useState(
    dataViewList || defaultEditForm
  )
  const [createNewForm, setCreateNewForm] = React.useState(false)
  const [billing, setBilling] = React.useState(false)
  const [shipping, setShipping] = React.useState(false)

  const customOnSubmit = (props) => {
    editAddress(props)
    setCreateNewForm(false)
  }

  const customRenderSubmitButton = ({ formUIProps }) => {
    const {
      errors,
      handleBlur,
      handleChange,
      handleSubmit,
      resetForm,
      touched,
      values,
      submitForm,
      renderSubmitButton,
    } = formUIProps

    const cancelbuttonAttrs = {
      variant: "outlined",
      onClick: () => setCreateNewForm(false),
    }
    const savebuttonAttrs = {
      variant: "contained",
      type: "submit",
      color: "primary",
    }

    return (
      <ButtonGroup>
        <Adm.Button {...cancelbuttonAttrs}>CANCEL</Adm.Button>
        <Adm.Button {...savebuttonAttrs}>SAVE</Adm.Button>
      </ButtonGroup>
    )
  }

  const commonFormAttribute = {
    formList,
    onSubmit: customOnSubmit,
    renderSubmitButton: customRenderSubmitButton,
  }
  return (
    <>
      <Paper
        className={classes.rightPaper}
        onClick={() => {
          setCreateNewForm(true)
        }}
      >
        <BorderColorOutlinedIcon
          style={{ fontSize: "20px", color: "#808080b0", float: "right" }}
        />
      </Paper>
      <DialogWrapper
        open={createNewForm}
        onClose={() => setCreateNewForm(false)}
        aria-labelledby="form-dialog-title"
        maxWidth="xl"
        m={4}
      >
        <div className={classes.FormPopup}>
          <Typography className={classes.popupTitle}>Edit Address</Typography>
          <Features.FormKit
            {...commonFormAttribute}
            isPlainObj={props.isPlainObj}
          />
        </div>
      </DialogWrapper>
    </>
  )
}

const defaultRenderHeader = (props) => {
  const {
    theme,
    classes,
    history,
    cardData,
    editAddress,
    isEditMode,
    editformList,
  } = props || {}

  const editCardAttr = {
    cardData,
    editformList,
    editAddress,
  }
  return cardData ? (
    <Grid container>
      <Grid item xs={6}>
        <Paper className={classes.paper}>
          <Typography
            className={`${classes.title} makeStyles-title-support`}
            color="textSecondary"
            gutterBottom
          >
            {cardData.name}
          </Typography>
        </Paper>
      </Grid>
      <Grid item xs={6}>
        {!isEditMode && <OverlaySample {...editCardAttr} />}
      </Grid>
    </Grid>
  ) : (
    <></>
  )
}

const defaultRenderContainer = (props) => {
  const { theme, classes, history, cardData, changeDefaultStatus, isEditMode } =
    props || {}
  const {
    line1,
    line2,
    line3,
    line4,
    city,
    country,
    zip_code_5,
    zip_code_4,
    shipping,
    billing,
    state,
    default: defaultKit = false,
  } = cardData
  return (
    cardData && (
      <>
        <Typography className={classes.pos} color="textSecondary" gutterBottom>
          {line1} {line2}
        </Typography>
        <Typography className={classes.pos} color="textSecondary" gutterBottom>
          {line3} {line4}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {city}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {country?.name || ""}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {state?.name || ""}
        </Typography>
        <Typography className={classes.pos} color="textSecondary">
          {zip_code_5}
        </Typography>
        <FooterKit>
          {defaultKit ? (
            !isEditMode ? (
              <DefaultTag
                style={{ cursor: "pointer" }}
                onClick={() => {
                  changeDefaultStatus(cardData)
                }}
              >
                {"default"}
              </DefaultTag>
            ) : (
              <DefaultTag>{"default"}</DefaultTag>
            )
          ) : !isEditMode ? (
            <DefaultTag
              style={{ cursor: "pointer" }}
              className="setdefaultui"
              onClick={() => {
                changeDefaultStatus(cardData)
              }}
            >
              {"set default"}
            </DefaultTag>
          ) : (
            <DefaultTag className="setdefaultui" style={{ display: "none" }}>
              {"set default"}
            </DefaultTag>
          )}
          <FooterButton>
            {billing === true && (
              <ButtonFlag className="gapr">{"billing"}</ButtonFlag>
            )}
            {shipping === true && <ButtonFlag>{"shipping"}</ButtonFlag>}
          </FooterButton>
        </FooterKit>
      </>
    )
  )
}

const dummyData = {
  name: "YYYY",
  line1: "3033 Geraldline Lane",
  city: "New York, NY 10016",
  country: "United States",
  zip_code_5: "10001-0001",
  defaut: true,
  billing: false,
  shipping: true,
}
const CheckCardData = (cardData) => {
  return cardData ? cardData : dummyData
}

const AddressCard = (props) => {
  const {
    isEditMode,
    isOpen = false,
    renderHeader = defaultRenderHeader,
    renderContainer = defaultRenderContainer,
    defaultBtn = true,
    billingBtn = true,
    shippingBtn = true,
    // eslint-disable-next-line no-use-before-define
    cardData = CheckCardData(cardData),
    editAddress,
    changeDefaultStatus,
    editformList,
    ...remainingProps
  } = props || {}
  const [edit, setEdit] = React.useState(true)
  const theme = useTheme()
  const classes = useStyles()
  let history = useHistory()

  const stateProps = {
    theme,
    classes,
    editAddress,
    changeDefaultStatus,
    history,
    cardData,
    isEditMode,
    editformList,
    ...remainingProps,
  }

  return (
    <AddressCardWrapper variant="outlined">
      <CardContentWrapper>
        {renderHeader(stateProps)}
        {renderContainer(stateProps)}
      </CardContentWrapper>
    </AddressCardWrapper>
  )
}

export { AddressCard }
export default AddressCard
