import _, { cloneDeep } from "lodash"

export const GeneratePostObject = (
  augPostObj,
  subData,
  childData,
  updateValue
) => {
  let copyAugPostObj = cloneDeep(augPostObj)

  if (childData !== "") {
    if (childData.name === "Horizontal Flip") {
      copyAugPostObj.Horizontal_Flip = updateValue
    } else if (childData.name === "Vertical Flip") {
      copyAugPostObj.Vertical_Flip = updateValue
    } else if (childData.name === "Rotation(180°)") {
      copyAugPostObj.Rotation_value = updateValue ? 180 : 0
    }
  } else {
    if (subData.name?.toLowerCase() === "brightness") {
      copyAugPostObj.Brightness_value = updateValue
    } else if (subData.name?.toLowerCase() === "hue") {
      copyAugPostObj.Hue_value = updateValue
    } else if (subData.name?.toLowerCase() === "contrast") {
      copyAugPostObj.Contrast_value = updateValue
    } else if (subData.name?.toLowerCase() === "saturation") {
      copyAugPostObj.Saturation_value = updateValue
    } else if (subData.name?.toLowerCase() === "grayscale") {
      copyAugPostObj.Grayscale = updateValue
    } else if (subData.name?.toLowerCase() === "sharpen") {
      copyAugPostObj.Sharpen_value = updateValue
    }
  }
  return copyAugPostObj
}
