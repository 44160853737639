import React, { useState } from "react"
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  FormHelperText,
  TextField,
  Typography,
} from "@material-ui/core"
import * as Utils from "@utils"
import styled from "styled-components"
import * as IconsGallery from "@iconsGallery"
import { makeStyles } from "@material-ui/core/styles"
import { useStyles } from "@material-ui/pickers/views/Calendar/Day"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const Error = styled(Typography)`
  color: #f44336;
  font-size: 0.6964285714285714rem;
  bottom: -24px;
  left: 12px;
  margin-left: 14px;
`

const getSelectedValue = (list, val, key = "id", labelPath = "name") =>
  _.find(list, (o) => _.get(o, key, "") === _.get(val, key, "")) || {
    [key]: "",
    [labelPath]: "",
  }

function DropdownKit(props) {
  const { t } = useTranslation()
  let {
    list = [],
    icons = [],
    defaultValue = "",
    onUpdate = () => "",
    className = "",
    name = "",
    placeholder = "Select",
    error = "",
    style = {},
    setRequiredName = () => "",
    label = "",
    labelPath = "",
    keyForValueReference = "id",
    isPlainObj = false,
  } = props || {}

  const [dropDownValue, setDropDownValue] = useState(() => defaultValue)
  const [dropDownlist, setDropDownlist] = useState(() => list)

  React.useEffect(() => {
    setDropDownValue(defaultValue)
  }, [defaultValue])

  React.useEffect(() => {
    setDropDownlist(list)
  }, [list])

  const onHandleChange = (event) => {
    const { target: { value: testVal = "" } = {} } = event || {}
    setDropDownValue(testVal)
    onUpdate(testVal)
    setRequiredName(false)
  }

  const renderList = () =>
    !Utils.JSUtils.isEmpty(dropDownlist) &&
    dropDownlist.map((data, index) => {
      let IconSet = () => <React.Fragment />
      if (!Utils.JSUtils.isEmpty(icons)) {
        if (undefined !== icons[index]) {
          IconSet = icons[index]
        }
      }
      return (
        <MenuItem
          value={data}
          data-key={index}
          key={index}
          disabled={data?.isDisabled ? data.isDisabled : false}
        >
          {!Utils.JSUtils.isEmpty(icons) && <IconSet />}
          {typeof data === "object" ? (
            !_.isEmpty(labelPath) ? (
              <span>{data[labelPath]}</span>
            ) : (
              <span>{data[label]}</span>
            )
          ) : (
            <span>{data}</span>
          )}
        </MenuItem>
      )
    })

  const StyledDropdown = makeStyles({
    select: {
      borderRadius: "0px 0 0 0px",
    },
  })
  const useStyles = makeStyles({
    formControl: {
      "& .MuiFormLabel-root.Mui-focused": {
        color: "var(--primaryColor)",
      },
      "& .MuiOutlinedInput-root": {
        "&.Mui-focused fieldset": {
          borderColor: "var(--primaryColor)",
        },
      },
    },
  })

  const classes = useStyles()

  const selectAttr = {
    labelId: "demo-simple-select-outlined-label",
    id: "demo-simple-select-outlined",
    className: classes.select,

    name: name,
    value:
      _.isPlainObject(defaultValue) ||
      (isPlainObj && _.isEmpty(keyForValueReference))
        ? getSelectedValue(list, defaultValue, keyForValueReference)
        : defaultValue,
    onChange: onHandleChange,
    displayEmpty: placeholder.length > 0,
  }

  return (
    <FormControl
      className={` ${className} cls-base-dropdown-kit ${
        error ? "cls-error" : ""
      }${classes.formControl}`}
      variant="outlined"
      size="small"
      style={style}
    >
      {Utils.JSUtils.isEmpty(dropDownlist) === false ? (
        <Select
          {...selectAttr}
          id="id-header-dropdownkit-kit"
          MenuProps={{ classes: { paper: "cls-header-dropdownkit-kit" } }}
        >
          {placeholder && (
            <MenuItem value="" disabled>
              {placeholder}
            </MenuItem>
          )}
          {renderList()}
        </Select>
      ) : (
        <TextField
          className={`cls-empty-textfield`}
          variant="outlined"
          size="small"
          type="text"
          name="state"
          disabled={true}
          value={placeholder}
        />
      )}
      {error && <Error>{t(error)}</Error>}
    </FormControl>
  )
}
export { DropdownKit }
export default DropdownKit
