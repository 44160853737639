import React, { useEffect, useState } from "react"
import {
  Box,
  Container,
  Dialog,
  IconButton,
  Typography,
  styled,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { withStyles } from "@material-ui/styles"
import {
  Delete,
  Close,
  ArrowForwardIos,
  ArrowBackIos,
} from "@material-ui/icons"
import { Trash2 } from "@iconsGallery"
import { width } from "@material-ui/system"

const LeftIconStyled = styled(IconButton)({
  position: "absolute",
  left: 25,
  top: "50%",
  transform: "translateY(-50%) scale(2.0)",
})
const RightIconStyled = styled(IconButton)({
  position: "absolute",
  right: 25,
  top: "50%",
  transform: "translateY(-50%) scale(2.0)",
})

const StyledDialog = withStyles((theme) => ({
  paper: {
    overflow: "hidden",
  },
}))(Dialog)

const StyledImageContainer = styled(Box)({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  overflow: "hidden",
  minWidth: "600px",
  minHeight: "560px",
})

const StyledImage = styled("img")({
  objectFit: "cover",
  width: "600px",
  height: "500px",
})

const ModalFooter = styled(Typography)({
  width: "100%",
  textAlign: "center",
  margin: "20px",
})

const ImageSlider = (props) => {
  const {
    selectedItem = {},
    defaultList = [],
    onUpdate = () => "",
    onDelete = () => "",
    totalNoRecords = 0,
    currentPageNumber = 0,
    setSelectedItem = () => "",
  } = props

  const { t } = useTranslation()
  const [currentIndex, setCurrentIndex] = useState(0)
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (selectedItem) {
      setIsOpen(true)
      setCurrentIndex(defaultList.findIndex((d) => d.id === selectedItem.id))
    }
  }, [selectedItem])

  const handleNext = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1)
  }

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => prevIndex - 1)
  }

  const handleClose = () => {
    setIsOpen(false)
    setSelectedItem(null)
  }

  const showRightArrow =
    !((currentIndex + 1) * currentPageNumber === totalNoRecords) &&
    currentIndex !== defaultList.length

  return (
    <StyledDialog
      open={isOpen}
      aria-labelledby="parent-modal-title"
      aria-describedby="parent-modal-description"
      maxWidth="md"
      disableBackdropClick="false"
      onClose={() => {
        handleClose()
      }}
    >
      <IconButton
        aria-label="close"
        style={{
          position: "absolute",
          right: "0px",
          top: "0px",
          zIndex: 5,
        }}
        onClick={handleClose}
      >
        <Close />
      </IconButton>
      <Box style={{ position: "relative", width: "800px" }}>
        {currentIndex !== 0 && (
          <LeftIconStyled onClick={handlePrev} disabled={currentIndex === 0}>
            <ArrowBackIos />
          </LeftIconStyled>
        )}
        <StyledImageContainer
          style={{
            fontSize: "28px",
            fontWeight: "600",
            color: "var(--primaryColor)",
          }}
        >
          {currentIndex === defaultList.length ? (
            t("More Images are available in next page")
          ) : (
            <StyledImage
              src={defaultList?.[currentIndex]?.imageUrl}
              alt={t("No Image Provided")}
            />
          )}
        </StyledImageContainer>
        {showRightArrow && (
          <RightIconStyled onClick={handleNext}>
            <ArrowForwardIos />
          </RightIconStyled>
        )}
        <ModalFooter variant="body2">
          {currentIndex !== defaultList.length && (
            <Typography
              style={{ margin: "10px" }}
              className="cls-media-common-txt"
            >
              {currentIndex + 1} / {defaultList.length} of {totalNoRecords}
            </Typography>
          )}
          {currentIndex !== defaultList.length && (
            <IconButton
              onClick={() => onDelete({ item: defaultList[currentIndex] })}
              style={{
                transform: "scale(2.0)",
              }}
            >
              <Trash2 />
            </IconButton>
          )}
        </ModalFooter>
      </Box>
    </StyledDialog>
  )
}

export default ImageSlider
export { ImageSlider }
