import React, { Suspense, useEffect } from "react"
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useLocation,
  Redirect,
} from "react-router-dom"
import {
  dashboardLayoutRoutes,
  authLayoutRoutes,
  presentationLayoutRoutes,
  sidebarRoutes,
} from "./index"
import { useDispatch, useSelector } from "react-redux"

import DashboardLayout from "../layouts/Dashboard"
import AuthLayout from "../layouts/Auth"
import PresentationLayout from "../layouts/Presentation"
import Page404 from "../pages/auth/Page404"
import RenderOnAuthenticated from "../../src/components/keycloak/RenderOnAuthenticated"
import jwt_decode from "jwt-decode"
import UserService from "../services/UserService"
import { useHistory } from "react-router-dom"
import { addRealmRoles, userDecodeDetials } from "../reduxSagas/admin/actions"
import { LaptopWindows } from "@material-ui/icons"
import axios from "axios"
import _ from "lodash"
import { JSUtils } from "@utils"
import Page403 from "../pages/auth/Page403"
import ForgetPassword from "../pages/auth/ForgetPassword"
import ResetPassword from "../pages/auth/ResetPasswordForm"
import { useTranslation } from "react-i18next"

const Routes = () => {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const history = useHistory()
  const userPermAttr = useSelector(
    (state) => state.adminReducer.userKeycloakAttributes
  )

  const childRoutesByJ6 = (
    Layout = () => null,
    routes = [],
    userPermAttr = {}
  ) => {
    const routesArray = routes.reduce((acc, x, i) => {
      const hasChildren = !_.isEmpty(x.children) && _.isArray(x.children)
      const hasComponent = !_.isEmpty(x.component)
      const isComponentIsObj = _.has(x, "component.component")
      const { isReadAllowed, isWriteAllowed } = JSUtils.getReadWritePermissions(
        x,
        userPermAttr
      )

      let path = _.get(x, "path", "no-path")
      const exact = _.get(x, "exact", true)
      const Component = _.get(x, "component", () => null)
      path = t(path)
      const render = (props) =>
        isReadAllowed ? (
          <Layout>
            <Component {...props} isWriteAllowed={isWriteAllowed} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/403", state: { from: path } }} />
        )
      const key = `route__${path}__${i}`

      if (isComponentIsObj) {
        let childRoutesArray = childRoutesByJ6(
          Layout,
          [Component],
          userPermAttr
        )
        acc = _.concat(acc, childRoutesArray)
      }

      if (hasComponent && !isComponentIsObj) {
        acc.push(<Route key={key} path={path} exact={exact} render={render} />)
      }

      if (hasChildren) {
        let childRoutesArray = childRoutesByJ6(
          Layout,
          _.get(x, "children", []),
          userPermAttr
        )
        acc = _.concat(acc, childRoutesArray)
      }

      return acc
    }, [])
    return routesArray
  }

  const childRoutesByJ5 = (
    Layout = () => null,
    routes = [],
    userPermAttr = {}
  ) => {
    const routesArray = routes.reduce((acc, x, i) => {
      const hasChildren = !_.isEmpty(x.children) && _.isArray(x.children)
      const hasComponent = !_.isEmpty(x.component)
      const isComponentIsObj = _.has(x, "component.component")
      const { isReadAllowed, isWriteAllowed } = JSUtils.getReadWritePermissions(
        x,
        userPermAttr
      )

      let path = _.get(x, "path", "no-path")
      if (path?.includes("/:")) {
        let gpath = x.path.replaceAll("/:", "/-")
        path = t(gpath)
      } else {
        let Qpath = x.path.replaceAll(":", "-")
        path = t(Qpath)
      }

      const exact = _.get(x, "exact", true)
      const Component = _.get(x, "component", () => null)

      const render = (props) =>
        isReadAllowed ? (
          <Layout>
            <Component {...props} isWriteAllowed={isWriteAllowed} />
          </Layout>
        ) : (
          <Redirect to={{ pathname: "/403", state: { from: path } }} />
        )
      const key = `route__${path}__${i}`

      if (isComponentIsObj) {
        let childRoutesArray = childRoutesByJ5(
          Layout,
          [Component],
          userPermAttr
        )
        acc = _.concat(acc, childRoutesArray)
      }

      if (hasComponent && !isComponentIsObj) {
        acc.push(<Route key={key} path={path} exact={exact} render={render} />)
      }

      if (hasChildren) {
        let childRoutesArray = childRoutesByJ5(
          Layout,
          _.get(x, "children", []),
          userPermAttr
        )
        acc = _.concat(acc, childRoutesArray)
      }

      return acc
    }, [])
    return routesArray
  }
  return (
    <>
      {_.size(userPermAttr) > 0 && (
        <RenderOnAuthenticated>
          <Router>
            <Switch>
              {childRoutesByJ5(
                DashboardLayout,
                dashboardLayoutRoutes,
                userPermAttr
              )}
              {childRoutesByJ6(DashboardLayout, sidebarRoutes, userPermAttr)}
              {childRoutesByJ6(AuthLayout, authLayoutRoutes)}
              {childRoutesByJ6(PresentationLayout, presentationLayoutRoutes)}
              <Route
                path="/403"
                exact={true}
                render={() => (
                  <AuthLayout>
                    <Suspense fallback={<div>{t("Loading")}....</div>}>
                      <Page403 />
                    </Suspense>
                  </AuthLayout>
                )}
              />
              <Route
                path="/forgetpassword"
                exact={true}
                render={() => (
                  <AuthLayout>
                    <Suspense fallback={<div>{t("Loading")}....</div>}>
                      <ForgetPassword />
                    </Suspense>
                  </AuthLayout>
                )}
              />
              <Route
                path="/resetpassword"
                exact={true}
                render={() => (
                  <AuthLayout>
                    <Suspense fallback={<div>{t("Loading")}....</div>}>
                      <ResetPassword />
                    </Suspense>
                  </AuthLayout>
                )}
              />
              <Route
                path="*"
                exact={true}
                render={() => (
                  <AuthLayout>
                    <Suspense fallback={<div>{t("Loading")}....</div>}>
                      <Page404 />
                    </Suspense>
                  </AuthLayout>
                )}
              />
            </Switch>
          </Router>
        </RenderOnAuthenticated>
      )}
    </>
  )
}

export default Routes

export const LocationDisplay = () => {
  const location = useLocation()
  return <div data-testid="location-display">{location.pathname}</div>
}
