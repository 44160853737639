import React from "react"
import * as Adm from "@adm"

function SearchFacetUIView(props) {
  const { edit: isEditMode, fetchData } = props

  return <Adm.SearchListUI isEdit={!isEditMode} list={fetchData} />
}

export { SearchFacetUIView }
