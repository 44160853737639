import React from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import {
    StepperWrapper,
    useQontoStepIconStyles,
    useColorlibStepIconStyles,
    QontoConnector,
    ColorlibConnector,
    useStyles
} from './styles'
import {
    SettingsIcon,
    GroupAddIcon,
    VideoLabelIcon,
    CheckIcon
} from '@iconsGallery'


function QontoStepIcon(props) {
  const classes = useQontoStepIconStyles()
  const { active, completed } = props
  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
      })}
    >
      {completed ? (
        <CheckIcon className={classes.completed} />
      ) : (
        <div className={classes.circle} />
      )}
    </div>
  )
}

QontoStepIcon.propTypes = {
  /**
   * @Inf =>  Whether this step is active.
   */
    active: PropTypes.bool,
    
  /**
   * @Inf => Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
}


function ColorlibStepIcon(props) {
  const classes = useColorlibStepIconStyles()
  const { active, completed } = props

  const icons = {
    1: <SettingsIcon />,
    2: <GroupAddIcon />,
    3: <VideoLabelIcon />,
  }

  return (
    <div
      className={clsx(classes.root, {
        [classes.active]: active,
        [classes.completed]: completed,
      })}
    >
      {icons[String(props.icon)]}
    </div>
  )
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   */
  active: PropTypes.bool,
  /**
   * Mark the step as completed. Is passed to child components.
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
}

export {QontoStepIcon, ColorlibStepIcon}