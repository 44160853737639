const maxTimeLimit = 3
export const defaultModalOperationHoursKit = [
  [
    {
      name: "sunday",
      time: [],
    },
    {
      name: "monday",
      time: [],
    },
    {
      name: "tuesday",
      time: [],
    },
    {
      name: "wednesday",
      time: [],
    },
    {
      name: "thursday",
      time: [],
    },
    {
      name: "friday",
      time: [],
    },
    {
      name: "saturday",
      time: [],
    },
  ],
]


export const operatingHrs = [
  {
    day: "sunday",
    selected: false,
    times: [],
    errorsArr: [],
    allDaySelected: false,
  },
  {
    day: "monday",
    selected: false,
    times: [],
    errorsArr: [],
    allDaySelected: false,
  },
  {
    day: "tuesday",
    selected: false,
    times: [],
    errorsArr: [],
    allDaySelected: false,
  },
  {
    day: "wednesday",
    selected: false,
    times: [],
    errorsArr: [],
    allDaySelected: false,
  },
  {
    day: "thursday",
    selected: false,
    times: [],
    errorsArr: [],
    allDaySelected: false,
  },
  {
    day: "friday",
    selected: false,
    times: [],
    errorsArr: [],
    allDaySelected: false,
  },
  {
    day: "saturday",
    selected: false,
    times: [],
    errorsArr: [],
    allDaySelected: false,
  }
];
