import React, { useEffect, useState } from "react"
import { Modal } from "@material-ui/core"
import * as IconsGallery from "@iconsGallery"
import * as Styles from "./styles"
import { useTranslation } from "react-i18next"
const LinearBarLoader = (props) => {
  const {
    innerWrapperStyles = {},
    isOpen = false,
    renderChildren = <></>,
    progress = 0,
    progressTitle = "Downloading...",
    subTitle = "Receiving File url",
    isLocalDownload = false,
  } = props
  const { t } = useTranslation()
  const [processPercentage, setProcessPercentage] = useState(
    `linear-gradient(90deg,  rgba(219,19,131,0.6) ${progress}%, #fff 0%)`
  )
  useEffect(() => {
    setProcessPercentage(
      `linear-gradient(90deg, rgba(219,19,131,0.6) ${progress}%, #fff 0%)`
    )
  }, [progress])
  return (
    <Styles.StyledProgressDiv>
      <div className={`cls-dkp-header-wrapper`}>
        <IconsGallery.CloudDownload style={{ fontSize: 40 }} />
        <Styles.StyledTypography variant="h4" className="cls-progressTitle">
          {progressTitle}
        </Styles.StyledTypography>
      </div>
      {isLocalDownload ? (
        <div className={`loading-bar animate`}>
          <span></span>
        </div>
      ) : (
        <>
          {progress > 0 ? (
            <Styles.LoaderWrapper
              container
              style={{ background: processPercentage }}
            ></Styles.LoaderWrapper>
          ) : (
            <div className={`loading-bar animate`}>
              <span></span>
            </div>
          )}
        </>
      )}

      <Styles.StyledTypography className="cls-subtitle" variant="body2">
        {`${t(subTitle)}: ${progress}%`}
      </Styles.StyledTypography>
    </Styles.StyledProgressDiv>
  )
}
export { LinearBarLoader }
export default LinearBarLoader
