import { makeStyles } from "@material-ui/core"


const useStyles = makeStyles({
  inputFieldText: {
    fontWeight: 700,
    fontSize: '14px'
  },
  customTooltip: {
    padding: "4px",
    fontSize: "10px",
    lineHeight: "0px",
    "&.MuiTooltip-tooltipArrow": {
      top: "-9px !important",
    },
  },
  toggleText: {
    marginBottom: "6px",
    marginTop: "45px",
    marginLeft: "120px",
  },
  textInputDisabled: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none'
    }
  },
  textInput: {
    borderColor: '1px solid #2f485826',
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  },
  AttributeButton: {
    margin: "10px",

  },
  AttributeGrid: {
    // padding: "10px"
  },
  toggleYes: {
    color: '#1565C0',
    fontWeight: 700,
    fontSize: '14px',
  },
  toggleNo: {
    color: '#9E9E9E',
    fontWeight: 700,
    fontSize: '14px',
  },
  toggleBar: {
    marginLeft: "10px",
  },
  Divider: {
    borderBottom: "0.1px solid lightGrey",
  },
})
export default useStyles