import { Formik } from "formik"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
} from "@material-ui/core"
import { AddCircleOutlineIcon } from "@iconsGallery"
import _ from "lodash"
import { commonFormUIYupSchema } from "./yupSchema"
import { useCommonFormUIContentStyles } from "./styles"
import DynamicListItemKit from "./dynamicListItemKit/index"
import { transformIntialValues, transformValidationSchema } from "./transform"

const FormUI = (formUIProps) => {
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    values,
    list,
    doDataUpdate,
    listColumnSplit,
    commonFormEdit,
  } = formUIProps
  const classes = useCommonFormUIContentStyles()
  return (
    <form
      onSubmit={handleSubmit}
      style={{
        margin: "20px",
        marginLeft: "0px",
      }}
    >
      {errors.submit && " "}
      <DynamicListItemKit {...formUIProps} />
      <Button variant="contained" color="secondary" type="submit">
        Save
      </Button>
    </form>
  )
}

function FormCommonFormUI(props) {
  const {
    setData,
    data,
    formValues,
    validationSchema,
    onSubmit,
    list: formList = [],
    doDataUpdate,
    listColumnSplit,
    commonFormEdit,
  } = props || {}
  const classes = useCommonFormUIContentStyles()
  const intialValueObj = transformIntialValues(formList)
  const validationSchemaObj = transformValidationSchema(formList)

  const formikAttr = {
    onSubmit: onSubmit,
    ...intialValueObj,
    ...validationSchemaObj,
  }
  const form = (formProps) => (
    <FormUI
      {...formProps}
      list={formList}
      listColumnSplit={listColumnSplit}
      commonFormEdit={commonFormEdit}
      doDataUpdate={doDataUpdate}
    />
  )
  return <Formik {...formikAttr}>{form}</Formik>
}

export { FormCommonFormUI }
