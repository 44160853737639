import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Paper,
  Typography,
} from "@material-ui/core"
import styled from "styled-components"

export const useAddAddressContentStyles = makeStyles({
  AddNew: {
    minWidth: "275px",
    background: "#0000ff12",
    border: 0,
    height: "220px",
    textAlign: "center",
    outline: "none",
    borderRadius: "8px",
    cursor: "pointer",
  },
  FormPopup: {
    paddingTop: "20px",
  },
  popupTitle: {
    paddingLeft: "30px",
    paddingBottom: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
  cardList: {
    display: "flex",
    maxWidth: "590px",
    overflowX: "auto",
  },
})

export const useEditAddressContentStyles = makeStyles({
  AddNew: {
    background: "#0000ff12",
    border: 0,
    textAlign: "center",
    outline: "none",
    borderRadius: "8px",
  },
  FormPopup: {
    paddingTop: "20px",
  },
  popupTitle: {
    paddingLeft: "30px",
    paddingBottom: "20px",
    fontSize: "16px",
    fontWeight: "bold",
  },
})

export const DialogWrapper = styled(Dialog)`
  .base-form-checkbox {
    display: inline-flex !important;
  }
`

export const ButtonGroup = styled(Grid)`
  display: flex;
  width: 90%;
  justify-content: space-between;
  margin: auto;
  margin-bottom: 20px;
  padding-top: 20px
`

export const CardWrapper = styled(Card)`
  width: 100%;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 2px rgba(63, 63, 68, 0.15);
  border-radius: 8px;
`
export const DialogWrapperKit = styled(Dialog)`
  width: 100%;
  box-shadow: 0px 0px 0px rgba(63, 63, 68, 0.05),
    0px 1px 2px rgba(63, 63, 68, 0.15);
  border-radius: 8px;
  .base-form-list-wrapper {
    border-bottom: 1px solid #d3d3d39c;
  }
  .base-form-checkbox {
    display: inline-flex !important;
  }
`
export const AddressCardListMainWrapper = styled(Grid)`
  background-color: transparent;
  border-radius: 8px;
  box-shadow: none;
  padding: 20px;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 12px;
  @media (min-width: 1281) and (max-width: 1600px) {
    grid-template-columns: repeat(3, 1fr);
  }
  @media (min-width: 1024px) and (max-width: 1280px) {
    grid-template-columns: repeat(2, 1fr);
  }
`
