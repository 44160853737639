import LocationTree from "./Tree"
import React from 'react'

const CheckboxTree = (props) => {
    const defaultTreeData = [{
        title: "Region A",
        id: "000",
        expanded: true,
        items: [
            {
                title: "State A",
                id: "010",
                expanded: true,
                items: [
                    {
                        title: "City A",
                        id: "011"
                    },
                    {
                        title: "Town A",
                        id: "012"
                    },
                    {
                        title: "Village A",
                        id: "013"
                    }
                ]
            }
        ]
    },
    {
        title: "Region B",
        id: "100",
        items: [
            {
                title: "State B",
                id: "110",
                expanded: true,
                items: [
                    {
                        title: "City B",
                        id: "111",
                    },
                    {
                        title: "Town B",
                        id: "112"
                    },
                    {
                        title: "Village B",
                        id: "113"
                    }
                ]
            }
        ]

    }
    ]
    const {
        treeData = defaultTreeData
    } = props || {}

    const onItemChange = () => {
        console.log("Midgaard")
    }

    return (<>
        <LocationTree
            tree={props.treeData}
            onItemChange={onItemChange}
            onSelect={props.onItemSelect}
            stores={props.stores}
            fetchEdit={props.fetchEdit}
            checkedNodes={props.checkedNodes}
            setCheckedArray={props.setCheckedArray}
            lookupMode={props.lookupMode}
            expandAll={props.expandAll}
            onExpandAction={props.onExpandAction}
            collapseAll={props.collapseAll}
            editorSearchTerm={props.editorSearchTerm}
        />
    </>
    )
}
export default CheckboxTree
export { CheckboxTree }