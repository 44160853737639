import React, { useState } from "react"
import _ from "lodash"
import DateRangeInput from "./DateRangeInput"
import { isValid, format, startOfDay, endOfDay } from "date-fns"
import { TimePicker, KeyboardTimePicker } from "@material-ui/pickers"
import moment from "moment"
import { Grid, ThemeProvider, Typography } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const withFormattedTime = (date, time, key = "from") => {
    if (isValid(time)) {
        return `${format(date, "yyyy-MM-dd'T'")}${format(
            time,
            key === "from" ? "HH:mm:'00.000'xxx" : "HH:mm:'59.999'xxx"
        )}`
    }
    return format(
        date,
        key === "from"
            ? "yyyy-MM-dd'T'HH:mm:'00.000'xxx"
            : "yyyy-MM-dd'T23:59:59.999'xxx"
    )
}

const getFormattedDate = (date, time, key = "from") => {
    if (isValid(date)) {
        return withFormattedTime(date, time, key)
    }
    return ""
}

export const getInitialDatesFromString = (str) => {
    if (_.isEmpty(str) || !_.isString(str)) {
        return { startDate: undefined, endDate: undefined, key: "dates" }
    }

    let dateArr = str.split("__||__")
    return {
        startDate: _.isEmpty(dateArr[0]) ? undefined : dateArr[0],
        endDate: _.isEmpty(dateArr[1]) ? undefined : dateArr[1],
        key: "dates",
    }
}

const SearchByDate = (props) => {
    const { t } = useTranslation()
    const {
        searchObjKey = "",
        searchObj = {},
        setSearchObj = () => { },
        label = "",
        select = false,
        SelectProps = {},
        searchByOptions = [],
        isLocationLookup = false,
        setFieldValue = () => { },
        showLabel = true,
    } = props
    const [initialDates, setInitialDates] = useState(
        getInitialDatesFromString(_.get(searchObj, searchObjKey, ""))
    )
    const [timeObj, setTimeObj] = useState(() => ({
        from: _.get(initialDates, "startDate", null),
        to: _.get(initialDates, "endDate", null),
    }))

    useEffect(() => {
        setInitialDates(
            getInitialDatesFromString(_.get(searchObj, searchObjKey, ""))
        )
    }, [searchObj])

    const handleChange = async (val) => {
        setFieldValue("fromDate", val?.startDate || null)
        await setSearchObj((c) => {
            let temp = _.cloneDeep(c)
            temp[searchObjKey] = `${getFormattedDate(
                _.get(val, "startDate", ""),
                _.get(timeObj, "from"),
                "from"
            )}__||__${getFormattedDate(
                _.get(val, "endDate", ""),
                _.get(timeObj, "to"),
                "to"
            )}`
            return temp
        })
    }

    const handleTimeChange = (time, key = "from") =>
        setSearchObj((c) => {
            let tempDates = getInitialDatesFromString(
                _.get(searchObj, searchObjKey, "")
            )
            if (!isValid(new Date(_.get(tempDates, "startDate", "")))) {
                tempDates["startDate"] = startOfDay(time)
                tempDates["endDate"] = endOfDay(time)
            }
            setTimeObj((c) => ({ ...c, [key]: time }))
            let temp = _.cloneDeep(c)
            temp[searchObjKey] = `${getFormattedDate(
                new Date(_.get(tempDates, "startDate", "")),
                key === "from" ? time : _.get(tempDates, "startDate"),
                "from"
            )}__||__${getFormattedDate(
                new Date(_.get(tempDates, "endDate", "")),
                key === "to" ? time : _.get(tempDates, "endDate"),
                "to"
            )}`
            return temp
        })

    return (
        <>
            <Grid item md={12} lg={12} style={{ paddingRight: "2px" }} className="cls-date-picker-kit">
                {showLabel && <Typography variant="h5" style={{ marginBottom: "1px" }}>
                    {t('Date Picker')}<span style={{ color: "red" }}>*</span>
                </Typography>}
                <DateRangeInput
                    initialDates={initialDates}
                    handleDateChange={handleChange}
                />
            </Grid>
        </>
    )
}
export { SearchByDate }
export default SearchByDate
