import React, { useState } from 'react';
import { makeStyles, MenuItem, TextField } from '@material-ui/core';
import _ from 'lodash';

const SelectInputSearch = (props) => {

    const { searchObjKey = '', searchObj = {}, setSearchObj = () => { }, label = '', select=false, SelectProps={}, searchByOptions=[] } = props;
    const value = _.get(searchObj, searchObjKey, '');
    const handleChange = (val) => setSearchObj(c => { let temp = _.cloneDeep(c); temp[searchObjKey] = val; return temp })

    return <TextField select={select} SelectProps={{}} label={label} variant='outlined' margin='dense' fullWidth value={value} onChange={e => handleChange(e.target.value)}>
        {_.map(_.get(_.find(searchByOptions, {id: searchObjKey}), 'options', []), (x, i) => <MenuItem key={`option_item_for_dynamic_filter_select_no_${i+1}`} value={ _.get(x, 'id', '') }>{_.get(x, 'label', '')}</MenuItem>)}
    </TextField>
}

export default SelectInputSearch