import * as constantsAPI from "@constants"
import moment from "moment"

const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}
const types = REDUX_CONSTANTS
const initialState = {
  openDate: moment(new Date()).format(),
  closeDate: moment(new Date()).format(),
}
export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.UPDATEOPENDATE:
      return {
        ...state,
        openDate: actions.openDate,
      }
    case types.UPDATECLOSEDATE:
      return {
        ...state,
        closeDate: actions.closeDate,
      }

    default:
      return state
  }
}
