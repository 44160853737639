import React, { useState, useEffect } from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
} from "@material-ui/core"
import { Formik } from "formik"
import * as Yup from "yup"
import { useDispatch, useSelector } from "react-redux"
import * as Features from "@features"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import {
  AddCircleOutlineIcon,
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  CloseIcon,
  Edit,
  Slash,
} from "@iconsGallery"
import { style } from "@material-ui/system"
import * as Adm from "@adm"
import LocationAttributeContent from "./LocationAttributeContent"
import _ from "lodash"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import { APIUrl } from "@utils"
import { useTranslation } from "react-i18next"
import { JSUtils } from "@utils"
const { API_URL_STORES } = APIUrl || {}
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const names = [
  {
    id: "ATM",
    value: "ATM",
  },
  {
    id: "Name",
    value: "Sample Text",
  },
  {
    id: "Age",
    value: "20",
  },
  {
    id: "Gender",
    value: "Male",
  },
]
const useStyles = makeStyles({
  cardSadhow: { boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset" },
})

const customRenderContainer = (props) => {
  const { edit, data } = props
  return (
    <>
      {data.length === 0 ? (
        <Features.NoRecordFound />
      ) : (
        <LocationAttributeContent edit={edit} names={names} data={data} />
      )}
    </>
  )
}

function LocationAttribute(props) {
  const { isWriteAllowed = false } = props
  const classes = useStyles()
  const [edit, setEdit] = useState(true)
  const [attribute, setAttribute] = useState([])
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const { t } = useTranslation()
  const formData = useSelector((state) => state.addLocationReducer)
  const handleChange = (event) => {
    setAttribute(event.target.value)
  }
  const [expanded, setExpanded] = useState(false)
  useEffect(() => {
    setData(_.get(props, "data.loc_attr", []))
  }, [props])

  const updateData = async () => {
    const dummyData = _.get(formData, "locationAttr", [])
    if (dummyData.length !== 0) {
      setLoading(true)
      setAlert(false)
      let body = {
        locationAttr:
          _.get(formData, "locationAttr") !== null
            ? formData.locationAttr
            : _.get(props, "data", []),
      }
      let response = await ServiceAPI.updateCommonDataURLOnId(
        API_URL_STORES +
          API_END_POINTS.updateLocationAddress +
          "/" +
          +props.storeId +
          "?" +
          "part=6",
        body
      )
      await setAlertType(_.get(response, "data.status", "error"))
      await setAlertMessage(JSUtils.formatMessageCode(response?.data, t, "Try Again Later!"))
      await setAlert(true)
      await setLoading(false)
    } else {
      await setAlertType("error")
      await setAlertMessage(t("Atleast One attribute is required"))
      await setAlert(true)
    }
  }
  return (
    <>
      <Adm.BackdropOverlay open={loading} />
      <Card
        style={{
          width: "100%",
          boxShadow:
            " 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Adm.AccordianPanel
          renderContainer={(containerProps) =>
            customRenderContainer({ ...containerProps, data })
          }
          label="Location Attribute"
          updateData={updateData}
          // resetData={resetData}
        />
      </Card>
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </>
  )
}
export { LocationAttribute, LocationAttributeContent }
export default LocationAttribute
