import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
export default function reducer(state = {}, actions) {
  switch (actions.type) {
    case types.AUTH_SIGN_IN_SUCCESS:
      return {
        ...state,
        user: {
          id: actions.id,
          email: actions.email,
          name: actions.name,
        },
      }

    case types.AUTH_SIGN_OUT:
      return {
        ...state,
        user: undefined,
      }

    default:
      return state
  }
}
