import axios from "../../utils/axios"
import * as constantsAPI from "@constants"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

export function signIn(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(API_END_POINTS.signIn, credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        }
        reject(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function signUp(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(API_END_POINTS.signUp, credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        }
        reject(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export function resetPassword(credentials) {
  return new Promise((resolve, reject) => {
    axios
      .post(API_END_POINTS.reset, credentials)
      .then((response) => {
        if (response.status === 200) {
          resolve(response.data)
        }
        reject(response.data)
      })
      .catch((error) => {
        reject(error)
      })
  })
}
