import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Button, Grid } from "@material-ui/core"
import { Plus } from "@iconsGallery"
import { ImportButtonUI, ExportButtonUI } from "@features"
import * as Adm from "@adm"
import styled from "styled-components"
import * as commonStylesKit from "@commonStyles"
import { useTranslation } from "react-i18next"
import { useHistory } from "react-router-dom"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
const { commonStyles } = commonStylesKit || {}
const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
const TemplateExport = styled(Grid)`
  Button {
    margin-right: 18px;
  }
`
const IotDevicesConfig = ({
  rowData,
  breadCrumbTitle,
  path,
  locationId,
  setDedupeOpen = () => [],
  openDialogNotToPath,
  setOpenCreateStoreConfig = () => {},
  setCreateapiSuccess = () => {},
  setCreateClicked = () => {},
  disableField = false,
  setCreateClickEnable = () => {},
  createButtonEnabled = true,
  title = "CREATE",
  urlGridState = {},
}) => {
  const BreadcrumbList = [
    {
      name: breadCrumbTitle?.[0],
      url: "",
    },
    {
      name: breadCrumbTitle?.[1],
    },
    {
      name: breadCrumbTitle?.[2],
      disable: true,
    },
  ]
  const commonClasses = commonStyles()
  const history = useHistory()
  const [loading, setLoading] = useState(null)
  const { t } = useTranslation()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const CreateButtonUI = ({ locationId, rowData, disableField = false }) => (
    <Button
      onClick={() => {
        history.push(path, { state: { urlGridState } })
      }}
      variant="contained"
      disabled={disableField}
      className={commonClasses.containedButton}
      startIcon={<Plus />}
      style={{ marginBottom: "10px" }}
    >
      {t(title)}
    </Button>
  )
  return (
    <Grid>
      <Helmet title={t("Training Products")} />
      <Adm.BackdropOverlay open={loading} />
      <PageHeaderBreadcrumbKit
        container
        justify="space-between"
        alignContent="center"
        alignItems="center"
        className="PageHeaderBreadcrumbKit"
        xs={12}
        sm={12}
        md={12}
        lg={12}
        xl={12}
      >
        <Grid item xs={12} sm={12} md={10} lg={10} xl={10}>
          <Adm.Breadcrumb list={BreadcrumbList} />
        </Grid>
        {createButtonEnabled ? (
          <>
            <Grid
              item
              xs={12}
              sm={12}
              md={2}
              lg={2}
              xl={2}
              style={{ display: "flex", paddingTop: isMobile ? "15px" : "" }}
              justify="flex-end"
            >
              <CreateButtonUI
                locationId={locationId}
                rowData={rowData}
                disableField={disableField}
              />
            </Grid>
          </>
        ) : (
          <></>
        )}
      </PageHeaderBreadcrumbKit>
    </Grid>
  )
}

export default IotDevicesConfig
