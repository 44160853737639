import React from "react"
import { IconButton } from "@material-ui/core"
import { CloseIcon, SearchIcon } from "@iconsGallery"
import "./MultiSearchUI.css"
import {
  useMultiSearchStyles,
  StyledTextField,
  DialogHeadingWrapper,
  SearchBoxWrapper,
  ResultsWrapper,
  CloseWrapper,
  StyledCloseIcon,
} from "./styles"

import * as ADM from "@adm"

function MultiSearchSearchBox(props) {
  const {
    textboxValue,
    onSearchBoxChange,
    dropDownAttr,
    tableDataState,
    onClose,
  } = props || {}

  const icon = <SearchIcon color="disabled" />
  const classes = useMultiSearchStyles()

  return (
    <DialogHeadingWrapper container direction="row" justify="space-between">
      <SearchBoxWrapper container direction="row">
        <StyledTextField
          variant="outlined"
          value={textboxValue}
          placeholder="   Search by"
          size="small"
          inputRef={(input) => {
            if (input != null) {
              input.focus()
            }
          }}
          InputProps={{
            startAdornment: icon,
            className: classes.textbox,
            size: "50",
          }}
          onChange={(event) => onSearchBoxChange(event)}
        />
        <ADM.DropdownKit {...dropDownAttr}/>
      </SearchBoxWrapper>
      <div style={CloseWrapper}>
        {tableDataState.data.length > 0 ? (
          <ResultsWrapper display="inline" style={{}}>
            {tableDataState.data.length} Results
          </ResultsWrapper>
        ) : null}
        <StyledCloseIcon onClick={(e) => onClose(true)} >
          <CloseIcon />
        </StyledCloseIcon>
      </div>
    </DialogHeadingWrapper>
  )
}

export default MultiSearchSearchBox
