import React from 'react'

import { Grid, Typography } from '@material-ui/core'
import {StepperWrapper, useStepperStyles, StepLabel, Step, StepperMaterial } from './styles'
import {QontoStepIcon, ColorlibStepIcon} from './Customization'
import { defaultStepperConfig, defaultRenderStepContent } from './renderProps'
import { useStepperState } from './States'
import StepperButton from '@material-ui/core/Button'


function Stepper(props = {}) {
  const {steps = defaultStepperConfig, renderStepContent = defaultRenderStepContent  }  = props || {}
  const classes = useStepperStyles()
  const {
    activeStep,
    setActiveStep,
    handleClickNext,
    handleClickBack,
    handleClickReset
  } = useStepperState({defaultIndex: 1})

  return (
    <StepperWrapper className={classes.root}>
      <Grid container row>
        <Grid item lg={2} md={2} sm={2} xs={2}>
          <StepperMaterial
            activeStep={activeStep}
            orientation="horizontal"
          >
            {steps.map((item) => (
              <Step key={item.id}>
                <StepLabel
                  StepIconComponent={ColorlibStepIcon}
                  onClick={() => {
                    setActiveStep(item.id)
                  }}
                >
                  {item.label}
                </StepLabel>
              </Step>
            ))}
          </StepperMaterial>
        </Grid>
        <Grid item lg={10} md={10} sm={10} xs={10}>
          {renderStepContent(activeStep)}
        </Grid>
      </Grid>
      <div>
        {activeStep === steps.length ? (
          <StepperButton onClick={handleClickReset} className={classes.button}>
            Reset
          </StepperButton>
        ) : (
          <div>
            <div>
              {activeStep === 0 ? (
                <StepperButton onClick={handleClickBack} className={classes.button}>
                  Cancel
                </StepperButton>
              ) : (
                <StepperButton onClick={handleClickBack} className={classes.button}>
                  Back
                </StepperButton>
              )}
              <StepperButton
                variant="contained"
                color="primary"
                onClick={handleClickNext}
                className={classes.button}
              >
                {activeStep === steps.length - 1 ? "Save" : "Next"}
              </StepperButton>
            </div>
          </div>
        )}
      </div>
    </StepperWrapper>
  )
}

export { Stepper }
export default Stepper