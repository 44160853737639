import { Checkbox } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"

function CheckboxCell(props) {
  const data = props.dataItem
  const dataValue = props.value
  const { t } = useTranslation()
  return (
    <td className="cls-td-kit-ele cls-td-kit-checkbox">
      <span className="cls-checkbox-kit">
        {data.inEdit ? (
          <Checkbox
            defaultChecked={dataValue}
            onClick={props.onClick}
            value={dataValue}
            style={{ color: "var(--primaryColor)" }}
          />
        ) : props.renderText ? (
            <span className="cls-CheckboxCell-kit">
              {t("Default")}
            </span>
        ) : (
          <span className="cls-CheckboxCell-kit" style={{ color: "#afafaf" }}>
            {t("Not Default")}
          </span>
        )}
      </span>
    </td>
  )
}

export default CheckboxCell
