import {
  dashboardLayout,
  authRoutes,
  landingRoutes,
  sidebarRoute,
} from "./RouterConfig"

// Routes using the Dashboard layout
export const dashboardLayoutRoutes = dashboardLayout

// Routes using the Auth layout
export const authLayoutRoutes = [authRoutes]

// Routes using the Presentation layout
export const presentationLayoutRoutes = [landingRoutes]

// Routes visible in the sidebar
export const sidebarRoutes = sidebarRoute
