import * as React from "react"
import TablePagination from "@material-ui/core/TablePagination"
import styled from "styled-components/macro"
import _ from "lodash"

const StyledPaginationMUI = styled(TablePagination)`
  [class*="Mui-selected"] {
    background-color: #00c5d7 !important;
    color: #fff;
    font-weight: 800;
  }
`

function CustomTablePagination(props) {
  const {
    pageNumber = 1,
    setPageNumber,
    rowsPerPage = 15,
    setRowsPerPage,
    handleChangePage = () => "",
    handleChangeRowsPerPage = () => "",
    totalRecords,
    totalPages,
  } = props || {}

  return (
    <>
      {totalRecords > 0 && (
        <StyledPaginationMUI
          component="div"
          count={totalRecords}
          page={pageNumber}
          onChangePage={handleChangePage}
          rowsPerPage={rowsPerPage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
          rowsPerPageOptions={[15, 30, 45, 60]}
        />
      )}
    </>
  )
}
export { CustomTablePagination }
export default CustomTablePagination
