import Styled from "styled-components"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
  Grid,
  Typography,
  Checkbox,
} from "@material-ui/core"

export const SearchSuggesionItemWrapper = Styled(Grid)`
  display: flex;
  align-items: center;
height:60px;
  &.active{
    background: #eaeaea;
  }
  
`
export const TitleWrapper = Styled(Typography)`
font-weight:bold
`
export const SubTitleWrapper = Styled(Typography)`
    line-height: 21px;
`
export const ViewContainerWrapper = Styled.div`
  min-height: 290px;
  font-family: 'Robato', Arial;
`

export const SearchSuggesionItemProductId = Styled(Typography)`
  width: 100px;
`

export const ViewItemWrapper = Styled(Grid)`
  display: flex;
  align-items: center;
  border:1px solid rgba(0, 0, 0, 0.1);
`
export const ViewProductIdItemWrapper = Styled(Grid)`
  width: 40%;
  height:60px;
  align-items: center;
  display: flex;
  border-right:1px solid rgba(0, 0, 0, 0.1);
  padding:10px;
  box-sizing: border-box;
`
export const ViewProductNameItemWrapper = Styled(Grid)`
  padding:10px;
  width: 60%;
  height:60px;
  display: flex;
  flex-direction: column;
  align-items: start;
  padding: 8px;
  justify-content: center;
  position: relative;
  .cls-addtional-beautify{
    font-weight: bold;
  }
  box-sizing: border-box;
`
export const ViewHeaderItemWrapper = Styled(Grid)`
  display: flex;
  align-items: center;
  height: 60px;
  align-items: center;
  display: flex;
  border:1px solid rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
`
export const ViewHeaderProductIdItemWrapper = Styled(Grid)`
  width: 39%;
  height:60px;
  align-items: center;
  display: flex;
  font-weight: 700;
  border-right:1px solid rgba(0, 0, 0, 0.1);
  padding:10px;
  box-sizing: border-box;
  background: #f1f1f1;
`
export const ViewHeaderProductNameItemWrapper = Styled(Grid)`
  width: 61%;
  height:60px;
  align-items: center;
  display: flex;
  padding:10px;
  font-weight: 700;
  background: #f1f1f1;
`

export const TextFieldWrapper = Styled(TextField)`
  width: 100%;
  margin-right:15px;
  margin-right:15px; 
  margin-right:15px;
  &.disabled {
    .icon-search-kit {
      opacity: 0.5;
      pointer-events: none;
    }
    input{
      background: #0000001c;
    }
  }
`
export const SearchSuggesionListWrapper = Styled(Grid)`
  width: calc(100% - 15px);
  margin-right:15px;
  background: #FFFFFF;
  position: absolute !important;
  z-index: 99 !important;
  left: 0;
  top: 40px;
  max-height: 180px;
  overflow: scroll;
  box-shadow: 0px 1px 2px #00000045;
  border-radius: 0px 0px 5px 5px;
`
export const EmptyWrapper = Styled(Grid)`
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 70%;
    justify-content: center;
`
export const EmptyTypography = Styled(Typography)`
  font-size: 18px;
  margin-top: 20px;
`
export const SearchInputMainWrapper = Styled(Grid)`
  display:flex;
  align-items: center;
  padding:10px 0;
  border:1px solid rgba(0, 0, 0, 0.1);
  margin-bottom:12px;
  margin-bottom:12px;
  max-width:500px;
  
`
export const searchErrorReq = Styled(Typography)`
  color: #f44336;
  font-size: 0.6964285714285714rem;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
`
export const SearchInputSubWrapper = Styled(Grid)`
  position: relative;
  width: 100%;
  display: flex;
  margin-right: 15px;
`
export const SearchNotResponse = Styled(Typography)`
  padding: 20px;
`
export const ListNoteResponse = Styled(Typography)`
  padding: 10px;
`

export const SearchInputCloseBttonWrapper = Styled(IconButton)`
    position: absolute !important;
    z-index: 99 !important;
    right: 16px;
    top: -4px;
    
`
