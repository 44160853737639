import * as Yup from "yup"

export const formInput = {

  formValues: {
    id: "",
    value: "",
  },
  validationSchema: {
    id: Yup.string()
      .required(`Enter Name`)
      .strict(true)
      .min(1, "Name can be of min-1 max-50 characters")
      .max(50, "Name can be of min-1 max-50 characters"),
    value: Yup.string().required(`Enter Value`)
      .strict(true)
      .min(1, "Value can be of min-1 max-50 characters")
      .max(50, "Value can be of min-1 max-50 characters"),
  },
}
