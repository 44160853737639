const LocationLookupFixture = [
    {
        field: "locationId",
        title: "Location Number",
        border: true,
        filter: false,
        show: true,
        min_width: 20
    },
    {
        field: "locationType.name",
        title: "Type",
        border: true,
        filter: false,
        show: true,
        min_width: 20
    },
    {
        field: "locationName",
        title: "Name",
        border: true,
        filter: false,
        show: true,
        min_width: 20
    },
    {
        field: "locationAddress.city",
        title: "City",
        border: true,
        filter: false,
        show: true,
        min_width: 20
    },
    {
        field: "locationAddress.region",
        title: "State",
        border: true,
        filter: false,
        show: true,
        min_width: 15
    },
    {
        field: "locationManager",
        title: "Manager",
        border: true,
        filter: false,
        show: true,
        min_width: 15
    },
    {
        field: "phoneNumber.number",
        title: "Phone",
        filter: false,
        show: true,
        min_width: 15
    },
    {
        field: "locationSupportEmail",
        title: "Email",
        border: true,
        filter: false,
        show: true,
        min_width: 15
    },
]
export default LocationLookupFixture