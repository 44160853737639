import React from "react"
import styled, { createGlobalStyle } from "styled-components/macro"

import { CssBaseline } from "@material-ui/core"
const GlobalStyle = createGlobalStyle`
  html,
  body,
  #root {
    height: 100%;
    overflow-x: hidden;
  }

  body {
    background: #1565c0; 
  }
  
`

const Root = styled.div`
  max-width: 520px;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  display: flex;
  min-height: 100%;
`

const Presentation = ({ children }) => {
  return (
    <Root>
      <CssBaseline />
      <GlobalStyle />
      {children}
    </Root>
  )
}

export default Presentation
