import React from "react"
import {
  Card,
  Grid,
  Button,
  Typography,
  TextField,
  Switch,
  Chip,
  makeStyles,
} from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { useTranslation } from "react-i18next"

const useStyles = makeStyles((theme) => ({
  textInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
}))

const CustomTextField = (props) => {
  const classes = useStyles()
  const { isError = false, errorMessage = "is Required", ...restProps } =
    props || {}
  return (
    <Grid style={{ minHeight: 60 }}>
      <TextField {...restProps} className={classes.textInput} />
      {isError && (
        <Typography style={{ color: "red", fontSize: 12 }}>
          {(errorMessage)}
        </Typography>
      )}
    </Grid>
  )
}

function ChipKit(props) {
  const { t } = useTranslation()
  const {
    onUpdate = () => "",
    bgColor = "green",
    title = "Success Status Code",
    defaultCodeList = [],
    fieldName = "",
    defaultValue = "",
    fieldName1 = ""
  } = props || {}
  const [getCode, setCode] = React.useState({ taxConfig: "", priceConfig: "", promotionConfig: "", pmsConfig: "" })
  const [getCodeList, setCodeList] = React.useState({ taxConfig: [], priceConfig: [], promotionConfig: [], pmsConfig: [] })

  React.useEffect(() => {
    setCodeList({
      ...getCodeList,
      [fieldName1]: defaultCodeList
    })
  }, [defaultCodeList])
  let checkDuplicate = getCodeList?.[fieldName1]?.filter((itm) => itm?.code === getCode)

  let checks =
    getCode[fieldName1]?.length > 0 && getCode[fieldName1]?.length === 3 && !checkDuplicate.length > 0

  return (
    <Grid item xs={12} sm={6} md={6} lg={5}>
      <Typography variant="h5" style={{ marginBottom: 10 }}>
        <span style={{ color: "black", fontWeight: "bold" }}>{title}</span>
        <span style={{ color: "red" }}>{`*`}</span>
        <span style={{ color: "black", fontWeight: "bold" }}>{`:`}</span>
      </Typography>
      <Grid style={{ position: "relative", width: "100%" }}>
        <Button
          disabled={!checks}
          onClick={() => {
            let updateLit = {
              [fieldName1]: [
                // ...getCodeList,
                ...getCodeList[fieldName1],

                {
                  code: Number(getCode[fieldName1]),
                  selected: true,
                },
              ]
            }
            setCodeList(updateLit[fieldName1]?.filter((itm) => itm?.selected === true))

            setCode({ ...getCode, [fieldName1]: "" })
            onUpdate({
              list: updateLit[fieldName1]?.filter((itm) => itm?.selected === true),

            })
          }}
          style={{
            background: !checks ? "grey" : "#df86bb",
            color: "white",
            position: "absolute",
            zIndex: "9",
            right: "0",
            minWidth: 37,
            width: 37,
            height: 37,
            fontSize: 24,
          }}
        >
          +
        </Button>
        <CustomTextField
          type="number"
          inputProps={{
            min: "200",
            max: "999",
            maxLength: "3",
            pattern: "^[0-9]*$",
          }}
          className="cls-statuscode-ui-txt"
          name={fieldName}
          value={getCode[fieldName1]}
          style={{ width: "100%" }}
          variant="outlined"
          size="small"
          placeholder={t("Enter Code")}
          isError={
            checkDuplicate?.length > 0
          }
          errorMessage={
            checkDuplicate?.length > 0
              ? t("Status Code Already Present!")
              : t("is Required")
          }
          onChange={(event) => {
            var max_chars = 3
            if (event.target.value.length > max_chars) {
              event.target.value = event.target.value.substr(0, max_chars)
            } else {
              setCode({ ...getCode, [fieldName1]: event.target.value })
            }
          }}
        />
      </Grid>

      <Grid
        style={{
          width: "100%",
          height: 120,
          border: "1px solid #C7C7C7",
          padding: 10,
          borderRadius: 8,
        }}
      >
        {getCodeList?.[fieldName1]?.length > 0 ? (
          getCodeList?.[fieldName1]?.map((itemCode, index) => {
            const { selected = false } = itemCode || {}
            return selected ? (
              <Chip
                label={itemCode?.code}
                size="small"
                variant="default"
                color="primary"
                style={{
                  fontWeight: "bold",
                  background: bgColor || "green",
                  borderRadius: 5,
                  marginRight: 5,
                  marginBottom: 5,
                }}
                onDelete={(e) => {
                  let lcgetCodeList = JSON.parse(JSON.stringify(getCodeList[fieldName1]))
                  lcgetCodeList[index].selected = false
                  setCodeList(
                    lcgetCodeList.filter((itm) => itm?.selected === true)
                  )
                  onUpdate({
                    list: lcgetCodeList.filter((itm) => itm?.selected === true),
                  })
                }}
              />
            )
              : (
                <></>
              )
          })
        ) : (
          <>{t('Please Add the Status Code')}</>
        )}
      </Grid>
    </Grid >
  )
}

export { ChipKit }
export default ChipKit
