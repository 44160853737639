import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import Helmet from "react-helmet";
import styled from "styled-components/macro";
import {
  Checkbox, Grid, IconButton, Breadcrumbs as MuiBreadcrumbs, Divider as MuiDivider, Paper as MuiPaper, Table, TableBody, SvgIcon,
  Box, TableContainer, TableCell, TableHead, TablePagination, TableRow, Typography, Button, TableSortLabel, TextField, makeStyles
} from "@material-ui/core";
import _ from 'lodash';
import update from 'immutability-helper';
import { DoneIcon } from "@iconsGallery"

import { spacing } from "@material-ui/system";
// import { getProductList } from "../../../../reduxSagas/admin/actions";
import EnhancedTableHead from "./tableHead"
import EnhancedTableToolbar from "./tableToolBar"

const useStyles = makeStyles(theme => ({
  tablePagination: {
    padding: 0,
    borderBottom: 'none'
  }
}))

const Divider = styled(MuiDivider)(spacing);
const Breadcrumbs = styled(MuiBreadcrumbs)(spacing);
const Paper = styled(MuiPaper)(spacing);

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] === a[orderBy]) {
    return -1
  }
  if (b[orderBy] === a[orderBy]) {
    return 1;
  }
  return 0
}
function getComparator(a, b, order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}
function CustomTable(props) {
  const [productsDataMap, setProductsDataMap] = useState([]); //props === undefined ? [] : props.productData === undefined ? [] : props.productData.data === undefined ? [] : props.productData.data.products === undefined ? [] : props.productData.data.products.product_groups;
  const [itemID, setItemID] = React.useState("");
  const [barcode, setBarCode] = React.useState("");
  const [title, setTitle] = React.useState("");
  const [brand, setBrand] = React.useState("");
  const [manfacturer, setManfacturer] = React.useState("");
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState(() => (props?.list || []));
  const [page, setPage] = React.useState(() => {
    let temp = _.toNumber(_.get(props, 'data.page', 0));
    if (_.isFinite(temp) && temp > 0) {
      return temp - 1
    };
    return 0
  });
  const dispatch = useDispatch();
  const classes = useStyles()
  const [dense, setDense] = React.useState(true);
  const [rowsPerPage, setRowsPerPage] = React.useState(props?.data?.limit || 10);
  //   const history = useHistory();


  useEffect(() => {
    setProductsDataMap(props?.data?.product_groups || [])
  }, [props?.data?.product_groups])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property)
  };
  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = props?.data?.product_groups?.map((n) => n.group_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([])
  }

  const handleCheck = (id, title, subTitle) => {
    const exstIdx = _.findIndex(selected, { id });
    let tempSelectedList;
    if (exstIdx > -1) {
      tempSelectedList = update(selected, { $splice: [[exstIdx, 1]] })
    } else {
      tempSelectedList = update(selected, { $push: [{ id, title, subTitle, selected: true }] })
    }
    console.log('tempSelectedList from handleCheck === ', tempSelectedList);
    setSelected(tempSelectedList);
  }

  const handleAssociationClick = () => {
    let formattedList = { [props?.apiName || 'buy_product_group']: selected.map(o => ({ group_id: o.id, group_title: o.title, group_subtitle: o.subTitle })) }
    // props?.onUpdateList({ buy_product_group: [{ group_id: '678787', group_title: 'testing group ', group_subtitle: 'desc 3' }] })
    props?.onUpdateList(formattedList)

  }

  const handleChangePage = (e, newPage, rowLimit = rowsPerPage) => {
    console.log('body from handleChangePage -- ', newPage)
    setPage(newPage);
    props?.fetchDataMethod({ page: newPage + 1, limit: rowLimit })
  };
  const handleChangeRowsPerPage = (event) => {
    let temp = _.toNumber(event.target.value)
    setRowsPerPage(temp);
    setPage(0);
    handleChangePage({}, 0, temp)
  }
  const isSelected = (id) => _.findIndex(selected, { id }) > -1;//selected.indexOf(name) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, (props?.data?.product_groups?.length || 0) - page * rowsPerPage);

  return (
    <div style={{ height: '100%', width: '100%' }}>
      <EnhancedTableToolbar numSelected={selected?.length || 0} />
      <TableContainer style={{ maxHeight: `calc(100% - ${132}px)`, height: 'auto' }}>
        <Table stickyHeader aria-labelledby="tableTitle" size={dense ? "small" : "medium"} aria-label="enhanced table">
          <EnhancedTableHead numSelected={selected?.length || 0} order={order} orderBy={orderBy} onSelectAllClick={handleSelectAllClick} onRequestSort={handleRequestSort} rowCount={/* props?.data?.product_groups?.length || */ 0} />
          <TableBody>
            {
              props?.data?.product_groups?.map((row, index) => {
                console.log('Row from productsDataMap is --- ', row, index)
                const isItemSelected = isSelected(row?.group_id);
                // console.log('isItemSelected ------- ', row.item_id, row.title)
                const labelId = `enhanced-table-checkbox-${index}`;
                return (
                  <TableRow hover role="checkbox" aria-checked={isItemSelected} tabIndex={-1} key={row.group_id} selected={isItemSelected}>
                    <TableCell padding="checkbox"><Checkbox checked={isItemSelected} onChange={() => handleCheck(row?.group_id, row?.group_name, row?.description)} inputProps={{ "aria-labelledby": labelId }} /></TableCell>
                    <TableCell >{row.group_name}</TableCell>
                    <TableCell >{row.description}</TableCell>
                  </TableRow>
                );
              })
            }
          </TableBody>

        </Table>
      </TableContainer>
      {props?.data?.product_groups?.length === 0 ? <Typography style={{ padding: "10px", color: 'grey', textAlign: "center" }}>No Records Found</Typography> : null}

      <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', paddingRight: 16, borderTop: `1px solid #e0e0e0` }}>
        <TablePagination className={classes.tablePagination} align='left' rowsPerPageOptions={[5, 10, 25]} count={props?.data?.totalDocs || props?.data?.product_groups?.length}
          rowsPerPage={rowsPerPage} page={page} onChangePage={handleChangePage} onChangeRowsPerPage={handleChangeRowsPerPage} />
        <Button onClick={handleAssociationClick} style={{ backgroundColor: "#1565C0" }} startIcon={<DoneIcon style={{ color: "#ffffff" }} />} >
          <Typography style={{ color: "#ffffff" }}>Associate Product Groups</Typography>
        </Button>
      </div>
    </div>
  )
}
export default CustomTable;

