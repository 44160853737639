import { Radio } from "@material-ui/core"
import { useState } from "react"

function RadioButton(props) {
  let { onCheck = () => "", checkedValue = "", value = "" } = props || {}

  const checkAttr = {
    checked: value === checkedValue,
    color: "primary",
    value: value,
    name: "radio-buttons",
    inputProps: { "aria-label": "secondary checkbox" },
    style: { color: "var(--primaryColor)" },
    className: "cls-radio-btn-kit",
  }

  return (
    <div>
      <Radio {...checkAttr} />
    </div>
  )
}
export { RadioButton }
export default RadioButton
