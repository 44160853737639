import React from "react"
import * as Adm from "@adm"
import { Grid, Typography, TextField } from "@material-ui/core"
import * as Render from "./renderProps"
import { disableCursor } from "@fullcalendar/core"
import { useTranslation } from "react-i18next"

function AvalaraFormFields(props) {
    const { t } = useTranslation()
    const {
        stateConfigUIProps = {},
        crudType = "",
        getAvalaraDetails = () => { },
        type = "",
        currentTab = "",
    } = props || {}
    const { getTaxConfig = {} } = stateConfigUIProps
    let displayData = ""
    switch (currentTab) {
        case "tax":
            displayData =
                <>
                    {getTaxConfig?.taxconfigName === "customTax" ?
                        <Grid>
                            <Typography variant="h3" >{t('TAX CONFIG')}</Typography>
                            <br />
                            {type !== "avalaraconfig" &&
                                <Grid style={{ marginBottom: 10 }}>{Render.renderTaxConfigDP(stateConfigUIProps, t, getAvalaraDetails)}</Grid>
                            }
                            <Grid container style={{ display: "flex", marginBottom: 10 }} md={12} lg={12} spacing={2}>
                                {Render.renderAPIEndpointTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.apiUrl, fieldName1: "taxConfig", t })}
                                {Render.renderConnectionMethodDP({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.connectionMethod, fieldName1: "taxConfig", t })}
                            </Grid>
                            <Grid>
                                <Typography variant="h5" style={{ fontSize: 18, marginBottom: 10, fontWeight: "bold" }}>
                                    {t(`Authentication Details`)}
                                </Typography>
                                {stateConfigUIProps?.getTaxConfig?.taxConfig?.connectionMethod === "Basic Authentication" ? (
                                    <>
                                        <Grid container style={{ display: "flex", }} md={12} lg={12} spacing={2}>
                                            {Render.renderUserNameTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.userName, fieldName1: "taxConfig", t })}
                                            {Render.renderPasswordTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.password, fieldName1: "taxConfig", t })}
                                        </Grid>
                                        <Grid container style={{ display: "flex" }} md={12} lg={12} spacing={2}>
                                            {Render.renderResponseTypeDP({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.responseType, fieldName1: "taxConfig", t })}
                                            <Grid style={{ marginLeft: 30 }}>
                                            </Grid>
                                        </Grid>
                                    </>
                                ) : (
                                    <Grid container style={{ display: "flex", marginBottom: 10 }} md={12} lg={12} spacing={2}>
                                        {Render.renderTokenTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.token, fieldName1: "taxConfig", t })}
                                        {Render.renderResponseTypeDP({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.responseType, fieldName1: "taxConfig", t })}
                                    </Grid>
                                )}
                                <Grid container style={{ display: "flex" }} md={12} lg={12} spacing={2}>
                                    {Render.renderSuccessStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.statusCode, fieldName1: "taxConfig", t })}
                                    {Render.renderErrorStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.statusErrorCode, fieldName1: "taxConfig", t })}
                                </Grid>
                                <Grid style={{ display: "flex", marginTop: 30 }} md={12} lg={12}>
                                    {Render.renderSampleTestCode({ ...stateConfigUIProps, nameKey: getTaxConfig?.taxConfig?.sampleTestCode, fieldName1: "taxConfig", responseType: getTaxConfig?.taxConfig?.responseType, t })}
                                </Grid>
                            </Grid>
                        </Grid >
                        :
                        <Grid>
                            <Typography variant="h3" >{t('TAX CONFIG')}</Typography>
                            <br />
                            <br />
                            {type !== "avalaraconfig" &&
                                <Grid style={{ marginBottom: 10 }}>{Render.renderTaxConfigDP(stateConfigUIProps, t, getAvalaraDetails)}</Grid>}
                            <Grid>{Render.renderHeader({ ...stateConfigUIProps, crudType, t })}</Grid>
                            <Grid>{Render.renderAccountNumber({ ...stateConfigUIProps, crudType, t })}</Grid>
                            <Grid> {Render.renderLicenseKey({ ...stateConfigUIProps, crudType, t })}</Grid>
                            <Grid>{Render.renderEnvironment({ ...stateConfigUIProps, crudType, t })}</Grid>
                            <Grid>{Render.renderCompanyCode({ ...stateConfigUIProps, crudType, t })}</Grid>
                        </Grid>
                    }
                </>
            break
        case "":
            displayData = (
                <Grid container md={12} lg={12} spacing={2}>
                    {type !== "avalaraconfig" &&
                        <Grid style={{ marginBottom: 10 }}>{Render.renderTaxConfigDP(stateConfigUIProps, t, getAvalaraDetails)}</Grid>}
                    {Render.renderHeader({ ...stateConfigUIProps, crudType, t })}
                    {Render.renderAccountNumber({ ...stateConfigUIProps, crudType, t })}
                    {Render.renderLicenseKey({ ...stateConfigUIProps, crudType, t })}
                    {Render.renderEnvironment({ ...stateConfigUIProps, crudType, t })}
                    {Render.renderCompanyCode({ ...stateConfigUIProps, crudType, t })}
                </Grid>
            )

            break
        case "price":
            displayData = (
                <Grid>
                    <Typography variant="h3">{t('PRICE CONFIG')}</Typography>
                    <br />
                    <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                        {Render.renderAPIEndpointTF({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.priceConfig?.apiUrl,
                            fieldName1: "priceConfig",
                            t
                        })}
                        {Render.renderConnectionMethodDP({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.priceConfig?.connectionMethod,
                            fieldName1: "priceConfig",
                            t
                        })}
                    </Grid>
                    <Grid>
                        <Typography
                            variant="h5"
                            style={{ fontSize: 18, marginBottom: 10, fontWeight: "bold" }}
                        >
                            {t(`Authentication Details`)}
                        </Typography>
                        {stateConfigUIProps?.getTaxConfig?.priceConfig?.connectionMethod === "Basic Authentication" ? (
                            <>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                    {Render.renderUserNameTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.priceConfig?.userName,
                                        fieldName1: "priceConfig",
                                        t
                                    })}
                                    {Render.renderPasswordTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.priceConfig?.password,
                                        fieldName1: "priceConfig",
                                        t
                                    })}
                                </Grid>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                                    {Render.renderResponseTypeDP({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.priceConfig?.responseType,
                                        fieldName1: "priceConfig",
                                        t
                                    })}
                                </Grid>
                            </>
                        ) : (
                            <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                {Render.renderTokenTF({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.priceConfig?.token,
                                    fieldName1: "priceConfig",
                                    t
                                })}
                                {Render.renderResponseTypeDP({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.priceConfig?.responseType,
                                    fieldName1: "priceConfig",
                                    t
                                })}
                            </Grid>
                        )}
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                            {Render.renderSuccessStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.priceConfig?.statusCode, fieldName1: "priceConfig", t })}
                            {Render.renderErrorStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.priceConfig?.statusErrorCode, fieldName1: "priceConfig", t })}
                        </Grid>
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginTop: 30 }}>
                            {Render.renderSampleTestCode({ ...stateConfigUIProps, nameKey: getTaxConfig?.priceConfig?.sampleTestCode, fieldName1: "priceConfig", responseType: getTaxConfig?.priceConfig?.responseType, t })}
                        </Grid>
                    </Grid>
                </Grid >
            )
            break
        case "promotion":
            displayData = (
                <Grid>
                    <Typography variant="h3">{t('PROMOTION CONFIG')}</Typography>
                    <br />
                    <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                        {Render.renderAPIEndpointTF({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.promotionConfig?.apiUrl,
                            fieldName1: "promotionConfig",
                            t
                        })}
                        {Render.renderConnectionMethodDP({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.promotionConfig?.connectionMethod,
                            fieldName1: "promotionConfig",
                            t
                        })}
                    </Grid>
                    <Grid>
                        <Typography
                            variant="h5"
                            style={{ fontSize: 18, marginBottom: 10, fontWeight: "bold" }}
                        >
                            {t(`Authentication Details`)}
                        </Typography>
                        {stateConfigUIProps?.getTaxConfig?.promotionConfig?.connectionMethod === "Basic Authentication" ? (
                            <>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                    {Render.renderUserNameTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.promotionConfig?.userName,
                                        fieldName1: "promotionConfig",
                                        t
                                    })}
                                    {Render.renderPasswordTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.promotionConfig?.password,
                                        fieldName1: "promotionConfig",
                                        t
                                    })}
                                </Grid>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                                    {Render.renderResponseTypeDP({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.promotionConfig?.responseType,
                                        fieldName1: "promotionConfig",
                                        t
                                    })}
                                </Grid>
                            </>
                        ) : (
                            <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                {Render.renderTokenTF({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.promotionConfig?.token,
                                    fieldName1: "promotionConfig",
                                    t
                                })}
                                {Render.renderResponseTypeDP({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.promotionConfig?.responseType,
                                    fieldName1: "promotionConfig",
                                    t
                                })}
                            </Grid>
                        )}
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                            {Render.renderSuccessStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.promotionConfig?.statusCode, fieldName1: "promotionConfig", t })}
                            {Render.renderErrorStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.promotionConfig?.statusErrorCode, fieldName1: "promotionConfig", t })}
                        </Grid>
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginTop: 30 }}>
                            {Render.renderSampleTestCode({ ...stateConfigUIProps, nameKey: getTaxConfig?.promotionConfig?.sampleTestCode, fieldName1: "promotionConfig", responseType: getTaxConfig?.promotionConfig?.responseType, t })}
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case "pms":
            displayData = (
                <Grid>
                    <Typography variant="h3">{t('PMS CONFIG')}</Typography>
                    <br />
                    <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>

                        {Render.renderAPIEndpointTF({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.pmsConfig?.apiUrl,
                            fieldName1: "pmsConfig",
                            t
                        })}
                        {Render.renderConnectionMethodDP({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.pmsConfig?.connectionMethod,
                            fieldName1: "pmsConfig",
                            t
                        })}
                    </Grid>
                    <Grid>
                        <Typography
                            variant="h5"
                            style={{ fontSize: 18, marginBottom: 10, fontWeight: "bold" }}
                        >
                            {t(`Authentication Details`)}
                        </Typography>
                        {stateConfigUIProps?.getTaxConfig?.pmsConfig?.connectionMethod === "Basic Authentication" ? (
                            <>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                    {Render.renderUserNameTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.pmsConfig?.userName,
                                        fieldName1: "pmsConfig",
                                        t
                                    })}
                                    {Render.renderPasswordTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.pmsConfig?.password,
                                        fieldName1: "pmsConfig",
                                        t
                                    })}
                                </Grid>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                                    {Render.renderResponseTypeDP({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.pmsConfig?.responseType,
                                        fieldName1: "pmsConfig",
                                        t
                                    })}
                                </Grid>
                            </>
                        ) : (
                            <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                {Render.renderTokenTF({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.pmsConfig?.token,
                                    fieldName1: "pmsConfig",
                                    t
                                })}
                                {Render.renderResponseTypeDP({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.pmsConfig?.responseType,
                                    fieldName1: "pmsConfig",
                                    t
                                })}
                            </Grid>
                        )}
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                            {Render.renderSuccessStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.pmsConfig?.statusCode, fieldName1: "pmsConfig", t })}
                            {Render.renderErrorStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.pmsConfig?.statusErrorCode, fieldName1: "pmsConfig", t })}
                        </Grid>
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginTop: 30 }}>
                            {Render.renderSampleTestCode({ ...stateConfigUIProps, nameKey: getTaxConfig?.pmsConfig?.sampleTestCode, fieldName1: "pmsConfig", responseType: getTaxConfig?.pmsConfig?.responseType, t })}
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        case "pms":
            displayData = (
                <Grid>
                    <Typography variant="h3">PMS CONFIG</Typography>
                    <br />
                    <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>

                        {Render.renderAPIEndpointTF({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.pmsConfig?.apiUrl,
                            fieldName1: "pmsConfig",
                        })}
                        {Render.renderConnectionMethodDP({
                            ...stateConfigUIProps,
                            nameKey: getTaxConfig?.pmsConfig?.connectionMethod,
                            fieldName1: "pmsConfig",
                        })}
                    </Grid>
                    <Grid>
                        <Typography
                            variant="h5"
                            style={{ fontSize: 18, marginBottom: 10, fontWeight: "bold" }}
                        >
                            {`Authentication Details`}
                        </Typography>
                        {stateConfigUIProps?.getTaxConfig?.pmsConfig?.connectionMethod === "Basic Authentication" ? (
                            <>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                    {Render.renderUserNameTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.pmsConfig?.userName,
                                        fieldName1: "pmsConfig",
                                    })}
                                    {Render.renderPasswordTF({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.pmsConfig?.password,
                                        fieldName1: "pmsConfig",
                                    })}
                                </Grid>
                                <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                                    {Render.renderResponseTypeDP({
                                        ...stateConfigUIProps,
                                        nameKey: getTaxConfig?.pmsConfig?.responseType,
                                        fieldName1: "pmsConfig",
                                    })}
                                </Grid>
                            </>
                        ) : (
                            <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                                {Render.renderTokenTF({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.pmsConfig?.token,
                                    fieldName1: "pmsConfig",
                                })}
                                {Render.renderResponseTypeDP({
                                    ...stateConfigUIProps,
                                    nameKey: getTaxConfig?.pmsConfig?.responseType,
                                    fieldName1: "pmsConfig",
                                })}
                            </Grid>
                        )}
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex" }}>
                            {Render.renderSuccessStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.pmsConfig?.statusCode, fieldName1: "pmsConfig" })}
                            {Render.renderErrorStatusCodeTF({ ...stateConfigUIProps, nameKey: getTaxConfig?.pmsConfig?.statusErrorCode, fieldName1: "pmsConfig" })}
                        </Grid>
                        <Grid container md={12} lg={12} spacing={2} style={{ display: "flex", marginTop: 30 }}>
                            {Render.renderSampleTestCode({ ...stateConfigUIProps, nameKey: getTaxConfig?.pmsConfig?.sampleTestCode, fieldName1: "pmsConfig", responseType: getTaxConfig?.pmsConfig?.responseType })}
                        </Grid>
                    </Grid>
                </Grid>
            )
            break
        default:
            break
    }
    return <>{displayData}</>
}

export { AvalaraFormFields }
export default AvalaraFormFields
