import React, { useState } from "react"
import { Grid, IconButton, Tooltip, Typography } from "@material-ui/core"
import SyncIcon from "@material-ui/icons/Sync"
import moment from "moment"
import { useTranslation } from "react-i18next"

const RenderSynced = ({
  lastSyncedOn = moment().format("MMM DD, YYYY hh:mm:ss A"),
  handleRefresh,
}) => {
  const { t } = useTranslation()
  const [syncedOn, setSyncedOn] = useState(lastSyncedOn)

  const handleOnclick = () => {
    setSyncedOn(moment().format("MMM DD, YYYY hh:mm:ss A"))
    handleRefresh()
  }

  return (
    <Grid
      style={{
        right: "0px",
        display: "flex",
        textAlign: "right",
        marginRight: "1%",
        float: "right",
      }}
    >
      <Grid className="cls-syncheader">
        <Typography variant="div" style={{ fontWeight: 400 }}>
          {t('Last Synced')}
        </Typography>
        <div>{syncedOn}</div>
      </Grid>
      <Tooltip title={t("Refresh")} placement="top">
        <IconButton style={{ alignSelf: "center" }} onClick={handleOnclick}>
          <SyncIcon />
        </IconButton>
      </Tooltip>
    </Grid>
  )
}

export default RenderSynced
