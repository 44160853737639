import * as React from "react"
import { UploadIcon, DownloadIcon } from "@iconsGallery"
import {
  Checkbox,
  FormControlLabel,
  Button,
  Radio,
  RadioGroup,
  TextField as MuiTextField,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  useMediaQuery,
} from "@material-ui/core"
import { useDropzone } from "react-dropzone"
import { PortButton, DragDropwrapper } from "./styles"
import { renderOverlay } from "./renderProps"
import { useTranslation } from "react-i18next"

const ImportButtonUI = (props) => {
  const { t } = useTranslation()
  const {
    isDialogOpen = false,
    setDialogOpen = () => { },
    label = "Import",
    onUpdate = () => { },
    onSubmit = () => { },
    setImportFileData = () => { },
    fileData = "",
    setFileData = () => { },
    setWorkbookDetails = () => { },
    setLoading = () => { },
    fileTypeList = [],
    setAlertType = () => { },
    setAlertMessage = () => { },
    setAlert = () => { },
    fileSize = "",
    setFileSize = () => { },
    downloadTemplateClick = () => { },
    disabled = false,
  } = props || {}
  const isMobile = useMediaQuery('(max-width: 600px)');
  const [importData, setImportData] = React.useState({})

  React.useEffect(() => {
    onUpdate(importData)
  }, [importData])

  const portAttr = {
    startIcon: <DownloadIcon style={{ width: "16px", paddingLeft: "2px" }} />,
    variant: "outlined",
    onClick: () => setDialogOpen(true),
    style: {
      width: "100%", padding: "5px 10px", marginBottom: "0px", maxWidth: "unset", color: "#9A5F85",
      borderColor: "#9A5F85"
    },
    disabled: disabled
  }

  const stateProps = {
    isDialogOpen,
    setDialogOpen,
    fileData,
    setFileData,
    isMobile,
    importData,
    setImportData,
    setWorkbookDetails,
    setLoading,
    fileTypeList,
    setAlertType,
    setAlertMessage,
    setAlert,
    fileSize,
    setFileSize,
    downloadTemplateClick,
    onSubmit,
    t
  }

  return (
    <>
      <PortButton {...portAttr}>{label}</PortButton>
      {renderOverlay(stateProps)}
    </>
  )
}

export { ImportButtonUI }
export default ImportButtonUI
