import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing } from "@material-ui/system"
import styled from "styled-components/macro"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
  Grid,
} from "@material-ui/core"

export const Typography = styled(MuiTypography)(spacing)

export const ColoredLine = ({ color }) => (
  <hr
    style={{
      borderTop: `1px solid ${color}`,
      marginBottom: 10,
    }}
  />
)

export const useStyles = makeStyles((theme) => ({
  cardOuter: {
    width: "100%",
  },
  cardOuter_wrapper: {
    transition: "all 400ms ease",
    "&:hover": {
      transform: "scale(1.05)",
    },
    "&:active": {
      transform: "scale(1.01)",
    },
  },
  cls_sub_container: {
    padding: "16px 16px 10px 16px",
    margin: "5px 5px",
    cursor: "pointer",
    boxShadow: "2px 0px 8px 4px #eeeeee",
    width: "100%",
    height: "100%",
  },
  tenant_list: {
    padding: "5px",
    lineHeight: 1,
    height: "auto",
    borderRadius: "6px",
    border: "1px solid #c6c6c6",
  },
  sub_wrapper: {
    display: "flex",
    flexDirection: "row",
  },
  logo_wrapper: {
    display: "flex",
    minWidth: 68,
    minHeight: 68,
    maxWidth: 68,
    maxHeight: 68,
    border: "1px solid #FFF",
    borderRadius: 4,
    overflow: "hidden",
    textAlign: "center",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 30,
    textTransform: "uppercase",
  },

  logo_image_wrapper: {
    maxWidth: 68,
    maxHeight: 68,
    minWidth: 68,
    minHeight: 68,
    border: "1px solid #E0E0E0",
    borderRadius: 4,
    overflow: "hidden",
  },
  tenant_list_logo_image_wrapper: {
    maxWidth: 60,
    maxHeight: 60,
    minWidth: 60,
    minHeight: 60,
    lineHeight: 0,
    border: "unset",
  },
  active_list_wrapper: {
    color: "#FFF",
  },
  list_sub_wrapper: {
    marginBottom: "10px",
  },
  inactive: {
    width: "15px",
    height: "15px",
    background: "#ff0000",
    borderRadius: "10px",
    margin: "2px 5px",
  },
  active: {
    width: "15px",
    height: "15px",
    background: "#32d342",
    borderRadius: "10px",
    margin: "2px 5px",
  },
  activeTenant: {
    background: "#D38BB9",
  },
}))

export const StyledMainWrapperCardView = styled(Grid)``
