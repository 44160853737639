import React from 'react'
import {StepperButton} from './styles'

export const defaultStepperConfig =  [
    { text: "Select campaign settings", id: 0 },
    { text: "Create an ad group", id: 1 },
    { text: "Create an ad", id: 2 },
  ]

export const defaultRenderStepContent = (step) => {
  switch (step) {
    case 0:
      return <StepperButton variant="contained">hi</StepperButton>
    case 1:
      return "What is an ad group anyways?"
    case 2:
      return "This is the bit I really care about!"
    default:
      return "Unknown step"
  }
}