import { Grid, Box, Tabs, Tab, Typography, Button } from "@material-ui/core"
import Styled from "styled-components"
export const StyledTabUI = Styled(Grid)`
    margin-top: 10px;
    position: relative;
     
    
    .cls-main-tab {
        .cls-styled-tab-ui-button {
            cursor:pointer;
        }
        .cls-ui-tab-control {
            border-bottom: 2px solid #df86bb;
        }
        .cls-styled-tab-ui-button {
            z-index: 9;
            min-width: fit-content;
            
            @media (min-width: 1201px) and (max-width: 1400px){
                min-width: fit-content;

            }

            @media (min-width: 1000px) and (max-width: 1200px) {
                min-width: fit-content;
                
                .cls-tab-ui-label, .cls-tab-ui-number {
                    font-size: 12px;
                    padding: 1px 3px;
                    min-width: 20px;
                }
            }

            @media (min-width: 350px) and (max-width: 851px) {
                min-width: 50px;
                .cls-tab-ui-label, .cls-tab-ui-number {
                   display:none;
                }
            }

            span {
                justify-content: start;
            }

            .cls-tab-ui {
                width: 100%;
                justify-content: space-between;
            }
        }
        .cls-styled-tab-ui-button {
            opacity: 1;
            span {
                color: #df86bb;
                font-size: 16px;
            }
            svg {
                fill: #df86bb;
            }
            .cls-tab-ui-number {
                margin-left: 10px;
                font-size: 12px;
            }
        }
        .cls-styled-tab-ui-button.Mui-selected {
            background: #df86bb;
        }
        .Mui-selected, .active {
            span {
                color: #fff;
            }
            svg {
                fill: #fff;
            }
            .cls-tab-ui-number {
                font-size: 12px;
                background: #fff;
                color: #1565C0;
            }
        }

        .MuiTabs-indicator {
            height: 100%;
            background-color: #df86bb;
        }
    }

     @media (min-width: 1000px) and (max-width: 1200px) {
        .cls-StyledSyncedButton-text{
                display:none;
        }
      }
      @media (min-width: 350px) and (max-width: 851px) {
        .cls-StyledSyncedButton-text{
                display:none;
        }
      }
`
export const StyledTabNumber = Styled(Grid)`
    background: #4CAF50;
    border-radius: 19px;
    padding: 1px 5px;
    min-width: 30px;
    color: #fff;
`
export const StyledTabChild = Styled(Grid)`
    position: absolute;
    right:0;
    display: flex;
    text-align: right;

    .cls-last-txt {
        color: #737374;
        font-size: 12px;
    }
    .cls-last-time {
        font-size: 12px;
        color: #000;
    }
    @media (min-width: 350px) and (max-width: 851px) {
        .cls-last-txt {
            color: #737374;
            font-size: 9px;
        }
        .cls-last-time {
            font-size: 8px;
            color: #000;
        }
     }
`
export const StyledSyncedButton = Styled(Button)`
    margin-left: 10px;
    background: #4CAF50;
    min-width: 118px;
    height: 36px;
    color: #fff;
    font-weight: bold;

     @media (min-width: 1000px) and (max-width: 1200px) {
        min-width: 50px;
     }

     @media (min-width: 350px) and (max-width: 851px) {
        min-width: 30px;
     }


    @keyframes spin { 
        100% { 
            transform:rotate(-360deg); 
        } 
    }

    &.cls-syncing {
        svg {
              animation:spin 4s linear infinite;
        }
    }
     &:active {
        color: #fff;
        background: #53be57;
    }
    &:hover {
        color: #fff;
        background: #53be57;
    }
    svg {
        margin-right: 8px;
    }
`
