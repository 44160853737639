import {
  ListItemText as MuiListItemText,
  ListItem as MuiListItem,
} from "@material-ui/core";

import styled from "styled-components/macro";

export const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(1) / 4}px
    ${(props) => props.theme.spacing(4)}px;
  background: ${(props) => props.theme.footer.background};
  position: relative;
`;

export const ListItem = styled(MuiListItem)`
  display: inline-block;
  width: auto;
  padding-left: ${(props) => props.theme.spacing(2)}px;
  padding-right: ${(props) => props.theme.spacing(2)}px;
  &,
  &:hover,
  &:active {
    color: #ff0000;
  }
`;

export const ListItemText = styled(MuiListItemText)`
  span {
    color: black;
  }
`;

export const CopyrightText = styled(MuiListItemText)`
  span {
    color: ${(props) => props.theme.footer.color};
  }
`;
