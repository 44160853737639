import React, { useState } from "react"
import * as FIXTURE from "./fixture"
import _ from "lodash"
import { useTranslation } from "react-i18next"
const moment = require("moment")

function useOperationHoursKit(props) {
  const {
    maxTimeLimit = 3,
    defaultList,
    onUpdate = () => "",
    edit = false,
    errors = {},
    touched = {},
    showCheckbox = false,
    dependencyList,
    cascadingList = [],
  } = props

  const [initStartTime, setStartTime] = React.useState(
    "1990-01-01T08:00:00.000Z"
  )
  const [initEndTime, setEndTime] = React.useState("1990-01-01T10:00:00.000Z")

  const AllDayStart = "1990-01-01T00:00:00.000Z"
  const AllDayEnd = "1990-01-01T23:59:00.000Z"

  const [getOperationHoursList, setOperationHoursList] = useState([])
  const { t } = useTranslation()

  React.useEffect(() => {
    setOperationHoursList(defaultList)
  }, [defaultList])

  /**
   *
   * @param Handle_Time_Change
   */
  const handleTimeChange = (eProps) => {
    let lcGetOperationHoursList = JSON.parse(
      JSON.stringify(getOperationHoursList)
    )
    const { e, type = "", dayName, timeIndex, weekIndex, dayIndex } = eProps
    const currentTime = moment(e).format("HH:mm")
    lcGetOperationHoursList[weekIndex][dayIndex]["times"][timeIndex][
      type
    ] = `1990-01-01T${currentTime}:00.000Z`
    let updateTimeObj =
      lcGetOperationHoursList[weekIndex][dayIndex]["times"][timeIndex]
    let timeArr = lcGetOperationHoursList[weekIndex][dayIndex]["times"]
    let isUpdateTime = true
    let changeErrorMessage = ""

    if (!updateTimeObj.time_from || !updateTimeObj.time_to) {
      changeErrorMessage = "Required Field"
    }

    let cusEndTime = updateTimeObj.time_to
      ? moment(updateTimeObj.time_to).unix()
      : 0
    let cusStartTime = updateTimeObj.time_from
      ? moment(updateTimeObj.time_from).unix()
      : 0

    let arrTimeData = dependencyList?.length
      ? timeArr
      : _.filter(timeArr, function (val, index) {
          return index !== timeIndex
        })
    if (cusStartTime > cusEndTime || cusStartTime === cusEndTime) {
      touched.days = false
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
        "errFrom"
      ] = t("Time Mismatch")
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
        "errTo"
      ] = t("Time Mismatch")
    } else {
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
        "errFrom"
      ] = ""
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
        "errTo"
      ] = ""

      if (timeArr.length > 1 || dependencyList?.length) {
        _.forEach(arrTimeData, function (item, index) {
          let startTime = moment(item.time_from).unix()
          let endTime = moment(item.time_to).unix()

          if (timeIndex !== index) {
            if (
              (startTime >= cusStartTime && endTime <= cusEndTime) ||
              (startTime <= cusStartTime && endTime >= cusEndTime)
            ) {
              changeErrorMessage = t("Time already set")
              isUpdateTime = false
            } else if (
              (startTime >= cusStartTime && startTime <= cusEndTime) ||
              (endTime >= cusStartTime && endTime <= cusEndTime)
            ) {
              changeErrorMessage = ""
              isUpdateTime = false
            }
          }

          if (dependencyList?.length) {
            let lcGetDependencyHoursList = JSON.parse(
              JSON.stringify(dependencyList)
            )

            let dependentTimes =
              lcGetDependencyHoursList[weekIndex][dayIndex]["times"]

            if (
              dependentTimes?.length &&
              isUpdateTime &&
              !lcGetDependencyHoursList?.[weekIndex]?.[dayIndex]?.allDaySelected
            ) {
              let isSuccess = false
              _.forEach(dependentTimes, function (item) {
                let dstartTime = moment(item.time_from).unix()
                let dendTime = moment(item.time_to).unix()
                if (startTime >= dstartTime && endTime <= dendTime) {
                  isSuccess = true
                  return
                }
              })

              if (!isSuccess) {
                isUpdateTime = false
                changeErrorMessage = t("after hours")
              }
            } else if (
              !lcGetDependencyHoursList?.[weekIndex]?.[dayIndex]?.allDaySelected
            ) {
              isUpdateTime = false
              changeErrorMessage = t("after hours")
            }
          }
        })

        if (isUpdateTime) {
          touched.days = true
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
            "errFrom"
          ] = ""
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
            "errTo"
          ] = ""
        } else {
          touched.days = false
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
            "errFrom"
          ] = changeErrorMessage || t("Time Mismatch")
          lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"][timeIndex][
            "errTo"
          ] = changeErrorMessage || t("Time Mismatch")
          changeErrorMessage = ""
        }
      } else {
        touched.days = true
      }
    }
    setOperationHoursList([...lcGetOperationHoursList])
    let lc = [...lcGetOperationHoursList]
    onUpdate(lc)
  }

  /**
   *
   * @param Handle_Time_Delete
   */
  const handleTimeDelete = (eProps) => {
    touched.days = true
    const { e, type = "", dayName, timeIndex, weekIndex, dayIndex } = eProps
    let lcGetOperationHoursList = JSON.parse(
      JSON.stringify(getOperationHoursList)
    )
    const timeToDelete =
      lcGetOperationHoursList[weekIndex][dayIndex]["times"][timeIndex]

    let startTime = moment(timeToDelete.time_from).unix()
    let endTime = moment(timeToDelete.time_to).unix()

    let lcGetCascadingList = JSON.parse(JSON.stringify(cascadingList))

    if (cascadingList?.length) {
      let cascadingTimes = lcGetCascadingList[weekIndex][dayIndex]["times"]

      cascadingTimes.forEach((item, index) => {
        let dstartTime = moment(item.time_from).unix()
        let dendTime = moment(item.time_to).unix()

        if (startTime <= dstartTime && endTime >= dendTime) {
          lcGetCascadingList[weekIndex][dayIndex]["errorsArr"][index][
            "errFrom"
          ] = t("after hours")
          lcGetCascadingList[weekIndex][dayIndex]["errorsArr"][index][
            "errTo"
          ] = t("after hours")
        }
      })
    }

    lcGetOperationHoursList[weekIndex][dayIndex]["times"].splice(timeIndex, 1)
    lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].splice(
      timeIndex,
      1
    )
    if (lcGetOperationHoursList[weekIndex][dayIndex]["times"]?.length === 0) {
      lcGetOperationHoursList[weekIndex][dayIndex]["selected"] = false
    }
    setOperationHoursList([...lcGetOperationHoursList])

    let lc = [...lcGetOperationHoursList]
    onUpdate(lc, [...lcGetCascadingList])
  }

  /**
   *
   * @param Handle_Time_Add
   */
  const handleTimeAdd = (eProps) => {
    const { e, type = "", dayName, timeIndex, weekIndex, dayIndex } = eProps
    let lcGetOperationHoursList = JSON.parse(
      JSON.stringify(getOperationHoursList)
    )
    lcGetOperationHoursList[weekIndex][dayIndex]["times"].push({
      time_from: undefined,
      time_to: undefined,
    })

    lcGetOperationHoursList[weekIndex][dayIndex]["selected"] = true
    let timesData = lcGetOperationHoursList[weekIndex][dayIndex]["times"]
    if (timesData.length > 0) {
      let changeErrorMessage = ""
      let isCreateTime = true
      _.forEach(timesData, function (item, index) {
        if (!item.time_to || !item.time_from) {
          isCreateTime = false
          changeErrorMessage = "Required field"
        } else {
          let startTime = moment(item.time_from).unix()
          let endTime = moment(item.time_to).unix()
          let cusStartTime = moment(initStartTime).unix()
          let cusEndTime = moment(initEndTime).unix()
          if (
            timesData.length - 1 !== index &&
            ((startTime >= cusStartTime && startTime <= cusEndTime) ||
              (endTime >= cusStartTime && endTime <= cusEndTime) ||
              (endTime <= cusStartTime && endTime >= cusEndTime))
          ) {
            isCreateTime = false
          } else if (
            index !== 0 &&
            ((startTime >= cusStartTime && endTime <= cusEndTime) ||
              (startTime <= cusStartTime && endTime >= cusEndTime))
          ) {
            isCreateTime = false
            changeErrorMessage = "Time already set"
          }

          if (dependencyList?.length) {
            let lcGetDependencyHoursList = JSON.parse(
              JSON.stringify(dependencyList)
            )

            let dependentTimes =
              lcGetDependencyHoursList[weekIndex][dayIndex]["times"]

            if (
              dependentTimes?.length &&
              !lcGetDependencyHoursList?.[weekIndex]?.[dayIndex]?.allDaySelected
            ) {
              let isSuccess = false
              _.forEach(dependentTimes, function (item) {
                if (item) {
                  let dstartTime = moment(item.time_from).unix()
                  let dendTime = moment(item.time_to).unix()
                  if (startTime >= dstartTime && endTime <= dendTime) {
                    isSuccess = true
                  }
                }
              })

              if (!isSuccess) {
                isCreateTime = false
                changeErrorMessage = t("after hours")
              }
            } else if (
              !lcGetDependencyHoursList?.[weekIndex]?.[dayIndex]?.allDaySelected
            ) {
              isCreateTime = false
              changeErrorMessage = t("after hours")
            }
          }
        }
      })
      if (isCreateTime) {
        lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].push({
          errFrom: "",
          errTo: "",
        })
        touched.days = true
      } else {
        lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].push({
          errFrom: changeErrorMessage || t("Time Mismatch"),
          errTo: changeErrorMessage || t("Time Mismatch"),
        })
      }
    } else {
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"].push({
        errFrom: "",
        errTo: "",
      })
      touched.days = true
    }
    setOperationHoursList([...lcGetOperationHoursList])
    let lc = [...lcGetOperationHoursList]
    onUpdate(lc)
  }

  /**
   *
   * @param Handle_All_Day
   */
  const handleTimeAllDay = (eProps) => {
    const {
      e,
      type = "",
      dayName,
      timeIndex,
      weekIndex = -1,
      dayIndex = -1,
    } = eProps
    if (weekIndex === -1 || dayIndex === -1) {
      return
    }
    let lcGetOperationHoursList = JSON.parse(
      JSON.stringify(getOperationHoursList)
    )
    let lcGetCascadingList = JSON.parse(JSON.stringify(cascadingList))
    if (e.target.checked) {
      lcGetOperationHoursList[weekIndex][dayIndex]["selected"] = true
      let timesData = lcGetOperationHoursList[weekIndex][dayIndex]["times"]
      lcGetOperationHoursList[weekIndex][dayIndex].allDaySelected =
        e.target.checked
      lcGetOperationHoursList[weekIndex][dayIndex]["errorsArr"] = []
      touched.days = true
      lcGetOperationHoursList[weekIndex][dayIndex]["times"] = []
      if (
        cascadingList?.length &&
        !lcGetCascadingList[weekIndex][dayIndex].allDaySelected
      ) {
        let cascadingTimes = lcGetCascadingList[weekIndex][dayIndex]["times"]
        cascadingTimes.forEach((item, index) => {
          lcGetCascadingList[weekIndex][dayIndex]["errorsArr"][index][
            "errFrom"
          ] = ""
          lcGetCascadingList[weekIndex][dayIndex]["errorsArr"][index]["errTo"] =
            ""
        })
      }
    } else {
      lcGetOperationHoursList[weekIndex][dayIndex].allDaySelected =
        e.target.checked
      let isAllDaySelected = false
      if (
        lcGetCascadingList &&
        lcGetCascadingList.length > 0 &&
        weekIndex !== -1 &&
        dayIndex !== -1
      ) {
        isAllDaySelected =
          lcGetCascadingList[weekIndex][dayIndex]["allDaySelected"] || false
      }
      if (cascadingList?.length && !isAllDaySelected) {
        let cascadingTimes =
          lcGetCascadingList[weekIndex][dayIndex]["times"] || []
        cascadingTimes.forEach((item, index) => {
          lcGetCascadingList[weekIndex][dayIndex]["errorsArr"][index][
            "errFrom"
          ] = t("after hours")
          lcGetCascadingList[weekIndex][dayIndex]["errorsArr"][index][
            "errTo"
          ] = t("after hours")
        })
      }
      if (
        lcGetCascadingList &&
        lcGetCascadingList.length > 0 &&
        weekIndex !== -1 &&
        dayIndex !== -1
      ) {
        lcGetCascadingList[weekIndex][dayIndex].allDaySelected =
          e.target.checked
      }
    }
    setOperationHoursList([...lcGetOperationHoursList])
    let lc = [...lcGetOperationHoursList]
    onUpdate(lc, [...lcGetCascadingList])
  }

  return {
    getOperationHoursList,
    setOperationHoursList,
    handleTimeChange,
    handleTimeDelete,
    handleTimeAdd,
    maxTimeLimit,
    edit,
    errors,
    showCheckbox,
    handleTimeAllDay,
    t,
  }
}

export { useOperationHoursKit }
export default useOperationHoursKit
