import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React, { Suspense } from "react"
import ReactDOM from "react-dom"
import App from "./App"
import "./mocks"
import { Provider } from "react-redux"
import store from "./redux/store/index"
import HttpService from "../src/services/HttpService"
import UserService from "../src/services/UserService"
import Preload from "./Preload"
const renderApp = (props = "test") => {
  if (
    (props === "static1" && localStorage.getItem("reload") === null) ||
    (props === "static2" && localStorage.getItem("reload") === null)
  ) {
    localStorage.setItem("reload", "true")
    window?.location.reload()
  }
  return ReactDOM.render(<Preload />, document.getElementById("root"))
}

UserService.initKeycloak(renderApp)
HttpService.configure()
