import React, { useEffect, useState } from "react"
import { Avatar, Card, Grid, Typography } from "@material-ui/core"
import { CustomerDetailInfo } from "./CustomerDetailInfo"
import { useStaticState } from "@material-ui/pickers"
import * as Features from "@features"
import styled from "styled-components"
import * as StyledDOM from "./styles"
import moment from "moment"

function CustomerSummaryHeaderCard(props) {
  const { isWriteAllowed } = props;
  let { data, updateData, resetData, genderList, ethnicityList, socialProfileList, setAllowPrompt = () => { } } = props || []
  const classesUI = StyledDOM.useCustomerSummaryHeaderCardStyles()
  const [formData, setFormData] = useState(data)
  const [isEdit, setIsEdit] = useState(false)
  const [isSaveFlag, setSaveFlag] = useState(false)

  useEffect(() => {
    setFormData(data)
  }, [props])

  const updateFormData = async (identifier, value) => {
    const obj = formData
    if (identifier === "preferred_contact[0].phone") {
      obj.preferred_contact[0].phone = value
    } else if (identifier === "preferred_contact[0].email") {
      obj.preferred_contact[0].email = value
    } else {
      obj[identifier] = value
    }
    await setFormData(obj)
  }

  const updateDataInternally = (values) => {
    const {
      title = "Mr.",
      gender = "Male",
      profile_name = "",
      social_network = "",
      ethnicity = "",
      preferred_contact = [],
      associate_flag = false,
    } = formData || {}
    const [FirstIndex = {}] = preferred_contact || []
    const { phone = false, email = false } = FirstIndex || {}
    const body = {
      title: formData.title || "",
      firstName: formData.firstName || "",
      middleInitial: formData.middleInitial ? formData.middleInitial : "",
      lastName: formData.lastName || "",
      associate_flag: formData.associate_flag || false,
      gender: formData.gender || {},
      phoneNumber: {
        countryCode: values.countryCode,
        number: values.phoneNumber.slice(values.countryCode.length).length == 0 ? '' :
          values.phoneNumber.slice(values.countryCode.length)
      } || { countryCode: '', number: '' },
      date_of_birth: moment(new Date(formData.date_of_birth)).format("MMM DD, YYYY"),
      userID: formData.userID || "",
      ethnicity: formData.ethnicity || {},
      preferred_contact: [
        {
          phone: formData.preferred_contact[0].phone || false,
          email: formData.preferred_contact[0].email || false,
        },
      ],
      social_network: formData.social_network || "",
      profile_name: formData.profile_name || "",
    }
    updateData(body)
  }
  const stateProps = {
    resetData,
    setIsEdit,
    data,
    isSaveFlag,
    setSaveFlag,
    isEdit,
    updateFormData,
    updateDataInternally,
    classesUI,
    socialProfileList,
    ethnicityList,
    genderList,
    isWriteAllowed,
    ...props,
  }
  return (
    <StyledDOM.CardWrapper>
      <CustomerDetailInfo {...stateProps} setAllowPrompt={setAllowPrompt} />
    </StyledDOM.CardWrapper>
  )
}
export { CustomerSummaryHeaderCard }
export default CustomerSummaryHeaderCard
