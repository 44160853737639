import * as ServiceAPI from "@services"
import * as Utils from "@utils"
import * as TransformModal from "./transformModal"
import _ from "lodash"

export const getAPIRetailerList = (props) => {
  const {
    identifer = "AS",
    setFetchAPIRetailerList,
    isActiveSyncLoad = false,
    setIsSyncLoad,
    setIsLoading,
    pageNumber = 0,
    rowsPerPage = 15,
    searchText = "",
  } = props || {}

  setIsLoading(true)
  if (isActiveSyncLoad) {
    setIsSyncLoad(true)
  }

  let data = {
    END_POINTS: `/api/dashboardInfo/getTenantsInfo`,
    body: {
      limit: rowsPerPage,
      searchText: searchText,
      page: pageNumber,
    },
  }

  ServiceAPI.doPostFetchAPI(data)
    .then((response) => {
      const { data = {} } = response?.data
      const { docs = [], totalDocs = "" } = data || {}
      setFetchAPIRetailerList({
        docs: Utils.JSUtils.isEmpty(docs)
          ? []
          : TransformModal.retailerList(docs),
        total: totalDocs,
      })

      setIsLoading(false)
      if (isActiveSyncLoad) {
        setIsSyncLoad(false)
      }
    })
    .catch((error) => {
      console.log("error", error)
      setIsLoading(false)
      setFetchAPIRetailerList({ docs: [], total: "" })
      if (isActiveSyncLoad) {
        setIsSyncLoad(false)
      }
    })
}
