import {
  GET_SIGNIN_DATA,
  GET_SIGNUP_DATA,
  RECEIVE_SIGNIN_DATA,
  GET_PRODUCT_LIST,
  RECEIVE_PRODUCT_LIST,
  GET_CREATE_PRODUCT,
  RECEIVE_CREATE_PRODUCT,
  GET_BARCODE_TYPE,
  RECEIVE_BARCODE_TYPE,
  GET_STORE_TYPE,
  RECEIVE_STORE_TYPE,
  GET_UNIT_OF_MEASURE,
  RECEIVE_UNIT_OF_MEASURE,
  GET_PRODUCT_DETAILS,
  RECEIVE_PRODUCT_DETAILS,
  GET_EDIT_PRODUCT,
  RECEIVE_EDIT_PRODUCT,
  EDIT_PRODUCT_PARAMS,
  RECEIVE_PRODUCT_PARAMS,
  GET_DELETE_PRODUCT,
  GET_CATEGORY_LIST,
  RECEIVE_CATEGORY_LIST,
  GET_SUB_CATEGORY_LIST,
  RECEIVE_SUB_CATEGORY_LIST,
  GET_PRODUCT_GROUP,
  RECEIVE_PRODUCT_GROUP,
  GET_CREATE_PRODUCT_GROUP,
  PRODUCT_GROUP_EDIT_PARAMS,
  RECEIVE_PRODUCT_GROUP_EDIT_PARAMS,
  ASSOCIATE_PRODUCT_GROUP,
  GET_DELETE_PRODUCT_GROUP,
  GET_PRODUCT_GROUP_DETAILS,
  RECEIVE_PRODUCT_GROUP_DETAILS,
  RECEIVE_PRODUCT_DELETE_GROUP,
  GET_ORDERS_LIST,
  RECEIVE_ORDERS_LIST,
  GET_SEARCH_TERM,
  GET_ORDER_DETAILS,
  RECEIVE_ORDERS_DETAILS_DATA,
  GET_DATE_TIME,
  GET_ORDER_STATE_STATUS,
  GET_DELETE_MULTIPLE_PRODUCT,
  USER_ROLE_INFORMATION,
  USER_DECODE_INFORMATION,
  SET_USER_ROLE_INFORMATION,
  SET_USER_DECODE_DATA,
  USER_KEYCLOAK_ATTRIBUTES,
  USER_KEYCLOAK_BEFORE_MODIFY_ATTRIBUTES,
  GET_LOCATION_LIST,
  RECEIVE_LOCATION_LIST,
  GET_SELECTED_LOCATION,
  RECEIVE_SELECTED_LOCATION,
  GET_TURNSTILE_LIST,
  RECEIVE_TURNSTILE_LIST,
  GET_GONDOLA_LIST,
  RECEIVE_GONDOLA_LIST,
  SELECTED_GONDOLA,
  RECEIVE_GONDOLA_SELECTED,
  POST_GROUNDSPLOT_DATA,
  POST_CART_MANAGER,
  GET_CAMERA_LIST,
  RECEIVE_CAMERA_LIST,
  CAMERA_SELECTED,
  RECEIVE_CAMERA_SELECTED,
  POST_CAMERA_GPU,
  CAMERA_UPDATE,
  POST_STORE_DIMENSION,
  GET_GONDOLA_DETAILS,
  RECEIVE_GONDOLA_DETAILS,
  POST_LOCATION_SETUP,
  GET_LOCATION_SETUP,
  RECEIVE_LOCATION_SETUP,
  GET_STORE_DIMENSION_STEP,
  RECEIVE_STORE_DIMENSION_STEP,
  GET_GONDOLA_STEP,
  RECEIVE_GONDOLA_STEP,
  GET_CAMERA_AND_GPU_STEP,
  RECEIVE_CAMERA_AND_GPU_STEP,
  GET_CART_MANAGER_STEP,
  RECEIVE_CART_MANAGER_STEP,
  SET_SMART_DEVICE_CONFIG_LOC,
  GET_SELECTED_SDC_LOCATION,
  GET_SELECTED_TURNSTILE_LOCATION,
  SET_SMART_DEVICE_CONFIG_LOC_NAME,
  SET_CREATE_SDC_SUCCESS_TOGGLE,
  GET_INVENTORY_LOCATION,
  TRAINING_PRODUCTS_INFO,
} from "./constants"

export function selectedInventoryLocation(data) {
  return {
    type: GET_INVENTORY_LOCATION,
    data,
  }
}
export function getTrainingProductsInfo(data) {
  return {
    type: TRAINING_PRODUCTS_INFO,
    data,
  }
}
export function getSignInData(data) {
  return {
    type: GET_SIGNIN_DATA,
    data,
  }
}

export function receiveSignIndata(data) {
  return {
    type: RECEIVE_SIGNIN_DATA,
    data,
  }
}

export function signUpData(data) {
  return {
    type: GET_SIGNUP_DATA,
    data,
  }
}

// Keycloak Information
export function addRealmRoles(data) {
  return {
    type: USER_ROLE_INFORMATION,
    data,
  }
}
export function userDecodeDetials(data) {
  return {
    type: USER_DECODE_INFORMATION,
    data,
  }
}

export function userKeycloakAttributes(data) {
  return {
    type: USER_KEYCLOAK_ATTRIBUTES,
    data,
  }
}

export function userKeycloakBeforeModifyAttributes(data) {
  return {
    type: USER_KEYCLOAK_BEFORE_MODIFY_ATTRIBUTES,
    data,
  }
}

export function getProductList(data) {
  return {
    type: GET_PRODUCT_LIST,
    data,
  }
}

export function receiveProductList(data) {
  return {
    type: RECEIVE_PRODUCT_LIST,
    data,
  }
}

export function getCreateProduct(data, data2, data3) {
  return {
    type: GET_CREATE_PRODUCT,
    data,
    data2,
    data3,
  }
}

export function receiveCreateProduct(data) {
  return {
    type: RECEIVE_CREATE_PRODUCT,
    data,
  }
}

export function getBarCodeType(data) {
  return {
    type: GET_BARCODE_TYPE,
    data,
  }
}

export function receiveBarCodeType(data) {
  return {
    type: RECEIVE_BARCODE_TYPE,
    data,
  }
}

export function getStoreType(data) {
  return {
    type: GET_STORE_TYPE,
    data,
  }
}

export function receiveStoreType(data) {
  return {
    type: RECEIVE_STORE_TYPE,
    data,
  }
}

export function getUnitOfMeasure(data) {
  return {
    type: GET_UNIT_OF_MEASURE,
    data,
  }
}

export function receiveUnitOfMeasure(data) {
  return {
    type: RECEIVE_UNIT_OF_MEASURE,
    data,
  }
}

export function getProductDetails(data, data2) {
  return {
    type: GET_PRODUCT_DETAILS,
    data,
    data2,
  }
}

export function receiveProductDetails(data) {
  return {
    type: RECEIVE_PRODUCT_DETAILS,
    data,
  }
}

export function getEditProduct(data, data2, data3, data4, data5, data6, data7) {
  return {
    type: GET_EDIT_PRODUCT,
    data,
    data2,
    data3,
    data4,
    data5,
    data6,
    data7,
  }
}

export function receiveEditProduct(data) {
  return {
    type: RECEIVE_EDIT_PRODUCT,
    data,
  }
}

export function editProductParams(data, data2, data3) {
  return {
    type: EDIT_PRODUCT_PARAMS,
    data,
    data2,
    data3,
  }
}

export function receiveProductParams(data) {
  return {
    type: RECEIVE_PRODUCT_PARAMS,
    data,
  }
}

export function getDeleteProduct(data) {
  return {
    type: GET_DELETE_PRODUCT,
    data,
  }
}

export function getDeleteMultipleProduct(data) {
  return {
    type: GET_DELETE_MULTIPLE_PRODUCT,
    data,
  }
}

export function getCategoryList(data) {
  return {
    type: GET_CATEGORY_LIST,
    data,
  }
}

export function receiveCategoryList(data) {
  return {
    type: RECEIVE_CATEGORY_LIST,
    data,
  }
}

export function getSubCategoryList(data) {
  return {
    type: GET_SUB_CATEGORY_LIST,
    data,
  }
}

export function receiveSubCategoryList(data) {
  return {
    type: RECEIVE_SUB_CATEGORY_LIST,
    data,
  }
}

export function getProductGroup(data) {
  return {
    type: GET_PRODUCT_GROUP,
    data,
  }
}

export function receiveProductGroup(data) {
  return {
    type: RECEIVE_PRODUCT_GROUP,
    data,
  }
}

export function getCreateProductGroup(data) {
  return {
    type: GET_CREATE_PRODUCT_GROUP,
    data,
  }
}

export function productGroupEditParams(data, data2) {
  return {
    type: PRODUCT_GROUP_EDIT_PARAMS,
    data,
    data2,
  }
}

export function receiveProductGroupEditParams(data) {
  return {
    type: RECEIVE_PRODUCT_GROUP_EDIT_PARAMS,
    data,
  }
}

export function assoicateProductGroup(data) {
  return {
    type: ASSOCIATE_PRODUCT_GROUP,
    data,
  }
}

export function getDeleteProductGroup(data) {
  return {
    type: GET_DELETE_PRODUCT_GROUP,
    data,
  }
}
export function receiveProductGroupDelete(data) {
  return {
    type: RECEIVE_PRODUCT_DELETE_GROUP,
    data,
  }
}

export function getProductGroupDetails(data) {
  return {
    type: GET_PRODUCT_GROUP_DETAILS,
    data,
  }
}

export function receiveProductGroupDetails(data) {
  return {
    type: RECEIVE_PRODUCT_GROUP_DETAILS,
    data,
  }
}

export function getOrdersList() {
  return {
    type: GET_ORDERS_LIST,
  }
}

export function receiveOrdersList(data) {
  return {
    type: RECEIVE_ORDERS_LIST,
    data,
  }
}

export function getSearchTerm(data) {
  return {
    type: GET_SEARCH_TERM,
    data,
  }
}

export function orderDetailsData(data) {
  return {
    type: GET_ORDER_DETAILS,
    data,
  }
}

export function receiveOrderDetailsData(data) {
  return {
    type: RECEIVE_ORDERS_DETAILS_DATA,
    data,
  }
}

export function getDateAndTime(data) {
  return {
    type: GET_DATE_TIME,
    data,
  }
}

export function getOrderByStateAndStatus(data) {
  return {
    type: GET_ORDER_STATE_STATUS,
    data,
  }
}

export function getLocationList() {
  return {
    type: GET_LOCATION_LIST,
  }
}
export function setlocationOfSmartDeviceConfig(value, updateId) {
  return {
    type: SET_SMART_DEVICE_CONFIG_LOC,
    value,
    updateId,
  }
}
export function selectedSDCLocation(value, updateId) {
  return {
    type: GET_SELECTED_SDC_LOCATION,
    value,
    updateId,
  }
}

export function setlocationNameOfSmartDeviceConfig(value, updateId) {
  return {
    type: SET_SMART_DEVICE_CONFIG_LOC_NAME,
    value,
    updateId,
  }
}
export function setSmartDeviceApiSuccessToggle(value, updateId) {
  console.log("setSmartDeviceApiSuccessToggle", value, updateId)
  return {
    type: SET_CREATE_SDC_SUCCESS_TOGGLE,
    value,
    updateId,
  }
}
export function receiveLocationList(data) {
  return {
    type: RECEIVE_LOCATION_LIST,
    data,
  }
}

export function selectedLocation(data) {
  return {
    type: GET_SELECTED_LOCATION,
    data,
  }
}
export function receiveSelectedLocation(data) {
  return {
    type: RECEIVE_SELECTED_LOCATION,
    data,
  }
}
export function getTurnstileList() {
  return {
    type: GET_TURNSTILE_LIST,
  }
}
export function receiveTurnstileList(data) {
  return {
    type: RECEIVE_TURNSTILE_LIST,
    data,
  }
}

export function getGondolaList() {
  return {
    type: GET_GONDOLA_LIST,
  }
}
export function receiveGondolaList(data) {
  return {
    type: RECEIVE_GONDOLA_LIST,
    data,
  }
}

export function gondolaSelected(data) {
  return {
    type: SELECTED_GONDOLA,
    data,
  }
}
export function receiveGondolaSelected(data) {
  return {
    type: RECEIVE_GONDOLA_SELECTED,
    data,
  }
}

export function postGroundsplot(data, data2) {
  return {
    type: POST_GROUNDSPLOT_DATA,
    data,
    data2,
  }
}
export function postCartManager(data) {
  return {
    type: POST_CART_MANAGER,
    data,
  }
}

export function getCameraList() {
  return {
    type: GET_CAMERA_LIST,
  }
}
export function receiveCameraList(data) {
  return {
    type: RECEIVE_CAMERA_LIST,
    data,
  }
}

export function cameraSelected(data) {
  return {
    type: CAMERA_SELECTED,
    data,
  }
}
export function receiveCameraSelected(data) {
  return {
    type: RECEIVE_CAMERA_SELECTED,
    data,
  }
}

export function postCameraAndGpu(data, data2) {
  return {
    type: POST_CAMERA_GPU,
    data,
    data2,
  }
}

export function cameraUpdate(data) {
  return {
    type: CAMERA_UPDATE,
    data,
  }
}

export function postStoreDimension(data, data2, data3) {
  return {
    type: POST_STORE_DIMENSION,
    data,
    data2,
    data3,
  }
}

export function getGondolaDetails(data) {
  return {
    type: GET_GONDOLA_DETAILS,
    data,
  }
}
export function receiveGondolaDetails(data) {
  return {
    type: RECEIVE_GONDOLA_DETAILS,
    data,
  }
}

export function postLocationSetup(data, data2, data3) {
  return {
    type: POST_LOCATION_SETUP,
    data,
    data2,
    data3,
  }
}

export function getLocationSetup(data) {
  return {
    type: GET_LOCATION_SETUP,
    data,
  }
}
export function receiveLocationSetup(data) {
  return {
    type: RECEIVE_LOCATION_SETUP,
    data,
  }
}

export function getStoreDimensionStep(data) {
  return {
    type: GET_STORE_DIMENSION_STEP,
    data,
  }
}
export function receiveStoreDimensionStep(data) {
  return {
    type: RECEIVE_STORE_DIMENSION_STEP,
    data,
  }
}

export function getGondalaStep(data) {
  return {
    type: GET_GONDOLA_STEP,
    data,
  }
}
export function receiveGondolaStep(data) {
  return {
    type: RECEIVE_GONDOLA_STEP,
    data,
  }
}

export function getCameraAndGPUStep(data) {
  return {
    type: GET_CAMERA_AND_GPU_STEP,
    data,
  }
}
export function receiveCameraAndGPUStep(data) {
  return {
    type: RECEIVE_CAMERA_AND_GPU_STEP,
    data,
  }
}

export function getCartManagerStep(data) {
  return {
    type: GET_CART_MANAGER_STEP,
    data,
  }
}
export function receiveCartManagerStep(data) {
  return {
    type: RECEIVE_CART_MANAGER_STEP,
    data,
  }
}

export function setUserRoleInformation(data) {
  return {
    type: SET_USER_ROLE_INFORMATION,
    data,
  }
}

export function setUserDecodeInformation(data) {
  return {
    type: SET_USER_DECODE_DATA,
    data,
  }
}
