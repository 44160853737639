import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import _ from "lodash"
import { useTranslation } from "react-i18next"

function CustomRedirect({ history = {} }) {
  const { location = {} } = history
  const { t } = useTranslation()
  //console.log(location.pathname, t("/dashboard"), "custom")
  const onTriggerRerouting = () => {
    if (location?.pathname === "/") {
      history.push(t("/dashboard"))
    }
  }

  React.useEffect(() => {
    if (location.pathname === "/") {
      onTriggerRerouting()
    }
  }, [])

  return <></>
}

export default CustomRedirect
export { CustomRedirect }
