import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing } from "@material-ui/system"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
  Grid,
  TextField,
} from "@material-ui/core"
import styled from "styled-components"

export const StyledTabUI = styled(Grid)`
  padding: 16px;
  padding-top: 0px;
  position: relative;
  .cls-submain-tab {
    .cls-styled-tab-ui-button {
      cursor: pointer;
    }
    .cls-ui-tab-control {
      border-bottom: 2px solid transparent !important;
    }
    .cls-styled-tab-ui-button {
      z-index: 9;
      min-width: fit-content !important;

      span {
        justify-content: start;
      }

      .cls-tab-ui {
        width: 100%;
        justify-content: space-between;
      }
    }
    .cls-styled-tab-ui-button {
      opacity: 1;
      span {
        color: #757575 !important;
        font-size: 13px !important;
      }
      svg {
        fill: #1665c0;
      }

      .cls-tab-subui {
        margin: auto;
      }
    }
    .cls-styled-tab-ui-button.Mui-selected {
      background: transparent !important;
    }
    .Mui-selected,
    .active {
      span {
        font-size: 13px !important;
        color: #000 !important;
      }
      svg {
        fill: #1565c0 !important;
      }
      .cls-tab-ui-number {
        background: transparent !important;
        color: #1565c0 !important;
      }
    }

    .MuiTabs-indicator {
      height: 2px !important;
    }
  }
`
export const StyledContainer = styled(Grid)`
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  background: #fff;
  display: table;
  overflow: hidden;
  flex-direction: row;
  width: 100%;
  min-height: 70vh;
  @media (min-width: 350px) and (max-width: 851px) {
    display: inline-flex;
  }
`

export const Typography = styled(MuiTypography)(spacing)

export const CardContent = styled(MuiCardContent)`
  position: relative;

  &:last-child {
    padding-bottom: ${(props) => props.theme.spacing(4)}px;
  }
`

export const BlackText = styled.span`
  color: #000;
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`
export const GreenText = styled.span`
  color: ${() => green[400]};
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightBold};
`

export const RedText = styled.span`
  color: ${() => red[400]};
  font-size: 15px;
  font-weight: ${(props) => props.theme.typography.fontWeightMedium};
`

export const SmallButton = styled(Button)`
  padding: 8px;
  min-width: 0;
  svg {
    width: 0.9em;
    height: 0.9em;
  }
`

export const useStyles = makeStyles((theme) => ({
  cardSelected: {
    backgroundColor: "#1565c0",
  },
  fontSelected: {
    color: "white",
  },
  headerValue: {
    fontWeight: "800",
    fontSize: "15px",
  },
  textColorstyle: {
    padding: "5px",
  },
  values: {
    fontSize: "24px",
    padding: "5px",
  },
  cardStyles: {
    boxShadow: "0px 0px 14px rgba(53, 64, 82, 0.05)",
    background: "#FFFFFF",
    minHeight: 144,
  },
  parentres: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    "@media(max-width: 768px)": {
      display: "flex",
      flexDirection: "column",
    },
  },
  boxStyle: {
    display: "flex",
    padding: "5px",
  },
}))

export const StyledSubDashboardContainer = styled(Grid)`
  width: 100%;
  height: 100%;
`
export const StyledSubDashboardHeader = styled(Grid)`
  padding: 4px;
  width: 100%;
  min-height: 58px;
`
export const StyledFilterWrapper = styled(Grid)`
  display: flex;
  flex-direction: row;
`
export const StyledDashboardRetailer = styled(Grid)`
  display: flex;
  flex-direction: column;
  min-height: 50vh;
`
export const StyledDashboardRevenu = styled(Grid)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 10px;
  @media (min-width: 350px) and (max-width: 851px) {
    grid-template-columns: auto;
  }
`
export const StyledDashboardGraph = styled(Grid)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
  margin-bottom: 10px;
  @media (min-width: 350px) and (max-width: 851px) {
    grid-template-columns: auto;
  }
`
export const StyledDashboardCardView = styled(Grid)`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 10px;
  padding: 16px;
  @media (min-width: 350px) and (max-width: 1366px) {
    grid-template-columns: repeat(3, 1fr);
  }
`

export const SearchBox = styled(TextField)`
  margin-left: 10px;
  width: 30%;
  background-color: #fff;

  fieldset {
    border-radius: 5px;
    height: 42px !important;
    border-bottom-right-radius: 0;
    border-top-right-radius: 0;
    border-right: 0;
  }
`
