import React, { useState } from "react"
import { Checkbox, CircularProgress, FormHelperText } from "@material-ui/core"
import TextField from "@material-ui/core/TextField"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import ListItemText from "@material-ui/core/ListItemText"
import { SearchIcon } from "@iconsGallery"
import { Button, OutlinedInput, FormControl } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const useStyle = makeStyles({
  disableTextHover: {
    "& .MuiListItem-button:hover": {
      background: "transparent !important",
    },
  },
  formControl: {
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
  menuCheckbox: {
    "& .MuiCheckbox-colorSecondary.Mui-disabled": {
      color: "gray !important",
    },
  },
  textInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
  },
})

function AutocompleteDropdown(props) {
  const {
    label = "",
    fetchData = () => "",
    data: dropdownData,
    filteredItems = [],
    handleDropdownChange = () => "",
    handleSearchTerm = () => {},
    totalDocs = "",
    handleShowMore = () => {},
    searchTerm = "",
    fullWidth = false,
    isLookup = true,
    placeholder = "",
    valueIdentifier = "name",
    error,
    helperText,
    isShowFooter = true,
    isShowSearch = true,
    selectionLimit = 0,
    disableMenuName = "",
    isPlainObj = false,
  } = props || {}
  const classes = useStyle()
  const [loading, setLoading] = React.useState(false)
  const [open, setOpen] = useState(false)
  const [selectedItems, setSelectedItems] = React.useState(filteredItems)
  const [searchText, setSearchText] = React.useState(searchTerm)
  const [selectedObj, setSelectedObj] = React.useState([])

  const { t } = useTranslation()
  React.useEffect(() => {
    setSelectedItems(selectedItems)
  }, [selectedItems])

  React.useEffect(() => {
    setSearchText(searchTerm)
  }, [searchTerm])

  React.useEffect(() => {
    setSelectedItems(filteredItems)
  }, [filteredItems])

  const ITEM_HEIGHT = 70
  const ITEM_PADDING_TOP = 5

  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
    getContentAnchorEl: () => null,
    anchorOrigin: { vertical: "bottom" },
  }

  const handleChange = (event) => {
    if (isPlainObj) {
      console.log(event.target.value, "selectedItems")
      if (selectionLimit > 0 && event.target.value.length <= selectionLimit) {
        setSelectedItems(event.target.value)
        handleDropdownChange(event.target.value, label, "isPlainObj")
      } else if (selectionLimit === 0) {
        setSelectedItems(event.target.value)
        handleDropdownChange(event.target.value, label, "isPlainObj")
      }
    } else if (!event.target.value.includes("")) {
      if (selectionLimit > 0 && event.target.value.length <= selectionLimit) {
        setSelectedItems(event.target.value)
        handleDropdownChange(event.target.value, label)
      } else if (selectionLimit === 0) {
        setSelectedItems(event.target.value)
        handleDropdownChange(event.target.value, label)
      }
    }
  }

  const handleSearch = (val) => {
    setLoading(true)
    setSearchText(val)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    handleSearchTerm(val, label)
  }

  let selectedId = []
  selectedItems?.map((x) => selectedId?.push(x.id))

  return (
    <FormControl className={classes.formControl} fullWidth={fullWidth}>
      <Select
        multiple
        value={selectedItems}
        onChange={(event) => {
          handleChange(event)
        }}
        defaultValue="none"
        placeholder={t(placeholder)}
        displayEmpty
        input={
          <OutlinedInput
            margin="dense"
            style={{ width: "30vh", height: "36px" }}
          />
        }
        renderValue={(selected) => {
          if (isPlainObj) {
            if (selected?.length > 1) {
              const remaining = selected?.length - 1
              return (
                <b>
                  {t(label)}: {selected[0]?.name} {t("and")} {remaining}{" "}
                  {t("more")}{" "}
                </b>
              )
            } else if (selected?.length === 1) {
              return (
                <b>
                  {t(label)}:{selected[0]?.name}
                </b>
              )
            } else {
              return <b>{t(label)}</b>
            }
          } else {
            if (selected.length > 1) {
              const remaining = selected.length - 1
              return (
                <b>
                  {t(label)}: {selected[0]} {t("and")} {remaining} {t("more")}{" "}
                </b>
              )
            } else if (selected.length === 1) {
              return (
                <b>
                  {t(label)}:{selected[0]}
                </b>
              )
            } else {
              return <b>{t(label)}</b>
            }
          }
        }}
        style={{
          backgroundColor: "transparent",
          height: "38px",
          width: fullWidth ? "100%" : isLookup ? "20vh" : "255px",
        }}
        id="id-detailedTransactionReport-common"
        labelId="id-detailedTransactionReport-common"
        MenuProps={{
          ...MenuProps,
          classes: { paper: "cls-detailedTransactionReport-common-type" },
        }}
      >
        {isShowSearch && (
          <>
            <MenuItem
              value=""
              onKeyDown={(e) => e.stopPropagation()}
              style={{
                padding: "5px 10px 10px 10px",
                backgroundColor: "#ffff",
                position: "sticky",
                top: "0px",
                zIndex: "1",
              }}
            >
              <TextField
                variant="outlined"
                size="small"
                placeholder={t("Search")}
                value={searchText}
                InputProps={{
                  endAdornment: (
                    <React.Fragment>
                      <SearchIcon style={{ color: "#CCC" }} />
                    </React.Fragment>
                  ),
                }}
                className={classes.textInput}
                onChange={(event) => {
                  handleSearch(event.target.value.replace(/(\s{2,})/g, " "))
                }}
              />
            </MenuItem>
          </>
        )}
        {loading ? (
          <CircularProgress size={30} style={{ margin: "80px" }} />
        ) : dropdownData !== undefined && dropdownData?.length > 0 ? (
          dropdownData.map((name, idx) => (
            <MenuItem
              key={name.id}
              value={isPlainObj ? name : name[valueIdentifier]}
              style={{ height: "36px" }}
              disabled={name.name === disableMenuName}
              className={classes.menuCheckbox}
            >
              <Checkbox
                checked={
                  isPlainObj
                    ? selectedId.indexOf(name[valueIdentifier]) > -1
                    : selectedItems.indexOf(name[valueIdentifier]) > -1
                }
                disabled={name.name === disableMenuName}
                style={{ marginRight: "10%", color: "var(--primaryColor)" }}
              />
              <ListItemText primary={name.name} style={{ fontSize: "14px" }} />
            </MenuItem>
          ))
        ) : (
          <MenuItem disabled style={{ height: "30px" }}>
            {t("No Items found")}
          </MenuItem>
        )}
        {isShowFooter && (
          <>
            {loading ? (
              <CircularProgress size={30} style={{ margin: "80px" }} />
            ) : totalDocs > dropdownData?.length > 0 ? (
              <MenuItem
                style={{ background: "transparent" }}
                value=""
                onKeyDown={(e) => e.stopPropagation()}
              >
                <Button
                  color="primary"
                  style={{ fontWeight: 700 }}
                  onClick={() => {
                    setLoading(true)
                    handleShowMore(label)
                    setTimeout(() => {
                      setLoading(false)
                    }, 2000)
                  }}
                >
                  {t("Show More")}
                </Button>
              </MenuItem>
            ) : null}

            <MenuItem
              disabled
              style={{
                background: "#bcb9b9",
                width: "100%",
                display: "flex",
                justifyContent: "end",
                height: "30px",
              }}
            >
              {selectedItems?.length + ` ${t("of")} ` + totalDocs}
            </MenuItem>
          </>
        )}
      </Select>
      {error && (
        <FormHelperText
          error
          style={{ marginLeft: 14, marginRight: 14, marginTop: 4 }}
        >
          {t(helperText)}
        </FormHelperText>
      )}
    </FormControl>
  )
}
export { AutocompleteDropdown }
export default AutocompleteDropdown
