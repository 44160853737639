import React, { useContext } from "react"
import { Button, Typography } from "@material-ui/core"
import PropTypes from "prop-types"
import classnames from "classnames"
import { TreeContext } from "./index"
import { useTranslation } from "react-i18next"

const PlaceholderRendererDefault = ({ isOver = false, canDrop = false }) => {
    const { t } = useTranslation()
    const { onAddClick = () => null, isViewMode = true, noNodesText = 'Uh-Oh! No Categories yet!', addNodeText = 'Add New Category' } = useContext(TreeContext)
    return (
        <div className={classnames("rst__placeholder", canDrop && "rst__placeholderLandingPad", canDrop && !isOver && "rst__placeholderCancelPad")}>
            {
                isViewMode
                    ? <Typography variant='h4'>{t(noNodesText)}</Typography>
                    : <Button variant="contained" disableElevation color="primary"
                        style={{ backgroundColor: "var(--primaryColor)" }}
                        onClick={onAddClick}>
                        {t(addNodeText)}
                    </Button>
            }
        </div>
    )
}


PlaceholderRendererDefault.propTypes = {
    isOver: PropTypes.bool,
    canDrop: PropTypes.bool,
}

export default PlaceholderRendererDefault
