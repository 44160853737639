import {
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
} from "@material-ui/core";
import styled from "styled-components/macro";

export const AppBar = styled(MuiAppBar)`
  background: ${(props) => props.theme.header.background};
  color: ${(props) => props.theme.header.color};
`;

export const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
  margin-left: ${(props) => props.theme.spacing(1.5)}px;
`;

export const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;
  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
  margin-right: ${(props) => props.theme.spacing(3.5)}px;
  border-right: 0.5px solid #9e9e9e;
`;

export const Input = styled(InputBase)`
  color: inherit;
  width: 100%;
  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(2.5)}px;
    width: 160px;
  }
`;
