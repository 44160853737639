import * as React from "react"

import { Formik } from "formik"
import _ from "lodash"
import { useCommonFormUIState } from "./state"
import { useCommonFormUIStyles } from "./styles"
import { View } from "./View"
import * as Features from "@features"
import * as Adm from "@adm"
import * as Utils from "@utils"
import styled from "styled-components"

const customRenderContainer = (props) => {
  const { commonFormData } = props
  return Utils.JSUtils.isEmpty(commonFormData) ? (
    <Features.NoRecordFound />
  ) : (
    <View {...props} />
  )
}

function CommonForm(props) {
  const {
    title = "facets",
    formList,
    onUpdate = () => "",
    isEditMode = true,
    isOpenMode = false,
    listColumnSplit = false,
    inputField = false,
  } = props || {}

  const commonFormState = useCommonFormUIState()

  const {
    edit,
    setEdit,
    attribute,
    setAttribute,
    alert,
    setAlert,
    alertMessage,
    setAlertMessage,
    alertType,
    setAlertType,
    data,
    setData,
    loading,
    setLoading,
    collapseOpen,
    setCollapseOpen,
    expanded,
    setExpanded,
  } = commonFormState || {}

  const handleChange = (event) => setAttribute(event.target.value)
  React.useEffect(() => {
    setData(formList || [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formList])

  const onUpdateData = async () => {
    onUpdate({ ...commonFormState })
  }

  return (
    <div>
      {customRenderContainer({
        commonFormData: data,
        commonFormSetData: setData,
        listColumnSplit,
        inputField,
      })}
    </div>
  )
}
export { CommonForm }
export default CommonForm
