import React from "react"
import {
  Box,
  CardContent as MuiCardContent,
  Chip,
  Typography as MuiTypography,
  Radio,
  FormControl,
  FormLabel,
  FormControlLabel,
  RadioGroup,
} from "@material-ui/core"
import {
  Loop as LoopIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"
import { SmallButton, useStyles, StyledMenu } from "./styles"
import * as Adm from "@adm"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const chipConvertFunc = (value) => {
  if (value === "365") {
    return "1 Year"
  } else if (value === "730") {
    return "Last 2 Years"
  } else if (value === "30" || value === "60" || value === "90") {
    return `${value} Days`
  } else {
    return "Last 2 Years"
  }
}

const RevenueFilter = (props) => {
  const { t } = useTranslation()
  const {
    filterOption: defaultfilterOption = [],
    defaultFilterValue = "",
    onUpdate = () => "",
    disabled = false,
  } = props || {}

  const [anchorEl1, setAnchorEl1] = React.useState(null)
  const [filterValue, setFilterValue] = React.useState(defaultFilterValue)
  const [filterOption, setFilterOption] = React.useState(defaultfilterOption)
  const handleClose = () => {
    setAnchorEl1(null)
  }

  const handleChange = (event, type) => {
    let year = event.target.value.split("_")[0]
    let calender = year
    setFilterValue(event.target.value)
    onUpdate({ value: event.target.value })
  }

  const renderChip = (value) => {
    return (
      <Chip
        label={t(chipConvertFunc(value))}
        size="small"
        variant="outlined"
        color="primary"
        style={{
          borderRadius: "30px",
          marginRight: 10,
          color: "var(--primaryColor)",
          border: `1px solid var(--primaryColor)`,
        }}
      />
    )
  }

  const classes = useStyles()
  return (
    <div className="cls-revenu-filter-days">
      <Box
        textAlign="right"
        className={`${classes.boxStyle} makeStyles-boxStyle-support`}
      >
        <SmallButton
          disabled={disabled}
          onClick={(event) => {
            setAnchorEl1(event.currentTarget)
          }}
        >
          {renderChip(filterValue)}
          <FilterListIcon />
        </SmallButton>
      </Box>
      <StyledMenu
        name={`filter`}
        id="simple-menu1-revenufilter"
        PaperProps={{
          style: {
            transform: "translateX(-25%) translateY(60%)",
            border: 0,
            boxShadow: "1px 1px 10px #b4b4b4",
            padding: "0px 10px",
          },
        }}
        MenuProps={{ classes: { paper: "cls-header-language-kit" } }}
        anchorEl={anchorEl1}
        open={Boolean(anchorEl1)}
        onClose={handleClose}
      >
        <FormControl
          style={{ background: "", padding: 10, margin: "auto" }}
          component="fieldset2"
        >
          <RadioGroup
            name={`filter`}
            aria-label={`filter`}
            value={filterValue}
            checked={filterValue}
            onChange={(event) => {
              handleChange(event, "filter")
            }}
          >
            {!_.isEmpty(filterOption) &&
              filterOption.map((item) => (
                <FormControlLabel
                  value={item.value}
                  control={<Radio style={{ color: "var(--primaryColor)" }} />}
                  label={t(item.label)}
                  onClick={handleClose}
                />
              ))}
          </RadioGroup>
        </FormControl>
      </StyledMenu>
    </div>
  )
}
export default RevenueFilter
export { RevenueFilter }
