import { ReduxConstants } from '@constants';
const { ACTION_TYPES, SAGA_ACTIONS: SAGAS } = ReduxConstants.REDUX_CONSTANTS;

export const fetchAllCategories = () => ({
    type: SAGAS.FETCH_PRODUCT_CATEGORIES
})

export const deleteCategory = (categoryID) => ({
    type: SAGAS.DELETE_CATEGORY,
    categoryID
})

export const addCategory = (formData) => ({
    type: SAGAS.ADD_CATEGORY,
    formData
})