import Axios from "axios"
import {
  LOC_HIERARCHY_URLS as URLS,
  MASTER_DATA_URLS,
} from "../../../utils/url"
import UserService from "../../UserService"
import * as ServiceAPI from "@services"
const CancelToken = Axios.CancelToken

//PATH VARIABLE REPLACER
function bindPath(url, pathVal) {
  var newUrl = url
  var pathExpression = /:[a-z0-9]+/gi
  var pathVar
  while (((pathVar = pathExpression.exec(url)), pathVar != null)) {
    let pathVarName = pathVar[0]
    newUrl = newUrl.replace(
      pathVarName,
      pathVal[pathVarName.substring(1, pathVarName.length)]
    )
  }
  return newUrl
}

const locationAxios = Axios.create()

locationAxios.interceptors.request.use(
  async function (config) {
    config.baseURL = config?.baseURL || URLS.baseURL

    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          config.headers["Authorization"] = "Bearer " + mydataToken
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          let tempTenantId = localStorage.getItem("tempTenantId")
          config.headers["X-Tenant-ID"] = tenantId || tempTenantId
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        let tempTenantId = localStorage.getItem("tempTenantId")
        config.headers["X-Tenant-ID"] = tenantId || tempTenantId
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }

    if (config.headers.path) {
      try {
        config.url = bindPath(config.url, config.headers.path)
      } catch (e) {
        console.error("ERROR OCCURED WHEN REPLACING PATH VARIABLES", e)
      }
    }
    delete config.headers.path
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

const locationKcAxios = Axios.create()

locationKcAxios.interceptors.request.use(
  async function (config) {
    config.baseURL = config?.baseURL || URLS.baseURL_KC

    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          config.headers["Authorization"] = "Bearer " + mydataToken
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          config.headers["X-Tenant-ID"] = tenantId
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        let tempTenantId = localStorage.getItem("tempTenantId")
        config.headers["X-Tenant-ID"] = tenantId || tempTenantId
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }

    if (config.headers.path) {
      try {
        config.url = bindPath(config.url, config.headers.path)
      } catch (e) {
        console.error("ERROR OCCURED WHEN REPLACING PATH VARIABLES", e)
      }
    }
    delete config.headers.path
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

export const locationApiMethods = {
  getAllLocations: (bodyData) => {
    return locationAxios.post(URLS.endpoints.getLocations, bodyData)
  },
  getAllLocationGroups: (bodyData) => {
    return locationAxios.post(URLS.endpoints.getLocationGroups, bodyData)
  },
  getLocationHierarchy: (cancelSource = { current: undefined }) => {
    if (cancelSource?.current) {
      cancelSource.current()
    }
    return locationAxios.get(URLS.endpoints.getLocationHierarchy, {
      cancelToken: new CancelToken(function executor(c) {
        cancelSource.current = c
      }),
    })
  },
  createHierarchy: (bodyData) => {
    return locationAxios.post(URLS.endpoints.create, bodyData)
  },
  updateHierarchy: (hierarchyID, bodyData) => {
    return locationAxios.post(URLS.endpoints.updateHierarchy, bodyData, {
      headers: { path: { hierarchyID } },
    })
  },
  updateLocationHierarchyMappings: (bodyData) => {
    return locationAxios.post(
      URLS.endpoints.updateLocationHierarchyMappings,
      bodyData
    )
  },
  deleteHierarchy: (hierarchyID) => {
    return locationAxios.delete(URLS.endpoints.deleteHierarchy, {
      headers: { path: { hierarchyID } },
    })
  },
  deleteLocationGroups: (bodyData) => {
    return locationAxios.delete(URLS.endpoints.deleteLocationGroups, bodyData)
  },
  deleteLocation: (bodyData) => {
    return locationKcAxios.post(
      URLS.baseURL + URLS.endpoints.deleteLocation,
      bodyData
    )
  },
  elasticSearchKCLocation: (bodyData) => {
    const sort = bodyData.sort
    const sortValues = Object.entries(sort)
    const payload = {
      searchParam: bodyData.searchTerm,
      sortpara: sortValues?.[0]?.[0],
      sortvalue: sortValues?.[0]?.[1] === "asc" ? 1 : -1,
      itemcount: bodyData.limit,
      pagenumber: bodyData.page,
      locationType: bodyData?.filter?.["locationType.name"] || undefined,
      city: bodyData?.filter?.["locationAddress.city"] || undefined,
      state: bodyData?.filter?.["locationAddress.region"] || undefined,
      locationHierarchy: bodyData?.filter?.locationHierarchy || undefined,
      pos_search: bodyData?.filter?.pos_search || false,
    }
    return locationKcAxios.post(URLS.endpoints.searchKcLocations, payload)
  },
  elasticSearchLocation: (bodyData) => {
    return locationAxios.post(URLS.endpoints.searchLocations, bodyData)
  },
  getProductImportStatus: (apiProps) => {
    const { locationId } = apiProps
    return locationAxios.get(
      MASTER_DATA_URLS.endpoints.locationProductsBulkUploadStatus,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
          locationId: locationId,
        },
      }
    )
  },
  uploadBulkProductData: (apiProps) => {
    const { formData, locationId = "" } = apiProps
    return locationAxios.post(
      `${MASTER_DATA_URLS.endpoints.locationProductsBulkUpload}/${locationId}`,
      formData,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
          "Content-Type": "multipart/form-data",
          "language-code": localStorage.getItem("i18nextLng"),
        },
      }
    )
  },
  getProductBulkUploadLogs: (apiProps) => {
    return locationAxios.post(
      MASTER_DATA_URLS.endpoints.locationProductsBulkUploadLogs,
      apiProps,
      {
        headers: {
          ...ServiceAPI.axiosConfig.headers,
        },
        baseURL: MASTER_DATA_URLS.baseURL,
      }
    )
  },
  doArchieveProductsStatus: (apiProps) => {
    const { isExport = false, bodyData = {}, locationId = "" } = apiProps
    return locationAxios.post(
      MASTER_DATA_URLS.endpoints.archieveProductsMappingStatus,
      bodyData,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
          isExport: isExport,
          locationId: locationId,
        },
      }
    )
  },
  getProductExportStatus: (apiProps) => {
    const { locationId = "" } = apiProps
    return locationAxios.get(
      MASTER_DATA_URLS.endpoints.locationBulkDownloadProductMappingStatus,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
          locationId: locationId,
        },
      }
    )
  },
  exportAllProducts: (apiProps) => {
    const { fileType = "", locationId = "" } = apiProps
    return locationAxios.get(
      `${MASTER_DATA_URLS.endpoints.locationExportAllProductsMapping}/${locationId}`,
      {
        baseURL: MASTER_DATA_URLS.baseURL,
        headers: {
          ...ServiceAPI.axiosConfig.headers,
          fileType: fileType,
          locationId: locationId,
          "language-code": localStorage.getItem("i18nextLng"),
        },
      }
    )
  },
}

export default locationApiMethods
