import React, { useState } from "react"
import {
  Card,
  Grid,
  Button,
  Typography,
  TextField,
  Switch,
  Chip,
  makeStyles,
} from "@material-ui/core"
import LocationOnIcon from "@material-ui/icons/LocationOn"
import { useTranslation } from "react-i18next"
import _ from "lodash"

const useStyles = makeStyles((theme) => ({
  textInput: {
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
    '& .MuiFormHelperText-contained': {
      textAlign: "end",
      color: `${(props) =>
        props?.defaultValue?.length === props?.maxLength
          ? "inherit"
          : "#df86bb"}`,
      fontSize: "12px",
      marginRight: "0px",
      fontStyle: "italic",
      fontWeight: "400",
    }
  },
  chip: {
    '& .MuiChip-deleteIcon': {
      color: "var(--primaryColor)"
    }
  }
}))

const CustomTextField = (props) => {
  const classes = useStyles()
  const { isError = false, errorMessage = "is Required", ...restProps } =
    props || {}
  return (
    <Grid style={{ minHeight: 60 }}>
      <TextField {...restProps} className={classes.textInput} />
      {isError && (
        <Typography style={{ color: "red", fontSize: 12 }}>
          {(errorMessage)}
        </Typography>
      )}
    </Grid>
  )
}

const MultiChipKit = (props) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const {
    onUpdate = () => "",
    errorMsg = "",
    idList = [],
    placeholder = "",
    inputProps = {},
    maxLength = "",
  } = props || {}

  const [selectedIdList, setSelectedIdList] = useState(idList || [])
  const [txtValue, setTxtValue] = useState("")
  let checkDuplicate = selectedIdList?.filter((itm) => itm === txtValue) || false

  let checks = txtValue?.length === 0 || checkDuplicate.length < 0 || txtValue?.length <= 13

  return (
    <Grid item xs={12} sm={6} md={6} lg={5} style={{ marginLeft: "50px", marginTop: "10px" }}>
      <Grid style={{ position: "relative", width: "100%" }}>
        <Button
          disabled={checks}
          onClick={() => {
            let updateLit = _.cloneDeep(selectedIdList)
            if (updateLit?.includes(txtValue)) {
              return false
            } else {
              updateLit?.push(txtValue)
              setTxtValue("")
            }
            setSelectedIdList(updateLit)
            onUpdate(updateLit)
          }}
          style={{
            background: checks ? "grey" : "#df86bb",
            color: "white",
            position: "absolute",
            zIndex: "9",
            right: "0",
            minWidth: 37,
            width: 37,
            height: 37,
            fontSize: 24,
          }}
        >
          +
        </Button>
        <CustomTextField
          className="cls-statuscode-ui-txt"
          name="name"
          value={txtValue}
          style={{ width: "100%", fontStyle: "italic" }}
          variant="outlined"
          size="small"
          helperText={`${t("Remaining")}: ${14 - (txtValue?.length || 0)
            }`}
          placeholder={t(placeholder)}
          isError={
            checkDuplicate?.length > 0 || errorMsg !== ""
          }
          inputProps={inputProps}
          errorMessage={
            errorMsg ? t(errorMsg) :
              checkDuplicate?.length > 0 &&
              t("Device Id Code Already Present!")

          }
          onChange={(event) => {
            setTxtValue(event.target.value)
          }}
          defaultValue={txtValue}
          maxLength={maxLength}
        />
      </Grid>

      {selectedIdList?.length > 0 &&
        <Grid
          style={{
            width: "100%",
            minHeight: "unset",
            borderRadius: 8,
            marginTop: "15px"
          }}
        >
          {selectedIdList?.length > 0 ? (
            selectedIdList?.map((item, index) => {
              return (
                <Chip
                  label={item}
                  className={classes.chip}
                  variant="default"
                  style={{
                    fontWeight: "bold",
                    background: "#ffffff",
                    border: "1px solid var(--primaryColor)",
                    borderRadius: 50,
                    marginRight: 5,
                    marginBottom: 5,
                  }}
                  onDelete={(e) => {
                    let updateLit = _.cloneDeep(selectedIdList)
                    _.remove(updateLit, x => x === item)
                    console.log(updateLit, "delete")
                    setSelectedIdList(updateLit)
                    onUpdate(updateLit)
                  }}
                />)

            })
          ) : (
            <>
              {t('Please Add the Device Id')}
            </>
          )}
        </Grid>}
    </Grid >
  )
}

export { MultiChipKit }
export default MultiChipKit
