import React, { useContext } from "react"
import styled from "styled-components/macro"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
  Grid,
  Typography,
  Checkbox,
  Input,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CardActions,
  CardContent,
} from "@material-ui/core"
import {
  Edit,
  Slash,
  CheckCircleIcon,
  ExpandMoreIcon,
  ExpandLessIcon,
  CloseIcon,
} from "@iconsGallery"
import Styled from "styled-components"
import * as Utils from "@utils"
import * as Adm from "@adm"
import * as SearchListDom from "./styles"
import * as ServiceAPI from "@services"
const { getElasticSearch } = ServiceAPI || {}

const OptionaTypography = styled(Typography)`
  font-size: 12px;
  padding-left: 8px;
  color: #676767;
`

export const customRenderContainer = (props) => {
  const {
    edit: isEditMode,
    fetchData: list = [],
    setFetchData,
    idName,
    titleName,
    idNameAPIKey,
    titleNameAPIKey,
    searchAPIString,
    removedFetchData,
    setRemoveFetchData,
    isMultipleSelect,
    isIntialCount,
    setIntialCount,
    setAnyChanges,
  } = props
  const attrs = {
    isMultipleSelect,
    isEdit: !isEditMode,
    list,
    idName,
    titleName,
    renderInputSuggesionUIList: Adm.SearchLisDefaultRenderSuggesionUIList,
    onSearchUpdate: (onSearchUpdateProps) =>
      customOnSearchUpdate({
        ...onSearchUpdateProps,
        searchAPIString,
        idName,
        titleName,
        idNameAPIKey,
        titleNameAPIKey,
      }),
    onUpdate: ({ fetchListData, removedFetchListData }) => {
      setRemoveFetchData(removedFetchListData)
      if (fetchListData.length > 0) {
        setFetchData(fetchListData)
      }
      if (isIntialCount >= 1) {
        setAnyChanges(true)
      }
      setIntialCount(isIntialCount + 1)
    },
  }

  return (
    <SearchListDom.FormWrapperKit>
      {Utils.JSUtils.isEmpty(list) === false && <Adm.SearchListUI {...attrs} />}
    </SearchListDom.FormWrapperKit>
  )
}
export const customOnSearchUpdate = async (props) => {
  const {
    event,
    setSearchText,
    fetchListData,
    setFetchListData,
    searchAPIString,
    idName,
    titleName,
    idNameAPIKey,
    titleNameAPIKey,
    subTitleNameAPIKey = "group_subtitle",
  } = props || {}
  const searchValue = event.target.value.trim()
  const renameKey = (obj, oldKey, newKey) => {
    obj[newKey] = obj[oldKey]
    delete obj[oldKey]
  }
  const prefixId = `/api/searchprice`
  const searchDataList = await getElasticSearch(
    `${prefixId}/${searchAPIString}?searchTerm=${searchValue}`
  )
  const { data } = searchDataList || {}
  const { status = "", searchResults } = data || {}
  if (
    status.toLocaleLowerCase() === "success" &&
    !Utils.JSUtils.isEmpty(searchResults)
  ) {
    const removeEmpty = searchResults.filter(
      (value) => JSON.stringify(value) !== "{}"
    )
    let result = JSON.stringify(removeEmpty)
      .replaceAll(idNameAPIKey, "id")
      .replaceAll(titleNameAPIKey, "title")
      .replaceAll(subTitleNameAPIKey, "subTitle")
    result = JSON.parse(result)

    let testKit = fetchListData
    let dataKit = [...result]

    if (fetchListData.length > 0) {
      dataKit = fetchListData.map((item) => {
        return result.filter((inneritem) => item.id !== inneritem.id)
      })[0]
    }

    testKit = [...testKit, ...dataKit]
    const filteredArr = testKit.reduce((acc, current) => {
      const x = acc.find((item) => item.id === current.id)
      if (!x) {
        return acc.concat([current])
      } else {
        return acc
      }
    }, [])

    setFetchListData([...filteredArr])
  }
}

export const customRenderHeader = (props) => {
  const {
    theme,
    classes,
    edit,
    setEdit,
    collapseOpen,
    setCollapseOpen,
    expanded,
    setExpanded,
    handleExpandClick,
    updateData,
    resetData = () => "",
    isEditMode = true,
    fetchData,
    removedFetchData,
    isOptional = false,
    setRemoveFetchData,
    setFetchData,
    isAnyChanges,
    setAnyChanges,
    isExpandedEnable = false,
  } = props || {}
  const isSelected =
    Utils.JSUtils.isEmpty(
      fetchData.filter((item) => item.selected === true)
    ) === false && isAnyChanges

  return (
    <CardHeader
      className={expanded === false ? classes.dividerBottom : ""}
      title={
        props.edit === true ? (
          props.label
        ) : (
          <div style={{ display: "flex" }}>
            {props.label}
            {isOptional ? (
              <OptionaTypography style={{ float: "left" }}>
                (Optional)
              </OptionaTypography>
            ) : (
              ""
            )}
          </div>
        )
      }
      action={
        <>
          {props.edit ? (
            <>
              {expanded === true && isEditMode === true && (
                <IconButton
                  onClick={() => {
                    setEdit(false)
                  }}
                  style={{ marginRight: "13px" }}
                >
                  <Edit className={classes.basicIconProperty} />
                </IconButton>
              )}
            </>
          ) : (
            <>
              {expanded === true ? (
                <>
                  <IconButton
                    onClick={() => {
                      resetData()
                      setFetchData(removedFetchData)
                      setEdit(true)
                    }}
                    style={{ marginRight: "20px" }}
                  >
                    <Slash
                      style={{ color: "red" }}
                      className={classes.basicIconProperty}
                    />
                  </IconButton>
                  <IconButton
                    style={{
                      marginRight: "18px",
                      pointerEvents: `${isSelected ? "unset" : "none"}`,
                    }}
                    onClick={() => {
                      if (isSelected) {
                        updateData(props)
                        setEdit(true)
                      }
                    }}
                  >
                    <CheckCircleIcon
                      style={{
                        color: `${isSelected ? "#4CAF50" : "#CCC"}`,
                      }}
                    />
                  </IconButton>
                </>
              ) : (
                ""
              )}
            </>
          )}
          {
            props?.additionalButton
          }
          {isExpandedEnable && (
            <>
              {expanded === true ? (
                <IconButton onClick={handleExpandClick}>
                  <ExpandLessIcon className={classes.iconUpDown} />
                </IconButton>
              ) : (
                <IconButton onClick={handleExpandClick}>
                  <ExpandMoreIcon className={classes.iconUpDown} />
                </IconButton>
              )}
            </>
          )}
        </>
      }
    ></CardHeader>
  )
}
