import React from "react"
import { Typography, Breadcrumbs, Button } from "@material-ui/core"
import { JSUtils } from "@utils"
import styled from "styled-components"
import { NavigateNextIcon } from "@iconsGallery"
import { Link } from "react-router-dom"
import _ from "lodash"
import { useTranslation } from "react-i18next"

const BreadcrumWrapper = styled.span`
  .btntxt {
    padding: 0px;
    font-size: 14px;
    font-weight: 400;
    color: var(--primaryColor);
    cursor: pointer; 
    &:hover {
      text-decoration: underline !important;
      background-color: unset;
    }
  }
  .disable-link {
    pointer-events: none;
    cursor: default;
    text-decoration: none;
    p {
      color: #0000008a;
    }
  }
  a {
    color: var(--primaryColor);
    text-decoration: none;
    &:hover {
      text-decoration: underline !important;
    }
  }
  &.disable {
    text-decoration: none;
    background: #f2f2f2;
    font-size: 14px;
    color: #0000008a;
    a {
      pointer-events: none;
      cursor: default;
      color: #0000008a;
      p {
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 21px;
        letter-spacing: 0.07875px;
        text-align: left;
      }
    }
  }
`

const BreadcrumbsKit = styled(Breadcrumbs)`
  .MuiBreadcrumbs-separator {
    margin-left: 0px;
    margin-right: 0px;
  }
`

const defaultRenderBreadcrumbsList = (props) => {
  const { list = [], t } = props || {}
  return (
    JSUtils.isEmpty(list) === false &&
    list.map((item, index) => {
      const { name, url = "", disable = false, redirectFunc = "", } = item || {}
      let linkAttr = {
        to: JSUtils.isEmpty(url) === false ? url : "",
      }
      return (
        <BreadcrumWrapper
          key={index}
          className={`${disable === true ? "disable" : ""}`}
        >
          {
            _.isFunction(redirectFunc) ?
              <Button
                className="btntxt"
                onClick={() => redirectFunc()}>{t(name)}</Button>
              :
              <Link className={`${JSUtils.isEmpty(url) === false ? "" : "disable-link"}`} {...linkAttr}>
                <Typography>{t(name)}</Typography>
              </Link>
          }
        </BreadcrumWrapper>
      )
    })
  )
}

const defaultList = [
  {
    name: "Level 01",
    url: "google.com",
    disable: true,
  },
  {
    name: "Level 02",
    url: "google.com",
    disable: false,
  },
  {
    name: "Level 02",
    disable: false,
  },
]

const defaultRenderSeparator = () => <NavigateNextIcon />

function Breadcrumb(props) {
  const { t } = useTranslation()
  const {
    list = defaultList || [],
    renderBreadcrumbsList = defaultRenderBreadcrumbsList,
    renderSeparator = defaultRenderSeparator,
    ariaLabel = "breadcrumb",
  } = props || {}

  const breadcrumAttributes = {
    separator: renderSeparator(),
  }
  return (
    <BreadcrumbsKit className="cls-breadcrumbs" aria-label={ariaLabel} {...breadcrumAttributes}>
      {renderBreadcrumbsList({ list, t })}
    </BreadcrumbsKit>
  )
}

export { Breadcrumb }
export default Breadcrumb
