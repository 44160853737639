import * as React from "react"
import { Formik } from "formik"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Typography,
  Collapse,
  TextField,
  Button,
  ListItemText,
} from "@material-ui/core"

import _ from "lodash"
import { facetUIYupSchema } from "./yupSchema"
import { renderFormList } from "./renderProp"
import {
  transformIntialValues,
  transformValidationSchema,
  transformGivenValues,
} from "./transformModal"
import { defaultformInput } from "./fixture"
import { useFacetUIContentStyles } from "./styles"
import { addMethod } from "yup"
import * as Adm from "@adm"
import * as ValidationKit from "./ValidationKit"

const defaultRenderSubmitButton = ({ formUIProps }) => {
  const {
    errors,
    handleBlur,
    handleChange,
    setOpenConfirmationDialog,
    handleSubmit,
    touched,
    values,
    submitForm,
    renderSubmitButton,
  } = formUIProps

  const savebuttonAttrs = {
    variant: "contained",
    type: "submit",
    color: "primary",
  }

  return <Adm.Button {...savebuttonAttrs}>submit</Adm.Button>
}
const FormInnerUI = (formUIProps) => {
  const classes = useFacetUIContentStyles()
  const {
    errors,
    handleBlur,
    handleChange,
    handleSubmit,
    resetForm,
    touched,
    setOpenConfirmationDialog,
    values,
    submitForm,
    renderSubmitButton,
    setAllowPrompt=()=>{},
  } = formUIProps

  

  return (
    <form noValidate onSubmit={handleSubmit}>
      {setAllowPrompt(false)}
      {errors.submit && ""}
      {renderFormList({ formUIProps })}
      {renderSubmitButton({ formUIProps })}
    </form>
  )
}

function FormKit(props) {
  const classes = useFacetUIContentStyles()
  const {
    setData,
    data,
    formList = defaultformInput,
    setCreateNewForm,
    onSubmit: onSubmitFormKit = () => { },
    label,
    formType,
    cardData,
    isPlainObj,
    keyForValueReference,
    renderSubmitButton = defaultRenderSubmitButton,
    isDetailView = false,
    onChangeForm = () => {},
    onInitalLoadForm = () => { },
    setFieldValue,
    ...remainingProps
  } = props || {}
 
  const intialValueObj = transformIntialValues(formList, cardData, formType)
  const [fetchFormList, setfetchFormList] = React.useState()
  const [resultFormList, setResultFormList] = React.useState({})
  const [formValues, setFormValues] = React.useState({})
  const [localFormList, setLocalFormList] = React.useState(
    intialValueObj.initialValues
  )
  const [openConfirmationDialog, setOpenConfirmationDialog] = React.useState(false)
  const [reDirect, setReDirect] = React.useState(false)
  const [allowPrompt, setAllowPrompt] = React.useState(true)
  
  

  React.useEffect(() => {
    setLocalFormList(intialValueObj.initialValues)
  }, [formList])

  React.useEffect(() => {
    onInitalLoadForm(localFormList)
  }, [])
  React.useEffect(() => {
    const form = { ...formValues, ...resultFormList }
    onChangeForm(form)
  }, [formValues, resultFormList])

  const validationSchemaObj = transformValidationSchema(formList)
  const formikAttribute = {
    onSubmit: (propsKit) => {
      onSubmitFormKit({ ...propsKit, ...resultFormList })
    },

    ...validationSchemaObj,
    ...intialValueObj,
  }

  const formSet = (formProps) => {
    const { values } = formProps
    const formSetAttr = {
      formList,
      classes,
      setCreateNewForm,
      openConfirmationDialog,
      setOpenConfirmationDialog,
      resultFormList,
      setResultFormList,
      label,
      isPlainObj,
      keyForValueReference,
      localFormList,
      setLocalFormList,
      renderSubmitButton,
      isDetailView,
      setFieldValue,  
      ...formProps,
      ...remainingProps,
    }
    setFormValues(values)
    return <FormInnerUI {...formSetAttr} />
  }
  return (
    <Formik enableReinitialize={true} {...formikAttribute}>
      {formSet}
    </Formik>
  )
}

export { FormKit, ValidationKit }
export default FormKit
