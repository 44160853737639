import { Switch, Box } from "@material-ui/core"
import React from "react"
import { useTranslation } from "react-i18next"
import customTableStyles from "../../styles/components/customTable/styles"
function CustomBooleanCell(props) {
  const { t } = useTranslation()
  const data = props.dataItem
  const dataValue = props.dataItem[props.field]
  const selectedValue = props?.filterData?.find(
    (a) => a.value?.toString() === dataValue?.toString()
  )
  const classes = customTableStyles()
  return (
    <td className="cls-td-kit-ele cls-td-kit-0">
      <Box
        className={
          selectedValue?.key
            ? ` ${classes.enabledBox} makeStyles-enabledBox-support`
            : `${classes.disabledBox} makeStyles-disabledBox-support`
        }
      >
        {t(selectedValue?.tab)}
      </Box>
    </td>
  )
}

export default CustomBooleanCell
