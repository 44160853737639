import React, { useEffect, useState } from "react"
import { JSUtils } from "@utils"

export const renderOnSave = (props, stateProps) => {
  const { rowData, setRowData } = stateProps || {}
  const newRowDataState = {
    ...rowData,
  }
  newRowDataState.data.map((val) => {
    if (val._id === props.dataItem._id) {
      val = props.dataItem
      val["inEdit"] = false
    }
    return val
  })
  newRowDataState["editID"] = null
  setRowData(newRowDataState)
}

export const renderSwitchHandler = (event, props, stateProps) => {
  const { rowData, setRowData } = stateProps || {}
  const newRowDataState = { ...rowData }
  newRowDataState.data.map((item) => {
    if (item._id === props.dataItem._id) {
      item[props.field] = !props.dataItem[props.field]
    }
    return item
  })
  setRowData(newRowDataState)
}

export const renderOnEdit = (
  props,
  history,
  stateProps,
  reDirectPath,
  editVariable,
  isType,
  InTableRowEdit,
  queryParams = "",
  tableGlobalState,
) => {
  const { rowData, setRowData } = stateProps || {}
  const { gridState } = tableGlobalState || {}
  if (InTableRowEdit) {
    const newRowDataState = {
      ...rowData,
    }
    newRowDataState.data.map((val) => {
      if (val._id === props.dataItem._id) {
        val.inEdit = true
      } else {
        val.inEdit = false
      }
      return val
    })
    newRowDataState["editID"] = props.dataItem._id
    setRowData(newRowDataState)
  } else {
    let urlPush = ""
    if (isType === "singel") {
      const preDefinedId = props.dataItem[editVariable] || ""
      urlPush = reDirectPath + preDefinedId
    }
    if (isType === "group") {
      let addUrl = ""
      const splitReDirectPath = reDirectPath.split("/")
      splitReDirectPath.forEach((item) => {
        if (item !== "") {
          let setItem = ""
          if (item.includes("=")) {
            const splitEqual = item.split("=")
            setItem = `${splitEqual[0]}=${props.dataItem[splitEqual[1]]}`
          } else {
            setItem = item
          }
          addUrl = addUrl + "/" + setItem
        }
      })
      urlPush = addUrl
    }
    if (queryParams) {
      urlPush = urlPush + `?${queryParams}=${props.dataItem[queryParams]}`
    }
    urlPush !== "" &&
      history.push(urlPush, {
        state: { urlGridState: gridState },
      })
  }
}

export const renderOnDiscard = (props, stateProps, InTableRowEdit) => {
  const { rowData, setRowData } = stateProps || {}
  const newRowDataState = {
    ...rowData,
  }
  if (InTableRowEdit) {
    newRowDataState.data.map((val) => {
      if (val._id === props.dataItem._id) {
        val.inEdit = false
      }
      return val
    })
    newRowDataState["editID"] = null
    setRowData(newRowDataState)
  } else {
    newRowDataState.data.map((val) => {
      if (val._id === props.dataItem._id) {
        val.inEdit = false
      }
      return val
    })
    newRowDataState["editID"] = null
    setRowData(newRowDataState)
  }
}

export const renderSelectionChange = (event, stateProps) => {
  const { rowData, setRowData } = stateProps || {}
  const { selection, setSelection } = stateProps || {}
  const newRowDataState = { ...rowData }

  const newSelectionState = { ...selection }
  if (newSelectionState?.selectedItems.includes(event.dataItem._id)) {
    const newSelectedItems = newSelectionState.selectedItems.filter(function (
      item
    ) {
      return item !== event.dataItem._id
    })
    newSelectionState.selectedItems = newSelectedItems
    setSelection(newSelectionState)
  } else {
    newSelectionState.selectedItems?.push(event.dataItem._id)
    setSelection(newSelectionState)
  }
  if (newSelectionState?.store_ids) {
    if (newSelectionState?.store_ids?.includes(event.dataItem._id)) {
      const newSelectedStores = newSelectionState.store_ids?.filter(function (
        item
      ) {
        return item !== event.dataItem._id
      })
      newSelectionState.store_ids = newSelectedStores
      const rowLocItems = event?.dataItems?.filter((o) => {
        if (o.locationIds?.includes(event.dataItem._id)) return o
      })
      newSelectionState.selectedLocationRowItems = rowLocItems
      setSelection(newSelectionState)
    } else {
      newSelectionState.store_ids?.push(event.dataItem._id)
      newSelectionState?.selectedLocationRowItems?.push(event.dataItem)
      setSelection(newSelectionState)
    }
  }
  if (newSelectionState?.customer_ids) {

    if (newSelectionState.customer_ids.includes(event.dataItem.customer_id)) {
      const newSelectedCustomers = newSelectionState.customer_ids.filter(
        function (item) {
          return item !== event.dataItem.customer_id
        }
      )
      newSelectionState.customer_ids = newSelectedCustomers
      setSelection(newSelectionState)
    } else {
      newSelectionState.customer_ids.push(event.dataItem.customer_id)
      setSelection(newSelectionState)
    }
  }
  if (newSelectionState?.inventoryIds?.includes(event.dataItem.inventoryId)) {
    const newSelectedCustomers = newSelectionState.inventoryIds.filter(
      function (item) {
        return item !== event.dataItem.inventoryId
      }
    )
    newSelectionState.inventoryIds = newSelectedCustomers
    setSelection(newSelectionState)
  } else {
    newSelectionState?.inventoryIds?.push(event.dataItem.inventoryId)
    setSelection(newSelectionState)
  }
  newRowDataState.data.map((item, i) => {
    if (item._id === event.dataItem._id) {
      item.selected = !event.dataItem.selected
    }
    return item
  })
  setRowData(newRowDataState)
}

export const renderSingleSelectionChange = (event, stateProps) => {
  const { rowData, setRowData } = stateProps || {}
  const { selection, setSelection } = stateProps || {}
  const newRowDataState = { ...rowData }

  const newSelectionState = { ...selection }
  newSelectionState.selectedItems = [event.dataItem._id]
  newSelectionState.store_ids = [event.dataItem._id]
  newSelectionState.selectedLocationRowItems = [event.dataItem]
  setSelection(newSelectionState)
  newRowDataState.data.map((item, i) => {
    if (item._id === event.dataItem._id) {
      newRowDataState.data[i].selected = true
    } else {
      newRowDataState.data[i].selected = false
    }
  })

  setRowData(newRowDataState)
}

export const renderHeaderSelectionChange = (event, stateProps) => {
  const { rowData, setRowData } = stateProps || {}
  const { selection, setSelection } = stateProps || {}
  const checked = event.syntheticEvent.target.checked
  const newRowDataState = { ...rowData }
  const newSelectionState = { ...selection }
  let newSelectedItems = selection?.selectedItems || []
  let newSelectedStores = selection?.store_ids || []
  let newSelectedStoreItems = selection?.selectedLocationRowItems || []
  let newSelectedCustomers = selection?.customer_ids || []
  newRowDataState?.data?.map((item) => {
    item.selected = checked
    if (checked === true) {
      if (!newSelectionState.selectedItems.includes(item._id)) {
        newSelectedItems.push(item._id)
        item.locationId && newSelectedStores.push(item.locationId)
        item.locationId && newSelectedStoreItems.push(item)
        item.customer_id && newSelectedCustomers.push(item.customer_id)
      }
    } else {
      newSelectedItems = newSelectedItems.filter(function (item1) {
        return item1 !== item._id
      })
      newSelectedStores = newSelectedStores
        ? newSelectedStores.filter(function (item1) {
          return item1 !== item.locationId
        })
        : []
      newSelectedStoreItems = newSelectedStoreItems
        ? newSelectedStoreItems.filter(function (item1) {
          return item1.locationId !== item.locationId
        })
        : []
      newSelectedCustomers = newSelectedCustomers.filter(function (item1) {
        return item1 !== item.customer_id
      })
    }
    return item
  })
  newSelectionState.selectedItems = newSelectedItems
  newSelectionState.store_ids = newSelectedStores
  newSelectionState.selectedLocationRowItems = newSelectedStoreItems
  newSelectionState.customer_ids = newSelectedCustomers
  setRowData(newRowDataState)
  setSelection(newSelectionState)
}

export const renderSetDefaultValue = (event, props, stateProps) => {
  const { rowData, setRowData } = stateProps || {}
  const newRowDataState = { ...rowData }
  newRowDataState["defaultID"] = props.dataItem._id
  newRowDataState.data.map((item) => {
    if (item._id === props.dataItem._id) {
      item[props.field] = !props.dataItem[props.field]
    }
    return item
  })
  setRowData(newRowDataState)
}

export const renderItemChange = (event, stateProps) => {
  const { rowData, setRowData } = stateProps || {}
  const data = rowData.data.map((item) =>
    item._id === event.dataItem._id
      ? { ...item, [event.field]: event.value }
      : item
  )
  setRowData({
    ...rowData,
    data: data,
  })
}

export const renderUpdateTableFilterData = (item, stateProps) => {
  const { columnData, setColumnData } = stateProps || {}
  const newColumnData = [...columnData]
  newColumnData.map((val) => {
    if (val.field === item.field) {
      val.show = !val.show
    }
    return {}
  })
  setColumnData(newColumnData)
}
