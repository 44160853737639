import {
    Checkbox,
    FormControlLabel,
    FormGroup,
    Grid, Typography,
} from "@material-ui/core"
import _ from "lodash"
import { withStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"

const CustomCheckbox = withStyles({
    root: {
        "&$checked": {
            color: "#41b62c",
        },
        '& .MuiSvgIcon-root': {
            fontSize: "30px"
        },
    },

    checked: {},
})((props) => <Checkbox {...props} />)

const StyledFormControlLabel = withStyles((theme) => ({
    root: {
    }
}))(FormControlLabel)


export const renderStageUnstageField = (props) => {
    const { heading = "Unstage Fields List", labelButton = "Move All Stage", list = [], name = "",
        onChangeFieldStage = () => { }, onChangeAllFieldList = () => { }, checked = false, setChecked = () => { },
        editPage = false, t
    } = props
    return (
        <>
            <Grid style={{ display: "flex", justifyContent: "space-between" }}>
                <Typography style={{ fontSize: "18px", fontWeight: 800 }}>{t(heading)}</Typography>
                {editPage ? null : <Typography style={{ fontSize: "14px", fontWeight: 800, color: "#d38bb9", cursor: "pointer" }}
                    onClick={() => { onChangeAllFieldList(name) }}
                >{t(labelButton)}</Typography>}
            </Grid>
            {list?.length > 0 ?
                <Grid style={{ border: "1px solid #000000", borderRadius: "8px", padding: "10px", margin: "10px 0px", height: "500px", overflowY: "scroll" }}>
                    <FormGroup>
                        {_.map(list, (x, i) => {
                            return (
                                <StyledFormControlLabel
                                    style={{ borderBottom: "3px solid #efefef", margin: "0px", padding: "5px 0px 5px 0px" }}
                                    control={
                                        <CustomCheckbox
                                            style={{ fontSize: "30px", marginRight: "10px", padding: "0px" }}
                                            checked={name === "stage"}
                                            disabled={editPage}
                                            color="green"
                                            onChange={e => {
                                                console.log(e.target.checked);
                                                onChangeFieldStage({
                                                    value: x,
                                                    fieldName: name,
                                                })
                                            }}
                                        />
                                    }
                                    label={x.key} />
                            )
                        })}
                    </FormGroup>
                </Grid>
                : <Grid
                    style={{
                        border: "1px solid #000000", borderRadius: "8px", padding: "10px", margin: "10px 0px", height: "500px", overflowY: "scroll", display: "grid",
                        alignContent: "center", textAlign: "center", fontSize: "22px", color: "#b0abab"

                    }}
                >{t('No records found')}</Grid>}
        </>
    )
}