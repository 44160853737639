export const GET_SIGNIN_DATA = "app/GET_SIGNIN_DATA"
export const RECEIVE_SIGNIN_DATA = "app/RECEIVE_SIGNIN_DATA"

export const GET_SIGNUP_DATA = "app/GET_SIGNUP_DATA"

export const GET_PRODUCT_LIST = "app/GET_PRODUCT_LIST"

export const RECEIVE_PRODUCT_LIST = "app/RECEIVE_PRODUCT_LIST"

export const GET_CREATE_PRODUCT = "app/GET_CREATE_PRODUCT"
export const RECEIVE_CREATE_PRODUCT = "app/RECEIVE_CREATE_PRODUCT"

export const GET_BARCODE_TYPE = "app/GET_BARCODE_TYPE"
export const RECEIVE_BARCODE_TYPE = "app/RECEIVE_BARCODE_TYPE"

export const GET_STORE_TYPE = "app/GET_STORE_TYPE"
export const RECEIVE_STORE_TYPE = "app/RECEIVE_STORE_TYPE"

export const GET_UNIT_OF_MEASURE = "app/GET_UNIT_OF_MEASURE"
export const RECEIVE_UNIT_OF_MEASURE = "app/RECEIVE_UNIT_OF_MEASURE"

export const GET_PRODUCT_DETAILS = "app/GET_PRODUCT_DETAILS"
export const RECEIVE_PRODUCT_DETAILS = "app/RECEIVE_PRODUCT_DETAILS"

export const GET_EDIT_PRODUCT = "app/GET_EDIT_PRODUCT"
export const RECEIVE_EDIT_PRODUCT = "app/RECEIVE_EDIT_PRODUCT"

export const EDIT_PRODUCT_PARAMS = "app/EDIT_PRODUCT_PARAMS"
export const RECEIVE_PRODUCT_PARAMS = "app/RECEIVE_PRODUCT_PARAMS"

export const GET_DELETE_PRODUCT = "app/GET_DELETE_PRODUCT"
export const GET_DELETE_MULTIPLE_PRODUCT = "app/GET_DELETE_MULTIPLE_PRODUCT"

export const GET_CATEGORY_LIST = "app/GET_CATEGORY_LIST"
export const RECEIVE_CATEGORY_LIST = "app/RECEIVE_CATEGORY_LIST"

export const GET_SUB_CATEGORY_LIST = "app/GET_SUB_CATEGORY_LIST"
export const RECEIVE_SUB_CATEGORY_LIST = "app/RECEIVE_SUB_CATEGORY_LIST"

export const GET_PRODUCT_GROUP = "app/GET_PRODUCT_GROUP"
export const RECEIVE_PRODUCT_GROUP = "app/RECEIVE_PRODUCT_GROUP"

export const GET_CREATE_PRODUCT_GROUP = "app/GET_CREATE_PRODUCT_GROUP"

export const PRODUCT_GROUP_EDIT_PARAMS = "app/PRODUCT_GROUP_EDIT_PARAMS"
export const RECEIVE_PRODUCT_GROUP_EDIT_PARAMS =
  "app/RECEIVE_PRODUCT_GROUP_EDIT_PARAMS"

export const ASSOCIATE_PRODUCT_GROUP = "app/ASSOCIATE_PRODUCT_GROUP"

export const GET_DELETE_PRODUCT_GROUP = "app/GET_DELETE_PRODUCT_GROUP"
export const RECEIVE_PRODUCT_DELETE_GROUP = "app/RECEIVE_PRODUCT_DELETE_GROUP"

export const GET_PRODUCT_GROUP_DETAILS = "app/GET_PRODUCT_GROUP_DETAILS"
export const RECEIVE_PRODUCT_GROUP_DETAILS = "app/RECEIVE_PRODUCT_GROUP_DETAILS"

export const GET_ORDERS_LIST = "app/GET_ORDERS_LIST"
export const RECEIVE_ORDERS_LIST = "app/RECEIVE_ORDERS_LIST"

export const GET_SEARCH_TERM = "app/GET_SEARCH_TERM"

export const GET_ORDER_DETAILS = "app/GET_ORDER_DETAILS"
export const RECEIVE_ORDERS_DETAILS_DATA = "app/RECEIVE_ORDERS_DETAILS_DATA"

export const GET_DATE_TIME = "app/GET_DATE_TIME"

export const GET_ORDER_STATE_STATUS = "app/GET_ORDER_STATE_STATUS"

export const GET_LOCATION_LIST = "app/GET_LOCATION_LIST"
export const SET_SMART_DEVICE_CONFIG_LOC = "app/SET_SMART_DEVICE_CONFIG_LOC"
export const GET_SELECTED_SDC_LOCATION = "app/GET_SELECTED_SDC_LOCATION"
export const SET_SMART_DEVICE_CONFIG_LOC_NAME =
  "app/SET_SMART_DEVICE_CONFIG_LOC_NAME"
export const SET_CREATE_SDC_SUCCESS_TOGGLE = "app/SET_CREATE_SDC_SUCCESS_TOGGLE"
export const RECEIVE_LOCATION_LIST = "app/RECEIVE_LOCATION_LIST"

export const GET_SELECTED_LOCATION = "app/GET_SELECTED_LOCATION"
export const RECEIVE_SELECTED_LOCATION = "app/RECEIVE_SELECTED_LOCATION"

export const GET_TURNSTILE_LIST = "app/GET_TURNSTILE_LIST"
export const RECEIVE_TURNSTILE_LIST = "app/RECEIVE_TURNSTILE_LIST"

export const GET_GONDOLA_LIST = "app/GET_GONDOLA_LIST"
export const RECEIVE_GONDOLA_LIST = "app/RECEIVE_GONDOLA_LIST"

export const SELECTED_GONDOLA = "app/SELECTED_GONDOLA"
export const RECEIVE_GONDOLA_SELECTED = "app/RECEIVE_GONDOLA_SELECTED"

export const POST_GROUNDSPLOT_DATA = "app/POST_GROUNDSPLOT_DATA"
export const POST_CART_MANAGER = "app/POST_CART_MANAGER"

export const GET_CAMERA_LIST = "app/GET_CAMERA_LIST"
export const RECEIVE_CAMERA_LIST = "app/RECEIVE_CAMERA_LIST"

export const CAMERA_SELECTED = "app/CAMERA_SELECTED"
export const RECEIVE_CAMERA_SELECTED = "app/RECEIVE_CAMERA_SELECTED"

export const POST_CAMERA_GPU = "app/POST_CAMERA_GPU"
export const CAMERA_UPDATE = "app/CAMERA_UPDATE"

export const POST_STORE_DIMENSION = "app/POST_STORE_DIMENSION"

export const GET_GONDOLA_DETAILS = "app/GET_GONDOLA_DETAILS"
export const RECEIVE_GONDOLA_DETAILS = "app/RECEIVE_GONDOLA_DETAILS"

export const POST_LOCATION_SETUP = "app/POST_LOCATION_SETUP"

export const GET_LOCATION_SETUP = "app/GET_LOCATION_SETUP"
export const RECEIVE_LOCATION_SETUP = "app/RECEIVE_LOCATION_SETUP"

export const GET_STORE_DIMENSION_STEP = "app/GET_STORE_DIMENSION_STEP"
export const RECEIVE_STORE_DIMENSION_STEP = "app/RECEIVE_STORE_DIMENSION_STEP"

export const GET_GONDOLA_STEP = "app/GET_GONDOLA_STEP"
export const RECEIVE_GONDOLA_STEP = "app/RECEIVE_GONDOLA_STEP"

export const GET_CAMERA_AND_GPU_STEP = "app/GET_CAMERA_AND_GPU_STEP"
export const RECEIVE_CAMERA_AND_GPU_STEP = "app/RECEIVE_CAMERA_AND_GPU_STEP"

export const GET_CART_MANAGER_STEP = "app/GET_CART_MANAGER_STEP"
export const RECEIVE_CART_MANAGER_STEP = "app/RECEIVE_CART_MANAGER_STEP"

export const USER_ROLE_INFORMATION = "app/USER_ROLE_INFORMATION"
export const USER_DECODE_INFORMATION = "app/USER_DECODE_INFORMATION"

export const USER_KEYCLOAK_ATTRIBUTES = "app/USER_KEYCLOAK_ATTRIBUTES"
export const USER_KEYCLOAK_BEFORE_MODIFY_ATTRIBUTES =
  "app/USER_KEYCLOAK_BEFORE_MODIFY_ATTRIBUTES"

export const SET_USER_ROLE_INFORMATION = "app/SET_USER_ROLE_INFORMATION"
export const SET_USER_DECODE_DATA = "app/SET_USER_DECODE_DATA"
export const GET_INVENTORY_LOCATION = "app/GET_INVENTORY_LOCATION"
export const TRAINING_PRODUCTS_INFO = "app/TRAINING_PRODUCTS_INFO"
