import React, { useRef, useEffect } from "react"

let font = "Inter"
const ImageText = ({ text: texts = "", fontSize: fontSizes = "18px" }) => {
  const canvasRef = useRef(null)

  let text = texts
  let fontSize = `${fontSizes} ${font}`

  const measureText = (text, font) => {
    const span = document.createElement("span")
    span.appendChild(document.createTextNode(text))
    Object.assign(span.style, {
      font: font,
      margin: "0",
      padding: "0",
      border: "0",
      whiteSpace: "nowrap",
    })
    document.body.appendChild(span)
    const { width, height } = span.getBoundingClientRect()
    span.remove()
    return { width, height }
  }

  useEffect(() => {
    const canvas = canvasRef.current
    const context = canvas.getContext("2d")

    canvas.width = measureText(text, fontSize)?.width + 20
    canvas.height = measureText(text, fontSize)?.height + 10
    context.font = fontSize
    context.fillStyle = "black"
    context.fillText(
      text || "",
      measureText(text, fontSize)?.width + 15,
      measureText(text, fontSize)?.height
    )
  }, [])

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <canvas ref={canvasRef} style={{ border: "0px solid black" }} />
      {/* <div
        contentEditable
        style={{
          position: "absolute",
          top: 0,
          left: 0,
          fontSize: fontSizes,
          fontFamily: font,
          color: "black",
          // backgroundColor: "rgba(255, 255, 255, 0.5)",
          pointerEvents: "auto",
          textAlign: "center",
          transform: "rotate(0deg) scaleX(-1)",
        }}
      >
        {text}
      </div> */}
    </div>
  )
}

export { ImageText }
export default ImageText
