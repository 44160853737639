import { makeStyles } from "@material-ui/core/styles"
const useStyles = makeStyles((theme) => ({
  root: {
    height: "100%",
    overflowY: "scroll",
    width: "100%",
  },
  //table header styling
  locationheader: {
    paddingTop: "10px !important",
    paddingBottom: "0px !important",
    borderLeft: "0",
    paddingRight: "0",
    background: "#e0e0e0",
    padding: "15px",
    display: "flex",
    width: "100%",
    height: "46px",
    fontWeight: "500",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    border: "0.5px solid rgba(0, 0, 0, 0.1)",
    position: "sticky",
    top: "0",
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
  },

  tableheader: {
    fontSize: "14px",
    fontWeight: "500",
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
    padding: "15px 15px",
    margin: "10px 0px",
  },
  tableHeaderGrid: {},
  tableheaderlocationAddress: {
    fontSize: "14px",
    fontWeight: "500",
    minwidth: "30%",
    maxWidth: "30%",
    fontWeight: "500",
    width: "30%",
  },
  tableheaderInventory: {
    fontSize: "14px",
    fontWeight: "500",
    minwidth: "50%",
    maxWidth: "50",
    fontWeight: "500",
    width: "35%",
  },
  tableheaderList: {
    fontSize: "14px",
    fontWeight: "500",
    minwidth: "50%",
    maxWidth: "50",
    fontWeight: "500",
    width: "35%",
  },
  tableheaderOffer: {
    fontSize: "14px",
    fontWeight: "500",
    minwidth: "50%",
    maxWidth: "50",
    fontWeight: "500",
    width: "35%",
  },
  // table content Styling
  contenttablegrid: {
    paddingTop: "0px !important",
    paddingBottom: "0px !important",
    borderLeft: "0",
    borderRight: "0",
    paddingLeft: "10px",
    paddingRight: "0",
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    border: "0.5px solid #EEEEEE",
    minHeight: "50px",
    color: "#000000",
  },
  contentlocationId: {
    paddingLeft: "10px",
    paddingRight: "0",
    width: "100%",
    justifyContent: "space-between",
    minHeight: "40px",
    maxHeight: "50px",
    width: "14%",
    borderRight: "1px solid rgba(0, 0, 0, 0.1)",
  },
  contentlocationAddress: {
    fontSize: "14px",
    color: "#000000",
    maxHeight: "50px",
    minHeight: "40px",
    paddingBottom: "15px",
    textOverflow: "ellipsis",
  },
  bodyGrid: {},

  chip: {
    padding: theme.spacing(0.5),
    borderRadius: "50px",
  },
  divider: {
    padding: "0.5em",
    height: "30px",
  },
  textInput: {
    position: "sticky",
    top: 0,
    margin: "10px ,30px,10px, 20px",

    "& .MuiFormHelperText-contained": {
      color: "#f44336",
      marginTop: "0px",
      fontSize: "10px",
      marginLeft: "8px",
    },
  },

  box: {
    display: "flex",
    width: "220px",
    border: "1px solid #d8d8de",
    borderRadius: "50px",
  },
  filterIconCss: {
    margin: "5px",
  },
  tableCellFilter: {
    padding: "5px 16px",
    "& .MuiTableCell-root": {
      padding: "5px 16px",
    },
  },
}))
export default useStyles
