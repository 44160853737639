import React from "react"

function CustomLogo(props) {
  const { showMore } = props || {}
  return (
    <>
      {showMore ? (
        <svg
          width="48"
          height="48"
          viewBox="0 0 48 48"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M13.4465 12.3293C15.0826 12.3069 16.6213 12.5986 18.0623 13.2118C19.5033 13.8177 20.7567 14.6553 21.83 15.7099C22.9033 16.7644 23.7439 18.0059 24.3668 19.4344C24.9898 20.8554 25.2975 22.3887 25.2975 24.0191C25.2975 25.6495 24.9898 27.1827 24.3668 28.6037C23.7439 30.0248 22.9033 31.2663 21.83 32.3134C20.7567 33.3604 19.5033 34.1906 18.0623 34.7964C16.6213 35.4022 15.0826 35.6939 13.4465 35.6789H5.31061C4.91283 35.6789 4.57508 35.5443 4.30489 35.2676C4.03469 34.9983 3.89209 34.6618 3.89209 34.2654V13.7503C3.89209 13.354 4.02719 13.0174 4.30489 12.7482C4.57508 12.4789 4.91283 12.3368 5.31061 12.3368H13.4465V12.3293ZM6.72913 15.1564V32.8444H13.4465C14.6849 32.8668 15.8557 32.6424 16.944 32.1862C18.0398 31.7225 18.993 31.0943 19.811 30.3015C20.6291 29.5087 21.2746 28.5664 21.7474 27.4894C22.2203 26.4124 22.4605 25.2531 22.4605 24.0191C22.4605 22.785 22.2278 21.6258 21.7474 20.5488C21.2746 19.4718 20.6291 18.5295 19.811 17.7217C18.993 16.914 18.0323 16.2857 16.944 15.822C15.8482 15.3583 14.6849 15.1414 13.4465 15.1639H6.72913V15.1564Z"
            fill="url(#paint0_linear_936_2570)"
          />
          <path
            d="M29.3737 29.4682V34.2819C29.3737 34.6738 29.2414 35.0066 28.9693 35.2728C28.7046 35.539 28.3737 35.6795 27.984 35.6795C27.5943 35.6795 27.2634 35.5464 26.9986 35.2728C26.7339 35.0066 26.5942 34.6738 26.5942 34.2819V13.7257C26.5942 13.3338 26.7266 13.001 26.9986 12.7348C27.2634 12.4686 27.5943 12.3281 27.984 12.3281C28.3737 12.3281 28.7046 12.4612 28.9693 12.7348C29.234 13.001 29.3737 13.3338 29.3737 13.7257V25.4013L41.3593 12.7644C41.646 12.476 41.9843 12.3281 42.3813 12.3281C42.749 12.3281 43.0725 12.4538 43.3372 12.6978C43.602 12.9862 43.749 13.319 43.7711 13.6887C43.7711 14.0436 43.6387 14.369 43.3667 14.6795L34.9327 23.5897L44.352 33.2763C44.6167 33.5647 44.7564 33.9048 44.7564 34.2819C44.7564 34.6591 44.6093 34.9918 44.3226 35.258C44.0578 35.5242 43.7343 35.6647 43.3667 35.6647C42.977 35.6647 42.6387 35.5168 42.3446 35.2284L33.0208 25.601L29.3737 29.4534V29.4682Z"
            fill="url(#paint1_linear_936_2570)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_936_2570"
              x1="14.6999"
              y1="11.5665"
              x2="14.3123"
              y2="40.9068"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_936_2570"
              x1="34.918"
              y1="13.1785"
              x2="35.7805"
              y2="36.9362"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
          </defs>
        </svg>
      ) : (
        <svg
          width="245"
          height="32"
          viewBox="0 0 245 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <g filter="url(#filter0_d_1465_2584)">
            <path
              d="M130.93 23.18V29.69C130.93 30.22 130.75 30.67 130.38 31.03C130.02 31.39 129.57 31.58 129.04 31.58C128.51 31.58 128.06 31.4 127.7 31.03C127.34 30.67 127.15 30.22 127.15 29.69V1.89C127.15 1.36 127.33 0.910003 127.7 0.550003C128.06 0.190003 128.51 0 129.04 0C129.57 0 130.02 0.180003 130.38 0.550003C130.74 0.910003 130.93 1.36 130.93 1.89V17.68L147.23 0.589996C147.62 0.199996 148.08 0 148.62 0C149.12 0 149.56 0.17 149.92 0.5C150.28 0.89 150.48 1.34 150.51 1.84C150.51 2.32 150.33 2.76001 149.96 3.18001L138.49 15.23L151.3 28.33C151.66 28.72 151.85 29.18 151.85 29.69C151.85 30.2 151.65 30.65 151.26 31.01C150.9 31.37 150.46 31.56 149.96 31.56C149.43 31.56 148.97 31.36 148.57 30.97L135.89 17.95L130.93 23.16V23.18Z"
              fill="url(#paint0_linear_1465_2584)"
            />
            <path
              d="M184.9 28.85C185.12 29.35 185.15 29.84 184.98 30.32C184.84 30.71 184.61 31.02 184.29 31.24C183.97 31.46 183.6 31.58 183.18 31.58C182.4 31.58 181.82 31.23 181.46 30.53L177.72 22.76H161.68L157.9 30.53C157.73 30.87 157.49 31.13 157.19 31.31C156.88 31.49 156.55 31.58 156.18 31.58C155.79 31.58 155.42 31.47 155.09 31.24C154.75 31.02 154.53 30.71 154.42 30.32C154.22 29.79 154.25 29.3 154.5 28.85L167.98 1.09C168.34 0.359996 168.92 0 169.7 0C170.06 0 170.4 0.100009 170.71 0.290009C171.02 0.490009 171.24 0.749996 171.38 1.09L184.9 28.85ZM175.87 18.94L169.7 6.25999L163.53 18.94H175.88H175.87Z"
              fill="url(#paint1_linear_1465_2584)"
            />
            <path
              d="M213.33 9.66C213.33 10.7 213.16 11.69 212.83 12.64C212.49 13.59 212.02 14.46 211.42 15.24C210.82 16.02 210.1 16.7 209.28 17.28C208.45 17.85 207.55 18.28 206.57 18.56L212.41 28.72C212.66 29.2 212.72 29.68 212.6 30.17C212.47 30.66 212.17 31.05 211.7 31.33C211.42 31.5 211.11 31.58 210.78 31.58C210.45 31.58 210.13 31.5 209.83 31.33C209.53 31.16 209.3 30.92 209.14 30.62L202.38 18.94H192.38V29.69C192.38 30.22 192.2 30.67 191.83 31.03C191.47 31.39 191.02 31.58 190.49 31.58C189.96 31.58 189.51 31.4 189.15 31.03C188.79 30.67 188.6 30.22 188.6 29.69V2.10001C188.6 1.57001 188.78 1.11999 189.15 0.759995C189.51 0.399994 189.96 0.210007 190.49 0.210007H191.79C192.63 0.210007 193.7 0.210002 195 0.190002C196.3 0.180002 197.74 0.169998 199.3 0.169998H203.92C205.24 0.169998 206.47 0.419994 207.62 0.899994C208.77 1.38999 209.76 2.07 210.6 2.94C211.44 3.81 212.1 4.82001 212.59 5.96001C213.08 7.11001 213.33 8.34 213.33 9.66V9.66ZM209.55 9.62C209.55 8.84 209.4 8.1 209.11 7.41C208.82 6.72 208.42 6.12001 207.91 5.60001C207.41 5.08001 206.81 4.68 206.12 4.38C205.43 4.09 204.7 3.94 203.91 3.94H192.36V15.15H203.91C204.67 15.15 205.39 15 206.07 14.71C206.76 14.42 207.35 14.02 207.86 13.51C208.37 13 208.77 12.42 209.08 11.75C209.39 11.08 209.54 10.36 209.54 9.61L209.55 9.62Z"
              fill="url(#paint2_linear_1465_2584)"
            />
            <path
              d="M217.28 3.94C216.75 3.94 216.3 3.76 215.94 3.39C215.58 3.03 215.39 2.58 215.39 2.05C215.39 1.52 215.57 1.07001 215.94 0.710007C216.3 0.350007 216.75 0.160004 217.28 0.160004H240.25C240.78 0.160004 241.23 0.340007 241.59 0.710007C241.95 1.07001 242.14 1.52 242.14 2.05C242.14 2.58 241.96 3.03 241.59 3.39C241.22 3.75 240.78 3.94 240.25 3.94H230.67V29.69C230.67 30.22 230.49 30.67 230.12 31.03C229.76 31.39 229.31 31.58 228.78 31.58C228.25 31.58 227.8 31.4 227.44 31.03C227.08 30.67 226.89 30.22 226.89 29.69L226.85 3.94H217.27H217.28Z"
              fill="url(#paint3_linear_1465_2584)"
            />
            <path
              d="M16.73 0.190007C18.91 0.160007 20.96 0.55 22.88 1.37C24.8 2.18 26.47 3.30001 27.9 4.71001C29.33 6.12001 30.45 7.78001 31.28 9.69001C32.11 11.59 32.52 13.64 32.52 15.82C32.52 18 32.11 20.05 31.28 21.95C30.45 23.85 29.33 25.51 27.9 26.91C26.47 28.31 24.8 29.42 22.88 30.23C20.96 31.04 18.91 31.43 16.73 31.41H5.89C5.36 31.41 4.91 31.23 4.55 30.86C4.19 30.5 4 30.05 4 29.52V2.09C4 1.56 4.18 1.11 4.55 0.750005C4.91 0.390005 5.36 0.200001 5.89 0.200001H16.73V0.190007ZM7.78 3.97001V27.62H16.73C18.38 27.65 19.94 27.35 21.39 26.74C22.85 26.12 24.12 25.28 25.21 24.22C26.3 23.16 27.16 21.9 27.79 20.46C28.42 19.02 28.74 17.47 28.74 15.82C28.74 14.17 28.43 12.62 27.79 11.18C27.16 9.74001 26.3 8.48 25.21 7.4C24.12 6.32 22.84 5.48001 21.39 4.86001C19.93 4.24001 18.38 3.95 16.73 3.98H7.78V3.97001Z"
              fill="url(#paint4_linear_1465_2584)"
            />
            <path
              d="M65.37 17.7C64.84 17.7 64.39 17.52 64.03 17.15C63.67 16.79 63.48 16.34 63.48 15.81C63.48 15.28 63.66 14.83 64.03 14.47C64.4 14.11 64.84 13.92 65.37 13.92H77.68C78.21 13.92 78.66 14.1 79.02 14.47C79.38 14.83 79.57 15.28 79.57 15.81C79.57 17.99 79.16 20.05 78.33 21.96C77.5 23.88 76.38 25.55 74.95 26.98C73.52 28.41 71.85 29.54 69.93 30.36C68.01 31.19 65.96 31.6 63.78 31.6C61.6 31.6 59.54 31.19 57.63 30.36C55.71 29.53 54.04 28.41 52.61 26.98C51.18 25.55 50.05 23.88 49.23 21.96C48.4 20.04 47.99 17.99 47.99 15.81C47.99 13.63 48.4 11.58 49.23 9.66C50.06 7.74 51.18 6.07 52.61 4.64C54.04 3.21 55.71 2.08999 57.63 1.25999C59.55 0.429995 61.6 0.0200043 63.78 0.0200043C66.24 0.0200043 68.57 0.570004 70.77 1.66C72.97 2.75 74.82 4.28001 76.34 6.24001C76.68 6.72001 76.8 7.18 76.72 7.63C76.64 8.11 76.43 8.5 76.09 8.81C75.75 9.12 75.33 9.27 74.83 9.27C74.21 9.27 73.72 9.03 73.36 8.56C72.18 7.05 70.76 5.88 69.1 5.05C67.43 4.22 65.66 3.81 63.79 3.81C62.14 3.81 60.58 4.12999 59.13 4.75999C57.68 5.38999 56.4 6.25 55.31 7.34C54.22 8.43 53.36 9.71 52.73 11.16C52.1 12.62 51.78 14.17 51.78 15.82C51.78 17.47 52.09 19.03 52.73 20.48C53.36 21.94 54.22 23.21 55.31 24.3C56.4 25.39 57.68 26.25 59.13 26.88C60.58 27.51 62.14 27.83 63.79 27.83C65.27 27.83 66.68 27.58 68.01 27.07C69.34 26.56 70.53 25.86 71.58 24.95C72.63 24.04 73.51 22.97 74.23 21.74C74.94 20.51 75.41 19.16 75.64 17.71H65.39L65.37 17.7Z"
              fill="url(#paint5_linear_1465_2584)"
            />
            <path
              d="M98.8 3.97C98.27 3.97 97.82 3.79 97.46 3.42C97.1 3.06 96.91 2.61 96.91 2.08C96.91 1.55 97.09 1.10001 97.46 0.740005C97.82 0.380005 98.27 0.190002 98.8 0.190002H121.77C122.3 0.190002 122.75 0.370005 123.11 0.740005C123.47 1.10001 123.66 1.55 123.66 2.08C123.66 2.61 123.48 3.06 123.11 3.42C122.74 3.78 122.3 3.97 121.77 3.97H112.19V29.72C112.19 30.25 112.01 30.7 111.64 31.06C111.28 31.42 110.83 31.61 110.3 31.61C109.77 31.61 109.32 31.43 108.96 31.06C108.6 30.7 108.41 30.25 108.41 29.72L108.37 3.97H98.79H98.8Z"
              fill="url(#paint6_linear_1465_2584)"
            />
            <path
              d="M42.0901 29.52C42.0901 30.05 41.9101 30.5 41.5401 30.86C41.1801 31.22 40.7301 31.41 40.2001 31.41C39.6701 31.41 39.2201 31.23 38.8601 30.86C38.5001 30.5 38.3101 30.05 38.3101 29.52V2.10001C38.3101 1.57001 38.4901 1.11999 38.8601 0.759995C39.2201 0.399994 39.6701 0.210007 40.2001 0.210007C40.7301 0.210007 41.1801 0.389995 41.5401 0.759995C41.9001 1.11999 42.0901 1.57001 42.0901 2.10001V29.52Z"
              fill="url(#paint7_linear_1465_2584)"
            />
            <path
              d="M89.32 29.62C89.32 30.15 89.14 30.6 88.77 30.96C88.41 31.32 87.96 31.51 87.43 31.51C86.9 31.51 86.45 31.33 86.09 30.96C85.73 30.6 85.54 30.15 85.54 29.62V2.2C85.54 1.67 85.72 1.22 86.09 0.860001C86.45 0.500001 86.9 0.309998 87.43 0.309998C87.96 0.309998 88.41 0.490001 88.77 0.860001C89.13 1.22 89.32 1.67 89.32 2.2V29.62V29.62Z"
              fill="url(#paint8_linear_1465_2584)"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_1465_2584"
              x="0"
              y="0"
              width="246.14"
              height="39.61"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dy="4" />
              <feGaussianBlur stdDeviation="2" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_1465_2584"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_1465_2584"
                result="shape"
              />
            </filter>
            <linearGradient
              id="paint0_linear_1465_2584"
              x1="138.47"
              y1="1.15"
              x2="139.63"
              y2="33.28"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_1465_2584"
              x1="168.61"
              y1="0.03"
              x2="169.78"
              y2="32.16"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint2_linear_1465_2584"
              x1="200.22"
              y1="-1.08"
              x2="201.38"
              y2="31.05"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint3_linear_1465_2584"
              x1="228.62"
              y1="-2.11"
              x2="229.78"
              y2="30.02"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="#DE127F" />
              <stop offset="1" stop-color="#BC24BF" />
            </linearGradient>
            <linearGradient
              id="paint4_linear_1465_2584"
              x1="18.4"
              y1="-0.829993"
              x2="17.88"
              y2="38.4"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint5_linear_1465_2584"
              x1="63.98"
              y1="-0.229996"
              x2="63.47"
              y2="39"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint6_linear_1465_2584"
              x1="110.31"
              y1="0.380002"
              x2="109.79"
              y2="39.61"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint7_linear_1465_2584"
              x1="40.4201"
              y1="-0.539993"
              x2="39.9001"
              y2="38.69"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
            <linearGradient
              id="paint8_linear_1465_2584"
              x1="87.64"
              y1="0.0799976"
              x2="87.12"
              y2="39.31"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0.38" stop-color="white" />
              <stop offset="1" stop-color="#787878" />
            </linearGradient>
          </defs>
        </svg>
      )}
    </>
  )
}

export { CustomLogo }
export default CustomLogo
