const defaultList = [
  {
    item_id: "73649846",
    commodity: "apple",
    product: "Apple Sauce",
    selected: true,
  },
  { item_id: "93745149", commodity: "Banana", product: "Banana Shake" },
  {
    item_id: "78742587",
    commodity: "Strawberry",
    product: "Strawberry Juice",
  },
  { item_id: "02500445", commodity: "Blue Berry", product: "Blueberry Pie" },
  {
    item_id: "75878742",
    commodity: "Pomogranate",
    product: "Pomogranate Juice",
  },
  { item_id: "02500412", commodity: "test", product: "test" },
]

export { defaultList }
