import { makeStyles, useTheme } from "@material-ui/core/styles"
export const useCommonFormUIStyles = makeStyles({
  cardSadhow: {
    boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset",
  },
})

export const useCommonFormUIContentStyles = makeStyles({
  dividerBottom: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },

  listProperty: {
    display: "flex",
    maxHeight: 400,
    overflowY: "scroll",
  },
  listItem: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
    height: "80px",
    alignItems: "center",
    padding: "20px",
  },
  verticalDivider: {
    flex: 1,
  },

  textFiled1: {
    width: "280px",
    marginRight: "32px",
  },
  textFiled2: {
    width: "250px",
    marginRight: "20px",
  },
})
