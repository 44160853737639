import React from "react"
import { useScheduler } from "./useScheduler"
import { Card, Grid, Button, Typography, TextField, makeStyles } from "@material-ui/core"
import * as Adm from "@adm"
import * as Render from "./renderProps"

const useStyles = makeStyles((theme) => ({
  textInput: {
    color: "var(--primaryColor)",
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderColor: "var(--primaryColor)",
    },
  },
}))

function Scheduler(props) {
  const classes = useStyles()

  const { onUpdate = () => "", defaultList = {} } = props || {}
  const stateSchedulerProps = useScheduler({ onUpdate, defaultList })

  const renderTemplate = () => {
    return (
      <Grid>
        <Grid style={{ marginBottom: 30, display: "flex" }}>
          {Render?.renderStartDateDatePicker(stateSchedulerProps, classes)}
          <Grid style={{ marginLeft: 20 }}>
            {Render?.renderEndDateDatePicker(stateSchedulerProps, classes)}
          </Grid>
        </Grid>
        <Grid>{Render?.renderRepeatEvery(stateSchedulerProps, classes)}</Grid>
      </Grid>
    )
  }
  return renderTemplate()
}

export { Scheduler }
export default Scheduler
