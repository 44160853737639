import React from 'react';
import { Slide, Dialog, Typography, IconButton, Drawer } from '@material-ui/core';
import { CloseIcon } from '@iconsGallery'
import useStyles from './styles';
import ProductLook from '../../../products/ProductLook';
import ProductGroupLookup from '../../../products/ProductGroupLookup';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AddProductForPriceEventDialog = (props) => {

    const classes = useStyles();
    const { open, handleClose, isProductGroup = false, checkedList = [], apiName = 'buy_product', onUpdateList = () => null
    } = props;

    return (
        <Drawer anchor='bottom' open={open} onClose={handleClose} className={classes.drawer}>
            <div className={classes.appbar}>
                <Typography variant='h3'>Product {isProductGroup ? 'Group Lookup' : 'LookUp'}</Typography>
                <IconButton onClick={handleClose}><CloseIcon /></IconButton>
            </div>
            <div className={classes.content}>
                {
                    isProductGroup
                        ? <ProductGroupLookup list={checkedList} apiName={apiName}
                            onUpdateList={(d) => { onUpdateList(d); handleClose() }} />
                        : <ProductLook list={checkedList} apiName={apiName} onUpdateList={(d) => { onUpdateList(d); handleClose() }} />

                }
            </div>
        </Drawer>
    )
}

export default AddProductForPriceEventDialog;