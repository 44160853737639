import React from 'react'

function useStepperState(props) {
  const { defaultIndex = 1 } = props || {}

  const [activeStep, setActiveStep] = React.useState(defaultIndex)
  const handleClickNext = () => setActiveStep(activeStep + 1)
  const handleClickBack = () => setActiveStep(activeStep - 1)
  const handleClickReset = () => setActiveStep(0)

  return {
    activeStep,
    setActiveStep,
    handleClickNext,
    handleClickBack,
    handleClickReset,
  }
}

export {useStepperState}