import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {}, THEMES = {} } = {} } =
  constantsAPI || {}

const types = REDUX_CONSTANTS
const initialState = {
  currentTheme: THEMES.DEFAULT,
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.THEME_SET:
      return {
        ...state,
        currentTheme: actions.payload,
      }

    default:
      return state
  }
}
