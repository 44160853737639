import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
// import update from 'immutability-helper'
import { CircularProgress, makeStyles, Typography } from "@material-ui/core"
import { Helmet } from "react-helmet"
import {
  Grid,
  IconButton,
  Card,
  Collapse,
  TextField,
  Card as MuiCard,
  CardMedia,
} from "@material-ui/core"
import { DropzoneArea, DropzoneDialog } from "material-ui-dropzone"
import { spacing } from "@material-ui/system"
import Paper from "@material-ui/core/Paper"
import Divider from "@material-ui/core/Divider"
import {
  CheckCircleIcon,
  ChevronUp,
  ChevronDown,
  Save,
  Slash,
  Edit,
} from "@iconsGallery"
import { useTranslation } from "react-i18next"
import { useWindowSize } from "@utils"
import * as ServiceAPI from "@services"
import { useDispatch, useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import { getTreeFromFlatData, getFlatDataFromTree } from "react-sortable-tree"
import _, { set } from "lodash"
import * as Adm from "@adm"
import * as Features from "@features"
import { APIUrl } from "@utils"
import DraggableTreeView from "../../components/DraggableTreeView"
import { CategoryForm } from "./CategoryForm"
import productsAPI, {
  productDataApiMethods,
} from "../../services/masterData/products/api"
import useMediaQuery from "@material-ui/core/useMediaQuery"
import { useTheme } from "@material-ui/core/styles"
import {
  getProcessedFlatData,
  getNewFlatDataWithOldExpandedStatus,
  getNodeId,
  getNodeKey,
  getParentNodeId,
  getTreeData,
  getFlatData,
  getAllChildrenIds,
} from "../../components/DraggableTreeView/treeDataUtils"
import {
  deleteCategory,
  fetchAllCategories,
} from "../../services/masterData/products/actions"
import { JSUtils } from "@utils"
import CategorizedProduct from "./CategorizedProducts"
import { ConfirmationDialog } from "@features"

const { API_URL_STORES } = APIUrl || {}
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}
const getTreeDataFxn = (x, idPath, parentIdPath) =>
  getTreeData(
    x,
    (n) => _.get(n, idPath, _.get(n, `node.${idPath}`, undefined)),
    (n) => _.get(n, parentIdPath, ""),
    ""
  )
const initialFormProps = {
  isOpen: false,
  data: {},
  mode: "",
  childNodeAvailable: false,
}
const initSnackbarProps = {
  isOpen: false,
  message: "",
  type: "info",
  autoHideDuration: 2500,
}

const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
function ProductCategoryDetail(props) {
  const { isWriteAllowed, isViewMode = false, categoryType = "" } = props
  const dispatch = useDispatch()
  const { categories } = useSelector((state) =>
    _.get(state, "productsReducer", {})
  )
  const [formProps, setFormProps] = useState({ ...initialFormProps })
  const [flatTreeData, setFlatTreeData] = useState(() => {
    return categories?.list || []
  })
  const [treeData, setTreeData] = useState(
    getTreeDataFxn(
      categories?.list || [],
      "categoryId",
      "mapped_parent_categories"
    )
  )
  const [processedFlatData, setProcessedFlatData] = useState([])
  const [selectedCategories, setSelectedCategories] = useState([])
  const [snackbarProps, setSnackbarProps] = useState(initSnackbarProps)
  const [height, width] = useWindowSize()
  const { t } = useTranslation()
  const [editMode, setEditMode] = useState(false)
  const [collapseOpen, setCollapseOpen] = useState(false)
  const [data, setData] = useState([])
  const formData = useSelector((state) => state.addLocationReducer)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [allowAlcoholUpdate, setAllowAlcoholUpdate] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false)
  const [open, setOpen] = useState(false)
  const [selectedNodeId, setSelectedNodeId] = useState("")
  const [showAdd, setShowAdd] = useState(false)
  const nodeToBeKeptInExpandedStatus = useRef(undefined)
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  const [category, setCategory] = useState()
  const [isAnyChanges, setAnyChanges] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [getNode, setNode] = useState()
  let nextScreen = useRef("")
  let iconMode = useRef("")

  let selectedCategoryId = useRef([])

  console.log(formProps, "formProps")

  const handleFormClose = () => {
    iconMode.current = ""
    setSelectedNodeId("")
    setFormProps({ ...initialFormProps })
  }
  const BreadcrumbList = [
    {
      name: "Master Data",
      disable: true,
    },
    {
      name: "Products",
      url: t("/master-data/products"),
    },
    {
      name: "Product Category",
    },
  ]
  let titlePath = "category_name"
  let idPath = "categoryId"
  let parentIdPath = "mapped_parent_categories"

  const handleFormOpen = () => {
    iconMode.current = ""
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    setSelectedNodeId("")
    setFormProps((c) => ({
      ...c,
      isOpen: true,
      mode: "create",
      data: {},
    }))
  }

  const handleViewOpen = async (categoryData, parentNode) => {
    iconMode.current = "view"
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    setSelectedNodeId(categoryData._id)
    selectedCategoryId.current = []
    if (props.isComInProductMapping) {
      props.setCategory(categoryData)
    } else {
      let responseData = {}
      productDataApiMethods
        .getProductCategoryById(categoryData.categoryId)
        .then((resp) => {
          responseData = _.get(resp, "data.data.ProductCategory", {})
          responseData = { ...responseData, categoryId: responseData?._id }
          const parentCategoryName = treeData.find(
            ({ categoryId }) =>
              categoryId === categoryData.mapped_parent_categories
          )
          setFormProps({
            isOpen: true,
            mode: "view",
            data: {
              ...responseData,
            },
            openNewNode: {},
          })
        })
        .catch((err) => {
          console.log(err, "err")
        })
    }
  }

  const handleAssociatedNode = (node) => {
    let nodeProps = node?.node
    iconMode.current = "associated"
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    setSelectedNodeId(nodeProps?._id)
    selectedCategoryId.current = [
      nodeProps?.categoryId,
      nodeProps?.category_name,
    ]
    setFormProps((c) => ({
      ...c,
      isOpen: true,
      mode: "Associated",
      data: {},
    }))
  }

  const handleAddNode = (parent) => {
    iconMode.current = ""
    setLoading(true)
    setTimeout(() => {
      setLoading(false)
    }, 2000)
    nodeToBeKeptInExpandedStatus.current = _.get(parent, "id")
    setFormProps({
      isOpen: true,
      mode: "create",
      data: {
        mapped_parent_categories: _.get(parent, "id"),
        parent_name: _.get(parent, "name"),
        parent_obj: parent,
        cancelCallback: () => {
          nodeToBeKeptInExpandedStatus.current = undefined
        },
      },
    })
  }

  const handleNodeDelete = (id) => {
    productDataApiMethods
      .deleteProductCategory(id.id)
      .then((resp) => {
        fetchNewFlatTreeData()
        handleFormClose()
        iconMode.current = ""
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(resp?.data, t),
          type: "success",
        })
      })
      .catch((err) => {
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(
            err?.response?.data,
            t,
            "Sorry! Delete operation failed"
          ),
          type: "error",
        })
      })
  }

  const fetchNewFlatTreeData = (oldFlatData = getFlatData(treeData)) => {
    dispatch(fetchAllCategories())
  }

  const adopterFunction = (data) => {
    let formData = new FormData()
    formData.append("payload", JSON.stringify(data))
    return formData
  }

  const handleNodeMove = (newParentData, nodeData) => {
    let tempParentID = _.compact([
      newParentData?.categoryId,
      ...(newParentData?.mapped_parent_categories || []),
    ]) //_.isNil(newParentID) ? [null] : [newParentID];
    nodeData.mapped_parent_categories = tempParentID[0]
    let tempData = adopterFunction(nodeData)
    setSnackbarProps({
      ...initSnackbarProps,
      isOpen: true,
      message: t("Hold On! Updating..."),
      type: "info",
      icon: <CircularProgress color="secondary" />,
    })
    productDataApiMethods
      .updateCategory(nodeData?.categoryId, tempData)
      .then((resp) => {
        fetchNewFlatTreeData(getFlatData(treeData))
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(
            resp?.data,
            t,
            "Updated Successfully"
          ),
          type: "success",
        })
      })
      .catch((err) => {
        setSnackbarProps({
          ...initSnackbarProps,
          isOpen: true,
          message: JSUtils.formatMessageCode(
            err?.data,
            t,
            "Sorry! Update operation failed"
          ),
          type: "error",
        })
      })
  }

  const handleCheckboxClick = (node, parentNode) => {
    let exstIdx = _.findIndex(selectedCategories, (o) => o === node?.categoryId)
    let childrenIds = getAllChildrenIds(node) || []
    let allIdsOfNode = [node?.categoryId, ...childrenIds]
    let temp
    if (exstIdx === -1) {
    } else {
      temp = _.without(selectedCategories, ...(allIdsOfNode || []))
    }
    setSelectedCategories(temp)
    setProcessedFlatData(
      getProcessedFlatData({ data: getFlatData(treeData), checkedList: temp })
    )
  }

  useEffect(fetchNewFlatTreeData, [])

  useEffect(() => {
    setProcessedFlatData(
      getProcessedFlatData({
        data: flatTreeData,
        checkedList: selectedCategories,
      })
    )
  }, [flatTreeData])

  useEffect(() => {
    setFlatTreeData(
      getNewFlatDataWithOldExpandedStatus(
        categories?.list || [],
        getFlatData(treeData),
        "categoryId",
        nodeToBeKeptInExpandedStatus.current,
        () => {
          nodeToBeKeptInExpandedStatus.current = undefined
        }
      )
    )
  }, [categories.list])

  useEffect(() => {
    let temp = getTreeDataFxn(
      processedFlatData,
      "categoryId",
      "mapped_parent_categories"
    )
    setTreeData(temp)
  }, [processedFlatData])

  const handleCustomCloseRedirect = () => {
    setAnyChanges(false)
    if (nextScreen.current === "Create") {
      handleFormOpen()
    } else if (nextScreen.current === "associate") {
      handleAssociatedNode(getNode)
    } else if (nextScreen.current === "openView") {
      handleViewOpen(_.get(getNode, "node"), _.get(getNode, "parentNode"))
    }
    setOpenConfirmationDialog(false)
  }

  return (
    <>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        deleteContent={`${t("Any unsaved changes will be lost")}.${t(
          "Are you sure you want to continue?"
        )}`}
      />
      <PageHeaderBreadcrumbKit
        container
        justify="space-between"
        alignContent="center"
        alignItems="center"
        className="PageHeaderBreadcrumbKit"
      >
        <Grid>
          <Adm.Breadcrumb list={BreadcrumbList} />
        </Grid>
      </PageHeaderBreadcrumbKit>
      <Helmet
        title={t(
          categoryType === "product" ? "Product Category" : "Hierarchy Editor"
        )}
      />
      <Grid
        container
        item
        xs={12}
        lg={12}
        md={12}
        sm={12}
        style={{ height: `100%` }}
        className="cls-producthierarchy"
      >
        <Adm.BackdropOverlay open={loading} />
        <div style={{ borderBottom: "1px solid #E0E0E0", width: "100%" }}>
          <Typography
            className="cls-producthierarchyheader"
            style={{
              fontWeight: 600,
              fontSize: "20px",
              padding: "10px 10px",
              background: "#fff",
            }}
          >
            {t(
              categoryType === "product"
                ? "Product Category"
                : "Hierarchy Editor"
            )}
          </Typography>
        </div>
        <Grid
          container
          style={{ background: "#ffff", height: `100%`, width: "100%" }}
        >
          <Grid
            item
            xs={formProps?.isOpen ? 5 : 12}
            sm={formProps?.isOpen ? 5 : 12}
            md={formProps?.isOpen ? 5 : 12}
            lg={formProps?.isOpen ? 5 : 12}
            style={{
              display: "flex",
              flexDirection: isMobile ? "row" : "",
              overflowX: "auto",
            }}
          >
            <DraggableTreeView
              iconMode={iconMode}
              categoryType={categoryType}
              showInnerAdd={setShowAdd}
              BreadcrumbList={BreadcrumbList[2].name}
              isWriteAllowed={isWriteAllowed}
              isViewMode={isViewMode}
              treeData={treeData}
              onChangeInTreeData={setTreeData}
              handleCheckboxClick={handleCheckboxClick}
              onAddClick={(node) => {
                nextScreen.current = "Create"
                setNode(props)
                if (isAnyChanges) {
                  setOpenConfirmationDialog(true)
                } else {
                  handleFormOpen(node)
                }
              }}
              flatTreeData={processedFlatData}
              lookupType={"Product"}
              handleAssociatedNode={(props) => {
                nextScreen.current = "associate"
                setNode(props)
                if (isAnyChanges) {
                  setOpenConfirmationDialog(true)
                } else {
                  handleAssociatedNode(props)
                }
              }}
              handleAddNode={handleAddNode}
              handleNodeMove={handleNodeMove}
              handleNodeDelete={handleNodeDelete}
              onEditClick={(node) => {
                nextScreen.current = "openView"
                setNode(node)
                if (isAnyChanges) {
                  setOpenConfirmationDialog(true)
                } else {
                  handleViewOpen(_.get(node, "node"), _.get(node, "parentNode"))
                }
              }}
              selectedNodeId={selectedNodeId}
              isComInProductMapping={
                props.isComInProductMapping && props.isComInProductMapping
              }
              canDragPropInProductMapping={
                props.canDragPropInProductMapping &&
                props.canDragPropInProductMapping
              }
              handlerParamsMaker={(n) => ({
                id: _.get(n, "node.categoryId"),
                name: _.get(n, "node.category_name"),
                obj: _.get(n, "node"),
                parentObj: _.get(n, "parentNode"),
              })}
              showAddRootNodeButton={props.showIcon === false ? false : true}
            />
          </Grid>
          {formProps?.isOpen && (
            <>
              <Grid
                item
                xs={7}
                sm={7}
                lg={7}
                md={7}
                xl={7}
                style={{
                  borderLeft: `1px solid #e0e0e0`,
                  overflowX: "auto",
                  width: "auto",
                  background: "#f8f8f8",
                  height: "fit-content",
                }}
              >
                {formProps?.mode === "Associated" ? (
                  <CategorizedProduct
                    category={category}
                    hideLeftHierarchy={true}
                    isLookUp={false}
                    ShowCheckBox={true}
                    isWriteAllowed={isWriteAllowed}
                    selectedCategoryId={selectedCategoryId}
                    iconMode={iconMode}
                    onClose={handleFormClose}
                    categoryType={categoryType}
                    setAnyChanges={setAnyChanges}
                  />
                ) : (
                  <CategoryForm
                    setAnyChanges={setAnyChanges}
                    formProps={formProps}
                    setFormProps={setFormProps}
                    onClose={handleFormClose}
                    data={formProps?.data || {}}
                    fetchNewFlatTreeData={fetchNewFlatTreeData}
                    handleViewOpen={handleViewOpen}
                    handleAssociatedNode={handleAssociatedNode}
                    isWriteAllowed={isWriteAllowed}
                  />
                )}
              </Grid>
              <Grid style={{ width: "100%", padding: "10px" }}></Grid>
            </>
          )}
        </Grid>
      </Grid>
      {snackbarProps?.isOpen && (
        <Adm.SnackBar
          open
          message={snackbarProps?.message || "Uh! May be wait!?"}
          type={snackbarProps?.type || "info"}
          icon={snackbarProps?.icon || undefined}
          handleClose={() => setSnackbarProps(initSnackbarProps)}
        />
      )}
    </>
  )
}
export { ProductCategoryDetail }
export default ProductCategoryDetail
