export const productColumnData = [
  {
    field: "title",
    title: "Title",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 15,
  },
  {
    field: "barcodeValue",
    title: "Item Barcode Value",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 12,
  },
  {
    field: "stockCode",
    title: "SKU",
    editable: false,
    editor: "text",
    border: true,
    filter: true,
    show: true,
    min_width: 10,
  },
]
