import { makeStyles, useTheme } from "@material-ui/core/styles"
import { maxHeight } from "@material-ui/system"
import Styled from "styled-components"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  CardHeader,
  Collapse,
  TextField,
  Button,
  ListItemText,
  Grid,
  Typography,
  Checkbox,
} from "@material-ui/core"

export const SearchNotResponse = Styled(Typography)`
  padding: 20px;
`

export const FormWrapperKit = Styled(Grid)`
  background: white;
  padding: 0 16px 16px 16px;
  box-sizing: border-box;
  .cls-search-input {
    border: 0px;
  }
  
  .base-form-fieldset-list-innerwrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 0;
    .base-form-label-main-wrapper {
      width: 330px;
      padding: 10px 0;
    }
  }
  .base-form-label-main-wrapper {
    width: 340px;
    label {
      font-family: "Robato", Arial;
      font-size: 14px !important;
      font-weight: 600 !important;
    }
  }
  .button-group {
    grid-column: 1 / span 2;
    display: flex;
    justify-content: space-between;
  }
  .cls-search-list-parent-wrapper {
    .cls-search-item-wrapper {
      border-top: none !important;
      button.MuiIconButton-root {
        margin-left: auto !important;
        height: 40px;
        position: absolute;
        right: 0px;
        top: 0;
        bottom: 0;
        margin: auto;
      }
    }
  }

  .cls-search-list-header-wrapper, .cls-search-item-wrapper{
    height: 60px;
    .cls-search-item-header-id, .cls-search-item-header-title,
    .cls-search-item-key-wrapper, .cls-search-item-value-wrapper {
      height: 60px;
      position: relative;
    }
  }

`
export const SearchSuggesionItemWrapper = Styled(Grid)`
  display: flex;
  align-items: center;
  height:60px;

  &.active {
    background: #eaeaea;
  }
`
export const TitleWrapper = Styled(Typography)`
font-weight:bold
`
export const SubTitleWrapper = Styled(Typography)`
    line-height: 21px;
`
export const SearchSuggesionItemProductId = Styled(Typography)`
  width: 100px;
`

export const ViewItemWrapper = Styled(Grid)`
  display: flex;
  align-items: center;
`
export const ViewProductIdItemWrapper = Styled(Grid)`
  margin-right:10px;
  width: 150px;
  height:40px;
  align-items: center;
  display: flex;
`
export const ViewProductNameItemWrapper = Styled(Grid)`
  margin-right:10px;
  width: 250px;
  height:40px;
  align-items: center;
  display: flex;
`
export const ViewHeaderItemWrapper = Styled(Grid)`
  display: flex;
  align-items: center;
  height:40px;
  align-items: center;
  display: flex;
`
export const ViewHeaderProductIdItemWrapper = Styled(Grid)`
  margin-right:10px;
  width: 150px;
  height:40px;
  align-items: center;
  display: flex;

`
export const ViewHeaderProductNameItemWrapper = Styled(Grid)`
 margin-right:10px;
  width: 250px;
`

export const TextFieldWrapper = Styled(TextField)`
  width: 100%;
  margin-right:15px;
`
export const SearchSuggesionListWrapper = Styled(Grid)`
  width: 320px;
  margin-right:15px;
  background: #eaeaea;
  position: absolute !important;
  z-index: 99 !important;
  left: 0;
  top: 40px;
`
export const SearchInputMainWrapper = Styled(Grid)`
  display:flex;
  align-items: center;

  
`
export const SearchInputSubWrapper = Styled(Grid)`
  position: relative;
  width: 320px;
  display: flex;
  margin-right: 15px;
`

export const SearchInputCloseBttonWrapper = Styled(IconButton)`
    position: absolute !important;
    z-index: 99 !important;
    right: 16px;
    top: -4px;
`

export const useFacetUIStyles = makeStyles({
  cardSadhow: {
    boxShadow: "0px -1px 0px 0px rgba(0, 0, 0, 0.12) inset",
  },
})

export const useFacetUIContentStyles = makeStyles({
  dividerBottom: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
  },

  listProperty: {
    display: "flex",
    maxHeight: 400,
    overflowY: "scroll",
  },
  listItem: {
    borderBottom: "0.1em solid rgba(0, 0, 0, 0.1)",
    height: "50px",
    alignItems: "center",
    padding: "20px",
  },
  verticalDivider: {
    // borderRight: "0.1em solid rgba(0, 0, 0, 0.1)",
    flex: 1,
  },

  textFiled1: {
    width: "320px",
    marginRight: "32px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  textFiled2: {
    width: "214px",
    marginRight: "20px",
    marginTop: "12px",
    marginBottom: "12px",
  },
  addButton: {
    backgroundColor: "#1565C0;",
    color: "white",
    maxWidth: "85px",
    maxHeight: "36px",
    marginTop: "12px",
  },
  formControl: {
    width: "320px",
  },
})
