import React from "react"
import { useTranslation } from "react-i18next"

function useScheduler(props) {
  const { t } = useTranslation()
  const {
    onUpdate = () => "",
    defaultList = {
      startDate: "2023-03-28",
      endDate: "2023-03-28",
      repeatevery_day: "Day",
      repeatevery_days: ["Monday"],
      repeatevery_timer_type: "Immediate",
      repeatevery_timer_value: "18:00",
      repeatevery_month_value: [
        {
          id: 1,
          repeatevery_days: ["Monday"],
        },
      ],
    },
  } = props || {}
  const getDefaultDaysList = [
    {
      name: "Sunday",
      letter: "S",
      selected: false,
    },
    {
      name: "Monday",
      letter: "M",
      selected: false,
    },
    {
      name: "Tuesday",
      letter: "T",
      selected: false,
    },
    {
      name: "Wednesday",
      letter: "W",
      selected: false,
    },
    {
      name: "Thursday",
      letter: "T",
      selected: false,
    },
    {
      name: "Friday",
      letter: "F",
      selected: false,
    },
    {
      name: "Saturday",
      letter: "S",
      selected: false,
    },
  ]

  const getDefaultMonthsList = [
    {
      name: "On day",
      selected: true,
    },
    {
      name: "On the",
      selected: false,
    },
  ]
  const getDefaultTimerList = [
    {
      name: "Immediate",
      selected: false,
    },
    {
      name: "Time",
      selected: false,
    },
  ]
  const getDefaultRecurrence = defaultList
  const [getRepeateveryDayList, setRepeateveryDayList] = React.useState([
    "Immediate",
    "Day",
    "Week",
    "Month",
  ])
  const [
    getRepeateveryOnTheTypeList,
    setRepeateveryOnTheTypeList,
  ] = React.useState(["First", "Second", "Third", "Fourth", "Last"])
  const [
    getRepeatEveryOnTheDayList,
    setRepeatEveryOnTheDayList,
  ] = React.useState()
  const [getTimerList, setTimerList] = React.useState([])
  const [getMonthsList, setMonthsList] = React.useState([])
  const [getDaysList, setDaysList] = React.useState([])
  const [getRecurrence, setRecurrence] = React.useState()
  React.useEffect(() => {
    if (getDefaultRecurrence?.repeatevery_timer_type !== undefined) {
      let miniList = getDefaultTimerList.map((itm) => {
        let obj = { selected: false }
        if (itm?.name === getDefaultRecurrence?.repeatevery_timer_type) {
          obj = { selected: true }
        }
        return { ...itm, ...obj }
      })

      setTimerList(miniList)
    }
    setRepeatEveryOnTheDayList(getDefaultDaysList?.map((itm) => itm?.name))

    if (getDefaultRecurrence?.repeatevery_month_selected !== undefined) {
      let miniList = getDefaultMonthsList.map((itm) => {
        let obj = { selected: false }
        if (itm?.name === getDefaultRecurrence?.repeatevery_month_selected) {
          obj = { selected: true }
        }
        return { ...itm, ...obj }
      })

      setMonthsList(miniList)
    }

    if (getDefaultRecurrence?.repeatevery_days !== undefined) {
      setDaysList(
        getDefaultDaysList.map((itm) => {
          let obj = { selected: itm?.selected }

          if (
            getDefaultRecurrence?.repeatevery_days &&
            getDefaultRecurrence?.repeatevery_days.includes(itm?.name)
          ) {
            obj = { selected: true }
          }
          return { ...itm, ...obj }
        })
      )
    }
    setRecurrence(getDefaultRecurrence)
  }, [defaultList])

  const onChangeValueTextField = (eventProps) => {
    const { value = "", fieldName = "" } = eventProps

    let dataUpdate = getRecurrence
    dataUpdate[fieldName] = value
    setRecurrence({ ...dataUpdate })
    onUpdate({ updatedList: { ...dataUpdate } })
  }
  return {
    getDaysList,
    setDaysList,
    getRecurrence,
    setRecurrence,
    onChangeValueTextField,
    getRepeateveryDayList,
    setRepeateveryDayList,
    getMonthsList,
    setMonthsList,
    getRepeateveryOnTheTypeList,
    setRepeateveryOnTheTypeList,
    getRepeatEveryOnTheDayList,
    setRepeatEveryOnTheDayList,
    getTimerList,
    setTimerList,
    t
  }
}

export { useScheduler }
