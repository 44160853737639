import React from "react"
import {
  Card,
  Grid,
  Button,
  Typography,
  TextField,
  makeStyles,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Link,
  Tooltip,
  IconButton,
} from "@material-ui/core"
import * as IconsGallery from "@iconsGallery"
import Download from "react-feather/dist/icons/download"
import {
  StyledDiv,
  StyledGridWrap,
  StyledGrid,
  StyledTypography,
  StyledTemplateWrapper,
} from "./styles.js"
import * as Styles from "./styles.js"

export const renderDropzone = (props) => {
  const {
    dropZoneHeading,
    dropZoneSubHeading,
    dropZoneSupportedFileDetails,
    dropZoneSizeText,
    getInputProps,
    getFileImpStatus = {},
    errorMessage,
    isPreUploadStarted = false,
    getFileSize = () => "",
    uploadFileState = "",
    isSync = false,
    t,
  } = props

  return (
    <StyledGridWrap item xs={8}>
      {!uploadFileState ? (
        <>
          {isSync ? (
            <>{renderFileImportExportSync({ t })}</>
          ) : (
            <>
              <IconsGallery.CloudUpload
                style={{ fontSize: 60, marginRight: "15px" }}
              />
              <input
                style={{ display: "none" }}
                id="raised-button-file"
                type="file"
                {...getInputProps()}
              />
              <label
                htmlFor="raised-button-file"
                className="cls-raised-button-file"
              >
                <span style={{ textAlign: "left" }}>
                  <Typography variant="h4">{t(dropZoneHeading)}</Typography>
                  <Typography variant="subtitle2">
                    {t(dropZoneSubHeading)}
                  </Typography>
                  <Typography variant="body2">
                    {t(dropZoneSupportedFileDetails)}
                  </Typography>
                  <Typography variant="caption" display="block">
                    {t(dropZoneSizeText)}
                  </Typography>
                  {errorMessage && (
                    <p style={{ color: "red", position: "absolute", top: 70 }}>
                      {t(errorMessage)}
                    </p>
                  )}
                </span>
              </label>
            </>
          )}
        </>
      ) : (
        <>
          {isPreUploadStarted ? (
            renderUploadingFileDetails({ ...getFileImpStatus, getFileSize, t })
          ) : (
            <></>
          )}
        </>
      )}
    </StyledGridWrap>
  )
}
export const renderStatusProcessing = (props) => {
  const {
    percentageCompleted = 0,
    uploadFileState = "",
    uploadErrorMessage = "",
    doSkipError = () => "",
    doViewLogs = () => "",
    getFileImpStatus = {},
    t = () => "",
    showSkipError = false,
  } = props
  const { failed_Count = 0 } = getFileImpStatus || {}
  return (
    <StyledTemplateWrapper>
      <StyledTypography
        variant="h2"
        style={{
          display: uploadFileState ? "block" : "none",
          color: "rgba(0,0,0,0.7)",
          fontSize: 24,
          fontWeight: "bold",
        }}
      >
        {`${percentageCompleted > 0
          ? percentageCompleted.toFixed(0)
          : percentageCompleted
          }%`}
      </StyledTypography>

      <StyledTypography
        variant="h6"
        className="cls-file-state"
        style={{
          display: uploadFileState ? "block" : "none",
          fontSize: 14,
          color: "rgba(0,0,0,0.7)",
        }}
      >
        {t(`${uploadFileState}`)}
      </StyledTypography>

      <StyledTypography
        variant="h6"
        className={`cls-dkph-trim`}
        style={{
          display:
            failed_Count > 0 ? "block" : showSkipError ? "block" : "none",
          color: "rgba(255,0,0,1)",
          fontWeight: "600",
          fontSize: 14,
        }}
      >
        {uploadErrorMessage ||
          (failed_Count > 0 ? `${failed_Count} ${t("Records Failed")}.` : ``)}
        {(percentageCompleted === 100 && failed_Count > 0) || showSkipError ? (
          <Button
            className="cls-dk-link-btn-skip"
            style={{
              display: failed_Count > 0 || showSkipError ? "block" : "none",
              marginRight: 5,
            }}
            underline="always"
            onClick={() => {
              doSkipError()
            }}
            disabled={failed_Count > 0 || showSkipError ? false : true}
          >
            {t(`Skip the error`)}
          </Button>
        ) : (
          <></>
        )}
      </StyledTypography>

      {percentageCompleted === 100 && failed_Count > 0 && (
        <Button
          className="cls-dk-link-btn-view"
          style={{
            position: "absolute",
            left: 100,
            top: 65,
            display: failed_Count > 0 ? "block" : "none",
          }}
          underline="always"
          onClick={() => {
            doViewLogs()
          }}
          disabled={failed_Count > 0 ? false : true}
        >
          {t(`View more`)}
        </Button>
      )}
    </StyledTemplateWrapper>
  )
}
const renderFileImportExportSync = (syncProps) => {
  const { t = () => "" } = syncProps
  return (
    <Styles.StyledSyncWrapper>
      <Styles.StyledSyncTypography
        variant="body2"
        className="cls-sync-ImportExport"
      >
        {t("Sychronizing_Status")}
      </Styles.StyledSyncTypography>
      <div className={`loading-bar animate`}>
        <span></span>
      </div>
    </Styles.StyledSyncWrapper>
  )
}
const renderUploadingFileDetails = (fileProps) => {
  const { fileName = "", fileSize = 0, getFileSize = () => "", t } = fileProps
  return (
    <Styles.StyledFilePreUploadDetails>
      <Styles.StyledFileTypography
        variant="subtitle2"
        className={`cls-dkp-file-name`}
      >
        {fileName || "File"}
      </Styles.StyledFileTypography>
      <Styles.StyledFileTypography
        variant="body2"
        className={`cls-dkp-file-size`}
      >{`${t("Size")}: ${getFileSize(fileSize || 0).toFixed(
        2
      )}(KB)`}</Styles.StyledFileTypography>
      <Styles.StyledFileTypography
        variant="body2"
        className={`cls-dkp-file-status`}
      >
        {t(`File is getting processed to be upload`)}
      </Styles.StyledFileTypography>
    </Styles.StyledFilePreUploadDetails>
  )
}
export const renderTemplateDetails = (props) => {
  const {
    templateText,
    templateFileText,
    percentageCompleted,
    processingText,
    onUpdateDownload,
    uploadFileState = "",
    uploadFileInitiated = false,
    uploadFileSuccess = false,
    uploadErrorMessage = "",
    doSkipError = () => "",
    doViewLogs = () => "",
    getFileImpStatus = {},
    t = () => "",
    showSkipError = false,
    isShowTemplateDownload = true
  } = props
  return (
    <Grid className={`cls-dkp-progress-wrapper`}>
      {uploadFileState ? (
        renderStatusProcessing({
          percentageCompleted,
          processingText,
          onUpdateDownload,
          uploadFileState,
          uploadFileInitiated,
          uploadFileSuccess,
          uploadErrorMessage,
          doSkipError,
          doViewLogs,
          getFileImpStatus,
          t,
          showSkipError,
        })
      ) : (
        isShowTemplateDownload && (<StyledDiv>
          <IconButton
            onClick={() => {
              onUpdateDownload()
            }}
            className="cls-dk-icon-btn"
          >
            <Download className="cls-dk-float" />
          </IconButton>
          <Typography variant="caption" gutterBottom>
            <div className="cls-download-txt">{templateText}</div>
            {/* <br /> */}
            <div className="cls-download-txt">{templateFileText}</div>
          </Typography>
        </StyledDiv>)
      )}
    </Grid>
  )
}
export const renderErrorFileDownload = (errorProps) => {
  const {
    errorLogTitle = "Missing Uploading Source",
    doHandleDownloadFile = () => "",
    getFileImpStatus = {},
    setIsLocalDownload = () => false,
    setOpenDownloadModal = () => false,
    t = () => "",
  } = errorProps
  const { failed_Records_Url = "" } = getFileImpStatus || {}
  return (
    <Styles.StyledErrorDiv
      style={{ display: failed_Records_Url ? "flex" : "none" }}
    >
      <Typography
        className="cls-dk-error-wrapper"
        variant="caption"
        gutterBottom
      >
        {t(errorLogTitle)}
        <br />
      </Typography>
      <IconButton
        disabled={failed_Records_Url ? false : true}
        onClick={() => {
          setIsLocalDownload(false)
          setOpenDownloadModal(true)
          doHandleDownloadFile({
            url: failed_Records_Url,
            fileExtension: "xlsx",
            fileName: t("MissingUploadSource"),
            isErrorLog: true,
          })
        }}
        style={{
          cursor: "pointer",
          marginTop: "0px",
          marginRight: "0px",
        }}
      >
        <Download className="cls-dk-float-inverse" />
      </IconButton>
    </Styles.StyledErrorDiv>
  )
}
export const renderExportDetails = (props) => {
  const {
    exportSourceText,
    exportSourceDate,
    exportViewText,
    exportFormatText,
    chooseExport,
    formats,
    setChooseExport,
    setFormats,
    handleExport,
    isSync = false,
    t = () => "",
  } = props
  return (
    <StyledGrid>
      <FormControl size="small" className="cls-dk-formcontrol-1">
        <Typography variant="caption" style={{ textAlign: "left" }}>
          {exportViewText}
        </Typography>
        <Select
          className="cls-dk-menu-1"
          labelId="demo-select-small-label"
          id="chooseExportId"
          value={chooseExport}
          onChange={(event) => setChooseExport(event.target.value)}
          variant="outlined"
        >
          <MenuItem value={"AllRecords"}>{t("All Records")}</MenuItem>
          <MenuItem value={"Current View"}>{t("Current View")}</MenuItem>
        </Select>
      </FormControl>
      <FormControl size="small" className="cls-dk-formcontrol-2">
        <Typography variant="caption" style={{ textAlign: "left" }}>
          {exportFormatText}
        </Typography>
        <Select
          className="cls-dk-menu-2"
          labelId="demo-select-small-label"
          id="formatsId"
          value={formats}
          onChange={(event) => setFormats(event.target.value)}
          variant="outlined"
        >
          <MenuItem value={"xlsx"}>XLSX</MenuItem>
          <MenuItem value={"csv"}>CSV</MenuItem>
        </Select>
      </FormControl>
      <IconButton
        disabled={isSync}
        onClick={handleExport}
        className="cls-dk-icon-btn"
        style={{
          display: isSync ? "none" : "block",
        }}
      >
        <Download className="cls-dk-float" />
      </IconButton>

      {isSync && <Styles.StyledSpinner />}
    </StyledGrid>
  )
}
