import React from "react"
import loadable from "@loadable/component"
import * as Adm from "@adm"

const loadingKit = {
  fallback: <Adm.BackdropOverlay />,
}

const SignIn = loadable(() => import("../pages/auth/SignIn"), loadingKit)
const SignUp = loadable(() => import("../pages/auth/SignUp"), loadingKit)
const ResetPassword = loadable(
  () => import("../pages/auth/ResetPassword"),
  loadingKit
)
const Page404 = loadable(() => import("../pages/auth/Page404"), loadingKit)
const Page500 = loadable(() => import("../pages/auth/Page500"), loadingKit)
const Page403 = loadable(() => import("../pages/auth/Page403"), loadingKit)

const FrictionlessStore = loadable(
  () => import("../pages/spacePlanning/FrictionlessDetailedView"),
  loadingKit
)

const ForgetPassword = loadable(
  () => import("../pages/auth/ForgetPassword"),
  loadingKit
)
const ProfileUpdate = loadable(
  () => import("../pages/auth/ProfileUpdate"),
  loadingKit
)
const SassDashboard = loadable(
  () => import("../pages/SassDashboard"),
  loadingKit
)
const Dashboard = loadable(() => import("../pages/dashboard"), loadingKit)
const Countries = loadable(
  () => import("../pages/content/countries"),
  loadingKit
)
const USStates = loadable(() => import("../pages/content/usStates"), loadingKit)
const Currencies = loadable(
  () => import("../pages/content/currencies"),
  loadingKit
)

const LanguageSync = loadable(
  () => import("../pages/content/languages/LanguageSync"),
  loadingKit
)

const Gender = loadable(() => import("../pages/content/gender"), loadingKit)
const CanadianProvincesList = loadable(() =>
  import("../pages/content/canadianProvincesList")
)
const Languages = loadable(
  () => import("../pages/content/languages"),
  loadingKit
)
const UnitsOfMeasure = loadable(
  () => import("../pages/content/unitsOfMeasure"),
  loadingKit
)
const BarcodeTypes = loadable(
  () => import("../pages/content/barcodeTypes"),
  loadingKit
)
const Tax = loadable(() => import("../pages/content/tax"), loadingKit)
const Locations = loadable(
  () => import("../pages/masterData/locations"),
  loadingKit
)

const Groups = loadable(() =>
  import("../pages/masterData/locations/GroupLocation/GroupLocationsList")
)

const Hierarchy = loadable(
  () => import("../pages/masterData/Hierarchy"),
  loadingKit
)

const HierarchyMapping = loadable(
  () => import("../pages/masterData/HierarchyMapping/index"),
  loadingKit
)

const ConfigurePos = loadable(
  () => import("../pages/frictionlessStore/deviceConfig/ConfigurePos/index"),
  loadingKit
)

const MainPos = loadable(
  () => import("../pages/frictionlessStore/deviceConfig/ConfigurePos/Pos"),
  loadingKit
)

const CreateNewPos = loadable(
  () =>
    import(
      "../pages/frictionlessStore/deviceConfig/ConfigurePos/CreatePos/index"
    ),
  loadingKit
)

const PosDetailedScreen = loadable(
  () =>
    import(
      "../pages/frictionlessStore/deviceConfig/ConfigurePos/PosDetailed/index"
    ),
  loadingKit
)

const ProductAttribute = loadable(
  () => import("../pages/masterData/products/ProductAttribute/index"),
  loadingKit
)

const Products = loadable(
  () => import("../pages/masterData/products/ProductsList"),
  loadingKit
)

const Inventory = loadable(
  () => import("../pages/masterData/inventory/storeInventory"),
  loadingKit
)

const InventoryAdjustment = loadable(
  () => import("../pages/masterData/inventory/inventoryAdjustment"),
  loadingKit
)

const InventoryCounts = loadable(
  () => import("../pages/masterData/inventory/inventoryCounts"),
  loadingKit
)

const InventoryAuditReports = loadable(
  () =>
    import("../pages/masterData/inventory/inventoryReports/AuditReport/index"),
  loadingKit
)

const InventorySnapshotReports = loadable(
  () =>
    import(
      "../pages/masterData/inventory/inventoryReports/SnapshotReport/index"
    ),
  loadingKit
)

const InventoryAdjustmentEdit = loadable(
  () =>
    import(
      "../pages/masterData/inventory/inventoryAdjustment/EditInventoryAdjustment"
    ),
  loadingKit
)

const AdjustInventoryView = loadable(
  () =>
    import(
      "../pages/masterData/inventory/inventoryAdjustment/AdjustInventoryView"
    ),
  loadingKit
)

const ProductsGroups = loadable(
  () => import("../pages/masterData/products/ProductGroupPage"),
  loadingKit
)

const ProductDataHierarchy = loadable(
  () => import("../pages/masterData/products/ProductHierarchyPage"),
  loadingKit
)

const ProductDataMapping = loadable(
  () => import("../pages/masterData/products/ProductsMapping/ProductMapping"),
  loadingKit
)

const Customers = loadable(
  () => import("../pages/masterData/customers"),
  loadingKit
)

const Price = loadable(() => import("../pages/masterData/price"), loadingKit)
const Transactions = loadable(() => import("../pages/transactions"), loadingKit)
const TrainingProducts = loadable(
  () => import("../pages/masterData/trainingProducts"),
  loadingKit
)

const TrainingProductsCreate = loadable(
  () => import("../pages/trainingProducts/create"),
  loadingKit
)

const ModelTrainingCreate = loadable(
  () =>
    import(
      "../pages/masterData/trainingProducts/modelTraining/createEditModelTraining"
    ),
  loadingKit
)

const ClassificationCreate = loadable(
  () => import("../pages/hitl/createEditHtil"),
  loadingKit
)

const productValidationImagesView = loadable(
  () =>
    import(
      "../pages/masterData/trainingProducts/productValidation/ProductImagesView"
    ),
  loadingKit
)

const hitlClassificationUImagesView = loadable(
  () => import("../pages/hitl/classification/ProductImagesView"),
  loadingKit
)

const hitlSegmentationUImagesView = loadable(
  () => import("../pages/hitl/segmentation/ProductImagesView"),
  loadingKit
)

const TrainingProductsEdit = loadable(
  () => import("../pages/trainingProducts/create"),
  loadingKit
)
const User = loadable(() => import("../pages/userManagement/users"), loadingKit)
const RolePermission = loadable(
  () =>
    import("../pages/userManagement/rolePermission/rolePermissionList/index"),
  loadingKit
)
const Permission = loadable(
  () => import("../pages/userManagement/permission/index"),
  loadingKit
)
const ProductCatalog = loadable(
  () => import("../pages/settings/productcatalog/index"),
  loadingKit
)
const ProductCatalogAdd = loadable(
  () => import("../pages/settings/ProductCatalogAdd/index"),
  loadingKit
)
const ProductCatalogEdit = loadable(
  () => import("../pages/settings/productCatalogEdit/ProductCatalogEdit"),
  loadingKit
)
const TaxConfig = loadable(
  () => import("../pages/settings/taxconfig/taxConfiglist"),
  loadingKit
)

const AvalaraConfig = loadable(() =>
  import("../pages/settings/avalaraconfig/AvalaraConfigList")
)

const AvalaraConfigAdd = loadable(() =>
  import("../pages/settings/avalaraconfig/AvalaraCommon")
)
const AvalaraConfigEdit = loadable(() =>
  import("../pages/settings/avalaraconfig/AvalaraCommon")
)

const TaxConfigAdd = loadable(
  () => import("../pages/settings/taxconfig/taxConfigAdd/index"),
  loadingKit
)

const TaxConfigEdit = loadable(
  () => import("../pages/settings/taxconfig/taxConfigEdit/index"),
  loadingKit
)

const RolePermissionDetails = loadable(
  () => import("../pages/userManagement/rolePermission/index"),
  loadingKit
)
const RolePermissionTenantDetails = loadable(
  () => import("../pages/userManagement/rolePermissionTenant/index"),
  loadingKit
)
const System = loadable(() => import("../pages/system"), loadingKit)
const EditProfile = loadable(() => import("../pages/editProfile"), loadingKit)
const Settings = loadable(() => import("../pages/settings"), loadingKit)
const Help = loadable(() => import("../pages/help"), loadingKit)
const Support = loadable(() => import("../pages/support"), loadingKit)
const Privacy = loadable(() => import("../pages/privacy"), loadingKit)

const settingPmsConfig = loadable(() => import("../pages/settings/pmsConfig"))

const settingPmsCreateEditConfig = loadable(() =>
  import("../pages/settings/pmsConfig/pmsConfigCreateEdit")
)

const settingPaymentGatewayTerminal = loadable(
  () => import("../pages/settings/PaymentGatewayTerminal"),
  loadingKit
)
const settingPaymentGatewayMobile = loadable(
  () => import("../pages/settings/PaymentGatewayMobile"),
  loadingKit
)

const settingsReceiptPrintTemplate = loadable(
  () => import("../pages/settings/ReceiptPrintTemplate/ReceiptList"),
  loadingKit
)

const settingsReceiptPrintEdit = loadable(
  () => import("../pages/settings/ReceiptPrintTemplate/ReceiptEdit"),
  loadingKit
)

const LiveMonitoring = loadable(
  () => import("../pages/liveMonitoring"),
  loadingKit
)

const TermsOfService = loadable(
  () => import("../pages/termsOfService"),
  loadingKit
)
const CreateLocation = loadable(() =>
  import("../pages/masterData/locations/CreateLocations")
)
const GroupLocationsList = loadable(() =>
  import("../pages/masterData/locations/GroupLocation/LocationGroupCreatePage")
)
const ProductGroupCreate = loadable(() =>
  import("../pages/masterData/products/createGroupModel/ProductGroupCreatePage")
)
const LocationDetailedPage = loadable(() =>
  import("../pages/masterData/locations/LocationDetailedPage/index")
)

const CustomerDetailedPage = loadable(() =>
  import("../pages/masterData/customers/CustomerDetailedPage")
)

const PriceDetailedPage = loadable(() =>
  import("../pages/masterData/price/priceDetailedPage")
)

const LocationGroupDetailedPage = loadable(() =>
  import(
    "../pages/masterData/locations/GroupLocation/LocationGroupDetailedPage"
  )
)

const ProductGroupDetailedPage = loadable(() =>
  import("../pages/masterData/products/GroupDetail/ProductGroupDetailedPage")
)

const AddNewAddress = loadable(() =>
  import("../pages/masterData/customers/AddNewAddress")
)

const CreateCustomer = loadable(() =>
  import("../pages/masterData/customers/CreateCustomers")
)
const CreatePrice = loadable(() =>
  import("../pages/masterData/price/CreatePrice")
)
const CreateProduct = loadable(() =>
  import("../pages/masterData/products/ProductCreatePage")
)
const EditProduct = loadable(() =>
  import("../pages/masterData/products/ProductDetailedPage/ProductDetailedPage")
)

const CreateProductGroup = loadable(() =>
  import("../pages/masterData/products/GroupDetail")
)
const Status = loadable(() => import("../pages/status"), loadingKit)

const EditOrderDetails = loadable(() =>
  import("../pages/transactions/EditOrder/EditOrder")
)

const Tenant = loadable(() => import("../pages/tenant/index"), loadingKit)
const Hitl = loadable(() => import("../pages/hitl/index"), loadingKit)

const CreateEditUser = loadable(() =>
  import("../pages/userManagement/users/userCreateEdit/index")
)

const TenantCreateEdit = loadable(() =>
  import("../pages/tenant/tenantCreateEdit/TenantCreateEdit")
)

const MonitoringProducts = loadable(
  () => import("../pages/liveMonitoring/products/ProductsList"),
  loadingKit
)

const EditLiveMonitoringProduct = loadable(() =>
  import("../pages/liveMonitoring/products/ProductDetailedPage")
)

const MonitoringDevices = loadable(
  () => import("../pages/liveMonitoring/devices/DevicesList"),
  loadingKit
)

const Devices = loadable(
  () => import("../pages/liveMonitoring/devices/Stores/Devices"),
  loadingKit
)
const Stores = loadable(
  () => import("../pages/liveMonitoring/devices/Stores"),
  loadingKit
)

const EditLiveMonitoringDevices = loadable(() =>
  import("../pages/liveMonitoring/devices/DeviceDetailedPage")
)

const SpacePlanning = loadable(
  () => import("../pages/spacePlanning/PlanogramDetailedView"),
  loadingKit
)

const SpacePlanningList = loadable(
  () => import("../pages/spacePlanning/spacePlanningList/index"),
  loadingKit
)

const PlanogramList = loadable(
  () =>
    import(
      "../pages/spacePlanning/spacePlanningList/FrictionlessStore/spacePlanningList"
    ),
  loadingKit
)

const ModalConfig = loadable(
  () => import("../pages/frictionlessStore/deviceConfig/ModalConfig/index"),
  loadingKit
)

const ModalConfigDetails = loadable(
  () =>
    import("../pages/frictionlessStore/deviceConfig/ModalConfig/ModalConfig"),
  loadingKit
)

const AuditLog = loadable(
  () => import("../pages/logviewer/auditLogs/AuditLog"),
  loadingKit
)
const Reports = loadable(
  () => import("../pages/reports/detailedTransactionReport"),
  loadingKit
)
const DetailedTransactionReport = loadable(
  () => import("../pages/reports/detailedTransactionReport"),
  loadingKit
)
const SalesSummaryReport = loadable(
  () => import("../pages/reports/salesSummaryReport"),
  loadingKit
)

const scheduledReports = loadable(
  () => import("../pages/reports/scheduledReport"),
  loadingKit
)
const scheduledReportsCrud = loadable(
  () => import("../pages/reports/scheduledReport/ScheduledReportsCreateEdit"),
  loadingKit
)
const Alerts = loadable(() => import("../pages/alerts/Alerts"), loadingKit)
const NewAlert = loadable(() => import("../pages/alerts/NewAlert"), loadingKit)
const Configuration = loadable(
  () => import("../pages/alerts/configuration/index"),
  loadingKit
)
const Monitor = loadable(
  () => import("../pages/alerts/monitor/index"),
  loadingKit
)

//for private route add "guard: AuthGuard" , after component ,(like after "component:Dashbaord",)

const PageComponentLibrary = {
  Alerts,
  NewAlert,
  Configuration,
  Monitor,
  ModalConfig,
  ModalConfigDetails,
  SpacePlanning,
  SpacePlanningList,
  FrictionlessStore,
  ForgetPassword,
  SignIn,
  SignUp,
  ResetPassword,
  Page404,
  Page500,
  Page403,
  Dashboard,
  Countries,
  USStates,
  Currencies,
  Gender,
  CanadianProvincesList,
  Languages,
  UnitsOfMeasure,
  BarcodeTypes,
  Tax,
  Locations,
  Products,
  Inventory,
  InventoryAdjustment,
  InventoryCounts,
  InventoryAuditReports,
  InventorySnapshotReports,
  InventoryAdjustmentEdit,
  AdjustInventoryView,
  Customers,
  Transactions,
  LiveMonitoring,
  Reports,
  DetailedTransactionReport,
  SalesSummaryReport,
  scheduledReports,
  scheduledReportsCrud,
  User,
  RolePermission,
  Permission,
  ProductCatalog,
  ProductCatalogAdd,
  ProductCatalogEdit,
  TaxConfig,
  TaxConfigAdd,
  TaxConfigEdit,
  AvalaraConfig,
  AvalaraConfigAdd,
  AvalaraConfigEdit,
  RolePermissionDetails,
  RolePermissionTenantDetails,
  System,
  EditProfile,
  Settings,
  Help,
  Support,
  Privacy,
  TermsOfService,
  CreateLocation,
  CreateCustomer,
  CreateProduct,
  EditProduct,
  LocationDetailedPage,
  LocationGroupDetailedPage,
  ConfigurePos,
  MainPos,
  CreateNewPos,
  PosDetailedScreen,
  ProductGroupDetailedPage,
  CustomerDetailedPage,
  PriceDetailedPage,
  AddNewAddress,
  Status,
  Price,
  CreatePrice,
  GroupLocationsList,
  CreateProductGroup,
  settingPaymentGatewayTerminal,
  settingPaymentGatewayMobile,
  EditOrderDetails,
  Groups,
  Hierarchy,
  ProductsGroups,
  ProductGroupCreate,
  ProductDataHierarchy,
  ProductDataMapping,
  HierarchyMapping,
  ProductAttribute,
  Tenant,
  Hitl,
  CreateEditUser,
  TenantCreateEdit,
  SassDashboard,
  MonitoringProducts,
  EditLiveMonitoringProduct,
  ProfileUpdate,
  MonitoringDevices,
  EditLiveMonitoringDevices,
  Devices,
  Stores,
  TrainingProducts,
  TrainingProductsCreate,
  TrainingProductsEdit,
  productValidationImagesView,
  ModelTrainingCreate,
  AuditLog,
  PlanogramList,
  ClassificationCreate,
  hitlClassificationUImagesView,
  hitlSegmentationUImagesView,
  settingPmsConfig,
  settingPmsCreateEditConfig,
  settingsReceiptPrintTemplate,
  settingsReceiptPrintEdit,
  LanguageSync,
}
export { PageComponentLibrary }
export default PageComponentLibrary
