import FormControl from "@material-ui/core/FormControl"
import Button from "@material-ui/core/Button"
import { AddIcon, SearchIcon } from "@iconsGallery"
import { withStyles, makeStyles } from "@material-ui/core/styles"
import React from "react"
import {
  Dialog,
  TextField,
  Typography,
  DialogTitle,
  IconButton,
  DialogContent,
  DialogActions,
  Table,
  TableCell,
  TableRow,
  TableContainer,
  Checkbox,
  Grid,
} from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import { useLocationFormContentStyles } from "./styles"
import styled from "styled-components"
import * as ADM from "@adm"

const NumberSelected = (props) => {
  if (props.selectednumber > 0) {
    return (
      <Typography style={props.style} display="inline" ml={2}>
        {props.selectednumber} selected
      </Typography>
    )
  } else {
    return null
  }
}

const TemplateNumberSelected = styled(NumberSelected)`
  &.MuiTypography-root {
    margin: 0;
    margin-left: 170px;
  }
`
const StyledTableCell = withStyles((theme) => ({
  root: {
    height: 6,
    padding: "2px",
    borderBottom: "transparent",
  },
}))(TableCell)

const StyledTableRow = withStyles((theme) => ({
  selected: {
    backgroundColor: "#c3bfbf9e !important",
  },
}))(TableRow)

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  numberSelected: {
    paddingLeft: "20px",
    marginLeft: "20px",
  },
  MuiTableRow: {
    root: { "&$selected, &$selected:hover": { backgroundColor: "#4f4e4e14" } },
  },
})

const data = [{ locationNam: "", locationId: "", city: "", country: "" }]

function LocationForm(props) {
  const defaultList = [
    {
      tabName: "fruits",
      list: [
        {
          name: "Orange",
          id: 1,
        },
        {
          name: "Grapes",
          id: 2,
        },
        {
          name: "Guava",
          id: 3,
        },
      ],
    },
    {
      tabName: "veg",
      list: [
        {
          name: "Cabbage",
          id: 1,
        },
        {
          name: "Tomato",
          id: 2,
        },
        {
          name: "Onion",
          id: 3,
        },
      ],
    },
  ]
  const { list = defaultList, onUpdate = () => "" } = props || {}
  const [openDialog, setOpenDialog] = React.useState(false)
  const [dropdownSelectIndex, setDropdownSelectIndex] = React.useState(0)
  let selectedState = []
  for (let i = 0; i < list.length; i++) {
    selectedState.push({
      tabName: list[i].tabName,
      selectedItems: [],
    })
  }
  const [selected, setSelected] = React.useState(selectedState)
  const [dialogBody, setDialogBody] = React.useState({ data: list[0].list })
  const [textboxValue, setTextboxValue] = React.useState("")
  const classes = useLocationFormContentStyles()

  const customOnUpdate = (props) => {
    const idx = list.findIndex((item) => item.tabName === props.value)
    setDropdownSelectIndex(idx)
    setDialogBody({
      ...dialogBody,
      data: list[idx].list,
    })
  }

  const isSelected = (id) => {
    if (selected[dropdownSelectIndex].selectedItems.length > 0) {
      return selected[dropdownSelectIndex].selectedItems.indexOf(id) !== -1
    }
    return false
  }
  const onSelect = (event, id) => {
    if (event.target.checked === true) {
      const newSelected = { ...selected }
      newSelected[dropdownSelectIndex].selectedItems.push(id)
      setSelected(newSelected)
    } else {
      if (selected[dropdownSelectIndex].selectedItems.length > 0) {
        const newSelected = selected[dropdownSelectIndex].selectedItems.filter(
          (item) => item !== id
        )
        const newSelectedState = { ...selected }
        newSelectedState[dropdownSelectIndex].selectedItems = newSelected
        setSelected(newSelectedState)
      }
    }
  }

  const onSearchBoxChange = (event) => {
    setTextboxValue(event.target.value)
  }

  const useStyles = makeStyles((theme) => ({
    tableRow: {
      root: {
        selected: {
          backgroundColor: "#e1dfdf",
        },
      },
    },
  }))

  React.useEffect(() => {
    const data = list[dropdownSelectIndex].list.filter((item) =>
      item.name.includes(textboxValue)
    )
    setDialogBody({
      ...dialogBody,
      data: data,
    })
  }, [textboxValue, dropdownSelectIndex])

  const rowClass = useStyles()

  const onClickSubmit = () => {
    onUpdate(selected)
    setOpenDialog(false)
  }

  const LocationDialogTitle = withStyles(styles)((props) => {
    const {
      children,
      classes,
      onClose,
      dropDownList,
      selectednumber,
      ...other
    } = props
    const dropDownItems = []
    dropDownList.map((item) => {
      dropDownItems.push(item.tabName)
      return item
    })
    return (
      <DialogTitle
        className={classes.root}
        style={{ width: "100%" }}
        {...other}
      >
        <Grid container direction="row" justify="space-between">
          <div style={{ float: "left" }}>
            <TextField
              variant="outlined"
              label={<SearchIcon />}
              value={textboxValue}
              onChange={(event) => {
                onSearchBoxChange(event)
              }}
              autoFocus={true}
              color="grey"
              size="small"
            />
            <ADM.DropdownKit
              defaultValue={list[dropdownSelectIndex].tabName}
              list={dropDownItems}
              onUpdate={customOnUpdate}
            />
          </div>
          <div
            style={{
              paddingLeft: "4em",
              paddingTop: "0.5em",
            }}
          >
            <TemplateNumberSelected
              style={{ float: "right" }}
              selectednumber={selectednumber}
              className={classes.numberSelected}
            />
          </div>
          {onClose ? (
            <div style={{ float: "right", marginRight: "1em" }}>
              <IconButton
                style={{ float: "right" }}
                aria-label="close"
                className={classes.closeButton}
                onClick={onClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          ) : null}
        </Grid>
      </DialogTitle>
    )
  })
  return (
    <div>
      <Button
        color="primary"
        startIcon={<AddIcon />}
        variant="contained"
        onClick={() => setOpenDialog(true)}
      >
        Add Location
      </Button>
      <FormControl>
        <Dialog
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          aria-labelledby="form-dialog-title"
          fullWidth
          style={{ width: "100%" }}
          classes={{ paper: classes.dialogPaper }}
        >
          <div className={classes.FormPopup}>
            <FormControl style={{ width: "100%" }}>
              <LocationDialogTitle
                id="customized-dialog-title"
                onClose={() => {
                  setOpenDialog(false)
                  setDialogBody({
                    ...dialogBody,
                    data: list[dropdownSelectIndex].list,
                  })
                }}
                selectednumber={
                  selected[dropdownSelectIndex].selectedItems.length
                }
                dropDownList={list}
              />
            </FormControl>
          </div>
          <DialogContent dividers>
            <TableContainer style={{ maxHeight: 500 }}>
              <Table stickyHeader>
                {dialogBody.data.length > 0 ? (
                  dialogBody.data.map((item, idx) => {
                    const isItemSelected = isSelected(item.id)
                    return (
                      <StyledTableRow
                        id={"location-table-row-" + idx}
                        className={rowClass.tableRow}
                        selected={isItemSelected}
                        style={{
                          MuiTableRow: {
                            root: {
                              "&$selected, &$selected:hover": {
                                backgroundColor: "#4f4e4e14",
                              },
                            },
                          },
                        }}
                        aria-checked={isItemSelected}
                      >
                        <StyledTableCell
                          id={"location-tablecell-select-" + idx}
                          className={classes.tableCell}
                          align="left"
                          width="20px"
                        >
                          <Checkbox
                            id={"select-" + idx}
                            checked={isItemSelected}
                            color="primary"
                            onClick={(event) => onSelect(event, item.id)}
                          />
                        </StyledTableCell>
                        <StyledTableCell
                          id={"location-tablecell-id-" + idx}
                          className={classes.tableCell}
                          align="left"
                        >
                          {item.id}
                        </StyledTableCell>
                        <StyledTableCell
                          id={"location-tablecell-name-" + idx}
                          className={classes.tableCell}
                          align="left"
                        >
                          {item.name}
                        </StyledTableCell>
                      </StyledTableRow>
                    )
                  })
                ) : (
                  <TableRow>
                    <StyledTableCell>
                      <Typography> None found </Typography>
                    </StyledTableCell>
                  </TableRow>
                )}
              </Table>
            </TableContainer>
          </DialogContent>
          <DialogActions disableSpacing={true}>
            <Button
              variant="contained"
              onClick={() => {
                onClickSubmit()
              }}
              color="primary"
            >
              SUBMIT
            </Button>
          </DialogActions>
        </Dialog>
      </FormControl>
    </div>
  )
}

export { LocationForm }
export default LocationForm
