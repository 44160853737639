import React, { useState, useEffect, useRef } from "react"
import _ from "lodash"
import { Grid, Typography, IconButton, Tooltip } from "@material-ui/core"
import { CloseRounded } from "@material-ui/icons"
import { useHistory } from "react-router-dom"
import Table from "../../pages/masterData/HierarchyMapping/Table/index"
import api from "../../services/masterData/locations/api"
import { productDataApiMethods } from "../../services/masterData/products/api"
import * as Adm from "@adm"
import styled from "styled-components"
import { ConfirmationDialog } from "@features"
import * as ServiceAPI from "@services"
import SearchBar from "../../components/AdmKit/SearchBar/SearchBar"
import * as constantsAPI from "@constants"
import { Helmet } from "react-helmet"
import { useTranslation } from "react-i18next"
import { CloseIcon } from "../../IconsGallery/IconsGallery"
import { productColumnData } from "./fixture"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const PageHeaderBreadcrumbKit = styled(Grid)`
  button {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
    padding-left: 18px;
    padding-right: 18px;
    max-width: unset;
  }
  margin-bottom: 24px;
  border-bottom: 1px #e0e0e0 solid;
  padding-bottom: 15px;
`
const CategorizedProduct = (props) => {
  const {
    isWriteAllowed,
    columns = null,
    isKCApi,
    hideLeftHierarchy = false,
    hideDropdownFilters = false,
    selectedCategoryId = [],
    setAnyChanges = () => {},
  } = props
  let history = useHistory()
  const { t } = useTranslation()
  let productURL =
    props.lookupType === "product" ||
    history?.location?.pathname === t("/master-data/products/hierarchy")
  const isKC = isKCApi || history?.location?.pathname?.includes("edgeDevice")

  const {
    isLookUp = false,
    handleCloseLookUp = () => {},
    handleLookUpPrimaryAction = () => {},
    selectedItems = [],
    lookupType = productURL ? "product" : "location",
    titlePath = productURL ? "category_name" : "name",
    idPath = productURL ? "categoryId" : "hierarchyId",
    parentIdPath = productURL ? "mapped_parent_categories" : "parent",
    isInventory = false,
    hideCheckBoxAll = false,
    sdcFilter = [],
    dataUniqueIdPath = "_id",
    ShowCheckBox = true,
    locationBasedProduct = false,
    categoryType = "",
    onClose = () => {},
  } = props
  const dataFetchMethod =
    lookupType === "product"
      ? productDataApiMethods.updateProductCategoryMappings
      : api.updateLocationHierarchyMappings // productDataApiMethods.getProductCategories
  const [selectedHierarchyIds, setHierarchySelectedIds] = useState(
    selectedCategoryId
  )
  const [triggerDataFetch, setTriggerDataFetch] = useState({
    uncategorized: false,
    categorized: false,
  })
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [loading, setLoading] = useState(false)
  const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false)
  const [reDirect, setReDirect] = useState(false)
  const [selectedDisAssociateItems, setSelectedDisAssociateItems] = useState([])
  const [SDCFilter, setSDCFilter] = useState(sdcFilter)
  const [locationTypeList, setlocationTypeList] = useState([])
  const [isCancel, setIsCancel] = useState(false)
  const [dropDownSelectedItems, setDropdownSelectedItems] = useState({
    city: [],
    state: [],
    brand: [],
    manufacturer: [],
  })

  const initialDataState = {
    page: 1,
    showMore: false,
    limit: 10,
    searchTerm: "",
  }

  const dropdownList = {
    list: [],
    totalDocs: "",
    nextPage: null,
  }

  const [dropdownDataState, setDropdownDataState] = useState({
    city: initialDataState,
    state: initialDataState,
    brand: initialDataState,
    manufacturer: initialDataState,
  })

  const [dropDownList, setDropdownList] = useState({
    city: dropdownList,
    state: dropdownList,
    brand: dropdownList,
    manufacturer: dropdownList,
  })

  const [searchTerm, setSearchTerm] = React.useState("")
  const savedCallback = useRef()

  useEffect(() => {
    setSelectedDisAssociateItems(selectedDisAssociateItems)
  }, [selectedDisAssociateItems])

  useEffect(() => {
    setHierarchySelectedIds(selectedCategoryId.current)
  }, [selectedCategoryId.current])

  const BreadcrumbList1 = [
    { name: "Master Data", disable: true },
    { name: "Products", url: t("/master-data/products") },
    { name: "Product Mapping" },
  ]
  const BreadcrumbList = [
    { name: "Master Data", disable: true },
    { name: "Locations", url: t("/master-data/locations") },
    { name: "Locations Mapping" },
  ]

  const disAssociateConformDialog = async (selectedItems, id, callback) => {
    setAlert(false)
    savedCallback.current = callback
    if (selectedItems?.length === 0 || id === undefined) {
      await setAlertType("error")
      await setAlertMessage(
        lookupType === "product"
          ? t("Select a Category")
          : t("Select a Hierarchy")
      )
      await setAlert(true)
    } else {
      setSelectedDisAssociateItems(selectedItems)
      setOpenConfirmationDialog(true)
    }
  }

  useEffect(() => {
    setTriggerDataFetch({ uncategorized: false, categorized: true })
  }, [isLookUp, selectedDisAssociateItems])

  const handleCustomCloseRedirect = () => {
    let bodyData = {}
    if (categoryType === "product" && isCancel) {
      onClose()
      setOpenConfirmationDialog(false)
      setAnyChanges(false)
    } else {
      if (lookupType === "product") {
        bodyData = {
          item_ids: _.map(selectedDisAssociateItems, (o) =>
            _.get(o, "item_id")
          ),
          category_Id: "",
        }
      } else {
        bodyData = {
          locationIds: _.map(selectedDisAssociateItems, (o) =>
            _.get(o, "locationId")
          ),
          hierarchyId: "",
        }
      }
      dataFetchMethod(bodyData)
        .then((resp) => {
          setTriggerDataFetch({ uncategorized: true, categorized: true })
          if (_.isFunction(savedCallback.current())) savedCallback.current()
          setAlertType(_.get(resp, "data.status", "error"))
          setAlertMessage(
            `${t("Selected")} ${lookupType}${t("(s) disassociated from")} ${
              selectedHierarchyIds[1]
            } ` +
              `${lookupType === "product" ? t("category") : t("hierarchy")} ${t(
                "successfully"
              )}`
          )
          setLoading(false)
          setAlert(true)
        })
        .catch((err) => {})
      setAlert(false)
      setLoading(true)
      setOpenConfirmationDialog(false)
      setSelectedDisAssociateItems([])
    }
  }

  const handleMapping = async (selectedItems, id, callback) => {
    console.log(id, "handleMapping")

    setAlert(false)
    if (selectedHierarchyIds.length === 0 || id === undefined) {
      await setAlertType("error")
      await setAlertMessage(
        lookupType === "product"
          ? t("Select a Category")
          : t("Select a Hierarchy")
      )
      await setAlert(true)
    } else {
      let bodyData = {}
      if (lookupType === "product") {
        bodyData = {
          item_ids: _.map(selectedItems, (o) => _.get(o, "item_id")),
          category_Id: id,
        }
      } else {
        bodyData = {
          locationIds: _.map(selectedItems, (o) => _.get(o, "locationId")),
          hierarchyId: id,
        }
      }
      dataFetchMethod(bodyData)
        .then((resp) => {
          setTriggerDataFetch({ uncategorized: true, categorized: true })
          if (_.isFunction(callback)) callback()
          setAlertType(_.get(resp, "data.status", "error"))
          setAlertMessage(
            `${t("Selected")} ${lookupType}${t("(s) associated to")} "${
              selectedHierarchyIds[1]
            }" ${lookupType === "product" ? t("category") : t("hierarchy")} ${t(
              "successfully"
            )}`
          )
          setAlert(true)
          setLoading(false)
        })
        .catch((err) => {})

      setAlert(false)
      setLoading(true)
    }
  }
  useEffect(() => {
    if (isLookUp) {
      if (lookupType === "location") {
        fetchCityData()
        fetchStateData()
        fetchLocationType()
      } else {
        fetchBrandData()
        fetchManufacturerData()
      }
    }
  }, [])

  useEffect(() => {
    setSearchTerm(searchTerm)
  }, [searchTerm])

  useEffect(() => {
    if (lookupType === "location") {
      fetchCityData()
    } else {
      fetchBrandData()
    }
  }, [dropdownDataState.city, dropdownDataState.brand])

  useEffect(() => {
    if (lookupType === "location") {
      fetchStateData()
    } else {
      fetchManufacturerData()
    }
  }, [dropdownDataState.state, dropdownDataState.manufacturer])

  useEffect(() => {
    setDropdownSelectedItems(dropDownSelectedItems)
  }, [dropDownSelectedItems])

  useEffect(() => {
    setDropdownDataState(dropdownDataState)
  }, [dropdownDataState])

  useEffect(() => {
    setDropdownList(dropDownList)
  }, [dropDownList])

  const fetchLocationType = async () => {
    let response = await ServiceAPI.getStoreCommonData(
      API_END_POINTS.getlocationtypes
    )
    let { data = {} } = response || {}
    let locationTypeList = []
    data?.data?.map((o) => locationTypeList.push(o.locationTypeName))
    setlocationTypeList(locationTypeList)
  }

  const fetchCityData = async () => {
    const cityDataState = dropdownDataState.city
    let bodyData = {
      ...cityDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.fetchStoreCommonData(
      API_END_POINTS.getAllCities,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let cityList = []
    data?.data?.docs?.map((o) => cityList.push({ id: o._id, name: o.name }))
    if (cityDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        city: {
          ...c.city,
          list: cityList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addCityList = dropDownList.city.list
      addCityList.push(...cityList)
      setDropdownList((c) => ({
        ...c,
        city: {
          ...c.city,
          list: addCityList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const fetchStateData = async () => {
    const regionDataState = dropdownDataState.state
    let bodyData = {
      ...regionDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.fetchStoreCommonData(
      API_END_POINTS.getAllStates,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let stateList = []
    data?.data?.docs?.map((o) => stateList.push({ id: o._id, name: o.name }))
    if (regionDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        state: {
          ...c.state,
          list: stateList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addStateList = dropDownList.state.list
      addStateList.push(...stateList)
      setDropdownList((c) => ({
        ...c,
        state: {
          ...c.state,
          list: addStateList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const fetchBrandData = async () => {
    const brandDataState = dropdownDataState.brand
    let bodyData = {
      ...brandDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.postProductCommonData(
      API_END_POINTS.getAllBrands,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let brandList = []
    data?.data?.docs?.map((o) => brandList.push({ id: o._id, name: o.name }))
    if (brandDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        brand: {
          ...c.brand,
          list: brandList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addBrandList = dropDownList.brand.list
      addBrandList.push(...brandList)
      setDropdownList((c) => ({
        ...c,
        brand: {
          ...c.brand,
          list: addBrandList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const fetchManufacturerData = async () => {
    const manufacturerDataState = dropdownDataState.manufacturer
    let bodyData = {
      ...manufacturerDataState,
    }
    delete bodyData["showMore"]
    let response = await ServiceAPI.postProductCommonData(
      API_END_POINTS.getAllManufacturers,
      bodyData
    )
    let { data: { data = {} } = {} } = response || {}
    let manufacturersList = []
    data?.data?.docs?.map((o) =>
      manufacturersList.push({ id: o._id, name: o.name })
    )
    if (manufacturerDataState?.showMore === false) {
      setDropdownList((c) => ({
        ...c,
        manufacturer: {
          ...c.manufacturer,
          list: manufacturersList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    } else {
      let addManufacturersList = dropDownList.manufacturer.list
      console.log(addManufacturersList, "manufacturersList")
      addManufacturersList.push(...manufacturersList)
      setDropdownList((c) => ({
        ...c,
        manufacturer: {
          ...c.manufacturer,
          list: addManufacturersList,
          totalDocs: data?.data?.totalDocs,
          nextPage: data.nextPage,
        },
      }))
    }
  }

  const handleDropdownSearchTerm = (searchVal, label) => {
    setDropdownDataState((c) => ({
      ...c,
      [label.toLowerCase()]: {
        ...c[label.toLowerCase()],
        searchTerm: searchVal,
        showMore: false,
        page: 1,
        limit: 10,
      },
    }))
  }

  const handleDropdownChange = (val, label) => {
    setDropdownSelectedItems((c) => ({ ...c, [label.toLowerCase()]: [...val] }))
  }

  const handleSearchTerm = (val) => {
    setSearchTerm(val)
  }

  const handleShowMore = async (label) => {
    const nxtPage = dropDownList && dropDownList[label.toLowerCase()].nextPage
    setDropdownDataState((c) => ({
      ...c,
      [label.toLowerCase()]: {
        ...c[label.toLowerCase()],
        limit: 10,
        page: nxtPage,
        showMore: true,
      },
    }))
  }

  const handleClear = () => {
    setSearchTerm("")
    setDropdownSelectedItems((c) => ({
      ...c,
      city: [],
      state: [],
      brand: [],
      manufacturer: [],
    }))
    if (sdcFilter)
      setSDCFilter([
        ...sdcFilter,
        {
          field: sdcFilter[0]?.field,
          operator: "eq",
          value: sdcFilter[0]?.value,
        },
      ])
  }

  let searchObj = {}
  if (lookupType === "location") {
    searchObj = {
      dropdown: [
        {
          label: "City",
          list: dropDownList.city.list,
          key: "name",
          filteredItems: dropDownSelectedItems.city,
          totalDocs: dropDownList?.city?.totalDocs,
          searchTerm: dropdownDataState.city.searchTerm,
        },
        {
          label: "State",
          list: dropDownList.state.list,
          key: "name",
          filteredItems: dropDownSelectedItems.state,
          totalDocs: dropDownList.state.totalDocs,
          searchTerm: dropdownDataState.state.searchTerm,
        },
      ],
      searchTerm: searchTerm,
    }
  } else {
    searchObj = {
      dropdown: [
        {
          label: "Brand",
          list: dropDownList.brand.list,
          key: "name",
          filteredItems: dropDownSelectedItems.brand,
          totalDocs: dropDownList.brand.totalDocs,
          searchTerm: dropdownDataState.brand.searchTerm,
        },
        {
          label: "Manufacturer",
          list: dropDownList.manufacturer.list,
          key: "name",
          filteredItems: dropDownSelectedItems.manufacturer,
          totalDocs: dropDownList.manufacturer.totalDocs,
          searchTerm: dropdownDataState.state.searchTerm,
        },
      ],
      searchTerm: searchTerm,
    }
  }

  const filterSelectedItems = {
    citySelectedItems: dropDownSelectedItems.city,
    stateSelectedItems: dropDownSelectedItems.state,
    brandSelectedItems: dropDownSelectedItems.brand,
    manufacturerSelectedItems: dropDownSelectedItems.manufacturer,
    searchTerm,
  }

  const handleLocationTypeFilter = (val) => {
    setSDCFilter([
      ...sdcFilter,
      { field: "locationType.name", operator: "eq", value: val },
    ])
  }

  return (
    <>
      <ConfirmationDialog
        openConfirmationDialog={openConfirmationDialog}
        setOpenConfirmationDialog={setOpenConfirmationDialog}
        setReDirect={setReDirect}
        handleCustomCloseRedirect={handleCustomCloseRedirect}
        deleteContent={
          categoryType === "product" && isCancel
            ? t("Are you sure you want to cancel?")
            : lookupType === "product"
            ? t(
                "Do you really want to Disassociate selected Product(s) from the category?"
              )
            : t(
                "Do you really want to Disassociate selected Location(s) from the hierarchy?"
              )
        }
      />

      <div
        style={{
          display: "flex",
          flexGrow: 1,
          height: isLookUp ? "60vh" : "100%",
          overflowY: "auto",
          background: "#f8f8f8",
        }}
      >
        <div
          className="cls-locationlookup"
          style={{
            ...(isLookUp ? {} : { height: "100%" }),
            border: "0px solid red",
            display: "flex",
            flexGrow: 1,
            flexWrap: "wrap",
          }}
        >
          <Adm.BackdropOverlay open={loading} />
          <Grid
            container
            style={{
              height: "100%",
              overflow: "hidden",
            }}
          >
            <Grid
              item
              container
              xs={12}
              style={{
                flexFlow: "nowrap column",
                height: "100%",
              }}
            >
              {!isLookUp ? (
                <>
                  <div
                    style={{
                      display: "flex",
                      flexGrow: 1,
                      border: "0px solid red",
                    }}
                  >
                    <Table
                      columns={productColumnData}
                      hierarchyMapping={true}
                      lookupType={lookupType}
                      selectedHierarchyIds={selectedHierarchyIds}
                      triggerDataFetch={triggerDataFetch}
                      setTriggerDataFetch={setTriggerDataFetch}
                      dataFetchTriggerKey="uncategorized"
                      title={
                        lookupType === "location"
                          ? "Uncategorized Location"
                          : "Uncategorized Product"
                      }
                      onPrimaryActionClick={(x, cb) =>
                        handleMapping(x, _.get(selectedHierarchyIds, "[0]"), cb)
                      }
                      additionalFilters={[
                        ...SDCFilter,
                        {
                          field:
                            lookupType === "product"
                              ? "product_mapped_category_id"
                              : "locationHierarchy",
                          operator: "eq",
                          value: "",
                        },
                        {
                          field:
                            lookupType === "product" ? "isActive" : "isActive",
                          operator: "eq",
                          value: true,
                        },
                      ]}
                      isInventory={isInventory}
                      hideCheckBoxAll={hideCheckBoxAll}
                      searchObj={searchObj}
                      gridNoRecordsText={
                        lookupType === "location"
                          ? isLookUp
                            ? ""
                            : "No records found"
                          : isLookUp
                          ? ""
                          : "No records found"
                      }
                      isWriteAllowed={isWriteAllowed}
                      preSelectedItems={selectedItems}
                      SDCFilter={SDCFilter}
                      dataUniqueIdPath={dataUniqueIdPath}
                      ShowCheckBox={ShowCheckBox}
                      isKC={isKC}
                      setAnyChanges={setAnyChanges}
                      categoryType={categoryType}
                    />
                  </div>
                </>
              ) : null}
              <div
                style={{
                  minHeight: 200,
                  height: isLookUp ? "100%" : "50%",
                  border: "0px solid red",
                }}
              >
                <Table
                  columns={productColumnData}
                  lookupType={lookupType}
                  hierarchyMapping={isLookUp ? false : true}
                  isLookUp={isLookUp}
                  preSelectedItems={selectedItems}
                  triggerDataFetch={triggerDataFetch}
                  setTriggerDataFetch={setTriggerDataFetch}
                  dataFetchTriggerKey="categorized"
                  onPrimaryActionClick={(x, cb) =>
                    isLookUp
                      ? handleLookUpPrimaryAction(x, cb)
                      : disAssociateConformDialog(
                          x,
                          _.get(selectedHierarchyIds, "[0]"),
                          cb
                        )
                  }
                  fetchOnlyOnSelectedIdUpdate
                  selectedHierarchyIds={selectedHierarchyIds}
                  setHierarchySelectedIds={setHierarchySelectedIds}
                  primaryActionLabel={
                    isLookUp
                      ? _.get(props, "primaryActionLabel", "ASSOCIATE")
                      : "DISASSOCIATE"
                  }
                  title={
                    lookupType === "location"
                      ? isLookUp
                        ? "Locations"
                        : "Locations Associated"
                      : isLookUp
                      ? "Products"
                      : "Products Associated"
                  }
                  additionalFilters={[
                    ...SDCFilter,
                    {
                      field:
                        lookupType === "product"
                          ? "product_mapped_category_id"
                          : "locationHierarchy",
                      operator: "eq",
                      value: _.get(
                        selectedHierarchyIds,
                        "[0]",
                        isLookUp ? "" : "*-*"
                      )?.toString(),
                    },
                    {
                      field: lookupType === "product" ? "isActive" : "isActive",
                      operator: "eq",
                      value: true,
                    },
                  ]}
                  isInventory={isInventory}
                  hideCheckBoxAll={hideCheckBoxAll}
                  searchObj={searchObj}
                  filterSelectedItems={filterSelectedItems}
                  gridNoRecordsText={
                    lookupType === "location"
                      ? isLookUp
                        ? "No records found"
                        : "There are no locations associated"
                      : isLookUp
                      ? "No records found"
                      : "There are no products associated"
                  }
                  isWriteAllowed={isWriteAllowed}
                  SDCFilter={SDCFilter}
                  dataUniqueIdPath={dataUniqueIdPath}
                  ShowCheckBox={ShowCheckBox}
                  isKC={isKC}
                  locationBasedProduct={locationBasedProduct}
                  setAnyChanges={setAnyChanges}
                  categoryType={categoryType}
                />
              </div>
            </Grid>
          </Grid>
        </div>
        <div style={{ textAlign: "end" }}>
          <Tooltip title={t("Close")} placement="top" id="id-kit-test-tooltip">
            <IconButton
              onClick={() => {
                setIsCancel(true)
                onClose()
              }}
            >
              <CloseIcon
                size={20}
                style={{ color: "rgba(158, 158, 158, 1)" }}
              />
            </IconButton>
          </Tooltip>
        </div>
        {alert ? (
          <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
        ) : null}
      </div>
    </>
  )
}

export default CategorizedProduct
