import * as constantsAPI from "@constants"
import * as ServiceAPI from "@services"
const {
  signIn: authSignIn,
  signUp: authSignUp,
  resetPassword: authResetPassword,
} = ServiceAPI || {}
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
export function signIn(credentials) {
  console.log("hello", credentials)
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_IN_REQUEST })
    return authSignIn(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_IN_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        })
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_IN_FAILURE })
        throw error
      })
  }
}

export function signUp(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_SIGN_UP_REQUEST })

    return authSignUp(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_SIGN_UP_SUCCESS,
          id: response.id,
          email: response.email,
          name: response.name,
        })
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_SIGN_UP_FAILURE })
        throw error
      })
  }
}

export function signOut() {
  return async (dispatch) => {
    dispatch({
      type: types.AUTH_SIGN_OUT,
    })
  }
}

export function resetPassword(credentials) {
  return async (dispatch) => {
    dispatch({ type: types.AUTH_RESET_PASSWORD_REQUEST })

    return authResetPassword(credentials)
      .then((response) => {
        dispatch({
          type: types.AUTH_RESET_PASSWORD_SUCCESS,
          email: response.email,
        })
      })
      .catch((error) => {
        dispatch({ type: types.AUTH_RESET_PASSWORD_FAILURE })
        throw error
      })
  }
}
