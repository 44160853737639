import React, { useEffect, useState, useCallback } from "react"
import { Grid } from "@material-ui/core"
import moment from "moment"
import _ from "lodash"
import {
  ExcelExport,
  ExcelExportColumn,
} from "@progress/kendo-react-excel-export"
import { useDropzone } from "react-dropzone"
import { makeStyles } from "@material-ui/core/styles"
import { useStyles } from "@material-ui/pickers/views/Calendar/Day"
import DescriptionIcon from "@material-ui/icons/Description"
import * as IconsGallery from "@iconsGallery"
import * as Styles from "./styles"
import * as Utils from "@utils"
import * as Adm from "@adm"
import {
  renderTemplateDetails,
  renderDropzone,
  renderExportDetails,
  renderErrorFileDownload,
} from "./renderProps"
import { useTranslation } from "react-i18next"
function GlobalImportExport(props) {
  const { t } = useTranslation()
  const {
    onUpdateUpload = () => "",
    onUpdateDownload = () => "",
    dropZoneHeading = "Upload",
    dropZoneSubHeading = "Choose The File To Be Upload",
    dropZoneSupportedFileDetails = t("Only XLSX format is supported"),
    dropZoneSizeText = "Maximum Upload File Size is 1 GB",
    templateText = t("DOWNLOAD"),
    templateFileText = t("Sample File"),
    exportSourceText = t("Latest Source"),
    exportSourceDate = moment(),
    percentageCompleted = 0,
    processingText = t("Processing..."),
    exportViewText = t("Choose Export"),
    exportFormatText = t("Formats"),
    exportfileName = t("sampleExport"),
    exportGridData = [],
    columnData = [],
    handleExportFormat = () => {},
    uploadFileSuccess = false,
    acceptedFiles = ".xlsx",
    uploadFileInitated = false,
    isMultipleFileUploadEnable = false,
    uploadFileState = "",
    uploadErrorMessage = "",
    doSkipError = () => "",
    doViewLogs = () => "",
    doCloseLogs = () => "",
    minFileSize = 0,
    maxFileSize = 0,
    maxNumberOfFiles = 1,
    isUploadDisabled = false,
    chooseExport = "AllRecords",
    setChooseExport = () => "",
    formats = "xls",
    setFormats = () => "",
    getFileImpStatus = {},
    isPreUploadStarted = false,
    getFileSize = () => false,
    isSync = false,
    doHandleDownloadFile = () => "",
    setIsLocalDownload = () => false,
    setOpenDownloadModal = () => false,
    showSkipError = false,
    setAlertType = () => {},
    setAlertMessage = () => {},
    setAlert = () => {},
    isShowTemplateDownload = true,
  } = props || {}

  const _export = React.useRef(null)
  const [exportFormatData, setExportFormatData] = useState([])
  const [fileName, setFileName] = React.useState(`${exportfileName}`)
  const [processPercentage, setProcessPercentage] = useState(
    `linear-gradient(90deg, #d9eef9 ${percentageCompleted}%, #fff 0%)`
  )
  const [errorMessage, setErrorMessage] = useState("")
  const handleExport = async () => {
    setFileName(fileName + "." + formats)
    let bodyData = {}
    let column = columnData.map((x) => {
      return { ...x, title: t(x.title) }
    })
    if (chooseExport === "Current View") {
      let exportFormatData = await handleExportFormat({
        body: bodyData,
        type: formats,
        state: chooseExport,
      })
      setExportFormatData(exportFormatData)
      if (_export.current !== null) {
        const optionsGridOne = _export.current.workbookOptions()
        optionsGridOne.sheets[0].title = t("Sheet1")
        _export.current.save(optionsGridOne)
        setAlertType("success")
        setAlertMessage(t("Successfully downloaded"))
        setAlert(false)
        setOpenDownloadModal(false)
        setIsLocalDownload(false)
        setFileName("")
      }
    } else {
      handleExportFormat({
        body: bodyData,
        type: formats,
        state: chooseExport,
      })
    }
  }
  const onDrop = (acceptedFiles, rejectedFiles) => {
    if (rejectedFiles.length > 0) {
      const [_firstIndexElement = {}, ...rest] = rejectedFiles || []
      const { errors = [] } = _firstIndexElement || {}
      const [_firstErrorIndexElement = {}] = errors || []
      const { message = "" } = _firstErrorIndexElement
      setErrorMessage(message)
    } else {
      setErrorMessage("")
      acceptedFiles.forEach((file) => {
        onUpdateUpload({ file })
      })
    }
  }
  const dropZoneProps = useDropzone({
    accept: acceptedFiles || ".xlsx",
    multiple: isMultipleFileUploadEnable,
    onDrop: onDrop,
    noClick: isUploadDisabled,
    noDrag: isUploadDisabled,
    maxSize: maxFileSize,
    maxFiles: maxNumberOfFiles,
    onDragOver: () => {
      setProcessPercentage(
        `linear-gradient(90deg, rgba(0,0,0,0.2) 100%, #fff 0%)`
      )
    },
    onDragLeave: () => {
      if (
        uploadFileState === "Error" ||
        getFileImpStatus?.failed_Count > 0 ||
        uploadFileState === "Failed" ||
        getFileImpStatus?.failed_Count === 0
      ) {
        setProcessPercentage(
          `linear-gradient(90deg, rgba(255,0,0,0.2) ${percentageCompleted}%, #fff 0%)`
        )
      } else {
        setProcessPercentage(`linear-gradient(90deg, #fff 100%, #fff 0%)`)
      }
    },
    onDragEnter: () => {
      if (
        uploadFileState === "Error" ||
        getFileImpStatus?.failed_Count > 0 ||
        uploadFileState === "Failed" ||
        getFileImpStatus?.failed_Count === 0
      ) {
        setProcessPercentage(
          `linear-gradient(90deg, rgba(255,0,0,0.2) ${percentageCompleted}%, #fff 0%)`
        )
      } else {
        setProcessPercentage(`linear-gradient(90deg, #fff 100%, #fff 0%)`)
      }
    },
  })
  const { getRootProps, getInputProps } = dropZoneProps

  const stateProps = {
    onUpdateUpload,
    onUpdateDownload,
    dropZoneHeading,
    dropZoneSubHeading,
    dropZoneSupportedFileDetails,
    dropZoneSizeText,
    templateText,
    templateFileText,
    exportSourceText,
    exportSourceDate,
    percentageCompleted,
    processingText,
    exportViewText,
    exportFormatText,
    chooseExport,
    formats,
    setChooseExport,
    setFormats,
    handleExport,
    acceptedFiles,
    getInputProps,
    getRootProps,
    uploadFileInitated,
    uploadFileSuccess,
    uploadFileState,
    uploadErrorMessage,
    isUploadDisabled,
    doSkipError,
    doViewLogs,
    doCloseLogs,
    getFileImpStatus,
    errorMessage,
    isPreUploadStarted,
    getFileSize,
    isSync,
    doHandleDownloadFile,
    setIsLocalDownload,
    setOpenDownloadModal,
    t,
    showSkipError,
    isShowTemplateDownload,
  }
  useEffect(() => {
    setFileName("")
  }, [])
  useEffect(() => {
    setProcessPercentage(
      `linear-gradient(90deg, rgba(89,204,224,1) ${percentageCompleted}%, #fff 0%)`
    )
    if (
      uploadFileState === "Error" ||
      getFileImpStatus?.failed_Count > 0 ||
      uploadFileState === "Failed" ||
      getFileImpStatus?.failed_Count === 0
    ) {
      setProcessPercentage(
        `linear-gradient(90deg, rgba(255,0,0,0.2) ${percentageCompleted}%, #fff 0%)`
      )
    }
  }, [percentageCompleted, uploadFileState])

  useEffect(() => {
    document.body.classList.add("cls-global-importExport")
    return () => {
      document.body.classList.remove("cls-global-importExport")
    }
  }, [])

  return (
    <>
      <ExcelExport
        data={exportFormatData}
        fileName={`${exportfileName}.${formats}`}
        ref={_export}
      >
        {columnData?.map((x) => {
          return <ExcelExportColumn field={x.field} title={t(x.title)} />
        })}
      </ExcelExport>
      <Styles.GlobalImportContainer>
        <Styles.GlobalGridLeftWrapper>
          {renderTemplateDetails(stateProps)}
        </Styles.GlobalGridLeftWrapper>
        <Styles.GlobalImportExportMainWrapper
          variant="outlined"
          style={{
            border: !uploadFileState ? "2px dashed #aaa" : "2px dashed #aaa",
            background: "#fafafa",
            boxShadow: `0 0  ${uploadFileState ? "3px #fafafa" : "0px #aaa"}`,
            pointerEvents: isUploadDisabled ? "none" : "auto",
          }}
          {...getRootProps({ onClick: (evt) => evt.preventDefault() })}
        >
          <Styles.GlobalGridWrapper
            container
            style={{ background: processPercentage }}
          >
            {renderDropzone(stateProps)}
          </Styles.GlobalGridWrapper>
        </Styles.GlobalImportExportMainWrapper>
        <Styles.GlobalGridRightTopWrapper>
          {renderErrorFileDownload(stateProps)}
        </Styles.GlobalGridRightTopWrapper>
        <Styles.GlobalGridRightWrapper>
          {renderExportDetails(stateProps)}
        </Styles.GlobalGridRightWrapper>
      </Styles.GlobalImportContainer>
    </>
  )
}
export { GlobalImportExport }
export default GlobalImportExport
