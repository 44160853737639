import React, { useState, useEffect } from "react"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import { Grid, Typography as MuiTypography } from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { spacing } from "@material-ui/system"
import * as Utils from "@utils"
import * as Adm from "@adm"
import { useDispatch, useSelector } from "react-redux"
import { addOpenDate } from "@reduxHelper"
import _, { values } from "lodash"
import moment from "moment"
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import "date-fns"
import frLocale from "date-fns/locale/fr"
import esLocale from "date-fns/locale/es"

const Typography = styled(MuiTypography)(spacing)
const useStyles = makeStyles({
  inputFieldText: {
    marginBottom: "8px",
    paddingLeft: "0px",
    fontWeight: "800",
  },
  inputFieldText1: {
    marginBottom: "0px",
    marginTop: "25px",
    paddingLeft: "0px",
    paddingTop: "10px",
  },
  inputFieldText2: {
    marginBottom: "0px",
    marginTop: "25px",
    paddingLeft: "4px",
    paddingTop: "10px",
    fontWeight: "800",
  },
  optionalFieldText: {
    color: "#9E9E9E",
    fontSize: "12px",
    marginBottom: "0px",
    marginTop: "25px",
    paddingLeft: "0px",
    marginLeft: "10px",
  },
  smallTextInput: { width: "445px" },
  autoCompleteHeight: {
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
  },
})
const CreateOpenDate = (props) => {
  const { isTablet, isDesktop, timeZone } = props
  const { useWindowSize } = Utils || {}
  const [height, width] = useWindowSize()
  const classes = useStyles(width)
  const { t } = useTranslation()
  let local = { es: esLocale, fr: frLocale }
  let lang = localStorage.getItem("i18nextLng")
  const dispatch = useDispatch()
  const reduxData = useSelector((state) => state.addLocationReducer)
  const [startDateError, setStartDateError] = useState(false)
  const [timeZoneOpen, setTimeZoneOpen] = React.useState(false)
  const [formData, setFormData] = useState(
    _.get(reduxData, "openDate") !== null
      ? {
        ...reduxData.openDate,
        timeZone: _.get(reduxData, "locationInfo.timezone"),
      }
      : {
        selectedOpenDate: moment(new Date()).format("MM/DD/YYYY"),
        selectedCloseDate: "",
        timeZone: _.get(reduxData, "locationInfo.timezone"),
      }
  )
  const closingDateError =
    Boolean(
      Date.parse(formData["selectedOpenDate"]) >
      Date.parse(formData["selectedCloseDate"])
    ) === true
      ? t("Select valid Close Date")
      : ""
  closingDateError !== ""
    ? props.setEndDateError(true)
    : props.setEndDateError(false)

  const handleFormChange = (val, type) => {
    const newFormData = { ...formData }
    newFormData[type] = val
    setFormData(newFormData)
    if (newFormData["selectedOpenDate"] === "") {
      setStartDateError(true)
    } else {
      setStartDateError(false)
    }
  }
  const handleTimeZoneChange = (e, val) => {
    const newFormData = { ...formData }
    newFormData["timeZone"] = val
    setFormData(newFormData)
    if (newFormData["selectedOpenDate"] === "") {
      setStartDateError(true)
    } else {
      setStartDateError(false)
    }
  }
  useEffect(() => {
    dispatch(addOpenDate(formData))
  }, [formData])
  return (
    <>
      <Grid
        container
        xl={10}
        lg={10}
        md={12}
        sm={12}
        xs={12}
        spacing={isDesktop ? 4 : 0}
        className="cls-opendate"
      >
        <Grid
          container
          direction="row"
          item
          xl={10}
          lg={10}
          md={12}
          sm={12}
          xs={12}
          style={{ marginTop: isDesktop ? "8px" : "" }}
        >
          <Grid
            direction="column"
            lg={5}
            md={6}
            sm={6}
            xs={12}
            xl={6}
            style={{ marginBottom: "20px" }}
          >
            <Typography
              variant="h5"
              className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
            >
              {t("Time Zone")} <span style={{ color: "red" }}> * </span>
            </Typography>
            <Adm.DropdownFilter
              list={Utils.JSUtils.getTimeZoneData()}
              defaultValue={formData.timeZone}
              selectedValue={timeZone || values?.timeZone}
              onChangeEvent={handleTimeZoneChange}
              popupOpen={timeZoneOpen}
              setPopupOpen={setTimeZoneOpen}
              disableClearable={true}
              classNameTextField={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
              placeholder={"Select Time Zone"}
              disabled={true}
              style={{ width: "100%" }}
            />
          </Grid>
        </Grid>
        <Grid container direction="row" item lg={10} md={12} sm={12} xs={12}>
          <Grid
            direction="column"
            item
            xs={12}
            sm={6}
            md={6}
            lg={5}
            style={{ marginBottom: "20px" }}
          >
            <Typography
              variant="h5"
              className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
            >
              {t("Open Date")} <span style={{ color: "red" }}>* </span>
            </Typography>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={local[lang]}
            >
              <KeyboardDatePicker
                disableToolbar
                okLabel=""
                cancelLabel=""
                autoOk={true}
                inputVariant="outlined"
                format="dd-MM-yyyy"
                placeholder={t("dd-mm-yyyy")}
                id="date-picker-inline"
                value={formData.selectedOpenDate}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(date) => {
                  handleFormChange(date, "selectedOpenDate")
                }}
                InputProps={{
                  readOnly: true,
                  style: { height: "40px" }
                }}
                className={classes.keyboardDatePicker}
                error={
                  startDateError
                    ? t("Select Open Date")
                    : ""
                }
                helperText={
                  startDateError
                    ? t("Select Open Date")
                    : ""
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
          <Grid
            direction="column"
            item
            xs={12}
            sm={6}
            md={6}
            lg={5}
            style={{ paddingLeft: isTablet ? "15px" : "" }}
          >
            <Typography
              variant="h5"
              className={`${classes.inputFieldText} makeStyles-inputFieldText-support`}
            >
              {t("Close Date")}
            </Typography>
            <MuiPickersUtilsProvider
              utils={DateFnsUtils}
              locale={local[lang]}
            >
              <KeyboardDatePicker
                style={{ height: "5px" }}
                disableToolbar
                okLabel=""
                cancelLabel=""
                autoOk={true}
                inputVariant="outlined"
                format="dd-MM-yyyy"
                placeholder={t("dd-mm-yyyy")}
                id="date-picker-inline"
                value={formData.selectedCloseDate || ""}
                KeyboardButtonProps={{
                  "aria-label": "change date",
                }}
                InputProps={{
                  readOnly: true,
                  style: { height: "40px" }
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                onChange={(date) => {
                  handleFormChange(date, "selectedCloseDate")
                }}
                className={classes.keyboardDatePicker}
                error={closingDateError}
                helperText={closingDateError
                }
              />
            </MuiPickersUtilsProvider>
          </Grid>
        </Grid>
      </Grid>
    </>
  )
}

export { CreateOpenDate }
export default CreateOpenDate
