import React, { useState, useEffect } from "react"
import { useSelector } from "react-redux"
import OpenDate from "./OpenDate"
import _ from "lodash"
import * as ServiceAPI from "@services"
import * as constantsAPI from "@constants"
import * as Adm from "@adm"
import { useTranslation } from "react-i18next"
import { JSUtils } from "@utils"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const EditOpenDate = (props) => {
  const {
    closeDateFlagOptional = false,
    setAllowPrompt = () => {},
    isWriteAllowed = false,
    isTablet = false,
    fetchData = () => {},
  } = props
  const locationReducerData = useSelector((state) => state.locationReducer)
  const [selectedOpenDate, setSelectedOpenDate] = React.useState(
    _.get(props.data, "openDate", "")
  )
  const [selectedCloseDate, setSelectedCloseDate] = React.useState(
    _.get(props.data, "closeDate", "")
  )
  const defaultTimeZone = _.get(props.data, "locationTimezone", "")
  const [timeZone, setTimeZone] = React.useState(defaultTimeZone)
  const [editMode, setEditMode] = React.useState(false)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [isAnyChanges, setAnyChanges] = React.useState(false)
  const { t } = useTranslation()

  useEffect(() => {
    setSelectedOpenDate(locationReducerData.openDate)
    setSelectedCloseDate(locationReducerData.closeDate)
  }, [locationReducerData])

  useEffect(() => {
    setSelectedOpenDate(_.get(props.data, "openDate", ""))
    setSelectedCloseDate(_.get(props.data, "closeDate", ""))
    setTimeZone(_.get(props.data, "locationTimezone", ""))
  }, [props])

  const handleDateChange = (date, type) => {
    type === "openDate" ? setSelectedOpenDate(date) : setSelectedCloseDate(date)
  }
  const handleTimeZoneChange = (e, value) => {
    if (value !== null) {
      setAllowPrompt(true)
    }
    setTimeZone(value)
  }

  const onSubmit = async () => {
    setLoading(true)
    setAlert(false)
    let isCheckEffectiveDate = selectedOpenDate
    let isCheckEndDate = selectedCloseDate
    let isCheckDate =
      isCheckEffectiveDate <= isCheckEndDate ||
      selectedCloseDate === "" ||
      selectedCloseDate === null
    let modifiedOpen = ""
    let modifiedClose = ""

    if (
      closeDateFlagOptional &&
      _.isEmpty(_.get(props.data, "closeDate", ""))
    ) {
      isCheckDate = true
    }

    if (isCheckDate === true && selectedOpenDate !== "") {
      let open =
        selectedOpenDate !== "" ? new Date(selectedOpenDate) : selectedOpenDate
      modifiedOpen = open !== "" ? open.toISOString() : open
      let close = ""

      if (
        JSON.stringify(new Date(selectedCloseDate)) === "null" &&
        closeDateFlagOptional
      ) {
        modifiedClose = ""
      } else {
        close =
          selectedCloseDate !== "" && selectedCloseDate !== null
            ? new Date(selectedCloseDate)
            : ""
        modifiedClose = close !== "" ? close.toISOString() : close
      }
      let body = {
        ...props.sendBackData,
        locationTimezone: timeZone,
        openDate: modifiedOpen,
        closeDate: modifiedClose,
      }
      let response = await ServiceAPI.fetchStoreCommonData(
        API_END_POINTS.updateLocation,
        body
      )
      setAnyChanges(false)
      setEditMode(false)
      await props.setData(_.get(response, "data.data", {}))
      await setAlertType(_.get(response, "data.status", "error"))
      await setAllowPrompt(false)
      await setAlert(true)
      await fetchData()
      if (await (_.get(response, "data.status") === "success")) {
        await setAlertMessage(
          JSUtils.formatMessageCode(response?.data, t, "Try Again Later!")
        )
      } else {
        await setAlertMessage(
          JSUtils.formatMessageCode(
            response?.response?.data,
            t,
            "Try Again Later!"
          )
        )
      }
    }
    await setLoading(false)
  }

  const slashHandler = () => {
    setSelectedOpenDate(_.get(props.data, "openDate", ""))
    setSelectedCloseDate(_.get(props.data, "closeDate", ""))
    setEditMode(false)
  }
  const stateProps = { isAnyChanges, setAnyChanges }
  return (
    <>
      <Adm.BackdropOverlay open={loading} />
      <OpenDate
        isWriteAllowed={isWriteAllowed}
        defaultTimeZone={defaultTimeZone}
        selectedOpenDat={selectedOpenDate}
        selectedCloseDat={selectedCloseDate}
        timeZon={timeZone}
        editMode={editMode}
        setEditMode={() => {
          setEditMode(true)
        }}
        saveHandler={onSubmit}
        slashHandler={slashHandler}
        handleTimeZoneChange={handleTimeZoneChange}
        handleDateChange={handleDateChange}
        open={loading}
        setAllowPrompt={setAllowPrompt}
        isTablet={isTablet}
        {...stateProps}
      />
      {alert ? (
        <Adm.SnackBar open={true} message={alertMessage} type={alertType} />
      ) : null}
    </>
  )
}
export { EditOpenDate }
export default EditOpenDate
