import React, { useState } from "react"
import Button from "@material-ui/core/Button"
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
} from "@material-ui/core"
import Alert from "@material-ui/lab/Alert"
import { DropzoneArea } from "material-ui-dropzone"
import { useTranslation } from "react-i18next"
import { withTheme } from "styled-components/macro"

function CustomDropzoneDialog(props) {
    const {
        open = false,
        handleClose = () => { },
        dropZoneDisableSubmit = true,
        handleSubmit = () => { },
        dropZoneMessage = "",
        dropZoneAlertType = "success",
        setDropZoneMessage = () => { },
        showDropZoneMessage = false,
        setShowDropZoneMessage = () => { },
    } = props
    const { t } = useTranslation()
    return (
        <Dialog open={open} onClose={handleClose} fullWidth>
            <DialogTitle>{t("Upload Files")}</DialogTitle>
            <DialogContent>
                {showDropZoneMessage && <Alert
                    severity={dropZoneAlertType}
                    onClose={() => {
                        setDropZoneMessage("")
                        setShowDropZoneMessage(false)
                    }}
                    style={{ marginBottom: "10px" }}
                >
                    {dropZoneMessage}
                </Alert>}
                <DropzoneArea {...props} showAlerts={false} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    {t("Cancel")}
                </Button>
                <Button
                    onClick={handleSubmit}
                    color="primary"
                    disabled={dropZoneDisableSubmit}
                >
                    {t("Submit")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

export { CustomDropzoneDialog }
export default withTheme(CustomDropzoneDialog)
