import React from "react"
import { Typography } from "@material-ui/core"
import * as Utils from "@utils"
import styled from "styled-components"
import { useTranslation } from "react-i18next"
const LastMSG = styled(Typography)`
  color: #6b778c;
  padding-top: 2px;
  white-space: nowrap;
  margin-top: 0px;
  font-size: 12px;
  text-align: right;
`

function LastUpdateMessage({
  date = "2021-09-24T08:49:45.815Z",
  createdDate = "2021-09-24T08:49:45.815Z",
}) {
  const { t } = useTranslation()
  const { dateString, monthString, yearString, timeString } = Utils.JSUtils.getDateKit(date) || {}
  const {
    dateString: createdDatedString = "",
    monthString: createdMonthString = "",
    yearString: createdYearString = "",
    timeString: createdTimeString = "",
  } = Utils.JSUtils.getDateKit(createdDate) || {}
  let created = Utils.JSUtils.UTCtoBrowserLocal(createdDate)

  let lastUpdate = Utils.JSUtils.UTCtoBrowserLocal(date)
  return (
    <>
      <LastMSG className="cls-lastupdate">{`${t('Created')} : ${created}`}</LastMSG>
      <LastMSG className="cls-lastupdate">{`${t('Last Updated')} : ${lastUpdate}`}</LastMSG>

    </>
  )
}

export { LastUpdateMessage }
export default LastUpdateMessage
