import React from "react"
import {
  Box,
  Card,
  Grid,
  Divider,
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Tooltip,
} from "@material-ui/core"
import {
  Typography,
  CardContent,
  GreenText,
  RedText,
  useStyles,
  BlackText,
  ColoredLine,
  StyledMainWrapperCardView,
} from "./styles"
import _ from "lodash"
import ImageIcon from "@material-ui/icons/Image"
import clsx from "clsx"
import { CheckCircleIcon, LockOpenIcon, LockOutlinedIcon } from "@iconsGallery"

function getRandomColor() {
  return (
    "rgb(" +
    (Math.floor(Math.random() * 40) + 190) +
    ", " +
    (Math.floor(Math.random() * 40) + 190) +
    ", " +
    (Math.floor(Math.random() * 40) + 190) +
    ")"
  )
}

function CardView(props) {
  const classes = useStyles()
  const {
    list = {},
    handleListView = () => "",
    index = 0,
    tenantListview = false,
    mainStateDashboard = {},
    flagLock = false,
  } = props
  const {
    image = "https://via.placeholder.com/68.png?text=placeholder",
    id = "",
  } = list
  return (
    <StyledMainWrapperCardView
      className={clsx(classes.cardOuter, {
        [classes.cardOuter_wrapper]: !tenantListview,
      })}
      onClick={() => {
        if (flagLock) {
        } else {
          handleListView(list)
        }
      }}
    >
      <Grid
        style={{
          position: "relative",
          cursor: flagLock ? "no-drop" : "pointer",
          opacity: flagLock ? "0.5" : "1",
        }}
        className={clsx(classes.cls_sub_container, {
          [classes.tenant_list]: tenantListview,
          [classes.activeTenant]:
            tenantListview && mainStateDashboard?.selectedTenantId === id,
        })}
      >
        {flagLock ? (
          <LockOutlinedIcon
            style={{
              position: "absolute",
              right: "3px",
              bottom: "5px",
              color: "#939393",
            }}
          />
        ) : (
          <LockOpenIcon
            style={{
              position: "absolute",
              right: "3px",
              bottom: "5px",
              color: "#939393",
            }}
          />
        )}
        <Grid
          container
          direction="column"
          style={{ position: "relative" }}
          className={clsx(classes.sub_wrapper, {
            [classes.list_sub_wrapper]: !tenantListview,
            [classes.active_list_wrapper]:
              tenantListview && mainStateDashboard?.selectedTenantId === id,
          })}
        >
          <Grid item>
            {image.includes("placeholder") ? (
              <Grid
                className={clsx(classes.logo_wrapper, {
                  [classes.tenant_list_logo_image_wrapper]: tenantListview,
                })}
                style={{
                  backgroundColor: !tenantListview
                    ? getRandomColor()
                    : "#ffc1c1",
                  color: !tenantListview ? "#000" : "#676565",
                }}
              >
                {list.title.length > 0 ? <span>{list.title[0]}</span> : ""}
              </Grid>
            ) : (
              <>
                {list?.image.length > 0 && (
                  <img
                    alt=""
                    className={clsx(classes.logo_image_wrapper, {
                      [classes.tenant_list_logo_image_wrapper]: tenantListview,
                    })}
                    src={image}
                  />
                )}
              </>
            )}
          </Grid>
          <Grid
            item
            xs={8}
            style={{
              marginLeft: 10,
              display: "flex",
              justifyContent: "space-between",
              width: "70%",
            }}
          >
            <div style={{ width: "100%" }}>
              <Tooltip title={list.title}>
                <Typography
                  style={{
                    width: "230px",
                    textTransform: "capitalize",
                    fontWeight: 800,
                    fontSize: tenantListview ? 14 : 16,
                    lineHeight: tenantListview ? "16px" : "",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {list?.title}
                </Typography>
              </Tooltip>
              <Typography
                style={{
                  fontWeight: 500,
                  fontSize: tenantListview ? 12 : 14,
                  width: "100%",
                  lineHeight: tenantListview ? "22px" : "",
                  whiteSpace: "nowrap",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                }}
              >
                {list.address}
              </Typography>
              {tenantListview && (
                <Typography
                  style={{
                    marginTop: "1px",
                    backgroundColor: "#47c952",
                    width: "fit-content",
                    color: "white",
                    padding: "2px 5px 2px",
                    fontSize: "10px",
                    lineHeight: "16px",
                    textTransform: "UPPERCASE",
                  }}
                >
                  {list.status}
                </Typography>
              )}
            </div>
            <div>
              {tenantListview && mainStateDashboard?.selectedTenantId === id && (
                <CheckCircleIcon
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                  }}
                />
              )}
            </div>
          </Grid>
        </Grid>
        {tenantListview ? null : (
          <Grid
            container
            style={{ borderTop: "1px solid #E0E0E0", paddingTop: 8 }}
          >
            <span
              className={
                list.status === "Active" ? classes.active : classes.inactive
              }
            ></span>
            {list.status}
          </Grid>
        )}
      </Grid>
    </StyledMainWrapperCardView>
  )
}
export default CardView
export { CardView }
