import { ReduxConstants } from "@constants"
import _ from "lodash"
import update from "immutability-helper"

const { ACTION_TYPES } = ReduxConstants.REDUX_CONSTANTS

const initState = {
  categories: {
    isLoading: false,
    error: "",
    list: [],
  },
}

const products = (state = initState, action) => {
  switch (action.type) {
    case ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_PENDING: {
      let tempState = update(state, {
        categories: { $merge: { isLoading: true, error: "" } },
      })
      return tempState
    }
    case ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_FULFILLED: {
      let tempState = update(state, {
        categories: {
          $merge: { isLoading: false, error: "", list: action.payload },
        },
      })
      return tempState
    }
    case ACTION_TYPES.FETCH_PRODUCT_CATEGORIES_REJECTED: {
      let tempState = update(state, {
        categories: { $merge: { isLoading: false, error: action.payload } },
      })
      return tempState
    }
    default: {
      return state
    }
  }
}

export default products
