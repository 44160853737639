import React from "react"
import styled from "styled-components/macro"
import { Link } from "react-router-dom"
import { makeStyles } from "@material-ui/core/styles"
import Helmet from "react-helmet"
import Spinner from "../../components/Spinner"
import { Button as MuiButton, Grid, Typography } from "@material-ui/core"
import { spacing } from "@material-ui/system"
import { useHistory } from "react-router-dom"
import { useTranslation } from "react-i18next"

const Button = styled(MuiButton)(spacing)

function Page404() {
  const history = useHistory()
  const { t } = useTranslation()

  const returnWeb = () => {
    let getRoleName = localStorage.getItem("role")
    let getTenantId = localStorage.getItem("tenantId")
    getRoleName = getRoleName.toLocaleLowerCase()
    const isSassRole = getRoleName.includes("saas")

    let dynamicRoutes = isSassRole
      ? `${t("/dashboard")}?${t("page=home")}`
      : `${t("/dashboard")}?${t(
        "page=frictionless&retailer"
      )}=${getTenantId}&${t("subpage")}=0`
    window?.location?.replace(dynamicRoutes)
  }

  return (
    <div style={{ position: "relative" }}>
      <img src="/static/img/PageNotFound1.svg" alt="pageNotFound Page" />
      <div style={{ position: "absolute", top: "88%", left: "52%" }}>
        <Button
          component={Link}
          to="/"
          variant="contained"
          style={{
            background: "#8080D2",
            width: "150px",
            borderRadius: "20px",
            color: "#FFF",
          }}
          onClick={() => returnWeb()}
        >
          {t("Go back home")}
        </Button>
      </div>
    </div>
  )
}

export default Page404
