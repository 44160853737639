import React, { useState, useEffect } from "react"
import {
  FormControl,
  Select,
  MenuItem,
  makeStyles,
  Typography,
} from "@material-ui/core"

import { JSUtils } from "@utils"

const usestyles = makeStyles({
  mainwrapper: {
    display: "flex",
    marginTop: "23px",
  },
  imgwrapper: {
    width: "35px",
    height: "20px",
    marginRight: "10px",
    marginTop: "3px",
  },
})

function LanguageSelection(props: any) {
  const classes = usestyles()
  const [languagesList, setLanguagesList] = useState(
    localStorage.getItem("languages") !== "undefined" &&
      localStorage.getItem("languages") !== null
      ? JSON.parse(localStorage.getItem("languages"))
      : []
  )
  const [selectedLanguage, setSelectedLanguage] = useState(
    localStorage.getItem("lang") !== "undefined" &&
      localStorage.getItem("lang") !== null
      ? localStorage.getItem("lang")
      : ""
  )

  useEffect(() => {
    setLanguagesList(
      localStorage.getItem("languages") !== "undefined" &&
        localStorage.getItem("languages") !== null
        ? JSON.parse(localStorage.getItem("languages"))
        : []
    )
    setSelectedLanguage(
      localStorage.getItem("lang") !== "undefined" &&
        localStorage.getItem("lang") !== null
        ? localStorage.getItem("lang")
        : ""
    )
  }, [])
  return (
    <div className={`${classes.mainwrapper}`}>
      {/* <img
        className={classes.imgwrapper}
        src={`https://flagcdn.com/w40/${countryCode || "us"}.png`}
        alt={`https://flagcdn.com/w40/${countryCode || "us"}.png`}
      /> */}
      {languagesList?.length > 1 ? (
        <FormControl size="small">
          <Select
            labelId="demo-simple-select-standard-label"
            id="demo-simple-select-standard"
            className="cls-demo-simple-select-standard-kit"
            value={selectedLanguage}
            onChange={(event) => {
              let valueKit = event.target.value
              const baseURL = window.location.origin
              setSelectedLanguage(valueKit)
              localStorage.setItem("i18nextLng", valueKit)
              localStorage.setItem("lang", valueKit)
              const [_firstLang = {}] =
                languagesList.filter(
                  (itm) => itm?.language_code === valueKit
                ) || {}
              localStorage.setItem(
                "language_display_direction",
                _firstLang?.display_direction || ""
              )
              document.querySelector("html").setAttribute("lang", valueKit)
              window.location.href = baseURL
            }}
            label="language"
            style={{ fontWeight: 800 }}
            MenuProps={{ classes: { paper: "cls-header-language-kit" } }}
          >
            {languagesList.map((item) => {
              return (
                <MenuItem
                  value={
                    item.code === "ARB" && item.language_code === undefined
                      ? "ar"
                      : item.language_code
                  }
                  className="cls-common-text-lan"
                >
                  {item.code}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      ) : (
        <Typography
          variant="subtitle2"
          style={{ color: "black", fontSize: "16px" }}
        >
          {languagesList?.find((x) => x.default)?.code}
        </Typography>
      )}
    </div>
  )
}
export default LanguageSelection
export { LanguageSelection }
