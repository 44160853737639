import React, { useState } from "react"
import _ from "lodash"
import DateRangeInput from "./DateRangeInput"
import { isValid, format, startOfDay, endOfDay } from "date-fns"
import { TimePicker, KeyboardTimePicker } from "@material-ui/pickers"
import moment from "moment"
import { Grid } from "@material-ui/core"
import { useEffect } from "react"
import { useTranslation } from "react-i18next"

const withFormattedTime = (date, time, key = "from") => {
  if (isValid(time)) {
    return `${format(date, "yyyy-MM-dd'T'")}${format(
      time,
      key === "from" ? "HH:mm:'00.000'xxx" : "HH:mm:'59.999'xxx"
    )}`
  }
  return format(
    date,
    key === "from"
      ? "yyyy-MM-dd'T'HH:mm:'00.000'xxx"
      : "yyyy-MM-dd'T23:59:59.999'xxx"
  )
}

const getFormattedDate = (date, time, key = "from") => {
  if (isValid(date)) {
    return withFormattedTime(date, time, key)
  }
  return ""
}

export const getInitialDatesFromString = (str) => {
  if (_.isEmpty(str) || !_.isString(str)) {
    return { startDate: undefined, endDate: undefined }
  }

  let dateArr = str.split("__||__")
  return {
    startDate: _.isEmpty(dateArr[0]) ? undefined : dateArr[0],
    endDate: _.isEmpty(dateArr[1]) ? undefined : dateArr[1],
    key: "dates",
  }
}

const SearchByDate = (props) => {
  const { t } = useTranslation()
  console.log(props, "SearchByDate")
  const {
    searchObjKey = "",
    searchObj = {},
    setSearchObj = () => { },
    label = "",
    select = false,
    SelectProps = {},
    searchByOptions = [],
    isLocationLookup = false,
    searchBy,
    onlyDate = false,
  } = props
  const [initialDates, setInitialDates] = useState(
    getInitialDatesFromString(_.get(searchObj, searchObjKey, ""))
  )
  const [timeObj, setTimeObj] = useState(() => ({
    from: _.get(initialDates, "startDate", null),
    to: _.get(initialDates, "endDate", null),
  }))

  useEffect(() => {
    console.log("from useEffect")
    setInitialDates(
      getInitialDatesFromString(_.get(searchObj, searchObjKey, ""))
    )
  }, [searchObj])

  useEffect(() => {
    console.log("from useEffect setInitialDates")
    setTimeObj({
      from: null,
      to: null,
    })
    setInitialDates({ key: "dates", startDate: undefined, endDate: undefined })
  }, [label])

  const handleChange = (val) =>
    setSearchObj((c) => {
      console.log("from handleChange in SearchByDate --- ", val)
      let temp = _.cloneDeep(c)

      temp[searchObjKey] = `${getFormattedDate(
        _.get(val, "startDate", ""),
        _.get(timeObj, "from"),
        "from"
      )}__||__${getFormattedDate(
        _.get(val, "endDate", ""),
        _.get(timeObj, "to"),
        "to"
      )}`
      setTimeObj({
        from: _.get(initialDates, "startDate", null),
        to: _.get(initialDates, "endDate", null),
      })
      console.log("from handleChange -- ", temp[searchObjKey])
      return temp
    })

  const handleTimeChange = (time, key = "from") =>
    setSearchObj((c) => {
      let tempDates = getInitialDatesFromString(
        _.get(searchObj, searchObjKey, "")
      )
      if (!isValid(new Date(_.get(tempDates, "startDate", "")))) {
        tempDates["startDate"] = startOfDay(time)
        tempDates["endDate"] = endOfDay(time)
      }
      console.log(
        "from handleChange in SearchByDate -- time --- ",
        time,
        tempDates,
        getInitialDatesFromString(_.get(searchObj, searchObjKey, ""))
      )
      setTimeObj((c) => ({ ...c, [key]: time }))
      let temp = _.cloneDeep(c)
      temp[searchObjKey] = `${getFormattedDate(
        new Date(_.get(tempDates, "startDate", "")),
        key === "from" ? time : _.get(tempDates, "startDate"),
        "from"
      )}__||__${getFormattedDate(
        new Date(_.get(tempDates, "endDate", "")),
        key === "to" ? time : _.get(tempDates, "endDate"),
        "to"
      )}`
      console.log("from handleChange --  time -- ", temp[searchObjKey])
      return temp
    })

  return (
    <Grid container item xs={12}>
      {onlyDate ? (
        <DateRangeInput
          initialDates={initialDates}
          handleDateChange={handleChange}
          searchObj={searchObj}
          searchObjKey={searchObjKey}
          searchBy={searchBy}
          label={label}
        />
      ) : (
        <Grid
          item
          xs={12}
          sm={6}
          md={6}
          lg={6}
          xl={6}
          style={{ paddingRight: "5px" }}
        >
          <DateRangeInput
            initialDates={initialDates}
            handleDateChange={handleChange}
            searchObj={searchObj}
            searchObjKey={searchObjKey}
            searchBy={searchBy}
            label={label}
          />
        </Grid>
      )}
      {onlyDate ? null : (
        <>
          <Grid
            item
            xs={6}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            style={{ paddingRight: "5px" }}
          >
            <TimePicker
              fullWidth
              variant="dialog"
              emptyLabel=""
              inputVariant="outlined"
              size="medium"
              margin="dense"
              label={t("From Time")}
              value={_.get(timeObj, "from", undefined)}
              onChange={(e) => {
                console.log("from timepicker onchange --- ", e)
                handleTimeChange(e, "from")
              }}
            />
          </Grid>
          <Grid item xs={6} sm={3} md={3} lg={3} xl={3}>
            <TimePicker
              fullWidth
              variant="dialog"
              emptyLabel=""
              inputVariant="outlined"
              size="medium"
              margin="dense"
              label={t("To Time")}
              value={_.get(timeObj, "to", undefined)}
              onChange={(e) => {
                console.log("from timepicker onchange to --- ", e)
                handleTimeChange(e, "to")
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  )
}

export default SearchByDate
