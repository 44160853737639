import React from "react"
import * as Adm from "@adm"
import { defaultList } from "./fixture"
import { MultiSearchFacetUIView } from "./MultiSearchFacetUIView"
import { CustomHeader } from "./CustomHeader"

const customRenderContainer = (props) => {
  return <MultiSearchFacetUIView {...props} />
}

const customRenderHeader = (props) => {
  return <CustomHeader {...props} />
}

function MultiSearchFacetUI(props) {
  const defaultTabs = [
    { name: "Commodity", attr: "commodity" },
    { name: "Product", attr: "product" },
  ]
  const defaultDisplayTabs = ["id", "commodity"]

  const {
    title = "facets",
    list = defaultList || [],
    tabs = defaultTabs,
    displayTabs = defaultDisplayTabs,
    buttonLabel = "ADD FRUITS",
    tableHeadings = ["Fruit ID", "Fruit Name"],
    idName = "id",
    onUpdate = () => "",
    isEditMode = true,
    isOpenMode = false,
    fetchTreeData = () => "",
    optional = false,
    listColumnSplit = false,
    elasticSearchEndpoint = "",
    inputField = false,
  } = props || {}

  const [fetchData, setFetchData] = React.useState(list)
  const [fetchDefaultData, setDefaultData] = React.useState(
    JSON.parse(JSON.stringify(list))
  )
  const [isIntialCount, setIntialCount] = React.useState(0)
  const [isAnyChanges, setAnyChanges] = React.useState(false)

  React.useEffect(() => {
    setFetchData(list)
    setDefaultData(JSON.parse(JSON.stringify(list)))
  }, [list])

  const [openDialog, setOpenDialog] = React.useState(false)
  const handleClose = (value: Boolean) => {
    setOpenDialog(!value)
  }

  const onUpdateData = async () => {
    setDefaultData(fetchData)
    onUpdate({ location: fetchData })
    setAnyChanges(false)
    setIntialCount(0)
  }

  const onDataUpdate = (update) => {
    if (update.length > 0) {
      setFetchData(update)
    }
    if (isIntialCount >= 4) {
      setAnyChanges(true)
    }
    setIntialCount(isIntialCount + 1)
  }

  const searchListProps = {
    list: fetchData,
    removedFetchData: fetchDefaultData,
    setRemoveData: setDefaultData,
    tabs: tabs,
    buttonLabel: buttonLabel,
    optional: optional,
    displayTabs: displayTabs,
    // treeData:treeData,
    fetchTreeData: fetchTreeData,
    open: openDialog,
    idName: idName,
    tableHeadings: tableHeadings,
    tableDataUpdate: onDataUpdate,
    elasticSearchEndpoint: elasticSearchEndpoint,
    onClose: handleClose,
  }

  const stateProps = {
    fetchData,
    setFetchData,
    isIntialCount,
    setIntialCount,
    isAnyChanges,
    setAnyChanges,
  }
  const attrAccordion = {
    isOpen: isOpenMode,
    isEditMode,
    label: title,
    updateData: onUpdateData,
    renderHeader: (accorProps) =>
      customRenderHeader({
        ...accorProps,
        ...stateProps,
        ...searchListProps,
      }),
    renderContainer: (accorProps) =>
      customRenderContainer({
        ...accorProps,
        ...stateProps,
        ...searchListProps,
      }),
  }

  return <Adm.AccordianPanel {...attrAccordion} />
}

export default MultiSearchFacetUI
export { MultiSearchFacetUI }
