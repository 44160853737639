import React from "react"
import { Grid, Button, Typography } from "@material-ui/core"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const TextViewMoreWrapper = styled(Grid)`
  width: 600px;
  display: flex;
  position: relative;
  background: #302532;
  border-radius: 10px;
  flex-direction: column;
  position: relative;
  code {
    width: calc(100% - 10px);
    color: #eeff8e;
    font-size: 14px;
    height: 40px;
    padding: 15px;
    text-wrap: wrap;
    transition: height 400ms ease;
    box-sizing: border-box;
    padding-top: 10px;
    word-wrap: break-word;
    &.active {
      height: 300px;
      overflow: auto;
    }

    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar {
      height: 5px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px transparent;
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background: #de127f;
      border-radius: 10px;
    }
    .cls-dkp-logs {
      font-family: "Poppins", sans-serif;
    }
  }

  Button {
    background: transparent;
    color: #ffffff;
    font-weight: bold;
    text-decoration: underline;
    background: transparent;
    font-style: italic;
    margin: auto;
    margin-right: -1px;
  }
  .cls-dkp-close {
    text-decoration: none;
    font-style: normal;
  }
`

const ShowMoreKit = (props) => {
  const {
    defaultValue = "",
    lengthWeight = 50,
    onClose = () => "",
    title = "Logs",
  } = props || {}
  const { t } = useTranslation()
  const [getIsShowMore, setIsShowMore] = React.useState(false)
  let isOverLength = defaultValue.length >= lengthWeight
  let cropText = isOverLength
    ? defaultValue.substring(0, lengthWeight).trim() + "..."
    : defaultValue
  const lines =
    defaultValue &&
    defaultValue.split("\n").map((line, index) => (
      <Typography className="cls-dkp-logs" key={index} variant="body1">
        {line}
      </Typography>
    ))
  return (
    <TextViewMoreWrapper>
      <code className={`${getIsShowMore ? "active" : ""} cls-showMore-code`}>
        {getIsShowMore ? lines : cropText}
        {getIsShowMore && (
          <>
            <br />
            <br />
          </>
        )}
      </code>
      {isOverLength && (
        <Button
          onClick={() => {
            setIsShowMore(!getIsShowMore)
          }}
        >
          {getIsShowMore ? t("Less") : t("Show")}
        </Button>
      )}
    </TextViewMoreWrapper>
  )
}

export { ShowMoreKit }
export default ShowMoreKit
