import React from "react"
import * as Adm from "@adm"
import { Grid, Typography, TextField } from "@material-ui/core"
import * as Render from "./renderProps"
import { useConfigUI } from "./useConfigUI"
import { useTranslation } from "react-i18next"

function ConfigUI(props) {
  const { t } = useTranslation()
  const {
    defaultList = {},
    onUpdate = () => "",
    getIsErrorIntiated = false,
    setIsErrorIntiated = () => "",
    getDefaultSampleCode = {},
  } = props || {}
  const stateConfigUIProps = useConfigUI({
    defaultList,
    onUpdate,
    getIsErrorIntiated,
    setIsErrorIntiated,
    getDefaultSampleCode,
  })

  return (
    <Grid>
      <Grid container style={{ marginBottom: 10 }} lg={10} md={12} spacing={2}>
        {Render.renderERPConfigDP(stateConfigUIProps)}
      </Grid>
      {stateConfigUIProps?.getMainDropdownValue === "API" ? (
        <>
          <Grid container lg={10} md={12} spacing={2} style={{ display: "flex", marginBottom: 10 }} >
            {Render.renderAPIEndpointTF(stateConfigUIProps)}
            {Render.renderConnectionMethodDP(stateConfigUIProps)}
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{ fontSize: 18, marginBottom: 10, fontWeight: "bold" }}
            >
              {t(`Authentication Details`)}
            </Typography>
            <Grid container lg={10} md={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
              {stateConfigUIProps?.getCMDropdownValue ===
                "Basic Authentication" ? (
                <>
                  {Render.renderUserNameTF(stateConfigUIProps)}
                  {Render.renderPasswordTF(stateConfigUIProps)}
                </>
              ) : (
                <>
                  {Render.renderTokenTF(stateConfigUIProps)}
                </>
              )}
            </Grid>
            <Grid container lg={10} md={12} spacing={2} style={{ marginBottom: 20 }}>
              {Render.renderResponseTypeDP(stateConfigUIProps)}
            </Grid>
            <Grid container lg={10} md={12} spacing={2} style={{ display: "flex" }}>
              {Render.renderSuccessStatusCodeTF(stateConfigUIProps)}
              {Render.renderErrorStatusCodeTF(stateConfigUIProps)}
            </Grid>
          </Grid>
          <Grid container lg={10} md={12} style={{ marginTop: 30 }}>
            {Render.renderSampleTestCode(stateConfigUIProps)}
          </Grid>
        </>
      ) : (
        <>
          <Grid container lg={10} md={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
            {Render.renderFTPTF(stateConfigUIProps)}
            {Render.renderFileTypeDP(stateConfigUIProps)}
          </Grid>
          <Grid>
            <Typography
              variant="h5"
              style={{ fontSize: 18, marginBottom: 15, fontWeight: "bold" }}
            >
              {t(`File Authentication Details`)}
            </Typography>
            <Grid container lg={10} md={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
              {Render.renderUserNameTF(stateConfigUIProps)}
              {Render.renderPasswordTF(stateConfigUIProps)}
            </Grid>
            {stateConfigUIProps?.getFTDropdownValue !== "XML" && (
              <Grid container lg={10} md={12} spacing={2} style={{ display: "flex", marginBottom: 10 }}>
                {Render.renderDelimiterTF(stateConfigUIProps)}
              </Grid>
            )}
          </Grid>
        </>
      )
      }
    </Grid >
  )
}

export { ConfigUI }
export default ConfigUI
