import React, { useState, useEffect } from "react"
import { TableCell, IconButton, TextField, TableRow, } from "@material-ui/core"
import useStyles from "../styles"
const TableFilter = (props) => {
    const { filterItem, setFilterItem, searchText = () => { }, disabledOtherLocation, } = props || {}
    const classes = useStyles();

    return (
        <>
            <TableRow>
                <TableCell style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)" }} className={classes.tableCellFilter} >
                    <div style={{ height: "40px", width: "150px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0px" }}>
                        <TextField
                            variant="standard"
                            size="small"
                            className={classes.textInput}
                            style={{ marginBottom: "8px" }}
                            type="text"
                            name="locationId"
                            value={filterItem.locationId}
                            onChange={(event) => { searchText(event) }}
                        />
                        <IconButton style={{ padding: "0px", marginLeft: "5px" }} onClick={() => {
                            setFilterItem((e) => {
                                return {
                                    ...e,
                                    locationId: ""
                                }
                            })
                        }} >
                            <img src={"/static/img/filter.svg"} alt={"filter"} width="15px" className={classes.filterIconCss} />
                        </IconButton>
                    </div>
                </TableCell>
                <TableCell style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)", }} className={classes.tableCellFilter} >
                    <div style={{ height: "40px", width: "150px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0px" }} >
                        <TextField
                            variant="standard"
                            size="small"
                            className={classes.textInput}
                            style={{ marginBottom: "8px" }}
                            type="text"
                            name="locationname"
                            value={filterItem.locationname}
                            onChange={(e) => { searchText(e) }}
                        />
                        <IconButton style={{ padding: "0px", marginLeft: "5px" }} onClick={() => {
                            setFilterItem((e) => {
                                return {
                                    ...e,
                                    locationname: ""
                                }
                            })
                        }} >
                            <img src={"/static/img/filter.svg"} alt={"filter"} width="15px" className={classes.filterIconCss} />
                        </IconButton>
                    </div>
                </TableCell>
                <TableCell style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)", }} className={classes.tableCellFilter} >
                    <div style={{ height: "40px", width: "150px", display: "flex", justifyContent: "space-between", alignItems: "center", gap: "0px" }}>
                        <TextField
                            variant="standard"
                            size="small"
                            className={classes.textInput}
                            style={{ marginBottom: "8px" }}
                            type="text"
                            name="locationAddress"
                            value={filterItem.locationAddress}
                            onChange={(e) => {
                                searchText(e)
                            }}
                        />
                        <IconButton style={{ padding: "0px", marginLeft: "5px" }} onClick={() => {
                            setFilterItem((e) => {
                                return {
                                    ...e,
                                    locationAddress: ""
                                }
                            })
                        }} >
                            <img src={"/static/img/filter.svg"} alt={"filter"} width="15px" className={classes.filterIconCss} />
                        </IconButton>
                    </div>
                </TableCell>
                {!disabledOtherLocation ? <>
                    <TableCell style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)", }}></TableCell>
                    <TableCell style={{ borderRight: "1px solid rgba(0, 0, 0, 0.1)", width: "15%px" }}></TableCell>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                </> : null}
            </TableRow>
        </>
    )
};

export default TableFilter;
