import { Grid } from "@material-ui/core"
import * as Render from "./renderProps"
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import { useStageUnstageUI } from "./useStageUnstageUI"
import { useTranslation } from "react-i18next";

function StageUnstageUI(props) {
    const { t } = useTranslation()
    const { defaultList = {}, onUpdate = () => { }, getDefaultFieldStage = {}, editPage = false } = props

    const stateConfigUIProps = useStageUnstageUI({
        defaultList,
        onUpdate,
        getDefaultFieldStage,
    })

    return (
        <Grid container direction="row" style={{ padding: "10px 0px" }}>
            <Grid item lg={4} md={3} xl={3}>
                {Render.renderStageUnstageField({ ...props, list: defaultList?.unstage, heading: "Unstage Fields List", labelButton: "Move All Stage", name: "unstage", ...stateConfigUIProps, t })}
            </Grid>
            <Grid item lg={1} md={1} style={{ textAlign: "center", alignSelf: "center" }}>
                <CompareArrowsIcon style={{ fontSize: "60px" }} />
            </Grid>
            <Grid item lg={4} md={3} xl={3}>
                {Render.renderStageUnstageField({ ...props, list: defaultList?.stage, heading: "Stage Fields List", labelButton: "Revert All Unstage", name: "stage", ...stateConfigUIProps, t })}
            </Grid>
        </Grid>
    )
}

export { StageUnstageUI }
export default StageUnstageUI