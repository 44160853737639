const defaultList = [
  { id: "73649846", title: "one", selected: true },
  { id: "93745149", title: "two" },
  { id: "78742587", title: "three", selected: true },
  { id: "02500445", title: "icon" },
  { id: "75878742", title: "vaio" },
  { id: "02500412", title: "test" },
]

export { defaultList }
