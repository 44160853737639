import { Grid } from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { green, red } from "@material-ui/core/colors"
import { spacing } from "@material-ui/system"
import styled from "styled-components/macro"
import {
  CardContent as MuiCardContent,
  Typography as MuiTypography,
  Button,
} from "@material-ui/core"

export const useStyles = makeStyles((theme) => ({
  cardStyles: {
    boxShadow: "0px 0px 14px rgba(53, 64, 82, 0.05)",
    background: "#FFFFFF",
  },
  imageIcon: {
    height: "6%",
    width: "6%",
  },
  active: {
    background: "#F9F9FC",
  },
  textField: {
    '& .MuiOutlinedInput-root': {
      '&.Mui-focused fieldset': {
        "borderColor": "var(--primaryColor)",
      }
    }
  }
}))

export const StyledGridMainWrapper = styled(Grid)`
  &:hover {
    background: #F9F9FC !important;
  }
`
export const StyledGridList = styled(Grid)`
  padding: 10px 15px 10px 10px;
  cursor: pointer;
`

export const StyledTabNumber = styled(Grid)`
  background: #f9f9fc;
  border-radius: 19px;
  padding: 5px 0px 2px 0px;
  width: 25px !important;
  color: var(--primaryColor);
  text-align: center;

  &.active {
  background: var(--primaryColor);
  color: #fff;
  }
`
