import React from "react"
import { SearchInput } from "./SearchInput"
import { ViewListUI } from "./ViewListUI"
import { useSearchListState } from "./state"
import { defaultSearchList } from "./fixture"
import { SearchListUIContext } from "./context"
import {
  ViewContainerWrapper,
  EmptyWrapper,
  EmptyTypography,
  searchErrorReq,
} from "./styles"
import * as Utils from "@utils"
import {
  defaultRenderSuggesionUIList,
  defaultRenderViewList,
  defaultRenderViewHeader,
} from "./renderProps"

const defaultOnSearchUpdate = (props) => {}

function SearchListUI(props) {
  const {
    list = defaultSearchList,
    renderInputSuggesionUIList = defaultRenderSuggesionUIList,
    renderViewList = defaultRenderViewList,
    renderViewHeader = defaultRenderViewHeader,
    onSearchUpdate = defaultOnSearchUpdate,
    isEdit = true,
    idName = "Id",
    titleName = "Title",
    onUpdate = () => {},
    isMultipleSelect = false,
    isDisable = true,
    isAddBtn = true,
    isProductGroup=false,
  } = props || {}

  const searchInputRef = React.createRef()

  const searchListState = useSearchListState({
    defaultFetchList: list,
    isMultipleSelect,
  })
  const {
    fetchListData,
    buttonTrigger,
    searchText,
    setSearchText,
    removedFetchListData,
  } = searchListState

  React.useEffect(() => {
    onUpdate({ fetchListData, removedFetchListData })
  }, [buttonTrigger])
  const resetInput = () => {
    const { current = {} } = searchInputRef || {}
    if (current) {
      current.querySelector("input").value = ""
    }
    setTimeout(() => {
      setSearchText("")
    }, 100)
  }

  const contextProps = {
    onSearchUpdate,
    searchInputRef,
    isEdit,
    idName,
    titleName,
    isMultipleSelect,
    resetInput,
    isDisable,
    ...searchListState,
  }

  const filterfetchListData =
    Utils.JSUtils.isEmpty(fetchListData) === false
      ? fetchListData.filter((item) => item.selected === true)
      : []
  console.log("filterfetchListData", filterfetchListData)
  const Empty = () => {
    return (
      <EmptyWrapper>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="83.771"
          height="67.819"
          viewBox="0 0 83.771 67.819"
        >
          <g
            id="Group_5"
            data-name="Group 5"
            transform="translate(-1363.607 -496.72)"
          >
            <g
              id="Group_4"
              data-name="Group 4"
              transform="translate(1363.607 496.72)"
            >
              <path
                id="Path_7"
                data-name="Path 7"
                d="M1656.182,482.3l30.549-8.282-12.253-13.3-29.633,8.333Z"
                transform="translate(-1644.846 -460.72)"
                fill="#a3a3a3"
              />
              <path
                id="Path_8"
                data-name="Path 8"
                d="M1675.4,482.3l-30.549-8.282,12.253-13.3,29.633,8.333Z"
                transform="translate(-1602.96 -460.72)"
                fill="#a3a3a3"
              />
              <path
                id="Path_9"
                data-name="Path 9"
                d="M1658.018,460.72l30.55,9.492-8.408,15.548-30.733-8.521Z"
                transform="translate(-1646.682 -439.142)"
                fill="#bcbcbc"
              />
              <path
                id="Path_10"
                data-name="Path 10"
                d="M1675.4,460.72l-30.549,9.492,8.431,15.685,29.613-8.494Z"
                transform="translate(-1602.96 -439.142)"
                fill="#bcbcbc"
              />
              <path
                id="Path_11"
                data-name="Path 11"
                d="M1718,535.088V558.34l28.318-7.755V535.866l-24.186,6.941Z"
                transform="translate(-1674.16 -490.521)"
                fill="#a3a3a3"
              />
              <path
                id="Path_12"
                data-name="Path 12"
                d="M1692.141,535.088v23.08l-27.809-7.412V535.894l23.637,6.665Z"
                transform="translate(-1652.655 -490.521)"
                fill="#a3a3a3"
              />
            </g>
          </g>
        </svg>

        <EmptyTypography>{`No Products, Yet!`}</EmptyTypography>
      </EmptyWrapper>
    )
  }
  return (
    <SearchListUIContext.Provider value={contextProps}>
      <ViewContainerWrapper className="view-container-wrapper">
        {isEdit && (
          <SearchInput
            renderSuggesionUIList={renderInputSuggesionUIList}
            isAddBtn={isAddBtn}
            isProductGroup={isProductGroup}
          />
        )}
        {Utils.JSUtils.isEmpty(filterfetchListData) === false ? (
          <ViewListUI
            renderViewHeader={renderViewHeader}
            renderViewList={renderViewList}
          />
        ) : (
          <Empty />
        )}
      </ViewContainerWrapper>
    </SearchListUIContext.Provider>
  )
}

export {
  SearchListUI,
  SearchListUIContext,
  defaultRenderSuggesionUIList as SearchLisDefaultRenderSuggesionUIList,
}
export default SearchListUI
