import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom"
import { Grid } from "@material-ui/core";
import { getProductList } from "../../../../reduxSagas/admin/actions";
import update from 'immutability-helper';
import _ from 'lodash';
import productsAPI, { productDataApiMethods } from '../../../../services/masterData/products';
import DraggableTreeView from "../../../../components/DraggableTreeView";
import { getProcessedFlatData, getNewFlatDataWithOldExpandedStatus, getNodeId, getNodeKey, getParentNodeId, getTreeData, getFlatData, getAllChildrenIds } from '../../../../components/DraggableTreeView/treeDataUtils'
import CustomTable from "./CustomTable"

const initialProductsData = { docs: [], totalPages: 0, totalDocs: 0, page: 1, limit: 10, hasNextPage: false, hasPrevPage: false, isLoading: false };

function ProductLook(props) {
  const [flatTreeData, setFlatTreeData] = useState([]);
  const [treeData, setTreeData] = useState(getTreeData(flatTreeData));
  const [processedFlatData, setProcessedFlatData] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [productsData, setProductsData] = useState(initialProductsData);

  const { list = [], apiName = 'buy_product', onUpdateList = () => null } = props;


  const fetchNewFlatTreeData = (oldFlatData) => {
    productsAPI.getAllProductCategories().then(resp => {
      let tempTreeData = getNewFlatDataWithOldExpandedStatus(resp?.data?.categories, oldFlatData);
      setFlatTreeData(tempTreeData);
    })
  }

  const fetchProductsList = (params) => {
    const { page = 1, limit = 10 } = params;
    setProductsData({ ...productsData, isLoading: true })
    productDataApiMethods.getAllProductsWithPagination(page, limit).then(resp => {
      console.log('resp from getAllProductsWithPagination ---> ', resp);
      setProductsData({ ...(resp?.data?.data?.products || initialProductsData), isLoading: false });
    }).catch(err => {
      console.error('Err from getAllProductsWithPagination ===> ', err);
      setProductsData(c => ({ ...c, isError: true, isLoading: false }))
    })
  }

  const handleCheckboxClick = (node, parentNode) => {
    // console.log('nodeId, parentId ---> ', node, parentNode);
    let exstIdx = _.findIndex(selectedCategories, o => o === node?.category_id);
    let childrenIds = getAllChildrenIds(node) || [];
    // console.log('temp from childrenIds---', childrenIds)
    let allIdsOfNode = [node?.category_id, ...childrenIds];
    let temp;
    if (exstIdx === -1) {
      temp = update(selectedCategories, { $push: allIdsOfNode });
    } else {
      temp = _.without(selectedCategories, ...(allIdsOfNode || []))
      // temp = update(selectedCategories, { $splice: [[exstIdx, 1]] })
    }
    setSelectedCategories(temp);
    setProcessedFlatData(getProcessedFlatData({ data: getFlatData(treeData), checkedList: temp }))
  }

  useEffect(() => {
    fetchNewFlatTreeData()
    fetchProductsList({})
  }, []);

  useEffect(() => {
    setProcessedFlatData(getProcessedFlatData({ data: flatTreeData, checkedList: selectedCategories }));
  }, [flatTreeData]);

  useEffect(() => {
    let temp = getTreeData(processedFlatData)
    setTreeData(temp)
  }, [processedFlatData])
  return (
    <Grid container item xs={12} style={{ border: `1px solid #e0e0e0`, height: `100%` }}>
      <Grid item xs={4} alignItems="center" container style={{ borderRight: `1px solid #e0e0e0` }}>
        <DraggableTreeView isViewMode
          treeData={treeData} onChangeInTreeData={setTreeData}
          handleCheckboxClick={handleCheckboxClick} />
      </Grid>

      <Grid item xs={8} style={{ paddingBlock: 8, height: '100%' }}>
        <CustomTable list={list} apiName={apiName} onUpdateList={onUpdateList} data={productsData} fetchDataMethod={d => fetchProductsList(d)} />
      </Grid>

    </Grid>
  )
}
export default ProductLook;