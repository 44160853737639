import { makeStyles } from "@material-ui/core/styles"

const customTableStyles = makeStyles((theme) => ({
  selectCheckList: {
    padding: "0 !important",
    "& .MuiButtonBase-root ": {
      padding: "7px 9px !important",
    },
  },
  selectCheckbox: {
    "& .MuiInputLabel-formControl ": {
      top: "-7px",
      left: "15px",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "var(--primaryColor)",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "var(--primaryColor)",
    },
  },
  table: {
    minWidth: 700,
  },
  tableCell: {
    fontSize: "14px",
    fontWeight: "400",
    height: "53px",
  },
  tableHead: {
    fontSize: "14px",
    fontWeight: "700",
  },
  headFont: {
    fontSize: "14px",
    fontWeight: "500",
    padding: "20px",
  },
  enabledBox: {
    borderRadius: "4px",
    backgroundColor: theme.palette.table.enabledBox.backgroundColor,
    color: theme.palette.table.enabledBox.textColor,
    textAlign: "center",
    width: "83px",
    fontSize: "14px",
    fontWeight: "400px",
  },
  disabledBox: {
    borderRadius: "4px",
    backgroundColor: theme.palette.table.disabledBox.backgroundColor,
    color: theme.palette.table.disabledBox.textColor,
    textAlign: "center",
    width: "86px",
    fontSize: "14px",
    fontWeight: "400px",
  },
  errorBox: {
    borderRadius: "4px",
    backgroundColor: "#f46565",
    color: "white",
    textAlign: "center",
    width: "90px",
    fontSize: "14px",
    fontWeight: "400px",
  },
  card: {
    borderRadius: "0px",
  },
  headerFont: {
    fontSize: "17px",
    fontWeight: "500",
  },
  tableHeader: {
    backgroundColor: theme.palette.table.tableHeadBackground,
    borderTop: theme.palette.table.tableBorder,
  },
  tableHeadFont: {
    fontSize: "14px",
    fontWeight: "700",
  },
  customTooltip: {
    backgroundColor: "#f46565",
    color: "white",
    padding: "5px",
    borderRadius: "10px",
    "& p": {
      maxWidth: "250px",
      overflow: "hidden",
      textOverflow: "ellipsis",
      maxHeight: "40px",
      margin: "4px",
    },
    "&.MuiTooltip-tooltipArrow": {
      top: "-9px !important",
      "& .MuiTooltip-arrow": {
        color: "#f46565",
      },
    },
  },
  clsLocationSwitch: {
    "& .MuiSwitch-switchBase": {
      color: "rgba(158, 158, 158, 1) !important",
    },
    "& .MuiSwitch-track": {
      backgroundColor: "rgba(158, 158, 158, 1) !important",
    },
    "& .Mui-checked.MuiSwitch-switchBase": {
      color: "var(--primaryColor) !important",
    },
    "& .Mui-checked.MuiSwitch-switchBase + .MuiSwitch-track": {
      backgroundColor: "var(--primaryColor) !important",
    },
  },
}))

export default customTableStyles
