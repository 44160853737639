import _ from "lodash"
import axios from "axios"
import { JSUtils } from "@utils"
import * as ServiceAPI from "@services"
import UserService from "../../services/UserService"
const { API_BASE_URL, KC_API_BASE_URL } = ServiceAPI

const GET_TRAINING_PRODUCTS_SERVICE = `${KC_API_BASE_URL}/api/kc/getTrainingProducts`
const CREATE_TRAINING_PRODUCT_SERVICE = `${KC_API_BASE_URL}/api/kc/trainingimages`
const GET_PRODUCT_BY_BARCODE = `${KC_API_BASE_URL}/api/kc/getProductName`
const GET_TRAINING_DETAILS = `${KC_API_BASE_URL}/api/kc/getTrainingProduct`

const trainingProducstAxios = axios.create()

trainingProducstAxios.interceptors.request.use(
  async function (config) {
    if (UserService.isLoggedIn()) {
      if (UserService.isAuthExpired() === true) {
        const myData = await UserService.updateRefreshToken()
        if (myData === true) {
          let mydataToken = UserService.getToken((res) => {
            return res
          })
          localStorage.setItem("token", mydataToken)
          config.headers["Authorization"] = "Bearer " + mydataToken
          let tenantId =
            localStorage.getItem("tenantId") === null
              ? ""
              : localStorage.getItem("tenantId")
          config.headers["X-Tenant-ID"] = tenantId
        } else {
          localStorage.clear()
          UserService.doLogout()
        }
      } else {
        let tenantId =
          localStorage.getItem("tenantId") === null
            ? ""
            : localStorage.getItem("tenantId")
        config.headers["X-Tenant-ID"] = tenantId
        config.headers["Authorization"] =
          localStorage.getItem("token") === null
            ? ""
            : "Bearer " + localStorage.getItem("token")
      }
    } else {
      localStorage.clear()
      UserService.doLogout()
    }
    return config
  },
  function (error) {
    return Promise.reject(error)
  }
)

async function getTrainingProductsApi(globalState = {}, locationId) {
  const {
    data,
    setLoading,
    setData,
    setPageData,
    setRowData,
    selection,
    setItems,
    gridState,
  } = globalState
  const dataState = {
    filter: {
      logic: "and",
      filters: [],
    },
    sort: [],
    take: 10,
    skip: 0,
  }
  const adopterFunction = (dataState, page, limit) => {
    let sort = dataState.sort.reduce((acc, o) => {
      acc[o.field] = o.dir === "asc" ? 1 : -1
      return acc
    }, {})
    return {
      page,
      limit,
      filter: dataState?.filter?.filters,
      sort,
    }
  }

  const page = Math.floor(
    (gridState.dataState.take + gridState.dataState.skip) /
    gridState.dataState.take
  )
  const limit = gridState.dataState.take
  setLoading(true)
  const body = adopterFunction(gridState.dataState, page, limit)
  let productName = ""
  let barcode = ""
  let imageCount = ""
  let trainedStatusFlag
  let serialnumber = ""
  let sortpara = "productName"
  let sortvalue = -1

  body?.filter?.forEach((val) => {
    if (val.field && val.field === "productName") {
      productName = val.value.trim()
    }
    if (val.field && val.field === "barcode") {
      barcode = val.value.trim()
    }
    if (val.field && val.field === "imageCount") {
      imageCount = val.value.trim() || undefined
    }
    if (val.field && val.field === "trainedStatusFlag") {
      trainedStatusFlag = JSON?.parse(val.value) ?? undefined
    }
  })
  if (body && body.sort && Object.values(body.sort).length !== 0) {
    sortpara = Object.keys(body.sort)[0]
    if (Object.values(body.sort)[0] === 1) {
      sortvalue = 1
    } else {
      sortvalue = -1
    }
  }

  try {
    const response = await trainingProducstAxios.get(
      GET_TRAINING_PRODUCTS_SERVICE,
      {
        params: {
          locationId: locationId,
          pagenumber: body.page,
          itemcount: body.limit,
          productName,
          barcode,
          imageCount: imageCount || undefined,
          sortvalue: sortvalue,
          sortpara: sortpara,
          trainedStatusFlag,
        },
      }
    )

    await setData(_.get(response, "data.response", []))
    await setPageData(_.get(data, "data.totalPages", {}))
    await setItems(_.get(response, "data.totalDocs"))
    let responseData = []
    let storeData = {}
    if (JSUtils.isEmpty(response.data) === false) {
      response.data.response.forEach((dataItem) => {
        dataItem._id = dataItem.barcode
        responseData.push(dataItem)
      })
      storeData = {
        data: responseData.map((dataItem, i) => {
          const { _id, updatedAt } = dataItem || {}
          const { dateString, monthString, yearString, timeString } =
            JSUtils.getDateKit(updatedAt) || {}

          let dataKit = {
            ...dataItem,
            updatedAt: `${monthString} ${dateString} ${yearString}, ${timeString}`,
          }

          return Object.assign(
            {
              selected: selection.selectedItems.includes(dataItem._id)
                ? true
                : false,
              inEdit: false,
            },
            dataKit
          )
        }),
        editID: null,
        defaultID: null,
      }
    }
    await setRowData(storeData)
    await setLoading(false)
  } catch (err) {
    await setLoading(false)
  }
}

async function createProductTraining(
  details,
  history,
  setAlertMessage,
  setAlert,
  setAlertType,
  setLoading,
  setPrompt,
  t
) {
  setPrompt(false)
  const userName = localStorage.getItem("tenantName")
    ? localStorage.getItem("tenantName")
    : ""
  setLoading(true)
  try {
    const res = await trainingProducstAxios.post(
      CREATE_TRAINING_PRODUCT_SERVICE,
      {
        images: details.images?.length ? details.images : [],
        locationId: details.locationId,
        productName: details.productName,
        barcode: details.barcode,
        deleted_images: details.deleted_images?.length
          ? details.deleted_images
          : [],
        lastUpdatedBy: userName,
        createdBy: userName,
        createFlag: details.createFlag ? true : false,
        adminPortal: true,
      }
    )

    if (res && res.data.status === "success") {
      await setAlertMessage(res?.data?.message)
      setAlert(true)
      setAlertType("success")
      setTimeout(() => {
        history.push(t(`/master-data/products/training-products`))
      }, 2000)
      return true
    } else if (res && res.data.status === "failed") {
      setAlertMessage(res.data.message)
      setAlert(true)
      setAlertType("error")
      await setLoading(false)
      return false
    } else {
      setAlertMessage(res.data.message)
      setAlert(true)
      setAlertType("error")
      await setLoading(false)
      return false
    }
  } catch (error) {
    console.log({ error })
    setAlertMessage(error?.response?.data?.message)
    setAlert(true)
    setAlertType("error")
    await setLoading(false)
    return false
  } finally {
  }
}

async function getProductNameByBarcode(barcode) {
  const res = await trainingProducstAxios.get(
    GET_PRODUCT_BY_BARCODE + `/${barcode}`
  )
  if (res) {
    return res
  } else {
    return false
  }
}

async function getTrainingDetailsByBarcode(barcode) {
  const res = await trainingProducstAxios.get(
    GET_TRAINING_DETAILS + `/${barcode}`
  )

  if (res) {
    return res
  } else {
    return false
  }
}

export {
  getTrainingProductsApi,
  createProductTraining,
  getProductNameByBarcode,
  getTrainingDetailsByBarcode,
}
