import React from "react"
import ButtonMaterial from "@material-ui/core/Button"

function BrandLoadingBar(props) {
  const {
    DK_BRAND_NAME = "DigitKart",
    location = {},
    _kc = {},
    DK_LOGO_IMG = "",
  } = window

  return (
    <div
      className="dk-loadBar dk-loadBar-brand-logo-kit"
      style={{ height: 120 }}
    >
      <div>
        <img
          alt="brandlogo"
          className="dk-loadBar-brand-logo "
          src={DK_LOGO_IMG}
        />

        <div className="dk-lds-ellipsis">
          <div className="dk-lds-ellipsis-dot"></div>
          <div className="dk-lds-ellipsis-dot"></div>
          <div className="dk-lds-ellipsis-dot"></div>
          <div className="dk-lds-ellipsis-dot"></div>
        </div>
      </div>
    </div>
  )
}

export { BrandLoadingBar }
export default BrandLoadingBar
