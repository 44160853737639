import { createStore, applyMiddleware } from "redux"
import { composeWithDevTools } from "redux-devtools-extension"
import axiosMiddleware from "redux-axios-middleware"
import createSagaMiddleware from "redux-saga"
import HttpService from '../../services/HttpService'

import thunk from "redux-thunk"

import { rootReducer } from "../reducers/index"

import rootSagas from "../../reduxSagas/sagas"

const saga = createSagaMiddleware()

const middleWares = [saga, thunk]

const composeEnhancers = composeWithDevTools({trace: true})

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleWares, axiosMiddleware(HttpService.getAxiosClient())))
)

export { store }
saga.run(rootSagas)

export default store
