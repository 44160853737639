import { makeStyles } from "@material-ui/core"

const useStyles = makeStyles((theme) => ({
  container: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
    "& .k-grid": {
      boxShadow: ["none", "!important"],
    },
  },
  header: {
    display: "flex",
    width: "100%",
    height: 48,
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(0, 2),
  },
  height60: {
    height: 60,
  },
  noPadding: {
    padding: 0,
  },
  headerActionsContainer: {
    display: "flex",
    width: "100%",
    height: "100%",
    flex: 1,
    justifyContent: "flex-end",
    alignItems: "center",
    gap: theme.spacing(2.5),
  },
  content: {
    width: "100%",
    height: `calc(100% - 48px)`, //border: '1px solid red'
  },
  title: {
    fontSize: theme.typography.pxToRem(16),
    fontWeight: 600,
    marginRight: "10px",
  },
  headerTitle: {
    fontSize: theme.typography.pxToRem(20),
  },
}))

export default useStyles
