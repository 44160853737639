/* eslint-disable no-use-before-define */
import React from "react"
import { Typography } from "@material-ui/core"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import fr from "react-phone-input-2/lang/fr.json"
import es from "react-phone-input-2/lang/es.json"

import styled from "styled-components"
import { useTranslation } from "react-i18next"

const Wrapper = styled.div`
  &.cls-error {
    .react-tel-input .flag-dropdown,
    input {
      border-color: #f44335;
    }
  }
  input {
    height: 40px;
  }

  &.base-phone-field {
    .react-tel-input .form-control {
      width: 225px;
      height: 35px;
    }
  }
  &.customer-edit-base-phone-field {
    .react-tel-input .form-control {
      width: 300px;
      height: 35px;
    }
    font-weight: bold;
  }

  &.location-base-phone-field {
    .react-tel-input .form-control {
      width: 93%;
      height: 40px;
    }
    font-weight: bold;
  }

  &.phone-full-width {
    .react-tel-input .form-control {
      width: 100%;
      height: 35px;
    }
  }

  &.create-phone-field {
    .react-tel-input .form-control {
      width: 372px;
      height: 35px;
    }
  }
`
const Error = styled(Typography)`
  color: #f44336;
  font-size: 0.6964285714285714rem;
  margin-left: 14px;
  margin-right: 14px;
  margin-top: 4px;
`

function PhoneField(props) {
  const { t } = useTranslation()
  const {
    onUpdate = () => {},
    defaultValue = "",
    defaultCountry = "",
    defaultErrorValue = false,
    error = "Enter Phone number",
    name = "test",
    entity = "",
    className,
    disabled = false,
    style,
    id = "",
  } = props
  const [phoneNumber, setPhoneNumber] = React.useState(defaultValue)
  const [isCountryText, setCountryText] = React.useState(defaultCountry)
  const [isIntialLoad, setIntialLoad] = React.useState(false)
  const [isError, setError] = React.useState(false)

  let lang = localStorage.getItem("i18nextLng")
  let locale = { es, fr }
  React.useEffect(() => {
    setError(defaultErrorValue)
  }, [defaultErrorValue])

  React.useEffect(() => {
    setPhoneNumber(defaultValue)
  }, [defaultValue])

  React.useEffect(() => {
    setCountryText(defaultCountry)
  }, [defaultCountry])

  const attr = {
    disabled: disabled,
    country: isCountryText || "us",
    value: defaultValue || "1",
    enableSearch: true,
    countryCodeEditable: false,
    copyNumbersOnly: true,
    specialLabel: "",
    inputProps: {
      name,
      style,
    },
    onChange: (phone, { dialCode, countryCode, format }) => {
      onUpdate({ value: phone, dialCode, format, countryCode })
      setIntialLoad(true)
    },
    isValid: (value, country, countries, hiddenAreaCodes) => {
      if (
        (value === country.countrycode || value === "") &&
        entity === "Customer"
      ) {
        setError(true)
        return false
      } else {
        setError(false)
        return true
      }
    },
  }
  console.log(attr, "attr")
  return (
    <Wrapper id={`${id}`} className={`${className}`}>
      <PhoneInput
        placeholder="Phone Number"
        localization={locale[lang]}
        {...attr}
      />
      {(isError || defaultErrorValue) && <Error>{t(error)}</Error>}
    </Wrapper>
  )
}

export { PhoneField }
export default PhoneField
