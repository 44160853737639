import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS

export const stepperSetActiveStep = (data) => (dispatch) => {
  dispatch({
    type: types.STEPPER_ACTIVE_STEP,
    step: data,
  })
}
