import * as React from "react"
import {
  Card,
  Divider,
  List,
  ListItem,
  IconButton,
  ListItemText,
  Chip,
  Box,
  Typography,
  Grid,
  TextField,
  MenuItem,
  Switch,
  Checkbox,
} from "@material-ui/core"
import styled from "styled-components"
export const ListWrapper = styled(List)`
  &.list-split {
    display: flex;
    flex-direction: initial;
    flex-wrap: wrap;
  }
`
export const ListItemWrapper = styled(ListItem)`
  &.list-split-sub {
    width: 50%;
  }
`
