export const stubData = {
  status: "success",
  message: "country list",
  data: {
    country: {
      itemCount: 5,
      pageCount: 1,
      next: {
        page: 3,
        limit: 5,
      },
      previous: {
        page: 1,
        limit: 5,
      },
      data: [],
    },
  },
}

export const customerCommunication = [
  "Loyality App",
  "Point of Sale",
  "CRM Online Portal",
  "Physical Registration Phone",
  "Phone Registration",
]
