import {
  SwipeableDrawer,
  Grid,
  Typography,
  CardHeader,
  Card,
  FormControlLabel,
  Checkbox,
  Slider,
  IconButton,
  Box,
} from "@material-ui/core"

export const renderAugmentDetails = (props) => {
  const { item, subItem, handleChange, classes, t } = props
  return (
    <>
      <Typography variant={"subtitle1"}>{t(subItem.name)}</Typography>
      {subItem.type === "checkbox" ? (
        <FormControlLabel
          className="cls-checkbox-svg-kit"
          value="default"
          control={
            <Checkbox
              style={{
                color: "var(--primaryColor)",
              }}
              checked={subItem.value}
              onChange={(event) => {
                handleChange(item, subItem, "", event.target.checked)
              }}
            />
          }
          label={t(subItem.name)}
        />
      ) : subItem.type === "range" ? (
        <>
          <Typography id="input-slider" gutterBottom>
            {t(subItem?.subName)}
          </Typography>
          <Slider
            size="medium"
            defaultValue={subItem?.value}
            step={subItem?.to === 0.5 ? 0.1 : 0.1}
            min={subItem?.from}
            max={subItem?.to}
            aria-label="Default"
            valueLabelDisplay="auto"
            className={classes.sliderCSS}
            onChange={(event, value) => {
              handleChange(item, subItem, "", value)
            }}
          />
          <Box
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="body2" style={{ cursor: "pointer" }}>
              {subItem.from}
              {subItem?.valueSuffix}
            </Typography>
            {subItem?.to <= 2 && (
              <Typography variant="body2" style={{ cursor: "pointer" }}>
                {subItem?.to === 0.5 ? 0 : 1}
              </Typography>
            )}
            <Typography variant="body2" style={{ cursor: "pointer" }}>
              {subItem.to}
              {subItem?.valueSuffix}
            </Typography>
          </Box>
        </>
      ) : null}
    </>
  )
}

export const renderChildAugmentDetails = (props) => {
  const { item, subItem, childItem, handleChange, classes, t } = props
  return childItem?.type === "checkbox" ? (
    <FormControlLabel
      className="cls-checkbox-svg-kit"
      value="default"
      control={
        <Checkbox
          style={{
            color: "var(--primaryColor)",
          }}
          checked={childItem?.value}
          onChange={(event) => {
            handleChange(item, subItem, childItem, event.target.checked)
          }}
        />
      }
      label={t(childItem.name)}
    />
  ) : childItem?.type === "range" ? (
    <>
      <Typography id="input-slider" gutterBottom>
        {t(childItem?.subName)}
      </Typography>
      <Slider
        size="medium"
        defaultValue={childItem.value}
        value={childItem.value}
        aria-label="Default"
        valueLabelDisplay="auto"
        className={classes.sliderCSS}
        min={childItem?.from}
        max={childItem?.to}
        onChange={(event, value) => {
          handleChange(item, subItem, childItem, value)
        }}
      />
      <Box
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="body2" style={{ cursor: "pointer" }}>
          {childItem.from}
          {childItem?.valueSuffix}
        </Typography>
        <Typography variant="body2" style={{ cursor: "pointer" }}>
          {childItem.to}
          {childItem?.valueSuffix}
        </Typography>
      </Box>
    </>
  ) : null
}
