import { process } from "@progress/kendo-data-query"
import {
  Grid,
  GridCell,
  GridColumn as Column,
  GridRowClickEvent,
  GridNoRecords,
  GridToolbar,
} from "@progress/kendo-react-grid"
import { ExcelExport } from "@progress/kendo-react-excel-export"
import { Tooltip } from "@progress/kendo-react-tooltip"
import "@progress/kendo-theme-bootstrap/dist/all.css"
import SwitchCell from "./SwitchCell"
import CheckboxCell from "./CheckboxCell"
import BooleanCell from "./CustomFilterCell"
import { useHistory } from "react-router-dom"
import ActionMenu from "./ActionMenu"
import IconMenu from "./IconMenu"
import commonStyles from "../../styles/Pages/common/styles"
import TextField from "@material-ui/core/TextField"
import moment from "moment"
import _, { isEmpty, isString, isArray } from "lodash"
import React, { useEffect } from "react"
import {
  Avatar,
  Box,
  Button,
  Link,
  List,
  ListItem,
  Typography,
  IconButton,
  Chip,
  ThemeProvider,
  Tooltip as MuiTooltip,
} from "@material-ui/core"
import styled from "styled-components"
import ShowCircleBasedOnProp from "./ShowCircleBasedOnProp"
import {
  Map,
  CheckCircleIcon,
  Slash,
  VisibilityIcon,
  AddModeratorIcon,
  ArIcon,
  CancelIcon,
  EditIcon,
  AnnotateIcon,
} from "@iconsGallery"
import CustomBooleanCell from "./CustomBooleanCell"
import { makeStyles } from "@material-ui/core/styles"
import CustomDateTimePicker from "./CustomDateTimePicker"
import { useTranslation } from "react-i18next"
import {
  IntlProvider,
  LocalizationProvider,
  loadMessages,
} from "@progress/kendo-react-intl"
import RoleDropdown from "./RoleDropdown"
import StatusColorBased from "./StatusColorBased"
import { KeyboardTimePicker } from "@material-ui/pickers"
import { theme, MainWrapperTableUIGrid } from "./styles"
import AccessTimeIcon from "@material-ui/icons/AccessTime"
import SingleDropdown from "./SingleDropdown"
import GridDropDownFilter from "./GridDropDownFilter"
import AdjustmentReason from "./AdjustmentReason"
import { ShowMoreKit } from "../AdmKit"
import { JSUtils } from "@utils"

const useStyles = makeStyles({
  basicTextFieldProperty: {
    "& .Mui-error": {
      whiteSpace: "pre-wrap",
    },
  },
})

const TemplateTableUIGrid = styled(Grid)`
 &.cls-modeltrainning-prefix .k-master-row td:not(td.cls-showpercent-kit, td.cls-td-kit-25,td.cls-td-kit-03) {
    z-index: 99;
    position: relative;
    background: transparent !important;
  }
 .k-grid-container {
    // max-height: 90vh;
    min-height: 20vh;
  }
  &.k-grid {
    background-color: #ffffff;
    box-shadow: 1px 2px 3px #0000002b;
  }
   &.k-grid tr.k-state-selected > td{
    background-color: #f2f2f2;
  }
 .k-checkbox:checked{
    background-color: var(--primaryColor);
    border-Color: var(--primaryColor);
  }
  .k-grid-table {
    width: 100% !important
  }
  .k-grid-header table {
     width: 100% !important
  }
  .k-header {
    background: #f4f7fa;

    .k-link {
      color: #000000;
      font-weight: 500;
      padding: 15px 12px !important;
    }
  }
  .k-filter-row {
    background: #ffffff;

    .k-dropdown .k-dropdown-wrap {
      background: white;
      border: none;
    }
    .k-button {
      background: white;
      border: none;
    }

    .k-i-filter::before {
      color: #353535;
    }
    .k-textbox {
      border-top: none;
      border-left: none;
      border-right: none;
      background: transparent;
    }
  }

  &.k-grid td {
    border-bottom: 1px solid #e0e0e0;
  }
  .k-filter-row th {
    padding: 16px 8px;
  }
 
  .k-master-row,
  .k-pager-wrap {
    background: transparent !important;
    cursor: ${(props) => (props.cursor ? "pointer" : "")};
    position:relative !important;
  }
  .k-pager-numbers .k-link {
    color:  #000;
  }
  .k-pager-numbers .k-link.k-state-selected {
    color: var(--primaryColor);
    background-color: #f2f2f2;
    border-color: var(--primaryColor) !important;
    box-shadow: none !important;
  }

   .k-pager-numbers .k-link:active {
    color: var(--primaryColor) !important ;
    background-color: #f2f2f2 !important;
  }
  .k-pager-numbers .k-link:focus, .k-pager-numbers .k-link.k-state-focus {
    box-shadow: none !important;
  }
  .k-checkbox {
    border: 2px solid #8c8c8c;
  }
  .bRDGfF {
    border-bottom: 1px solid #fefefe52;
  }

  .k-grid tbody td {
  }
  &.k-grid td {
    padding:${(props) =>
    props.BreadcrumbsValue2 === "Tax" ? "1rem 10px" : "0.4rem 10px"};
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &.k-grid th {
    padding: 0.4rem 0.4rem;
    border-style: solid;
    outline: 0;
    font-weight: inherit;
    text-align: inherit;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
    .k-grid-header thead tr:first-child th{ 
    background-color: #f9f9fc;
  }
  &.k-grid .k-filter-row th:empty {
    padding: 0 !important;
  }
  &.k-grid td,
  .k-grid .k-grid-content,
  .k-grid .k-grid-header-locked,
  .k-grid .k-grid-content-locked {
    border-color: transparent;
    border-bottom: 1px solid #e8e8e8;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
  .selected {
    margin-bottom: 10px,
    margin-left: 5px,
  }
.k-dropdown-operator {
    width: auto;
    display: none;
}
.k-icon-button, .k-button.k-button-icon {
    width: calc(0.2em + 0.2rem );
    height: calc(1.5em + 0.75rem + 2px);
    padding: 0.1rem;
}
.k-pager-info.k-label{
  display: block
}
.k-checkbox{
  margin-bottom: 6px;
  margin-left: 6px;
}
.k-pager-nav.k-link{
  color: #000;
}
.k-dropdown {
  background-color: #f9f9fc;
}
.k-grid-header .k-i-sort-asc-sm, .k-grid-header .k-i-sort-desc-sm{
   color:  #000;
   place-self: flex-start;
   position: absolute;
   top: 40%;
   right: 5px;
}
.k-grid th.k-state-selected, .k-grid td.k-state-selected, .k-grid tr.k-state-selected > td{
      background-color: var(--primaryColor);
}
.k-grid-header{
  padding: 0px
}
.k-virtual-content{
  overflow-y: auto;
}
`

const loadingPanel = (
  <div className="k-loading-mask">
    <span className="k-loading-text">Loading</span>
    <div className="k-loading-image"></div>
    <div className="k-loading-color"></div>
  </div>
)

const MaterialDataGrid = ({
  isGrid = false,
  addOnClassName = "cls-card-ui-kit",
  scrollable = "scrollable",
  isInlineEditAllowed = false,
  errorMessage = {},
  gridState,
  columnData,
  pathProps,
  rowData = [],
  setRowData,
  setGridState,
  itemChange,
  onDropDownCellClick = () => { },
  setDefaultValue,
  headerSelectionChange,
  isLookup,
  selectionChange,
  onDiscard = () => { },
  onEdit = (e) => { },
  onDelete = () => { },
  onSave = () => { },
  onView = () => { },
  onCopy = () => { },
  onStop = () => { },
  switchHandler,
  pageData,
  actionMenu,
  additionalActions = () => { },
  reDirectButton,
  reDirectTo,
  number_of_items,
  pageChangeEvent,
  gridNoRecordsText = "No records found",
  loader,
  onRowClick = () => null,
  otherProps = {},
  menutype = "",
  buttonCount = 4,
  ShowCheckBox = true,
  checkBoxWidth = "50px",
  hideCheckboxAll = false,
  isOnRowClick = true,
  pageable = true,
  BreadcrumbsValue2 = "",
  cursor = false,
  isDisableViewIcon = false,
  isDisableEditIcon = true,
  pageSizes = [],
  showCopyIcon = false,
  showPauseIcon = false,
  isDisableDeleteButton = false,
  handleMultipleDropdown = () => { },
  RoleDropdownList = [],
  setSelectedRoles = () => { },
  handleSingleDropdownChange = () => { },
  isBulkUploadLog = false,
  handleLanguageChange = () => { },
  onUpdateImageView = () => "",
  onImageEdit = () => "",
  renderMenuCardUI = () => "",
  classNamePrefix = "",
  showImageEdit = false,
  showDeleteIcon = false,
  moduleName = "",
}) => {
  rowData = {
    ...rowData,
    data: rowData?.data === undefined ? [] : rowData?.data,
  }
  const { t } = useTranslation()
  let history = useHistory()
  const classes = useStyles()
  const inputRef = React.useRef()
  const [selectionStart, setSelectionStart] = React.useState()
  const commonClasses = commonStyles()
  const gridRowsStyle = `tr.k-master-row k-alt {background:transparent;}`
  const [focused, setFocused] = React.useState("")
  const [selectedDate, setSelectedDate] = React.useState(null)
  const [selectedTime, setSelectedTime] = React.useState(null)
  const [loading, setLoading] = React.useState(false)

  let lang = localStorage.getItem("i18nextLng")
  let langkit = JSON.parse(localStorage.getItem("languageskit")) || []
  let message = {}
  let locale = JSUtils?.formatLocales(langkit)

  message[localStorage.getItem("lang")] = {
    grid: {
      pagerItemsPerPage: t("pagerItemsPerPage"),
      pagerOf: t("pagerOf"),
      pagerItems: t("pagerItems"),
      pagerInfo: t("pagerInfo"),
    },
  }
  loadMessages(message[lang], locale[lang])

  useEffect(() => {
    return () => {
      const newGridState = { ...gridState }
      newGridState.dataState.filter = {
        logic: "and",
        filters: [],
      }
    }
  }, [])

  useEffect(() => {
    window["windowGridState"] = gridState
  }, [gridState])

  const handleDateChange = (date, field, type) => {
    let dateTime = selectedDate === null ? new Date() : selectedDate
    dateTime = moment.utc(date).format("YYYY-MM-DD")
    setSelectedDate(date)

    const newGridState = { ...gridState }
    const newFilterObj = {
      logic: "and",
      filters: [],
    }
    if (newGridState.dataState.filter === null) {
      newGridState.dataState.filter = newFilterObj
    }
    const newFilter = [
      {
        field: field,
        operator: "eq",
        value: dateTime,
      },
    ]
    const filterIndex = newGridState?.dataState?.filter?.filters?.findIndex(
      (filter) => filter.field === field
    )
    filterIndex !== -1
      ? newGridState.dataState.filter.filters.length !== 0
        ? newGridState.dataState.filter.filters.splice(
          filterIndex,
          1,
          newFilter[0]
        )
        : (newGridState.filter.filters = newFilter[0])
      : newGridState.dataState.filter
        ? newGridState.dataState.filter.filters.push(newFilter[0])
        : (newGridState.dataState.filter["filters"] = newFilter)
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }

  const handleTimeChange = (date, field, type) => {
    let tempTime = moment(date).format("hh:mm A").split(" ")
    setSelectedTime(date)
    const newGridState = { ...gridState }
    const newFilterObj = {
      logic: "and",
      filters: [],
    }
    if (newGridState.dataState.filter === null) {
      newGridState.dataState.filter = newFilterObj
    }
    const newFilter = [
      { field: `${field}.${type}`, operator: "contains", value: tempTime[0] },
      {
        field: `${field}.time_format`,
        operator: "eq",
        value: tempTime[1],
      },
    ]

    if (newGridState.dataState.filter.filters.length !== 0 && date !== null) {
      const filterIndex = newGridState?.dataState?.filter?.filters?.findIndex(
        (filter) => filter.field === `${field}.${type}`
      )
      newGridState.dataState.filter.filters.splice(filterIndex, 1, newFilter[0])
      const filterIndex2 = newGridState?.dataState?.filter?.filters?.findIndex(
        (filter) => filter.field === `${field}.time_format`
      )
      newGridState.dataState.filter.filters.splice(
        filterIndex2,
        1,
        newFilter[1]
      )
    } else if (date === null) {
      newGridState.dataState.filter.filters = []
    } else {
      newGridState.dataState.filter.filters.push(...newFilter)
    }
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }

  const dropDownFilterChange = (value, field) => {
    const newFilterObj = {
      logic: "and",
      filters: [],
    }
    const newGridState = { ...gridState }
    if (newGridState.dataState.filter === null) {
      newGridState.dataState.filter = newFilterObj
    }
    const newFilter = [
      {
        field: field,
        operator: "eq",
        value: value,
      },
    ]
    const filterIndex =
      newGridState.dataState &&
      newGridState.dataState.filter &&
      newGridState.dataState.filter.filters &&
      newGridState.dataState.filter.filters.findIndex(
        (filter) => filter.field === field
      )
    if (value !== "All") {
      filterIndex !== -1
        ? newGridState.dataState.filter.filters.length !== 0
          ? newGridState.dataState.filter.filters.splice(
            filterIndex,
            1,
            newFilter[0]
          )
          : (newGridState.filter.filters = newFilter[0])
        : newGridState.dataState.filter
          ? newGridState.dataState.filter.filters.push(newFilter[0])
          : (newGridState.dataState.filter["filters"] = newFilter)
    } else {
      newGridState.dataState.filter.filters.splice(filterIndex, 1)
    }
    // setGridState(newGridState)
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }

  const onClearButtonClick = (field) => {
    if (field === "dateTime") {
      setSelectedDate(null)
    }
    if (field === "time") {
      setSelectedTime(null)
    }

    const newGridState = { ...gridState }
    const filterIndex =
      newGridState.dataState &&
      newGridState.dataState.filter &&
      newGridState.dataState.filter.filters.findIndex(
        (filter) => filter.field === field
      )
    newGridState.dataState.filter.filters.splice(filterIndex, 1)
    // setGridState(newGridState)
    setGridState((c) => ({ dataState: { ...c.dataState, skip: 0 } }))
  }
  const handleKeyPress = (e) => {
    if (e.which === 32) {
      e.preventDefault()
    }
  }

  const gridWidth = 900

  const setPercentage = (percentage) => {
    return Math.round(gridWidth / 100) * percentage
  }

  const _export = React.useRef(null)
  const excelExport = () => {
    if (_export.current !== null) {
      _export.current.save(
        rowData.data,
        _.filter(columnData, _.matches({ show: true }))
      )
    }
  }

  return (
    <MainWrapperTableUIGrid
      className={`cls-main-global-table-kit ${isGrid ? "cls-grid-ui-card" : ""
        } ${addOnClassName}`}
    >
      {loader && loadingPanel}
      <style type="text/css">{gridRowsStyle}</style>
      <ExcelExport ref={_export} />
      <LocalizationProvider language={locale[lang]}>
        <IntlProvider locale={lang}>
          <TemplateTableUIGrid
            pageable={
              pageable
                ? {
                  buttonCount: buttonCount,
                  pageSizes: pageSizes?.length > 0 ? pageSizes : true,
                }
                : false
            }
            filterable
            sortable
            scrollable={scrollable}
            style={{
              height: isBulkUploadLog ? "410px" : "auto",
              width: "100% !important",
              stripeRows: "false",
            }}
            {...rowData}
            {...gridState.dataState}
            onDataStateChange={(e) => {
              if (e.dataState.filter !== null) {
                let data = []
                data = e.dataState?.filter?.filters.map((item) => {
                  item.value = isString(item.value)
                    ? item.value?.replace(/(\s{2,})/g, " ")
                    : item.value
                  return item
                })
                e.dataState.filter.filters = data
              }
              window["windowGridState"] = { dataState: e.dataState }
              setGridState({ dataState: e.dataState })
            }}
            onSortChange={({ sort }) => {
              setGridState((c) => ({
                dataState: { ...c.dataState, sort, skip: 0 },
              }))
            }}
            editField="inEdit"
            selectedField="selected"
            onSelectionChange={(event) => selectionChange(event)}
            onHeaderSelectionChange={(event) => headerSelectionChange(event)}
            onItemChange={(event) => itemChange(event)}
            total={number_of_items}
            // className="ecom-table-ui"
            className={`${hideCheckboxAll ? commonClasses.hideCheckboxAll : ""
              } ${classNamePrefix}`}
            GridLinesVisibility
            onRowClick={(event) => {
              hideCheckboxAll && selectionChange(event)
              isOnRowClick && _.isFunction(onRowClick) && onRowClick(event)
            }}
            {...otherProps}
            BreadcrumbsValue2={BreadcrumbsValue2}
            cursor={cursor}
          >
            <GridNoRecords className="cls-gridNoRecordsText-kit">
              {gridNoRecordsText && t(gridNoRecordsText)}
            </GridNoRecords>
            {ShowCheckBox && isInlineEditAllowed === true && (
              <Column
                field="selected"
                width={checkBoxWidth}
                filterable={false}
                headerSelectionValue={
                  !_.isEmpty(_.get(rowData, "data", [])) &&
                  _.filter(_.get(rowData, "data", []), (x) =>
                    _.get(x, "selected")
                  ).length === _.get(rowData, "data", []).length
                }
              />
            )}
            {columnData &&
              columnData.map((val, index) => {
                const { isPermissionModeBTN = false } = val || {}
                return (
                  val.show && (
                    <Column
                      field={val.field && val.field}
                      title={t(val?.title)}
                      editable={val.editable && val.editable}
                      sortable={val.sortable && val.sortable}
                      editor={val.editor && val.editor}
                      filterable={val.filter && val.filter}
                      filter={val.filterType && val.filterType}
                      onKeyPress={(e) => handleKeyPress(e)}
                      filterCell={
                        val.filterType === "time"
                          ? (props) => (
                            <ThemeProvider theme={theme}>
                              <KeyboardTimePicker
                                clearLabel={t("Clear")}
                                okLabel={t("OK")}
                                cancelLabel={t("CANCEL")}
                                clearable
                                fullWidth
                                variant="dialog"
                                emptyLabel=""
                                inputVariant="outlined"
                                size="medium"
                                margin="dense"
                                value={selectedTime}
                                placeholder="HH:MM"
                                onChange={(date) =>
                                  handleTimeChange(date, val.field, "time")
                                }
                                keyboardIcon={<AccessTimeIcon />}
                              />
                            </ThemeProvider>
                          )
                          : val.filterType === "dateTime"
                            ? (props) => (
                              <CustomDateTimePicker
                                field={val.field}
                                selectedDate={selectedDate}
                                selectedTime={selectedTime}
                                handleDateChange={handleDateChange}
                                onClearButtonClick={onClearButtonClick}
                                value={
                                  gridState?.dataState?.filter?.filters[
                                  gridState?.dataState?.filter?.filters?.findIndex(
                                    (filter) => filter.field === val.field
                                  )
                                  ]
                                }
                              />
                            )
                            : val.filterType === "boolean" &&
                              val.customFilter === true
                              ? (props) => (
                                <BooleanCell
                                  data={val.filterData && val.filterData}
                                  onKeyPress={(e) => handleKeyPress(e)}
                                  value={
                                    gridState.dataState.filter
                                      ? gridState.dataState.filter.filters[
                                      gridState.dataState.filter.filters.findIndex(
                                        (filter) => filter.field === val.field
                                      )
                                      ]
                                      : { value: "All" }
                                  }
                                  dropdownChange={dropDownFilterChange}
                                  onClearButtonClick={onClearButtonClick}
                                  field={val.field}
                                />
                              )
                              : null
                      }
                      width={setPercentage(val.min_width)}
                      cell={
                        val.editor === "text" && val.cell
                          ? (props) =>
                            props.dataItem.inEdit ? (
                              <td className="cls-td-kit-ele cls-td-kit-1">
                                <TextField
                                  size="small"
                                  variant="outlined"
                                  id="outlined-required"
                                  className={classes.basicTextFieldProperty}
                                  name={val.field}
                                  value={props.dataItem[val.field]}
                                  onFocus={(e) => {
                                    setFocused(val.field)
                                    e.currentTarget.setSelectionRange(
                                      selectionStart,
                                      selectionStart
                                    )
                                  }}
                                  onKeyUp={(event) => {
                                    setSelectionStart(
                                      event.target.selectionStart
                                    )
                                  }}
                                  onChange={(event) => {
                                    setSelectionStart(
                                      event.target.selectionStart
                                    )
                                    setFocused(val.field)
                                    if (event) {
                                      itemChange({
                                        field: event.target.name,
                                        value: event.target.value,
                                        dataItem: props.dataItem,
                                        title: t(val.title),
                                      })
                                    }
                                  }}
                                  autoFocus={focused === val.field}
                                  error={
                                    props.dataItem[val.field] === "" ||
                                    !isEmpty(errorMessage[val.field])
                                  }
                                  helperText={
                                    props.dataItem[val.field] === ""
                                      ? `${t(val.title)} ${t("is Required")}`
                                      : errorMessage[val.field]
                                        ? `${errorMessage[val.field]}`
                                        : ""
                                  }
                                  inputProps={{
                                    maxLength: val.maxLength || 255,
                                  }}
                                />
                              </td>
                            ) : (
                              <td className="cls-td-kit-ele cls-td-kit-2">
                                <Typography
                                  variant="body2"
                                >
                                  {val.field === "roleName" &&
                                    props.dataItem.setFlag
                                    ? t(props.dataItem[val.field])
                                    : props.dataItem[val.field]}
                                </Typography>
                              </td>
                            )
                          : val.cell && val.switchCell
                            ? (props) => (
                              <SwitchCell
                                dataItem={props.dataItem}
                                value={props.dataItem[props.field]}
                                renderBox={props.dataItem[props.field]}
                                filterData={val.filterData}
                                custom={val.customSwitchCell}
                                tooltipContent={
                                  props.dataItem[val.tooltipContent]
                                }
                                onChange={(event) => {
                                  switchHandler(event, props)
                                }}
                              />
                            )
                            : val.isTranslate
                              ? (props) => {
                                return (
                                  <td className="cls-translate-txt">
                                    {t(props.dataItem[props.field])}
                                  </td>
                                )
                              }
                              : val.checkbox
                                ? (props) => (
                                  <CheckboxCell
                                    dataItem={props.dataItem}
                                    value={props.dataItem[props.field]}
                                    renderText={props.dataItem[props.field]}
                                    onClick={(event) => {
                                      setDefaultValue(event, props)
                                    }}
                                  />
                                )
                                : val.statusProcess
                                  ? () => ""
                                  : val.progressCard
                                    ? (props) => {
                                      return (
                                        <td className="cls-td-kit-ele cls-td-kit-03">
                                          <div className="cls-dotted-line"></div>
                                          {props.dataItem[props.field] === 0 &&
                                            props.dataItem?.statusProcess ===
                                            "Created" ? (
                                            <></>
                                          ) : props.dataItem[props.field] === 100 ? (
                                            <div className="cls-progress-augument-completed">
                                              {t("Augment Completed")}
                                            </div>
                                          ) : (
                                            <>
                                              <div className="cls-progress-bar-aug-wrapper">
                                                <div>{`${props.dataItem[props.field] || 0
                                                  }% ${JSUtils?.formatStatusInprogress(
                                                    props?.dataItem?.["statusProcess"]
                                                  )}`}</div>
                                                <div className="cls-progress-bar-aug">
                                                  <div
                                                    style={{
                                                      width: `${props.dataItem[props.field] || 0
                                                        }%`,
                                                    }}
                                                    className="cls-progress-bar-kit"
                                                  ></div>
                                                </div>
                                              </div>
                                              <div className="cls-progress-augument-processing">
                                                {t("Augment Processing")}...
                                              </div>
                                            </>
                                          )}
                                        </td>
                                      )
                                    }
                                    : val.imageWithText
                                      ? (props) => {
                                        return (
                                          <td className="cls-td-kit-ele cls-td-kit-03">
                                            {props.dataItem?.isAnnoatedEdited && (
                                              <span
                                                style={{
                                                  display: "flex",
                                                  alignItems: "end",
                                                }}
                                              >
                                                <AnnotateIcon
                                                  style={{
                                                    zIndex: "100",
                                                    position: "absolute",
                                                    backgroundColor: "#df86bb",
                                                    borderRadius: "10px",
                                                  }}
                                                  onClick={() =>
                                                    onImageEdit({ item: props.dataItem })
                                                  }
                                                />
                                              </span>
                                            )}
                                            <img
                                              onClick={() =>
                                                onUpdateImageView({
                                                  item: props.dataItem,
                                                })
                                              }
                                              className="cls-image-k-url"
                                              id="unique-image"
                                              src={props.dataItem[props.field]}
                                              alt={props.dataItem[props.field]}
                                            />
                                            <span
                                              style={{
                                                display: "flex",
                                                justifyContent: "end",
                                              }}
                                            >
                                              {showImageEdit && (
                                                <EditIcon
                                                  style={{
                                                    color: "black",
                                                    zIndex: "100",
                                                    position: "absolute",
                                                    cursor: "pointer",
                                                    backgroundColor: "#ffffff9e",
                                                    borderRadius: "10px",
                                                  }}
                                                  onClick={() =>
                                                    onImageEdit({ item: props.dataItem })
                                                  }
                                                />
                                              )}
                                            </span>
                                            {val?.showVerified &&
                                              props.dataItem[val?.verifyKey] !==
                                              undefined && (
                                                <span
                                                  style={{
                                                    position: "absolute",
                                                    top: "3px",
                                                    left: "3px",
                                                    color: props.dataItem[val?.verifyKey]
                                                      ? "rgb(62, 126, 72)"
                                                      : "rgb(211 60 60)",
                                                    width: "20px",
                                                  }}
                                                >
                                                  {props.dataItem[val?.verifyKey] ? (
                                                    <CheckCircleIcon />
                                                  ) : (
                                                    <CancelIcon />
                                                  )}
                                                </span>
                                              )}
                                          </td>
                                        )
                                      }
                                      : val.imageWithIcon
                                        ? (props) => {
                                          return (
                                            <td className="cls-td-kit-ele cls-td-kit-03">
                                              <img
                                                onClick={() =>
                                                  onUpdateImageView({
                                                    item: props.dataItem,
                                                  })
                                                }
                                                className="cls-image-k-url"
                                                id="unique-image"
                                                src={props.dataItem[props.field]}
                                                alt={props.dataItem[props.field]}
                                              />
                                              <span
                                                style={{
                                                  position: "absolute",
                                                  bottom: "10px",
                                                }}
                                              >
                                                <ArIcon />
                                              </span>
                                            </td>
                                          )
                                        }
                                        : val.statusChip
                                          ? (props) => {
                                            return (
                                              <td className="cls-td-kit-ele cls-td-kit-3">
                                                {props.dataItem[props.field]?.map((x) => {
                                                  return (
                                                    <Chip
                                                      style={{ margin: "5px" }}
                                                      label={x?.name}
                                                      className={
                                                        x?.status === true
                                                          ? commonClasses.statusChipCompleted
                                                          : commonClasses.statusChipProgress
                                                      }
                                                      // color={x?.status === true ? "success" : "#efefef"}
                                                      size="small"
                                                    />
                                                  )
                                                })}
                                              </td>
                                            )
                                          }
                                          : val.toolTip
                                            ? (props) => {
                                              let fieldTitle = ""
                                              if (props.field?.includes(".")) {
                                                fieldTitle = props.field?.split(".")
                                              }

                                              return (
                                                <td className="cls-td-kit-ele cls-td-kit-4">
                                                  <MuiTooltip
                                                    id="id-kit-test-tooltip"
                                                    className="test-tooltip"
                                                    title={
                                                      <p className="test-tooltip">
                                                        {fieldTitle === ""
                                                          ? props.dataItem[props.field]
                                                          : props.dataItem[fieldTitle[0]][
                                                          fieldTitle[1]
                                                          ]}
                                                      </p>
                                                    }
                                                    placement="bottom-center"
                                                    classes={{
                                                      tooltip: `${commonClasses.customTooltip} makeStyles-customTooltip-support-kit`,
                                                    }}
                                                    arrow
                                                  >
                                                    <Box
                                                      className={`${commonClasses.tootlipEllipse} makeStyles-tootlipEllipse-support`}
                                                    >
                                                      {fieldTitle === ""
                                                        ? props.dataItem[props.field]
                                                        : props.dataItem[fieldTitle[0]][
                                                        fieldTitle[1]
                                                        ]}
                                                    </Box>
                                                  </MuiTooltip>
                                                </td>
                                              )
                                            }
                                            : val.showCircleColor
                                              ? (props) => (
                                                <ShowCircleBasedOnProp showColor={"red"} />
                                              )
                                              : val.renderDate
                                                ? (props) => (
                                                  <td className="cls-td-kit-ele cls-td-kit-5">
                                                    {_.isEmpty(props.dataItem[props.field]) ? (
                                                      "-"
                                                    ) : (
                                                      <p>
                                                        {val.format
                                                          ? moment(
                                                            props.dataItem[props.field]
                                                          ).format(val.format)
                                                          : moment(
                                                            props.dataItem[props.field]
                                                          ).format("MMM DD, YYYY")}
                                                      </p>
                                                    )}
                                                  </td>
                                                )
                                                : val.button
                                                  ? (props) => {
                                                    let isPermissionAvailable =
                                                      props.dataItem[props.field] === undefined
                                                        ? "ADD"
                                                        : "CHANGE"
                                                    const isDefaultKit =
                                                      typeof props.dataItem.isRowEditable !==
                                                        "undefined"
                                                        ? props.dataItem.isRowEditable
                                                        : isInlineEditAllowed

                                                    return (
                                                      <td className="cls-td-kit-ele cls-td-kit-6">
                                                        {isPermissionModeBTN ? (
                                                          props.dataItem[props.field] === "-" ||
                                                            props.dataItem.inEdit ? (
                                                            "-"
                                                          ) : props.dataItem[props.field] ? (
                                                            <IconButton
                                                              variant="outlined"
                                                              onClick={() => onView(props)}
                                                            >
                                                              <Box
                                                                className={`cls-set-button ${commonClasses.enabledBox}`}
                                                              >
                                                                {t("Set")}
                                                              </Box>
                                                            </IconButton>
                                                          ) : (
                                                            <>
                                                              <IconButton
                                                                variant="outlined"
                                                                onClick={() => onView(props)}
                                                              >
                                                                <VisibilityIcon
                                                                  // onClick={props.view}
                                                                  style={{ fill: "#df86bb" }}
                                                                />
                                                              </IconButton>
                                                            </>
                                                          )
                                                        ) : (
                                                          <Button
                                                            variant="outlined"
                                                            className={
                                                              commonClasses.defaultOutlinedButton
                                                            }
                                                            onClick={() => {
                                                              reDirectTo(props.dataItem)
                                                            }}
                                                            style={{
                                                              marginBottom: "0px",
                                                              marginTop: "0px",
                                                              color: "var(--primaryColor)",
                                                            }}
                                                          >
                                                            <Map size="15" /> &ensp;
                                                            <span className="cls-button-span-txt">
                                                              {t("List")}
                                                            </span>
                                                          </Button>
                                                        )}
                                                      </td>
                                                    )
                                                  }
                                                  : val.text
                                                    ? (props) => (
                                                      <td className="cls-td-kit-ele cls-td-kit-7">
                                                        {props.dataItem[props.field] ? "Yes" : "No"}
                                                      </td>
                                                    )
                                                    : val.verifiedWithoutEdit
                                                      ? (props) => (
                                                        <td style={{ padding: "10px" }}>
                                                          {props.dataItem[props.field] &&
                                                            (val.options
                                                              ? val.options.includes(
                                                                props.dataItem[props.field]
                                                              )
                                                              : true) ? (
                                                            <CheckCircleIcon
                                                              style={{ width: "20px", color: "#4CAF50" }}
                                                            />
                                                          ) : (
                                                            <Slash
                                                              style={{ width: "20px", color: "gray" }}
                                                            />
                                                          )}
                                                        </td>
                                                      )
                                                      : val.verified
                                                        ? (props) =>
                                                          props.dataItem.inEdit ? (
                                                            <SwitchCell
                                                              dataItem={props.dataItem}
                                                              value={props.dataItem[props.field]}
                                                              renderBox={props.dataItem[props.field]}
                                                              filterData={val.filterData}
                                                              onChange={(event) => {
                                                                switchHandler(event, props)
                                                              }}
                                                            />
                                                          ) : (
                                                            <td
                                                              className="cls-td-kit-ele cls-td-kit-8"
                                                              style={{ padding: "10px" }}
                                                            >
                                                              {props.dataItem[props.field] &&
                                                                (val.options
                                                                  ? val.options.includes(
                                                                    props.dataItem[props.field]
                                                                  )
                                                                  : true) ? (
                                                                <CheckCircleIcon
                                                                  style={{
                                                                    width: "20px",
                                                                    color:
                                                                      typeof props.dataItem
                                                                        .isRowEditable === "undefined" ||
                                                                        props.dataItem.isRowEditable
                                                                        ? "#4CAF50"
                                                                        : "rgba(0, 0, 0, 0.26)",
                                                                  }}
                                                                />
                                                              ) : (
                                                                <Slash
                                                                  style={{ width: "20px", color: "gray" }}
                                                                />
                                                              )}
                                                            </td>
                                                          )
                                                        : val.isSubfield
                                                          ? (props) => (
                                                            <td
                                                              className="cls-td-kit-ele cls-td-kit-9"
                                                              style={{ padding: "15px 10px" }}
                                                            >
                                                              <Typography style={{ fontSize: "14px" }}>
                                                                {props.dataItem[props.field]}
                                                              </Typography>
                                                              <Typography
                                                                style={{ fontSize: "12px", color: "gray" }}
                                                              >
                                                                {props.dataItem[val.subfield]}
                                                              </Typography>
                                                            </td>
                                                          )
                                                          : val.image
                                                            ? (props) => {
                                                              return (
                                                                <td
                                                                  className="cls-td-kit-ele cls-td-kit-10"
                                                                  style={{
                                                                    display: "flex",
                                                                    padding: "15px 10px",
                                                                  }}
                                                                >
                                                                  <Avatar
                                                                    className="cls-ussrname-icon-kit"
                                                                    src={props?.dataItem[val?.subfield]}
                                                                    style={{
                                                                      backgroundColor: "var(--avatarBgColor)",
                                                                      color: "var(--primaryColor)",
                                                                      height: "45px",
                                                                      width: "45px",
                                                                      fontSize: "14px",
                                                                    }}
                                                                  >
                                                                    {_.upperCase(
                                                                      props?.dataItem[val.field]?.charAt(0)
                                                                    )}
                                                                    {val.secondField &&
                                                                      _.upperCase(
                                                                        props?.dataItem[
                                                                          val.secondField
                                                                        ]?.charAt(0)
                                                                      )}
                                                                  </Avatar>
                                                                  <Typography
                                                                    className="cls-ussrname-kit"
                                                                    style={{
                                                                      display: "flex",
                                                                      paddingLeft: "30px",
                                                                      paddingTop: "10px",
                                                                    }}
                                                                  >
                                                                    {props?.dataItem[val.field]}{" "}
                                                                    {val.secondField &&
                                                                      props?.dataItem[val.secondField]}
                                                                  </Typography>
                                                                </td>
                                                              )
                                                            }
                                                            : val.feildCircle
                                                              ? (props) => (
                                                                <td className="cls-td-kit-ele cls-td-kit-11">
                                                                  <div
                                                                    style={{ display: "flex", flexWrap: "wrap" }}
                                                                  >
                                                                    {_.isArray(
                                                                      props.dataItem[val.iteratorName]
                                                                    ) ? (
                                                                      props.dataItem[val.iteratorName].map(
                                                                        (val, index) => {
                                                                          return (
                                                                            <div
                                                                              className={
                                                                                commonClasses.feildCircleStyle
                                                                              }
                                                                            >
                                                                              {val?.name === undefined
                                                                                ? val
                                                                                : val?.name}
                                                                            </div>
                                                                          )
                                                                        }
                                                                      )
                                                                    ) : (
                                                                      <div
                                                                        className={
                                                                          _.isEmpty(
                                                                            props.dataItem[val.iteratorName]
                                                                          )
                                                                            ? ""
                                                                            : commonClasses.feildCircleStyle
                                                                        }
                                                                      >
                                                                        {props.dataItem[val.iteratorName]}
                                                                      </div>
                                                                    )}
                                                                  </div>
                                                                </td>
                                                              )
                                                              : val.circleStatus
                                                                ? (props) => {
                                                                  return (
                                                                    <td className="cls-td-kit-ele cls-td-kit-12">
                                                                      <div
                                                                        style={{
                                                                          display: "flex",
                                                                          flexWrap: "wrap",
                                                                        }}
                                                                      >
                                                                        {val.filterData?.some(
                                                                          (f) =>
                                                                            props.dataItem[val?.field] ===
                                                                            f.value && f.key
                                                                        ) ? (
                                                                          <div
                                                                            className={commonClasses.circleActive}
                                                                            style={{ marginTop: "2px" }}
                                                                          ></div>
                                                                        ) : (
                                                                          <div
                                                                            className={commonClasses.circleInactive}
                                                                          ></div>
                                                                        )}
                                                                      </div>
                                                                    </td>
                                                                  )
                                                                }
                                                                : val.list
                                                                  ? (props) => (
                                                                    <div
                                                                      style={{ display: "flex", flexWrap: "wrap" }}
                                                                    >
                                                                      {_.isArray(props.dataItem[val.field]) ? (
                                                                        props.dataItem[val.field].map(
                                                                          (val, index) => {
                                                                            return (
                                                                              <div
                                                                                className={
                                                                                  commonClasses.feildCircleStyle
                                                                                }
                                                                              >
                                                                                {val?.name === undefined
                                                                                  ? val
                                                                                  : val?.name}
                                                                              </div>
                                                                            )
                                                                          }
                                                                        )
                                                                      ) : (
                                                                        <div
                                                                          className={
                                                                            _.isEmpty(props.dataItem[val.field])
                                                                              ? ""
                                                                              : commonClasses.feildCircleStyle
                                                                          }
                                                                        >
                                                                          {props.dataItem[val.field]}
                                                                        </div>
                                                                      )}
                                                                    </div>
                                                                  )
                                                                  : val.renderDateTime
                                                                    ? (props) => (
                                                                      <td className="cls-td-kit-ele cls-td-kit-14">
                                                                        <p
                                                                          style={{
                                                                            whiteSpace: "nowrap",
                                                                            overflow: "hidden",
                                                                            textOverflow: "ellipsis",
                                                                          }}
                                                                        >
                                                                          {props.dataItem[props.field]
                                                                            ? val.format === "UTCLocal"
                                                                              ? moment(props.dataItem[props.field])
                                                                                .utc()
                                                                                .local()
                                                                                .format("MMM DD, YYYY hh:mm:ss A")
                                                                              : val.format === "Local"
                                                                                ? `${moment(
                                                                                  props.dataItem[props.field].slice(
                                                                                    0,
                                                                                    19
                                                                                  )
                                                                                ).format("MMM DD, YYYY hh:mm:ss A")}`
                                                                                : moment(props.dataItem[props.field])
                                                                                  .utc()
                                                                                  .format("MMM DD, YYYY hh:mm:ss A")
                                                                            : "-"}
                                                                        </p>
                                                                      </td>
                                                                    )
                                                                    : val.renderDateTimeNoUtc
                                                                      ? (props) => (
                                                                        <td className="cls-td-kit-ele cls-td-kit-15">
                                                                          <p
                                                                            style={{
                                                                              whiteSpace: "nowrap",
                                                                              overflow: "hidden",
                                                                              textOverflow: "ellipsis",
                                                                            }}
                                                                          >
                                                                            {props.dataItem[props.field]
                                                                              ? moment(
                                                                                moment
                                                                                  .utc(props.dataItem[props.field])
                                                                                  .toDate()
                                                                              )
                                                                                .local()
                                                                                .format("MMM DD, YYYY hh:mm:ss A")
                                                                              : "-"}
                                                                          </p>
                                                                        </td>
                                                                      )
                                                                      : val.renderImageList
                                                                        ? (props) => (
                                                                          <td className="cls-td-kit-ele cls-td-kit-16">
                                                                            {
                                                                              <Link
                                                                                target="_blank"
                                                                                href={props.dataItem[props.field][0]}
                                                                              >
                                                                                {t("Click to see image")}
                                                                              </Link>
                                                                            }
                                                                          </td>
                                                                        )
                                                                        : val.renderCustomBooleanCell
                                                                          ? (props) =>
                                                                            props.dataItem.inEdit ? (
                                                                              <td className="cls-td-kit-ele cls-td-kit-17">
                                                                                <BooleanCell
                                                                                  data={val.filterData && val.filterData}
                                                                                  onKeyPress={(e) => handleKeyPress(e)}
                                                                                  value={
                                                                                    props.dataItem[props.field]
                                                                                      ? {
                                                                                        value: val.filterData?.find(
                                                                                          (a) =>
                                                                                            a.value ===
                                                                                            props.dataItem[props.field]
                                                                                        )?.value,
                                                                                      }
                                                                                      : { value: val.defaultItem }
                                                                                  }
                                                                                  dropdownChange={(value, field) =>
                                                                                    onDropDownCellClick({
                                                                                      field,
                                                                                      value,
                                                                                      dataItem: props.dataItem,
                                                                                    })
                                                                                  }
                                                                                  onClearButtonClick={onClearButtonClick}
                                                                                  field={val.field}
                                                                                  defaultItem={val.defaultItem}
                                                                                  hideClearButton={true}
                                                                                />
                                                                              </td>
                                                                            ) : (
                                                                              <CustomBooleanCell
                                                                                {...props}
                                                                                filterData={val.filterData}
                                                                              />
                                                                            )
                                                                          : val.hyphenDisplay
                                                                            ? (props) => {
                                                                              return (
                                                                                <>
                                                                                  {!_.isUndefined(
                                                                                    _.get(props.dataItem, props.field)
                                                                                  ) ? (
                                                                                    <>
                                                                                      <td className="cls-td-kit-ele cls-td-kit-18">
                                                                                        <Typography>
                                                                                          {_.get(props.dataItem, props.field)}
                                                                                        </Typography>
                                                                                      </td>
                                                                                    </>
                                                                                  ) : (
                                                                                    <>
                                                                                      <td
                                                                                        className="cls-td-kit-ele cls-td-kit-19"
                                                                                        style={{ textAlign: "center" }}
                                                                                      >
                                                                                        <Typography>{"-"}</Typography>
                                                                                      </td>
                                                                                    </>
                                                                                  )}
                                                                                </>
                                                                              )
                                                                            }
                                                                            : val.renderFilterCell
                                                                              ? (props) => (
                                                                                <td className="cls-td-kit-ele cls-td-kit-20">
                                                                                  <Typography>
                                                                                    {props.dataItem.inEdit &&
                                                                                      !localStorage
                                                                                        .getItem("role")
                                                                                        ?.includes("SaaS-Site-Admin")
                                                                                      ? "-"
                                                                                      : t(
                                                                                        val.filterData?.find(
                                                                                          (f) =>
                                                                                            f.value?.toString() ===
                                                                                            props?.dataItem[
                                                                                              props?.field
                                                                                            ]?.toString()
                                                                                        )?.tab
                                                                                      )}
                                                                                  </Typography>
                                                                                </td>
                                                                              )
                                                                              : val.arrValues
                                                                                ? (props) => (
                                                                                  <>
                                                                                    <RoleDropdown
                                                                                      field={props.field}
                                                                                      dataItem={props.dataItem}
                                                                                      value={props.dataItem[props.field]}
                                                                                      bgColor={val.arrbgColor}
                                                                                      handleChange={(event) => {
                                                                                        handleMultipleDropdown(event, props)
                                                                                      }}
                                                                                      list={RoleDropdownList || []}
                                                                                      setSelectedRoles={setSelectedRoles}
                                                                                    />
                                                                                  </>
                                                                                )
                                                                                : val.statusColorBased
                                                                                  ? (props) => (
                                                                                    <StatusColorBased
                                                                                      {...props}
                                                                                      title={props.dataItem[props.field]}
                                                                                      colorsArr={val.colorsArr}
                                                                                      fieldMap={val.fieldMap}
                                                                                      subfieldMap={val.subfieldMap}
                                                                                    />
                                                                                  )
                                                                                  : val.showPercent
                                                                                    ? (props) => (
                                                                                      <td className="cls-showpercent-kit">
                                                                                        <div
                                                                                          style={{
                                                                                            width: `${props.dataItem[props.field]}%`,
                                                                                            top: "0",
                                                                                            position: "absolute",
                                                                                            height: "98%",
                                                                                            background: "#eefbfd",
                                                                                            left: 0,
                                                                                          }}
                                                                                        ></div>
                                                                                        <p
                                                                                          style={{
                                                                                            fontWeight: 600,
                                                                                            position: "relative",
                                                                                            zIndex: 99,
                                                                                          }}
                                                                                        >
                                                                                          {props.dataItem[props.field]}%
                                                                                        </p>
                                                                                      </td>
                                                                                    )
                                                                                    : val.singledropdown
                                                                                      ? (props) => (
                                                                                        <SingleDropdown
                                                                                          {...props}
                                                                                          dataItem={props.dataItem}
                                                                                          list={val.dropdownValues}
                                                                                          value={props.dataItem[props.field]}
                                                                                          handleChange={(e) =>
                                                                                            handleSingleDropdownChange(e, { ...props })
                                                                                          }
                                                                                        />
                                                                                      )
                                                                                      : val.gridDropDownFilter
                                                                                        ? (props) => (
                                                                                          <GridDropDownFilter
                                                                                            {...props}
                                                                                            dataItem={props.dataItem}
                                                                                            list={val.gridDropDownFilterData || []}
                                                                                            defaultValue={props.dataItem[props.field]}
                                                                                            selectedValue={props.dataItem[props.field]}
                                                                                            handleChangeEvent={(e, val) => {
                                                                                              handleLanguageChange(val, props)
                                                                                            }}
                                                                                          />
                                                                                        )
                                                                                        : val.adjustmentReason
                                                                                          ? (props) => (
                                                                                            <AdjustmentReason
                                                                                              {...props}
                                                                                              dataItem={props.dataItem}
                                                                                              list={val.dropdownValues}
                                                                                              value={props.dataItem[props.field]}
                                                                                              handleChange={(e) =>
                                                                                                handleSingleDropdownChange(e, { ...props })
                                                                                              }
                                                                                            />
                                                                                          )
                                                                                          : val.actionButton
                                                                                            ? (props) => {
                                                                                              return (
                                                                                                <>
                                                                                                  {!_.isUndefined(
                                                                                                    props?.dataItem?.action?.name
                                                                                                  ) ? (
                                                                                                    <td className="cls-td-kit-ele cls-td-kit-21">
                                                                                                      <Button
                                                                                                        variant="outlined"
                                                                                                        className={
                                                                                                          commonClasses.defaultOutlinedButton
                                                                                                        }
                                                                                                        onClick={() => {
                                                                                                          onSave(props)
                                                                                                        }}
                                                                                                        style={{
                                                                                                          marginBottom: "0px",
                                                                                                          marginTop: "0px",
                                                                                                          color: "white",
                                                                                                          minWidth: `150px`,
                                                                                                          backgroundColor: _.find(
                                                                                                            val.colorsArr,
                                                                                                            {
                                                                                                              id:
                                                                                                                props?.dataItem?.action
                                                                                                                  ?.action_id,
                                                                                                            }
                                                                                                          )?.colorCode,
                                                                                                        }}
                                                                                                      >
                                                                                                        {props?.dataItem?.action?.name}
                                                                                                      </Button>
                                                                                                    </td>
                                                                                                  ) : (
                                                                                                    <td className="cls-td-kit-ele cls-td-kit-22">
                                                                                                      <Button
                                                                                                        variant="outlined"
                                                                                                        className={
                                                                                                          commonClasses.defaultOutlinedButton
                                                                                                        }
                                                                                                        disabled={true}
                                                                                                        style={{
                                                                                                          marginBottom: "0px",
                                                                                                          marginTop: "0px",
                                                                                                          color: "white",
                                                                                                          minWidth: `150px`,
                                                                                                          backgroundColor: "grey",
                                                                                                        }}
                                                                                                      >
                                                                                                        {props?.dataItem?.status?.name}
                                                                                                      </Button>
                                                                                                    </td>
                                                                                                  )}
                                                                                                </>
                                                                                              )
                                                                                            }
                                                                                            : val.showmorewithTooltip
                                                                                              ? (props) => {
                                                                                                let rem =
                                                                                                  props?.dataItem[props?.field]?.length > 2 &&
                                                                                                  props?.dataItem[props?.field]?.length - 2
                                                                                                return (
                                                                                                  <MuiTooltip
                                                                                                    title={
                                                                                                      <>
                                                                                                        {props.dataItem[props?.field]?.map(
                                                                                                          (x, i) => {
                                                                                                            return (
                                                                                                              <>
                                                                                                                <span>{x}</span>
                                                                                                                {<br />}
                                                                                                              </>
                                                                                                            )
                                                                                                          }
                                                                                                        )}
                                                                                                      </>
                                                                                                    }
                                                                                                    classes={{
                                                                                                      tooltip: commonClasses.customTooltip,
                                                                                                    }}
                                                                                                  >
                                                                                                    <td>
                                                                                                      {props?.dataItem[props?.field]?.length >
                                                                                                        2 ? (
                                                                                                        <>
                                                                                                          {props.dataItem[props?.field]?.map(
                                                                                                            (x, i) => {
                                                                                                              if (i <= 1) {
                                                                                                                return (
                                                                                                                  <>
                                                                                                                    <span>{x}</span>
                                                                                                                    {i !== 1 && <br />}
                                                                                                                  </>
                                                                                                                )
                                                                                                              }
                                                                                                            }
                                                                                                          )}
                                                                                                          <b> and {rem} more</b>
                                                                                                        </>
                                                                                                      ) : (
                                                                                                        props.dataItem[props?.field]?.map(
                                                                                                          (x, i) => {
                                                                                                            return <span>{x}</span>
                                                                                                          }
                                                                                                        )
                                                                                                      )}
                                                                                                    </td>
                                                                                                  </MuiTooltip>
                                                                                                )
                                                                                              }
                                                                                              : val.ObjValue
                                                                                                ? (props) => {
                                                                                                  return (
                                                                                                    <td className="cls-td-kit-ele cls-td-kit-24">
                                                                                                      <Typography
                                                                                                        variant="body2"
                                                                                                        style={{
                                                                                                          fontSize: "14px",
                                                                                                          paddingLeft: "8px",
                                                                                                        }}
                                                                                                      >
                                                                                                        {`${props.dataItem[val.field]?.time} ${props.dataItem[val.field]?.time_format
                                                                                                          }`}
                                                                                                      </Typography>
                                                                                                    </td>
                                                                                                  )
                                                                                                }
                                                                                                : val.specialBooleanType
                                                                                                  ? (props) => {
                                                                                                    const { dataItem = {} } = props
                                                                                                    const styleFn = val.externalStyles
                                                                                                    const key = props.dataItem[val.field]
                                                                                                    const value = val?.mappings?.find(
                                                                                                      (v) => key === v.status
                                                                                                    )?.value
                                                                                                    return (
                                                                                                      <td
                                                                                                        {...val.extraProps}
                                                                                                        style={styleFn ? { ...styleFn(key) } : {}}
                                                                                                      >
                                                                                                        <span>{value}</span>
                                                                                                      </td>
                                                                                                    )
                                                                                                  }
                                                                                                  : val.showMoreKit
                                                                                                    ? (props) => {
                                                                                                      const { dataItem = {} } = props
                                                                                                      const { errorMSG = [] } = dataItem
                                                                                                      const concatErrorMsg = (errorMSG && `${errorMSG}. `) || ""
                                                                                                      return (
                                                                                                        <td>
                                                                                                          <Typography
                                                                                                            variant="body2"
                                                                                                            style={{
                                                                                                              fontSize: "14px",
                                                                                                              paddingLeft: "8px",
                                                                                                            }}
                                                                                                          >
                                                                                                            <ShowMoreKit
                                                                                                              defaultValue={concatErrorMsg}
                                                                                                            />
                                                                                                          </Typography>
                                                                                                        </td>
                                                                                                      )
                                                                                                    }
                                                                                                    : null
                      }
                    />
                  )
                )
              })}
            {!isLookup ? (
              actionMenu ? (
                <Column
                  title={t("Actions")}
                  width={setPercentage(10)}
                  resizable={true}
                  filterable={false}
                  sortable={false}
                  cell={(props) => (
                    <td className="cls-td-kit-ele cls-td-kit-25">
                      {renderMenuCardUI(props)}
                      <div className="cls-td-kit-action-button">
                        <ActionMenu
                          data={props.dataItem}
                          isDisableSaveButton={!isEmpty(errorMessage)}
                          additionalMenus={additionalActions}
                          isInlineEditAllowed={
                            typeof props.dataItem.isRowEditable !== "undefined"
                              ? isInlineEditAllowed &&
                              props.dataItem.isRowEditable
                              : isInlineEditAllowed
                          }
                          in_edit={() => {
                            onEdit(props)
                          }}
                          delete={() => {
                            onDelete(props)
                          }}
                          cancel={() => {
                            onDiscard(props)
                          }}
                          save={() => {
                            onSave(props)
                          }}
                          menutype={menutype}
                        />
                      </div>
                    </td>
                  )}
                />
              ) : (
                <Column
                  title={t("Actions")}
                  width={scrollable === "none" ? "" : setPercentage(18)}
                  filterable={false}
                  sortable={false}
                  cell={(props) => {
                    return (
                      <td className="cls-td-kit-ele cls-td-kit-26">
                        <IconMenu
                          showPauseIcon={showPauseIcon}
                          showCopyIcon={showCopyIcon}
                          showDeleteIcon={showDeleteIcon}
                          isDisableEditIcon={isDisableEditIcon}
                          isDisableViewIcon={isDisableViewIcon}
                          isDisableDeleteButton={isDisableDeleteButton}
                          isDisableSaveButton={!isEmpty(errorMessage)}
                          data={props.dataItem}
                          moduleName={moduleName}
                          isInlineEditAllowed={
                            typeof props.dataItem.isRowEditable !== "undefined"
                              ? isInlineEditAllowed &&
                              props.dataItem.isRowEditable
                              : isInlineEditAllowed
                          }
                          in_edit={() => {
                            onEdit(props)
                          }}
                          delete={() => {
                            onDelete(props)
                          }}
                          cancel={() => {
                            onDiscard(props)
                          }}
                          save={() => {
                            onSave(props)
                          }}
                          view={() => {
                            onView(props)
                          }}
                          copy={() => {
                            onCopy(props)
                          }}
                          stop={() => {
                            onStop(props)
                          }}
                        />
                      </td>
                    )
                  }}
                />
              )
            ) : null}
          </TemplateTableUIGrid>
        </IntlProvider>
      </LocalizationProvider>
    </MainWrapperTableUIGrid>
  )
}

export default MaterialDataGrid
