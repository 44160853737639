import React from "react"
import ButtonMaterial from "@material-ui/core/Button"
import styled from "styled-components"
import * as IconsGallery from "@iconsGallery"
import * as Adm from "@adm"

const LoadingSpinner = (props) => {
  return <Adm.BackdropOverlay />
}
export { LoadingSpinner }
export default LoadingSpinner
