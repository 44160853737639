const API_BASE_URL =
  process.env.REACT_APP_API_ENDPOINT ||
  "http://ec2-44-238-116-65.us-west-2.compute.amazonaws.com"
const KC_API_BASE_URL = process.env.REACT_APP_KC_API_ENDPOINT
const API_BASE_ROLE_URL = process.env.REACT_APP_KEYCLOAK_ROLE_ENDPOINT
const API_DSHBOARD_URL_TEST = process.env.REACT_APP_API_DASHBOARD
export const DK_BASE_URL =
  process.env.REACT_APP_BASE_DOMAIN_URL || "https://prod8.digitkart.ai"
export const API_URL = `${API_BASE_URL}` //Content Modal

export const API_KC_URL = `${KC_API_BASE_URL}`
export const API_CM_URL = `${API_BASE_URL}`.replace(
  "apigw",
  "contentmanagement"
)
export const API_UM_URL = `${API_BASE_URL}`.replace("apigw", "usermanagement")
export const API_MD_URL = `${API_BASE_URL}`.replace("apigw", "masterdata")
export const API_DASHBOARD_URL = `${API_BASE_URL}`.replace("apigw", "dashboard")
export const API_DEVICES_URL = `${API_BASE_URL}`.replace(
  "apigw",
  "frictionless"
)
export const API_INVENTORY_URL = `${API_BASE_URL}`.replace("apigw", "inventory")
export const API_AUGMENTATION_URL = `${API_BASE_URL}`.replace(
  "apigw",
  "augmentation"
)
export const API_CL_URL = `${API_BASE_URL}`.replace(
  "apigw",
  "continuouslearning"
)
export const API_RECEIPT_URL = `${API_BASE_URL}`.replace("apigw", "receipt")

export const API_PG_URL = `${KC_API_BASE_URL}`.replace("/api/frictionless", "")
export const API_URL_STORES = `${API_BASE_URL}`
export const API_URL_CUSTOMERS = `${API_BASE_URL}`

export const API_URL_CUSTOMERS_NO_PROXY = `${API_BASE_URL}`

export const API_URL_ROLES = `${API_BASE_ROLE_URL}`

export const API_URL_CUSTOMERS_COMMON = `${API_BASE_URL}`

export const API_URL_USER = `${API_BASE_URL}`

export const API_URL_PRICES = `${API_BASE_URL}`

export const API_URL_INVENTORY = `${API_BASE_URL}`

export const API_URL_LOC_HIERARCHY = `${API_BASE_URL}/api/stores/locationHierarchy`

export const API_URL_PRODUCT = `${API_BASE_URL}`

export const SAMPLE_FILE_TEMPLATE = "/api/products/getFileTemplate"

export const LOC_HIERARCHY_URLS = {
  baseURL: `${API_MD_URL}/api/stores`,
  baseURL_KC: `${KC_API_BASE_URL}/api/kc`,
  endpoints: {
    getLocations: "/getlocations",
    getLocationGroups: "/getlocationgroups",
    getLocationHierarchy: "/locationHierarchy",
    create: "/createlocationHierarchy",
    updateHierarchy: "/updatelocationHierarchy/:hierarchyID",
    deleteHierarchy: "/deletelocationHierarchy/:hierarchyID",
    updateLocationHierarchyMappings: "/updateLocationHierarchyMappings",
    deleteLocationGroups: "/api/stores/deletelocationgroups",
    deleteLocation: "/deletelocation",
    searchLocations: "/searchlocations",
    searchKcLocations: "/searchLocationstest",
  },
}

export const PRODUCT_CATEGORY_URLS = {
  baseURL: `${API_MD_URL}/api/productcategory`,
  endpoints: {
    getAll: "/getall",
    create: "/create",
    fetchCategory: "/:categoryID",
    updateCategory: "/update/:categoryID",
    deleteCategory: "/delete/:categoryID",
  },
}

export const PRODUCT_DATA_URLS = {
  baseURL: `${API_MD_URL}/api/products`,
  endpoints: {
    getProducts: "/getProducts",
    exportAllProduct: "/exportAllProduct",
    getProductCategories: "/productCategory",
    createCategory: "/createProductCategory",
    updateCategory: "/updateProductCategory",
    deleteCategory: "/deleteProductCategory",
    getAllWithPagination: "/paginate",
    getAllProductGroupsWithPagination: "/getproductgroups",
    deleteProductMany: "/api/products/deletemany",
    deleteProductGroups: "/api/products/deleteProductGroups",
    getProductById: "/getProductById",
    updateProduct: "/update",
    searchAttributes: "/searchAttributes",
    updateProductCategoryMappings: "/updateProductCategoryMappings",
    getProductCategoryById: "/getProductCategoryById",
    searchProducts: "/searchProducts",
    getProductsAttribute: "/getProductAttributes",
    createProductAttribute: "/createProductAttribute",
    getProductAttrById: "/productattribute",
    updateProductAttribute: "/updateProductAttribute",
  },
}
export const DEVICE_DATA_URLS = {
  baseURL: `${API_MD_URL}/api/stores`,
  baseURL_KC: `${KC_API_BASE_URL}/api/kc`,
  baseURL_KC_TEST: `${API_DEVICES_URL}/api/kc`,
  baseURL_dashboard: `${API_DASHBOARD_URL}/api/dashboardInfo`,
  endpoints: {
    getLocations: "/getlocations",
    getTenants: "/gettenants",
    getTenantsInfo: "/getTenantsInfo",
    getlocationDetails: "/getlocationDetails",
    getPOSinformation: "/getposinformation",
    getdevicesinformation: "/getdevicesinformation",
  },
}
export const MASTER_DATA_URLS = {
  baseURL: `${DK_BASE_URL}/api/masterdata`,
  endpoints: {
    bulkUploadProductStatus: "/api/products/getBulkUploadProductsStatus",
    bulkUploadProducts: "/api/products/bulkUploadProducts",
    bulkUploadLogs: "api/products/getProductsUploadLogList",
    exportAllProducts: "/api/products/exportAllProduct",
    bulkDownloadProductStatus: "/api/products/getProductsExportStatus",
    archieveProductsStatus: "/api/products/archiveProductsStatus",
    archieveProductsMappingStatus: "/api/products/archiveProductMappingStatus",
    locationProductsBulkUploadStatus:
      "/api/products/getBulkUploadProductMappingStatus",
    locationProductsBulkUpload: "/api/products/bulkUploadProductMappings",
    locationProductsBulkUploadLogs:
      "/api/products/getProductMappingUploadLogList",
    locationBulkDownloadProductMappingStatus:
      "/api/products/getProductsMappingExportStatus",
    locationExportAllProductsMapping: "/api/products/exportAllProductMappings",
  },
}

export const INVENTORY_DATA_URLS = {
  baseURL: `${DK_BASE_URL}/api/inventory`,
  endpoints: {
    bulkUploadInventoryStatus: "/api/kc/getBulkUploadInventoryCountStatus",
    bulkExportInventoryStatus: "/api/kc/getInventoryCountExportStatus",
    archieveInventoryStatus: "/api/kc/archiveInventoryCountStatus",
    bulkUploadInventoryData: "/api/kc/bulkUploadInventoryCount",
    bulkExportInventoryData: "/api/kc/exportAllInventoryCount",
    bulkUploadInventoryLogs: "/api/kc/getInventoryCountUploadLogList",
  },
}

export const LANGUAGE_DATA_URLS = {
  baseURL: `${DK_BASE_URL}/api/contentmanagement`,
  endpoints: {
    getBulkUploadLanguageStatus: "/api/language/getBulkUploadLanguageStatus",
    getBulkExportLanguageStatus: "/api/language/getBulkExportLanguageStatus",
    bulkImportLanguageData: "/api/language/bulkImportLanguageData",
    bulkExportLanguageData: "/api/language/bulkExportLanguageData",
    bulkUploadLanguageArchieve: "/api/language/archiveLanguageStatus",
    bulkUploadLanguageLogs: "/api/language/getLanguageUploadLogList",
  },
}
