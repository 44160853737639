import React, { useEffect } from "react"
import styled from "styled-components/macro"
import { makeStyles } from "@material-ui/core/styles"
import {
  Grid,
  Typography as MuiTypography,
  IconButton,
  Card,
  Collapse,
  Tooltip,
} from "@material-ui/core"
import { useTranslation } from "react-i18next"
import { spacing } from "@material-ui/system"
import "./styles.css"
import Divider from "@material-ui/core/Divider"
import {
  CheckCircleIcon,
  ChevronUp,
  ChevronDown,
  Slash,
  Edit,
} from "@iconsGallery"
import * as Utils from "@utils"
import * as Features from "@features"
import * as Adm from "@adm"
import moment from "moment"
import {
  DatePicker,
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers"
import DateFnsUtils from "@date-io/date-fns"
import "date-fns"
import frLocale from "date-fns/locale/fr"
import esLocale from "date-fns/locale/es"

const MainWrapper = styled(Grid)`
  .cls-open-calender {
    display: flex;
    align-items: center;
  }
  .cls-timezone {
    display: flex;
    align-items: center;
    padding-top: 10px;
  }
  .cls-close-calender {
    display: flex;
    align-items: center;
    padding-top: 0px;

    .cls-calender-date-native {
      margin-top: 0px;
    }
  }

  .cls-open-date-label {
    margin-right: 68px;
  }
  .cls-close-date-label {
    margin-right: 55px;
  }
`
const Typography = styled(MuiTypography)(spacing)
const useStyles = makeStyles({
  root: {
    flexGrow: 1,
  },
  paper: {
    height: 150,
  },
  contolChildGridSecond: {
    display: "flex",
    flexDirection: "row",
    marginTop: 15,
    marginBottom: 15,
  },
  contolChildGridFirst: (width) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    marginBottom: 15,
    marginTop: 15,
    paddingLeft: 20 + width,
    fontSize: "13px",
    marginRight: "15px",
  }),

  contolChildGridFirstdivSecSpan: (width) => ({
    paddingLeft: width,
    marginTop: "10px",
    paddingTop: "10px",
  }),
  contolChildGridSecdivFirstSpan: (width) => ({
    paddingLeft: 10 + width,
    fontSize: "14px",
  }),
  autoCompleteHeight: {
    "& .MuiOutlinedInput-root": {
      "&.Mui-focused fieldset": {
        borderColor: "var(--primaryColor)",
      },
    },
    "& .MuiAutocomplete-inputRoot": {
      height: "38px",
      width: "100%",
    },
    "& .MuiAutocomplete-input": {
      padding: "0px 4px !important",
    },
  },
})

const OpenDate = ({
  isWriteAllowed = false,
  selectedOpenDat,
  selectedCloseDat,
  setAllowPrompt = () => {},
  timeZon,
  editMode,
  setEditMode,
  saveHandler,
  slashHandler,
  handleDateChange,
  handleTimeZoneChange,
  loading = false,
  isAnyChanges,
  setAnyChanges,
  defaultTimeZone,
  isTablet = false,
}) => {
  const { useWindowSize } = Utils || {}
  const [height, width] = useWindowSize()
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const classes = useStyles(width)
  const { t } = useTranslation()
  let local = { es: esLocale, fr: frLocale }
  let lang = localStorage.getItem("i18nextLng")
  const [selectedOpenDate, setSelectedOpenDate] = React.useState(
    selectedOpenDat
  )
  const [selectedCloseDate, setSelectedCloseDate] = React.useState(
    selectedCloseDat
  )
  const [timeZone, setTimeZone] = React.useState()
  const [timeZoneOpen, setTimeZoneOpen] = React.useState(false)
  const closeDateValidation =
    selectedCloseDate < selectedOpenDate && selectedCloseDate !== ""

  useEffect(() => {
    setTimeZone(timeZon)
  }, [timeZon])

  useEffect(() => {
    setSelectedOpenDate(selectedOpenDat)
    setSelectedCloseDate(selectedCloseDat)
  }, [selectedCloseDat, selectedOpenDat])

  const onGlobalUpdate = (name, value = "") => {
    if (name === "openDate") {
      value === "" ? setAnyChanges(false) : setAnyChanges(true)
    } else {
      setAnyChanges(true)
    }
  }
  return (
    <MainWrapper lg={12} md={12} sm={12} xs={12}>
      <Adm.BackdropOverlay open={loading} />
      <Card
        className="cls-opendate"
        style={{
          width: "100%",
          boxShadow:
            " 0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          row="true"
          justify="space-between"
          alignContent="center"
          alignItems="center"
          style={{ paddingLeft: "16px", height: "60px", cursor: "pointer" }}
          onClick={(props) => {
            slashHandler(props)
            setCollapseOpen(!collapseOpen)
            setTimeZone(defaultTimeZone)
            setAllowPrompt(false)
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              {t("Open Date")}
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <div>
              {collapseOpen ? (
                <div>
                  {editMode ? (
                    <>
                      <Tooltip title={t("Cancel")} placement="top">
                        <IconButton
                          onClick={(e, props) => {
                            e.stopPropagation()
                            slashHandler(props)
                            setTimeZone(defaultTimeZone)
                            setAnyChanges(false)
                            setAllowPrompt(false)
                          }}
                        >
                          <Slash
                            size={20}
                            style={{
                              color: "red",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("Save")} placement="top">
                        <IconButton
                          style={{
                            pointerEvents: `${
                              isAnyChanges
                                ? !closeDateValidation
                                  ? "unset"
                                  : "none"
                                : "none"
                            }`,
                          }}
                          onClick={(e) => {
                            e.stopPropagation()
                            saveHandler()
                          }}
                        >
                          <CheckCircleIcon
                            fontSize="medium"
                            style={{
                              color: `${
                                isAnyChanges
                                  ? !closeDateValidation
                                    ? "#4CAF50"
                                    : "#CCC"
                                  : "#CCC"
                              }`,
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={t("Edit")} placement="top">
                        <IconButton
                          disabled={isWriteAllowed === true ? false : true}
                          onClick={(e) => {
                            e.stopPropagation()
                            setEditMode(true)
                            setAllowPrompt(true)
                          }}
                        >
                          <Edit
                            size={20}
                            style={{
                              color: "rgba(158, 158, 158, 1)",
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {collapseOpen ? (
              <IconButton>
                <ChevronUp style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            ) : (
              <IconButton>
                <ChevronDown style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Collapse in={collapseOpen} timeout="auto">
          {timeZone === "" ? (
            <Features.NoRecordFound />
          ) : (
            <>
              <Grid item lg={12} md={12} sm={12} xs={12}>
                <Divider />
                <Grid container direction="column">
                  <Grid
                    container
                    direction="row"
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "16px 16px 25px 16px" }}
                  >
                    <Grid
                      container
                      direction="row"
                      lg={6}
                      md={9}
                      sm={9}
                      xs={12}
                    >
                      <Grid
                        lg={3}
                        md={2}
                        sm={4}
                        xs={3}
                        style={{ alignSelf: "center" }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Time Zone")}
                          {editMode ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                      <Grid lg={7} md={7} sm={8} xs={8}>
                        {!editMode ? (
                          <Typography style={{ fontWeight: 500 }}>
                            {timeZone}
                          </Typography>
                        ) : (
                          <Adm.DropdownFilter
                            list={Utils.JSUtils.getTimeZoneData()}
                            selectedValue={timeZone}
                            onChangeEvent={(e, details) => {
                              handleTimeZoneChange(e, details)
                              onGlobalUpdate()
                            }}
                            disabled={true}
                            popupOpen={timeZoneOpen}
                            setPopupOpen={setTimeZoneOpen}
                            disableClearable={true}
                            classNameTextField={`${classes.autoCompleteHeight} makeStyles-autoCompleteHeight-support`}
                            placeholder={"Select Time Zone"}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                  <Divider />
                  <Grid
                    container
                    direction="row"
                    lg={12}
                    md={12}
                    sm={12}
                    xs={12}
                    style={{ padding: "16px 16px 25px 16px", margin: "0px" }}
                    spacing={isTablet ? 4 : 1}
                  >
                    <Grid
                      container
                      direction="row"
                      lg={6}
                      md={6}
                      sm={9}
                      xs={12}
                      style={{ padding: "10px 0px" }}
                    >
                      <Grid
                        lg={3}
                        md={3}
                        sm={4}
                        xs={3}
                        style={{ alignSelf: "center" }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Open Date")}{" "}
                          {editMode ? (
                            <span style={{ color: "red" }}>*</span>
                          ) : (
                            ""
                          )}
                        </Typography>
                      </Grid>
                      <Grid lg={7} md={8} sm={8} xs={8}>
                        {!editMode ? (
                          <Typography style={{ fontWeight: 500 }}>
                            {new Date(selectedOpenDate).toLocaleDateString(
                              "en-GB",
                              {
                                weekday: "long",
                                year: "numeric",
                                month: "short",
                                day: "numeric",
                              }
                            )}
                          </Typography>
                        ) : (
                          <div>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={local[lang]}
                            >
                              <KeyboardDatePicker
                                disableToolbar
                                okLabel=""
                                cancelLabel=""
                                autoOk={true}
                                inputVariant="outlined"
                                format="dd-MM-yyyy"
                                placeholder={t("dd-mm-yyyy")}
                                id="date-picker-inline"
                                value={selectedOpenDate}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputProps={{
                                  readOnly: true,
                                  style: { height: "40px" },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(date) => {
                                  handleDateChange(date, "openDate")
                                  handleDateChange(
                                    moment(selectedCloseDate),
                                    "closeDate"
                                  )
                                  onGlobalUpdate("openDate", date)
                                }}
                                className={classes.keyboardDatePicker}
                                error={
                                  selectedOpenDate === ""
                                    ? t("Select Open Date")
                                    : ""
                                }
                                helperText={
                                  selectedOpenDate === ""
                                    ? t("Select Open Date")
                                    : ""
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                    <Grid
                      container
                      direction="row"
                      lg={6}
                      md={6}
                      sm={9}
                      xs={12}
                      style={{ padding: "10px 0px" }}
                    >
                      <Grid
                        lg={3}
                        md={3}
                        sm={4}
                        xs={3}
                        style={{ alignSelf: "center" }}
                      >
                        <Typography style={{ fontWeight: "bold" }}>
                          {t("Close Date")}
                        </Typography>
                      </Grid>
                      <Grid lg={7} md={8} sm={8} xs={8}>
                        {!editMode ? (
                          <Typography style={{ fontWeight: 500 }}>
                            {selectedCloseDate
                              ? new Date(selectedCloseDate).toLocaleDateString(
                                  "en-GB",
                                  {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "short",
                                    day: "numeric",
                                  }
                                )
                              : "-"}
                          </Typography>
                        ) : (
                          <div>
                            <MuiPickersUtilsProvider
                              utils={DateFnsUtils}
                              locale={local[lang]}
                            >
                              <KeyboardDatePicker
                                disableToolbar
                                okLabel=""
                                cancelLabel=""
                                autoOk={true}
                                inputVariant="outlined"
                                format="dd-MM-yyyy"
                                placeholder={t("dd-mm-yyyy")}
                                id="date-picker-inline"
                                value={selectedCloseDate}
                                KeyboardButtonProps={{
                                  "aria-label": "change date",
                                }}
                                InputProps={{
                                  readOnly: true,
                                  style: { height: "40px" },
                                }}
                                InputLabelProps={{
                                  shrink: true,
                                }}
                                onChange={(date) => {
                                  handleDateChange(date, "closeDate")
                                  handleDateChange(
                                    moment(selectedOpenDate),
                                    "openDate"
                                  )
                                  onGlobalUpdate()
                                }}
                                className={classes.keyboardDatePicker}
                                error={
                                  selectedCloseDate < selectedOpenDate &&
                                  selectedCloseDate !== ""
                                    ? t("Select valid Close Date")
                                    : ""
                                }
                                helperText={
                                  selectedCloseDate < selectedOpenDate &&
                                  selectedCloseDate !== ""
                                    ? t("Select valid Close Date")
                                    : ""
                                }
                              />
                            </MuiPickersUtilsProvider>
                          </div>
                        )}
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Collapse>
      </Card>
    </MainWrapper>
  )
}

export { OpenDate }
export default OpenDate
