import React from "react"
import {
  Checkbox,
  IconButton,
  Link,
  TableBody,
  TableCell,
  TableHead,
  Grid,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next";

const useStyles = makeStyles((theme) => ({
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  // Fully visible for active icons
  activeSortIcon: {
    opacity: 1,
  },
  // Half visible for inactive icons
  inactiveSortIcon: {
    opacity: 0.4,
  },
}))

const headCells = [
  { id: "Location Number", numeric: true, disablePadding: false, label: "Location Number" },
  { id: "Location Name", numeric: true, disablePadding: false, label: "Location Name" },
  { id: "Location Address", numeric: false, disablePadding: false, label: "Location Address" },
  { id: "Inventory On Hand", numeric: true, disablePadding: false, label: "Inventory On Hand", sortable: false, },
  { id: "List Price", numeric: true, disablePadding: false, label: "List Price", sortable: false, },
  { id: "Offer Price", numeric: true, disablePadding: false, label: "Offer Price", sortable: false, },
  { id: "", numeric: true, disablePadding: false, label: "" },

];
const locationHeaders = [
  {
    id: "Location Number",
    numeric: true,
    disablePadding: false,
    label: "Location Number",
  },
  {
    id: "Location Name",
    numeric: true,
    disablePadding: false,
    label: "Location Name",
  },
  {
    id: "Location Address",
    numeric: false,
    disablePadding: false,
    label: "Location Address",
  },
  { id: "", numeric: true, disablePadding: false, label: "" },
]
export const EnhancedTableHead = (props) => {
  const { t } = useTranslation()
  const { order, valuesOrder, onRequestSort, sorting = () => { }, edit, } = props;
  const classes = useStyles();
  const SortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <>
      <TableHead
        order={order}
        orderBy={valuesOrder}>
        <TableRow>
          {headCells.map((headCell, index) => {
            return (<TableCell onClick={(index) => sorting(headCells)}
              style={{
                borderRight: (headCells.length - 2 === index) ? "" : "1px solid rgba(0, 0, 0, 0.1)",
                padding: "10px", backgroundColor: "lightgrey",
              }}
              padding={headCell.disablePadding ? 'none' : 'normal'}
              key={headCell.id}
              sortDirection={valuesOrder === headCell.id ? order : false}>
              <TableSortLabel
                active={valuesOrder === headCell.id}
                direction={valuesOrder === headCell.id ? order : "asc"}
                onClick={SortHandler(headCell.id)}>
                {t(headCell.label)}
                {valuesOrder === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>)
          })}
        </TableRow>
      </TableHead>
    </>
  );
}
export const LocationHeaders = (props) => {
  const { t } = useTranslation()
  const { order, valuesOrder, onRequestSort, showHeader, edit, sorting = () => { } } = props;
  const SortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <>

      <TableHead style={{ backgroundColor: "lightgrey", width: "100%" }}>
        <TableRow>
          {locationHeaders.map((headCell, index) => {
            if (headCell.id === "" && edit) {
              return <></>
            } else {
              return (<TableCell onClick={() => sorting(locationHeaders)}
                style={{ borderRight: (locationHeaders.length - 2 === index) ? "" : "1px solid rgba(0, 0, 0, 0.1)", padding: "10px", }}
                padding={headCell.disablePadding ? 'none' : 'normal'}
                key={headCell.id}
                sortDirection={valuesOrder === headCell.id ? order : false}
              >
                <TableSortLabel
                  active={valuesOrder === headCell.id}
                  direction={valuesOrder === headCell.id ? order : "asc"}
                  onClick={SortHandler(headCell.id)}
                >
                  {t(headCell.label)}
                </TableSortLabel>
              </TableCell>)
            }
          })}
        </TableRow>
      </TableHead>
    </>
  )
}
