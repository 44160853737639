import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
const initialState = {
  treeData: [
    {
      title: "Chain Name",
      id: Math.floor(100000 + Math.random() * 900000),
      expanded: true,
      children: [
        {
          title: "Zone Name",
          expanded: true,
          id: Math.floor(100000 + Math.random() * 900000),
          children: [
            {
              title: "Market Name",
              id: Math.floor(100000 + Math.random() * 900000),
              expanded: true,
              children: [
                {
                  title: "Location Name",
                  id: Math.floor(100000 + Math.random() * 900000),
                },
              ],
            },
          ],
        },
      ],
    },
  ],
}
export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.UPDATE_TREE:
      return {
        treeData: actions.treeData,
      }
    case types.RESET_TREE:
      return {
        treeData: actions.treeData,
      }
    default:
      return state
  }
}
