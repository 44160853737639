import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS
export function setTheme(value) {
  return {
    type: types.THEME_SET,
    payload: value,
  }
}
