import styled from "styled-components/macro"
import { Grid, Box, Typography } from "@material-ui/core"
export const StyledProgressDiv = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  margin: 10px;
  height: fit-content;
  .cls-dkp-header-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    margin: 10px 10px;
  }
  .cls-subtitle {
    font-weight: 400;
    font-size: 14px;
    word-wrap: break-word;
    width: 300px;
    text-align: left;
    margin: 5px 10px 5px 15px;
  }
  .loading-bar {
    //position: absolute;
    margin: 5px 10px 5px 10px;
    height: 20px;
    width: 90%;
    border-radius: 50px;
    bottom: 10px;
  }

  span {
    display: block;
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 50px;
    background-image: linear-gradient(to bottom, #fff, #f599f2 60%);
    box-shadow: inset 0 2px 9px rgba(0, 0, 0, 0.3),
      inset 0 -2px 6px rgba(0, 0, 0, 0.4);
    overflow: hidden;
  }

  span:after {
    content: "";
    position: absolute;
    top: 0px;
    left: 0;
    bottom: 0;
    right: 0;
    background-image: linear-gradient(
      -45deg,
      rgba(255, 255, 255, 0.2) 25%,
      transparent 25%,
      transparent 50%,
      rgba(255, 255, 255, 0.2) 50%,
      rgba(255, 255, 255, 0.2) 75%,
      transparent 75%,
      transparent
    );
    z-index: 1;
    background-size: 50px 50px;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    border-top-left-radius: 20px;
    border-bottom-left-radius: 20px;
    overflow: hidden;
  }

  .loading-bar > span:after,
  .animate > span > span {
    animation: load 1s infinite;
  }

  @keyframes load {
    0% {
      background-position: 0 0;
    }
    100% {
      background-position: 50px 50px;
    }
  }
`

export const StyledBox = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  height: 200px;
  background-color: #fff;
  overflow: hidden;
  padding: 4px;
  border-radius: 10px;
  z-index: 99;
  .cls-dkp-progressbar-wrapper {
    width: 600px;
    height: auto;
  }
`
export const StyledTypography = styled(Typography)`
  font-weight: 900;
  font-family: "Poppins", sans-serif;
  font-size: 20px;
  text-transform: capitalize;
  color: #724e64;
  word-wrap: break-word;
  width: 250px;
  text-align: left;
  margin: 10px 10px 10px 5px;
`

export const LoaderWrapper = styled(Grid)`
  height: 25px;
  border-radius: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100% - 50px);
  border: 2px solid #d7d7d7;
  margin: 3px 10px 3px 10px;
`
