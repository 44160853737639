import { Grid, IconButton, TextField } from "@material-ui/core"
import { Delete } from "@material-ui/icons"
import AddBoxIcon from "@material-ui/icons/AddBox"
import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next"

const MultipleEmail = (props) => {
  const { t } = useTranslation()
  const {
    name,
    value,
    email,
    setCreateEditObj,
    setAnyChanges,
    setPageValidation,
    totalRows,
    displayRows,
    placeholder = "",
  } = props
  const [emailList, setEmailList] = useState(email)
  const [errorMessage, setErrorMessage] = useState("")
  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setEmailList(email)
  }, [email])

  const validateEmail = (input) => {
    if (input !== "") {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
      return !emailRegex.test(input)
    } else {
      return false
    }
  }

  const handleChange = (e, i) => {
    let tempEmail = [...emailList]
    const emailValue = e.target.value
    let duplicateEmail = false
    tempEmail.map((item) => {
      if (item.value === emailValue) {
        duplicateEmail = true
      }
    })
    const validEmail = validateEmail(emailValue)
    tempEmail[i]["value"] = emailValue
    tempEmail[i]["error"] = validEmail
    tempEmail[i]["duplicate"] = duplicateEmail
    setEmailList(tempEmail)
    setAnyChanges(true)
    setCreateEditObj((prevState) => ({
      ...prevState,
      emails: tempEmail,
    }))
    setPageValidation((prevState) => ({
      emails: false,
    }))
  }

  const removeEmail = (x, i) => {
    let tempEmail = [...emailList]
    tempEmail.splice(i, 1)
    setEmailList(tempEmail)
    setAnyChanges(true)
    setCreateEditObj((prevState) => ({
      ...prevState,
      emails: tempEmail,
    }))
  }

  const addNewEmail = (idx) => {
    let tempEmail = [...emailList]
    if (tempEmail.length < 10) {
      tempEmail.push({ id: idx, value: "" })
      setEmailList(tempEmail)
      setAnyChanges(true)
      setCreateEditObj((prevState) => ({
        ...prevState,
        emails: tempEmail,
      }))
    }
  }
  const EmailPair = (x, i) => {
    return (
      <Grid container spacing={1}>
        <Grid
          item
          lg={10}
          style={{ marginRight: "10px", marginBottom: "15px" }}
        >
          <TextField
            style={{
              width: "100%",
              backgroundColor:
                emailList.length !== i + 1 || emailList.length === 11
                  ? "#00000014"
                  : "unset",
            }}
            variant="outlined"
            size="small"
            type="text"
            name={`name${i}`}
            value={x.value}
            onChange={(e) => handleChange(e, i)}
            error={x.error || x.duplicate}
            placeholder={placeholder || ""}
            helperText={
              x.error
                ? t("Please enter a valid Email")
                : x.duplicate
                ? "Please enter unique email Id"
                : ""
            }
            disabled={emailList.length !== i + 1 || emailList.length === 11}
          />
        </Grid>
        <Grid item>
          {emailList.length !== i + 1 || emailList.length === 10 ? (
            <IconButton
              style={{ padding: "0px" }}
              onClick={() => removeEmail(x, i)}
            >
              <Delete
                style={{ color: "var(--primaryColor)", fontSize: "38px" }}
              />
            </IconButton>
          ) : (
            <IconButton
              style={{ padding: "0px" }}
              disabled={x.error || x.value === "" || x.duplicate}
              onClick={() => addNewEmail(i + 1)}
            >
              <AddBoxIcon
                style={{
                  color:
                    x.error || x.value === "" || x.duplicate
                      ? "var(--greyColor)"
                      : "var(--primaryColor)",
                  fontSize: "38px",
                }}
              />
            </IconButton>
          )}
        </Grid>
      </Grid>
    )
  }
  return (
    <Grid container lg={12}>
      <Grid container>
        <Grid item xs={6}>
          {emailList
            .slice(0, totalRows / displayRows)
            ?.map((x, i) => EmailPair(x, i))}
        </Grid>
        <Grid item xs={6}>
          {emailList
            .slice(totalRows / displayRows, totalRows)
            ?.map((x, i) => EmailPair(x, i + totalRows / displayRows))}
        </Grid>
      </Grid>
    </Grid>
  )
}
export { MultipleEmail }
export default MultipleEmail
