import React, { useEffect, useState, useRef } from "react"
import styled from "styled-components/macro"
import {
  Grid, Typography as MuiTypography, IconButton, Card, Collapse, Tooltip
} from "@material-ui/core"
import { spacing } from "@material-ui/system"
import Divider from "@material-ui/core/Divider"
import {
  CheckCircleIcon, ChevronUp, ChevronDown, Slash, Edit,
} from "@iconsGallery"
import { AlcholTradingHours } from "./AlcholTradingHours"
import { useTranslation } from "react-i18next"
import * as ServiceAPI from "@services"
import { useSelector } from "react-redux"
import * as constantsAPI from "@constants"
import _ from "lodash"
import * as Adm from "@adm"
import * as Features from "@features"
import { BusinessHours } from "./BusinessHours"
import { JSUtils } from "@utils"
const { APIEndPoint: { API_END_POINTS = {} } = {} } = constantsAPI || {}

const AccordionInnerMainWrapper = styled(Grid)`
  padding-left: 16px;
  padding-right: 16px;
  align-items: normal;
  alignitems: center;
  flex-direction: column;
  .cls-businesshours,
  .cls-alcholtradinghours {
    padding: 0px;
    // max-width: 100%;
  }
  @media (min-width: 1024px) and (max-width: 1600px) {
    padding: 16px;
    flex-direction: column;
    .cls-businesshours{
    }
    .cls-businesshours,
    .cls-alcholtradinghours {
      padding: 8px;
      // max-width: 100%;
    }
  }
`
const Typography = styled(MuiTypography)(spacing)
function OperatingHours(props) {
  const { isWriteAllowed, setAllowPrompt = () => { }, fetchData = () => { } } = props
  const { t } = useTranslation()
  const [editMode, setEditMode] = React.useState(false)
  const [collapseOpen, setCollapseOpen] = React.useState(false)
  const [data, setData] = useState([])
  const formData = useSelector((state) => state.addLocationReducer)
  const [loading, setLoading] = useState(false)
  const [alert, setAlert] = useState(false)
  const [alertMessage, setAlertMessage] = useState("")
  const [alertType, setAlertType] = useState("")
  const [allowAlcoholUpdate, setAllowAlcoholUpdate] = useState(false)
  const [allowBusinessUpdate, setAllowBusinessUpdate] = useState(false)
  const [allowUpdate, setAllowUpdate] = useState(false)
  const isAllOkay = useRef(true)
  const [clearVal, setClearVal] = useState(false)
  const isBusinessHoursTimeOkay = useRef(true)
  const isAlcoholHoursTimeOkay = useRef(true)
  const [isError, setIsError] = useState([])

  useEffect(() => {
    setIsError(isError)
  }, [isError])

  const checkError = (ind) => {
    isError[ind] = false
    setIsError(isError)
  }

  const saveHandler = () => {
    if (
      isAllOkay.current &&
      isBusinessHoursTimeOkay.current &&
      // isAlcoholHoursTimeOkay.current &&
      !isError?.includes(true)
    ) {
      setAllowUpdate(true)
      setEditMode(false)
      sendData()
    } else {
      setAlertType("error")
      setAlertMessage(t("Issue highlighted in operating hours set, recheck"))
      setAlert(true)
    }
  }
  const sendData = async () => {
    setLoading(true)
    setAlert(false)
    let body = {
      ...props.sendBackData,
      businessHrs: formData.operatingHoursBusiness,
      alcoholTradingHrs: formData.operatingHoursAlchol,
    }

    let response = await ServiceAPI.fetchStoreCommonData(
      API_END_POINTS.updateLocation,
      body
    )
    await setAlertType(_.get(response, "data.status", "error"))
    await setAlertMessage(JSUtils.formatMessageCode(response?.data, t, "Try Again Later!"))
    await setAllowPrompt(false)
    await setAlert(true)
    await setLoading(false)
  }
  const slashHandler = () => {
    setAllowUpdate(false)
    setEditMode(false)
  }
  useEffect(() => {
    setData(props.data)
  }, [props])

  return (
    <Grid lg={12} md={12} sm={12} xs={12}>
      <Adm.BackdropOverlay open={loading} />
      <Card className="cls-operatinghours"
        style={{
          width: "100%",
          boxShadow: "0px 0px 0px rgba(63, 63, 68, 0.05), 0px 1px 2px rgba(63, 63, 68, 0.15)",
          borderRadius: "8px",
        }}
      >
        <Grid
          container
          row="true"
          justify="space-between"
          alignContent="center"
          alignItems="center"
          style={{ paddingLeft: "14px", height: "60px", borderBottom: "1px solid #e0e0e0", cursor: "pointer" }}
          onClick={() => {
            if (!collapseOpen) {
              setEditMode(false)
              setClearVal(true)
            }
            setCollapseOpen(!collapseOpen)
          }}
        >
          <Grid item>
            <Typography
              variant="h6"
              align="center"
              style={{ fontWeight: "bold" }}
            >
              {t("Operating Hours")}{" "}
            </Typography>
          </Grid>
          <Grid item style={{ display: "flex", alignItems: "center" }}>
            <div>
              {collapseOpen ? (
                <div>
                  {editMode ? (
                    <>
                      <Tooltip title={t("Cancel")} placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setAllowUpdate(false)
                            setEditMode(false)
                            setClearVal(true)
                          }}
                        >
                          <Slash size={20} style={{ color: "red" }} />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title={t("Save")} placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            saveHandler()
                          }}
                          style={{
                            marginRight: "2px",
                            pointerEvents: `${allowUpdate ? "unset" : "none"}`,
                          }}
                        >
                          <CheckCircleIcon
                            fontSize="medium"
                            style={{ color: allowUpdate ? "#4CAF50" : "#CCC" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  ) : (
                    <>
                      <Tooltip title={t("Edit")} placement="top">
                        <IconButton
                          onClick={(e) => {
                            e.stopPropagation();
                            setEditMode(true)
                          }}
                          disabled={isWriteAllowed ? false : true}
                        >
                          <Edit
                            size={20}
                            style={{ color: "rgba(158, 158, 158, 1)" }}
                          />
                        </IconButton>
                      </Tooltip>
                    </>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
            {collapseOpen ? (
              <IconButton>
                <ChevronUp style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            ) : (
              <IconButton  >
                <ChevronDown style={{ color: "rgba(0, 0, 0, 1)" }} />
              </IconButton>
            )}
          </Grid>
        </Grid>
        <Grid container item lg={12} md={12} sm={12} xs={12} >
          <Collapse in={collapseOpen} timeout="auto" style={{ width: "100%" }}>
            {data.openDate === undefined ? (
              <Features.NoRecordFound />
            ) : (
              <AccordionInnerMainWrapper container row="true">
                <Grid
                  item
                  xl={8}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={12}
                  className="cls-businesshours"
                >
                  <BusinessHours
                    editMode={editMode}
                    setUpdate={setAllowBusinessUpdate}
                    creatingTheBusinnessHours={true}
                    data={data}
                    setAllowUpdate={setAllowUpdate}
                    isAllOkayRef={isAllOkay}
                    isTimeArrOkayRef={isBusinessHoursTimeOkay}
                    isAlcoholHoursTimeOkay={isAlcoholHoursTimeOkay}
                    setAllowPrompt={setAllowPrompt}
                    clearVal={clearVal}
                    setClearVal={setClearVal}
                    fetchData={fetchData}
                    checkError={checkError}
                    isError={isError}
                    setIsError={setIsError}
                  />
                </Grid>
                <Grid
                  item
                  xl={8}
                  lg={8}
                  md={12}
                  sm={12}
                  xs={12}
                  className="cls-alcholtradinghours"
                >
                  <AlcholTradingHours
                    editMode={editMode}
                    setUpdate={setAllowAlcoholUpdate}
                    creatingTheAlcoholTradingHours={true}
                    data={data}
                    setAllowUpdate={setAllowUpdate}
                    isAllOkayRef={isAllOkay}
                    isTimeArrOkayRef={isAlcoholHoursTimeOkay}
                    setAllowPrompt={setAllowPrompt}
                    isError={isError}
                    setIsError={setIsError}
                  />
                </Grid>
              </AccordionInnerMainWrapper>
            )}
          </Collapse>
        </Grid>
      </Card>
      {alert ? (
        <Adm.SnackBar
          open={true}
          message={alertMessage}
          type={alertType}
          handleClose={() => setAlert(false)}
        />
      ) : null}
    </Grid>
  )
}
export { OperatingHours, BusinessHours, AlcholTradingHours }
export default OperatingHours
