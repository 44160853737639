//customized components are imported like <Cusotmized.componentName/> from styles folder

import React, { useState } from "react";
import { Menu, MenuItem } from "@material-ui/core";
import * as Customized from "../../styles/components/LanguagesDropdown/styles";
import { useTranslation } from "react-i18next";

function LanguagesDropdown() {
  var localStoreLanguage = localStorage.getItem("i18nextLng");
  const { i18n } = useTranslation();
  const [anchorMenu, setAnchorMenu] = React.useState(null);

  const toggleMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const closeMenu = () => {
    setAnchorMenu(null);
  };

  const menuHandler = (countryLabel) => {
    i18n.changeLanguage(countryLabel);
    setAnchorMenu(null);
  };

  const altName = (localStoreLanguage) => {
    let name;
    if (localStoreLanguage === "en") {
      name = "English";
    }
    if (localStoreLanguage === "fr") {
      name = "French";
    }
    return name;
  };

  return (
    <React.Fragment>
      <Customized.IconButton
        aria-owns={Boolean(anchorMenu) ? "menu-appbar" : undefined}
        aria-haspopup="true"
        onClick={toggleMenu}
        color="inherit"
      >
        <Customized.Flag
          src={"/static/img/flags/" + localStoreLanguage + ".png"}
          alt={altName(localStoreLanguage)}
        />
      </Customized.IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorMenu}
        open={Boolean(anchorMenu)}
        onClose={closeMenu}
        data-testid="english"
      >
        <MenuItem onClick={() => menuHandler("en", "English")}>
          English
        </MenuItem>
        <MenuItem onClick={() => menuHandler("fr", "French")}>French</MenuItem>
        {/* <MenuItem onClick={() => closeMenu('us')}>German</MenuItem>
        <MenuItem onClick={() => closeMenu('us')}>Dutch</MenuItem> */}
      </Menu>
    </React.Fragment>
  );
}

export default LanguagesDropdown;
