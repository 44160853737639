import React from "react"
import {
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@material-ui/core"
import { CloseIcon } from "@iconsGallery"
import { StyledIconButton, TableWrapper, TableCellWrapper, TableDataCellWrapper, TableDataCellWrapper2 } from "./styles"

const RenderTableEditableContainer = (props) => {
  const {
    tableData,
    columns,
    removeItem,
    editMode,
    tableHeadings,
    rowId,
  } = props
  
  return (
    <TableWrapper>
      <Table stickyHeader aria-label="sticky table">
        <TableHead>
          <TableRow>
            {tableHeadings.map((col, idx) => (
              <TableCellWrapper key={idx}>
                <b>{col}</b>
              </TableCellWrapper>
            ))}
            {editMode ? <TableCellWrapper></TableCellWrapper> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {tableData.data.map((row, idx) =>
              <TableRow>
                <TableDataCellWrapper key={row[columns[0]] + idx}>
                  {row[columns[0]]}
              </TableDataCellWrapper>
              {editMode
                ? (<TableCell key={row[columns[1]] + idx}>
                  {row[columns[1]]?row[columns[1]]:"-"}
                </TableCell>)
                : (<TableDataCellWrapper2 key={row[columns[1]] + idx}>
                  {row[columns[1]]?row[columns[1]]:"-"}
                </TableDataCellWrapper2>)}
                {editMode ? (
                  <TableDataCellWrapper2 key={"close" + idx} align="right">
                    <StyledIconButton onClick={() => removeItem(row[rowId])}>
                      <CloseIcon />
                    </StyledIconButton>
                  </TableDataCellWrapper2>
                ) : null}
              </TableRow>
          )}
        </TableBody>
      </Table>
    </TableWrapper>
  )
}

export { RenderTableEditableContainer }
