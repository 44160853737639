import React from "react"
import styled from "styled-components/macro"
import { Link, useHistory } from "react-router-dom"

import Helmet from "react-helmet"

import { Button as MuiButton, Typography } from "@material-ui/core"
import { spacing } from "@material-ui/system"
import { useTranslation } from "react-i18next"
import Spinner from "../../components/Spinner"
const Button = styled(MuiButton)(spacing)

const Wrapper = styled.div`
  padding: ${(props) => props.theme.spacing(6)}px;
  text-align: center;
  background: transparent;

  ${(props) => props.theme.breakpoints.up("md")} {
    padding: ${(props) => props.theme.spacing(10)}px;
  }
`

function Page403() {
  const history = useHistory()
  const { t } = useTranslation()

  const returnWeb = () => {
    let getRoleName = localStorage.getItem("role")
    let getTenantId = localStorage.getItem("tenantId")
    getRoleName = getRoleName.toLocaleLowerCase()
    const isSassRole = getRoleName.includes("saas")

    let dynamicRoutes = isSassRole
      ? `${t("/dashboard")}?${t("page=home")}`
      : `${t("/dashboard")}?${t(
        "page=frictionless&retailer"
      )}=${getTenantId}&${t("subpage")}=0`
    window?.location?.replace(dynamicRoutes)
  }

  return (
    <Wrapper>
      <Helmet title={t("403 Forbidden")} />
      <Typography component="h1" variant="h1" align="center" gutterBottom>
        403
      </Typography>
      <Typography component="h2" variant="h5" align="center" gutterBottom>
        {t("Access Denied / Forbidden")}
      </Typography>
      <Typography component="h2" variant="body1" align="center" gutterBottom>
        {t(`You don't have permission to access this page`)}
      </Typography>

      <Button
        component={Link}
        to="/"
        variant="contained"
        color="secondary"
        mt={2}
        onClick={() => returnWeb()}
      >
        {t("Return to website")}
      </Button>
    </Wrapper>
  )
}

export default Page403
