import React from "react"
export function useGlobalStates(props) {
  /**
   * @SOCKET_Connection_INIT
   */
  const getRealmID = localStorage.getItem("realmId")
  const onInitSocket = (arg: any) => {
    const {
      socketUrl = "",
      initData = {},
      setLiveData = () => {},
      liveData = {},
      setWaitModal = () => false,
      setPreUploadFlag = () => false,
      setAlert = () => false,
      setAlertMessage = () => "",
      setAlertType = () => "",
    } = arg || {}
    setAlert(false)
    let { wsTelemtry = {} } = arg || {}
    if (!socketUrl) {
      setWaitModal(false)
      setPreUploadFlag(false)
      return
    }
    /**
     * @INFO_wsTelemtry_is_Ref
     *   */

    if (wsTelemtry?.current) {
      wsTelemtry.current.close()
    }
    try {
      wsTelemtry.current = new WebSocket(socketUrl)
      wsTelemtry.current.onopen = function (e) {
        wsTelemtry.current.send(
          JSON.stringify({
            ...initData,
            x_kc_realm_id: getRealmID,
          })
        )
        console.log("<< socket initiated", socketUrl)
        onHandleLiveDataSocket({
          liveData,
          setLiveData,
          wsTelemtry,
          setWaitModal,
          setPreUploadFlag,
          setAlert,
          setAlertMessage,
          setAlertType,
          socketUrl,
        })
      }
    } catch (e) {
      console.log("<< socket init connection error: ", e)
      setWaitModal(false)
      setPreUploadFlag(false)
      setAlertType("error")
      setAlertMessage("Error in socket connection")
      setAlert(true)
    }
  }
  /**
   * @SOCKET_LIVE_TELEMETRY
   */
  const onHandleLiveDataSocket = (arg: any) => {
    const {
      liveData = {},
      setLiveData = () => {},
      setWaitModal = () => false,
      setPreUploadFlag = () => false,
      setAlert = () => false,
      setAlertMessage = () => "",
      setAlertType = () => "",
      socketUrl = "",
    } = arg || {}
    let { wsTelemtry = {} } = arg || {}
    if (!wsTelemtry?.current) {
      setWaitModal(false)
      setPreUploadFlag(false)
      return () => {}
    }
    try {
      wsTelemtry.current.onmessage = (event) => {
        const msg = JSON.parse(event?.data || `{}`) || {}
        const { status = "", message = "", stack = "" } = msg || {}
        console.log("<< telemetry ready to msg>> ", msg)

        if (status === "failed") {
          setAlertType("error")
          setAlertMessage(`${message}.${stack}`)
          setAlert(true)
          console.log(`<< telemetry msg failed: `, msg, socketUrl)
        } else {
          setWaitModal(false)
          setPreUploadFlag(false)
          setLiveData({ ...liveData, ...msg } || {})
        }
      }
      return () => {}
    } catch (e) {
      console.log("<< socket live telemtry error: ", e)
      setWaitModal(false)
      setPreUploadFlag(false)
    }
  }

  return {
    onInitSocket,
    onHandleLiveDataSocket,
  }
}
