import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

const types = REDUX_CONSTANTS

const initialState = {
  customerInfo: null,
  customerCommunication: null,
  customerAttributes: [],
}

export default function reducer(state = initialState, actions) {
  switch (actions.type) {
    case types.ADD_CUSTOMER_INFO:
      return {
        ...state,
        customerInfo: actions.customerInfo,
      }
    case types.ADD_CUSTOMER_COMMUNICATION:
      return {
        ...state,
        customerCommunication: actions.customerCommunication,
      }
    case types.ADD_CUSTOMER_ATTRIBUTES:
      return {
        ...state,
        customerAttributes: actions.customerAttributes,
      }
    case types.ADD_CUSTOMER_RESET:
      return {
        customerInfo: null,
        customerCommunication: null,
        customerAttributes: [],
      }
    default:
      return state
  }
}
