export const axiosConfig = {
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*",
    "Access-Control-Allow-Credentials": true,
    Authorization:
      localStorage.getItem("token") === null
        ? ""
        : "Bearer " + localStorage.getItem("token"),
    "X-KC-Realm-ID": localStorage.getItem("realmId"),
    "X-KC-Host": localStorage.getItem("kcHost"),
  },
}
