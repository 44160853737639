import * as constantsAPI from "@constants"
const { ReduxConstants: { REDUX_CONSTANTS = {} } = {} } = constantsAPI || {}

export const addProductBasicInfo = (data) => (dispatch) => {
    dispatch({
        type: REDUX_CONSTANTS.ADD_PRODUCT_BASIC_INFO,
        productBasicInfo: data,
    })
}

export const addProductMedia = (data) => (dispatch) => {
    dispatch({
        type: REDUX_CONSTANTS.ADD_PRODUCT_MEDIA,
        productMedia: data,
    })
}

export const addProductLocations= (data) => (dispatch) => {
    dispatch({
        type: REDUX_CONSTANTS.ADD_PRODUCT_LOCATIONS,
        productLocations: data,
    })
}

export const addProductSpecification = (data) => (dispatch) => {
    dispatch({
        type: REDUX_CONSTANTS.ADD_PRODUCT_SPECIFICATION,
        productSpecification: data,
    })
}

export const addProductHierarchy = (data) => (dispatch) => {
    dispatch({
        type: REDUX_CONSTANTS.ADD_PRODUCT_TYPE_HIERARCHY,
        productHierarchy: data,
    })
}
export const addProductReset = () => (dispatch) => {
    dispatch({
        type: REDUX_CONSTANTS.ADD_PRODUCT_RESET,
    })
}

