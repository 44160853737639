import React, { useMemo, useRef, useState, useEffect } from "react"
import PolygonAnnotation from "./PolygonAnnotation"
import { Stage, Layer, Image } from "react-konva"
import {
  IconButton,
  Card,
  CardHeader,
  CardContent,
  Grid,
} from "@material-ui/core"
import PanToolIcon from "@material-ui/icons/PanTool"
import GestureIcon from "@material-ui/icons/Gesture"
import { DeleteIcon } from "@iconsGallery"
import { cloneDeep } from "lodash"
import usePolygonalEditorState from "./usePolygonalEditorStates"
import {
  renderButtons,
  renderPolygonEditor,
  renderPolygonLayers,
} from "./renderProps"
import * as Features from "@features"
import * as Adm from "@adm"

function PolygonalEditor(props) {
  const {
    defaultPoints = [],
    onUpdate = () => "",
    imageUrl = "",
    polygonData = [],
    showSnackBar = false,
    setShowSnackBar = () => "",
    snackBarMessage = "",
    setSnackBarMessage = () => "",
    snackBarMessageType = "warning",
    setSnackBarMessageType = () => "",
    isEnableCloseButton,
    setEnableCloseButton,
  } = props || {}
  const PolygonalEditorState = usePolygonalEditorState({
    defaultPoints,
    onUpdate,
    imageUrl,
    showSnackBar,
    setShowSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    snackBarMessageType,
    setSnackBarMessageType,
    isEnableCloseButton,
    setEnableCloseButton,
  })
  const {
    image,
    setImage,
    imageRef,
    dataRef,
    points,
    setPoints,
    originalSize,
    size,
    setSize,
    flattenedPoints,
    setFlattenedPoints,
    position,
    setPosition,
    isMouseOverPoint,
    setMouseOverPoint,
    isPolyComplete,
    setPolyComplete,
    layerList,
    setLayerList,
    getMousePos,
    handleMouseDown,
    handleMouseMove,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handlePointDragMove,
    undo,
    reset,
    handleGroupDragEnd,
    handleLayerSelection,
    handleDeleteLayer,
    handleAddLayer,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    handleCustomCloseRedirect,
    dragPolygon,
    setDragPolygon,
    handleLayerNameChange,
    handleLayerNameEdit,
    addClicked,
    setAddClicked,
    t,
  } = PolygonalEditorState

  const polyProps = {
    image,
    setImage,
    imageRef,
    dataRef,
    points,
    setPoints,
    originalSize,
    size,
    setSize,
    flattenedPoints,
    setFlattenedPoints,
    position,
    setPosition,
    isMouseOverPoint,
    setMouseOverPoint,
    isPolyComplete,
    setPolyComplete,
    layerList,
    setLayerList,
    getMousePos,
    handleMouseDown,
    handleMouseMove,
    handleMouseOverStartPoint,
    handleMouseOutStartPoint,
    handlePointDragMove,
    undo,
    reset,
    handleGroupDragEnd,
    handleLayerSelection,
    handleDeleteLayer,
    handleAddLayer,
    openConfirmationDialog,
    setOpenConfirmationDialog,
    handleCustomCloseRedirect,
    dragPolygon,
    setDragPolygon,
    handleLayerNameChange,
    handleLayerNameEdit,
    addClicked,
    setAddClicked,
    showSnackBar,
    setShowSnackBar,
    snackBarMessage,
    setSnackBarMessage,
    snackBarMessageType,
    setSnackBarMessageType,
    setEnableCloseButton,
    t,
  }
  return (
    <>
      <Grid container style={{ marginLeft: "35px", width: "98%" }}>
        <Adm.SnackBar
          open={showSnackBar}
          message={snackBarMessage}
          type={snackBarMessageType}
          handleHide={() => {
            setShowSnackBar(false)
            setSnackBarMessage("")
          }}
        />
        <Features.ConfirmationDialog
          openConfirmationDialog={openConfirmationDialog}
          setOpenConfirmationDialog={setOpenConfirmationDialog}
          handleCustomCloseRedirect={handleCustomCloseRedirect}
          deleteContent={`${t("Are you sure you want to delete?")}`}
        />
        {renderButtons(polyProps)}
        {renderPolygonEditor(polyProps)}
        {renderPolygonLayers(polyProps)}
      </Grid>
    </>
  )
}

export default PolygonalEditor
export { PolygonalEditor }
