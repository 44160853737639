import React from "react"
import { defaultConfig } from "./fixtures"
import { ContentToolContext } from "./context"
import { View } from "./View"

const customRenderContainer = (props) => {
  console.log({ props })
  return <> DATA HERE</>
}

function ContentTool(props) {
  const { list = defaultConfig } = props || {}
  const [facetList, setFacetList] = React.useState(list)
  const contextProps = { facetList, setFacetList }

  return (
    <ContentToolContext.Provider value={contextProps}>
      {facetList &&
        facetList.map((facetItem) => (
          <>
            <View item={facetItem} />
            <br />
          </>
        ))}
    </ContentToolContext.Provider>
  )
}

export { ContentTool }
export default ContentTool
