import React from "react"
import styled, { useTheme } from "styled-components/macro"
import { Link, useHistory } from "react-router-dom"
import * as Adm from "@adm"
import Helmet from "react-helmet"
import {
  Button as MuiButton,
  Typography,
  useMediaQuery,
} from "@material-ui/core"
import { spacing } from "@material-ui/system"
import UserService from "../../../src/services/UserService"
const Button = styled(MuiButton)(spacing)

const Wrapper = styled.div`
  padding: 6px;
  text-align: center;
  background: transparent;
`
function UnAuthorizedUser() {
  const history = useHistory()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.down("xl"))
  const isTablet = useMediaQuery(theme.breakpoints.down("md"))
  const isMobile = useMediaQuery(theme.breakpoints.down("xs"))
  console.log(isDesktop, "isDesktop")
  const returnWeb = () => {
    UserService.doLogout()
  }
  return (
    <>
      <Wrapper>
        <div style={{ position: "relative", width: "100%", height: "100%" }}>
          <Adm.PageNotFound
            isDesktop={isDesktop}
            isTablet={isTablet}
            isMobile={isMobile}
          />
          <div style={{ position: "absolute", top: "90%", left: "54%" }}>
            <Button
              to="/"
              variant="contained"
              style={{
                background: "#8080D2",
                width: "150px",
                borderRadius: "20px",
                color: "#FFF",
              }}
              onClick={() => returnWeb()}
            >
              Log out
            </Button>
          </div>
        </div>
      </Wrapper>
    </>
  )
}
export default UnAuthorizedUser
