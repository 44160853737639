import React from "react"
import {
  Checkbox,
  Grid,
  IconButton,
  Box,
  Toolbar,
  Tooltip,
  Typography,
} from "@material-ui/core"
import {
  Delete as DeleteIcon,
  FilterList as FilterListIcon,
} from "@material-ui/icons"
import * as Adm from "@adm"

function EnhancedTableToolbar(props) {
  const { numSelected } = props
  return (
    <Toolbar>
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            height: 32,
          }}
        >
          {numSelected ? (
            <>
              <Typography color="inherit" variant="subtitle1">
                {" "}
                {numSelected} selected{" "}
              </Typography>
              <Tooltip title="Delete">
                <IconButton aria-label="Delete">
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </>
          ) : null}
        </div>
      </div>
    </Toolbar>
  )
}
export default EnhancedTableToolbar
