import React, { useState } from "react"
import {
  makeStyles,
  Grid,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
} from "@material-ui/core"
import _ from "lodash"
import TextSearch from "./TextSearch"
import TextSearchLookup from "./TextSearchLookup"
import SelectInputSearch from "./SelectInputSearch"
import DateRangeInput from "./SearchByDate/DateRangeInput"
import SearchByDate from "./SearchByDate"

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    height: 60,
    display: "flex",
    alignItems: "center",
    paddingInline: theme.spacing(1),
  },
  spacer: {
    paddingInline: theme.spacing(1.5),
  },
}))

const SearchComponentSwitch = (props) => {
  const classes = useStyles()
  const { searchBy = "" } = props

  switch (searchBy) {
    case "textfield": {
      return (
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          className={classes.spacer}
          container
        >
          <TextSearch {...props} />
        </Grid>
      )
    }
    case "select": {
      return (
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          className={classes.spacer}
          container
        >
          <TextSearch select {...props} />
        </Grid>
      )
    }
    case "lookup": {
      return (
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          className={classes.spacer}
          container
        >
          <TextSearchLookup select {...props} />
        </Grid>
      )
    }
    case "datetime": {
      return (
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={6}
          xl={6}
          className={classes.spacer}
          container
        >
          <SearchByDate {...props} />
        </Grid>
      )
    }
    case "date": {
      return (
        <Grid
          item
          xs={12}
          sm={4}
          md={4}
          lg={4}
          xl={4}
          className={classes.spacer}
          container
        >
          <SearchByDate {...props} onlyDate />
        </Grid>
      )
    }
    default: {
      return null
    }
  }
}

export default SearchComponentSwitch
