import React from "react"
import { DropDownList } from "@progress/kendo-react-dropdowns"
import { useTranslation } from "react-i18next"
import { LocalizationProvider, loadMessages } from "@progress/kendo-react-intl"
import { JSUtils } from "@utils"

function CustomFilterCell(props) {
  const dropdownRef = React.useRef(null)
  const { t } = useTranslation()
  const defaultText = "All"
  const {
    data = [
      { tab: t("True"), value: "true" },
      { tab: t("False"), value: "false" },
    ],
    defaultItem = t("All"),
    value = { value: "" },
    dropdownChange = () => "",
    onClearButtonClick = () => "",
    hideClearButton = false,
    field,
  } = props || {}
  const hasValue = (value) =>
    Boolean(value?.toString() && value?.toString() !== defaultItem)
  const dropDownItems = data.map((item) => {
    if (defaultText?.toLowerCase() !== item.tab?.toLowerCase()) {
      return {
        ...item,
        tab: t(item.tab),
        value: item.value,
      }
    }
    return item
  })
  const selectedItemIndex = dropDownItems.findIndex(
    (item) => item.value === value.value
  )
  const defaultItemExists =
    data.find((item) => item.value === props?.defaultItem?.toLowerCase()) !==
    undefined
  React.useEffect(() => {
    window["dropdownRef"] = dropdownRef
  }, [])

  let lang = localStorage.getItem("i18nextLng")
  let langkit = JSON.parse(localStorage.getItem("languageskit")) || []
  let message = {}
  let locale = JSUtils?.formatLocales(langkit)

  message[localStorage.getItem("lang")] = {
    dropdowns: {
      nodata: t("DATA NOT FOUND"),
      clear: t("Clear"),
    },
  }
  loadMessages(message[lang], locale[lang])

  return (
    <div className="k-filtercell">
      <LocalizationProvider language={locale[lang]}>
        <DropDownList
          id="cls-dropdown"
          ref={dropdownRef}
          data={[...dropDownItems.map((item) => t(item.tab))]}
          onChange={(e) => {
            const selectedValue =
              e.value !== defaultItem
                ? dropDownItems[
                    dropDownItems.findIndex((item) => item.tab === e.value)
                  ].value
                : defaultText
            dropdownChange(selectedValue, field)
          }}
          value={
            selectedItemIndex !== -1
              ? dropDownItems[selectedItemIndex].tab
              : defaultItem
          }
          defaultItem={!defaultItemExists ? defaultItem : null}
        />
      </LocalizationProvider>
      {hasValue(value.value) && !hideClearButton ? (
        <button
          className="k-button k-button-icon k-clear-button-visible"
          title="Clear"
          style={{ visibility: hasValue(value.value) }}
          onClick={() => {
            onClearButtonClick(field)
          }}
        >
          <span className="k-icon k-i-filter-clear" />
        </button>
      ) : null}
    </div>
  )
}

export default CustomFilterCell
