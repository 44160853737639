export const augmentConfig = [
  {
    name: "GEOMETRIC TRANSFORMATION",
    type: "accordion",
    isOpen: true,
    value: true,
    child: [
      {
        name: "FLIP",
        type: "heading",
        child: [
          {
            name: "Vertical Flip",
            type: "checkbox",
            value: false,
          },
          {
            name: "Horizontal Flip",
            type: "checkbox",
            value: false,
          },
        ],
      },
      {
        name: "ROTATE",
        type: "heading",
        child: [
          {
            name: "Rotation(180°)",
            type: "checkbox",
            value: false,
          },
        ],
      },
    ],
  },
  {
    name: "COLOUR DISTRIBUTION",
    type: "accordion",
    value: true,
    isOpen: false,
    child: [
      {
        name: "HUE",
        subName: "Percent of Outputted Images to here",
        type: "range",
        value: 0,
        from: -0.5,
        to: 0.5,
        valueType: "number",
        valueSuffix: "",
      },
      {
        name: "CONTRAST",
        subName: "Percent of Outputted Images to here",
        type: "range",
        value: 1,
        from: 0.4,
        to: 2,
        valueType: "number",
        valueSuffix: "",
      },
      {
        name: "Brightness",
        subName: "Percent of Outputted Images to here",
        type: "range",
        value: 1,
        from: 0.5,
        to: 1.5,
        valueType: "number",
        valueSuffix: "",
      },
      {
        name: "Saturation",
        subName: "Percent of Outputted Images to here",
        type: "range",
        value: 1,
        from: -2,
        to: 2,
        valueType: "number",
        valueSuffix: "",
      },
      {
        name: "Grayscale",
        subName: "Percent of Outputted Images to here",
        type: "checkbox",
        value: false,
      },
    ],
  },
  {
    name: "CLARITY",
    type: "accordion",
    value: true,
    isOpen: false,
    child: [
      {
        name: "SHARPEN",
        subName: "Percent of Outputted Images to here",
        type: "range",
        value: 1,
        from: 0,
        to: 25,
        valueType: "percentage",
        valueSuffix: "%",
      },
    ],
  },
]

export const postObject = {
  trianing_product_id: "",
  Vertical_Flip: false,
  Horizontal_Flip: false,
  Rotation: true,
  Rotation_value: 0,
  Brightness: true,
  Brightness_value: 1,
  Hue: true,
  Hue_value: 1,
  Contrast: true,
  Contrast_value: 1,
  Saturation: true,
  Saturation_value: 1,
  Grayscale: false,
  Sharpen: true,
  Sharpen_value: 1,
  file_ids: [],
}
