import React, { useEffect, useState } from "react"
import {
  Grid,
  Divider,
  Button,
  Card,
  Typography,
  AppBar,
  Tabs,
  Tab,
  emphasize,
  Tooltip,
} from "@material-ui/core"
import { makeStyles, useTheme } from "@material-ui/core/styles"
import { useTranslation } from "react-i18next"
import TableFilter from "../../components/tableFilter"
import { renderUpdateTableFilterData } from "./renderProps"
import styled from "styled-components"
import IconButton from "@material-ui/core/IconButton"
import { Trash2 as DeleteIcon } from "@iconsGallery"

const TemplateTableHeaderGrid = styled(Grid)`
  .ecom-card-table-header {
    border: 1px solid #d9d9db;
    border-bottom: none;
    border-radius: 5px 5px 0 0 !important;
  }
`
const useStyles = makeStyles({
  customStyleOnTab: {
    fontSize: "25px",
    color: "black",
    alignItems: "left",
  },
  activeTab: {
    fontSize: "16px",
    fontWeight: "600",
    color: "pink",
  },
  customStyleOnActiveTab: {
    color: "red",
  },
})
const StyledTabs = styled(Tabs)`
  & .MuiTabs-indicator {
    background: none;
  }
  .MuiTab-wrapper {
    flex-direction: unset;
    justify-content: left;
  }
`
const TabelHeader = (props) => {
  const { setOpenConfirmationDialog = () => "" } = props || {}
  const { t } = useTranslation()
  const {
    tableGlobalState,
    title,
    tabValue,
    handleTabChange,
    tabs,
    displayTableHeader = true,
    isLookup = false,
    loading,
  } = props || {}
  const { columnData, selection } = tableGlobalState
  //console.log("slection.length---------------------->", selection)
  const classes = useStyles()
  const NumberSelected = (props) => t(props.numberSelected + " selected")
  return (
    <TemplateTableHeaderGrid lg={12} md={12} sm={12} xs={12}>
      <Card className="ecom-card-table-header">
        <Grid
          container
          row="true"
          justify="space-between"
          alignContent="center"
          alignItems="center"
          style={{
            padding: isLookup ? "" : "20px",
            height: isLookup ? "40px" : "60px",
          }}
        >
          <Grid item>
            <AppBar
              position="static"
              style={{ backgroundColor: "transparent" }}
            >
              <StyledTabs
                value={tabValue}
                aria-label="simple tabs example"
                onChange={handleTabChange}
                classes={{ indicator: classes.customStyleOnActiveTab }}
              >
                {tabs.map((item, idx) => {
                  return (
                    <Tab
                      label={
                        <span style={{ fontSize: "15px", border: "none" }}>
                          <b>
                            {/* <span className="cls-plain-text-ui-kit"> */}
                            {t(item)}
                            {/* </span> */}
                          </b>
                        </span>
                      }
                      style={{ color: "black" }}
                    />
                  )
                })}
              </StyledTabs>
            </AppBar>
          </Grid>
          <Grid item>
            {tabValue !== 0 ? null : (
              <Grid item>
                {selection.selectedItems.length ||
                  (selection &&
                    selection.store_ids &&
                    selection.store_ids.length) ? (
                  <Grid
                    container
                    row="true"
                    spacing={4}
                    alignContent="center"
                    alignItems="center"
                  >
                    <Grid item>
                      <NumberSelected
                        numberSelected={
                          selection.store_ids &&
                            selection.store_ids.length >
                            selection.selectedItems.length
                            ? selection.store_ids.length
                            : selection.selectedItems.length
                        }
                      />
                    </Grid>
                    {displayTableHeader > 0 ? (
                      <Tooltip title="Archive" placement="top">
                        <Grid item>
                          <IconButton
                            onClick={() => setOpenConfirmationDialog(true)}
                          >
                            <DeleteIcon stroke="grey" />
                          </IconButton>
                        </Grid>
                      </Tooltip>
                    ) : null}
                    <Grid item>
                      {displayTableHeader > 0 ? (
                        <TableFilter
                          showLocationicon={props.showLocationicon}
                          handleDedupeModal={props.handleDedupeModal}
                          locationId={props.locationId}
                          dropDownList={props.dropDownList}
                          tableFilterData={columnData}
                          updateTableFilterData={(item) =>
                            renderUpdateTableFilterData(item, tableGlobalState)
                          }
                        />
                      ) : null}
                    </Grid>
                  </Grid>
                ) : (
                  <div>
                    {displayTableHeader ? (
                      <TableFilter
                        showLocationicon={props.showLocationicon}
                        handleDedupeModal={props.handleDedupeModal}
                        locationId={props.locationId}
                        dropDownList={props.dropDownList}
                        tableFilterData={columnData}
                        updateTableFilterData={(item) =>
                          renderUpdateTableFilterData(item, tableGlobalState)
                        }
                      />
                    ) : null}
                  </div>
                )}
              </Grid>
            )}
          </Grid>
        </Grid>
      </Card>
    </TemplateTableHeaderGrid>
  )
}

export { TabelHeader }
