import React from "react"
import { useState } from "react"
import {
  Menu,
  MenuItem,
  IconButton,
  Box,
  Tooltip,
  colors,
} from "@material-ui/core"
import {
  MoreVertIcon,
  EditIcon,
  DeleteIcon,
  SaveIcon,
  CancelIcon,
  VisibilityIcon,
} from "@iconsGallery"
import styled from "styled-components"
import { useTranslation } from "react-i18next"

const MenuListParentWrapper = styled.div`
  .MuiIconButton-root {
    width: 20px;
    margin: auto;
    display: flex;
  }
`
const MenuListWrapper = styled.div`
  box-shadow: 0px 2px 2px #0000008f;
  border: 1px solid #eaeaea;
`

function ActionMenu(props) {
  const { isInlineEditAllowed = false, isDisableSaveButton = false } = props
  const [actionsState, setActionsState] = useState(null)
  const { t } = useTranslation()
  const ITEM_HEIGHT = 45

  const handleMenu = (event) => {
    setActionsState(event.currentTarget)
  }

  const handleClose = (event) => {
    setActionsState(null)
    /*  event.stopPropagation(null) */
  }
  return (
    <>
      {props.data.inEdit ? (
        <>
          <Box style={{ display: "flex", justifyContent: "center" }}>
            <Tooltip title={t("Save")} placement="top">
              <IconButton
                key={"save"}
                style={{ padding: "10px" }}
                onClick={props.save}
                disabled={isDisableSaveButton}
              >
                <SaveIcon
                  style={{
                    color: isDisableSaveButton ? "grey" : colors.green[600],
                  }}
                />
              </IconButton>
            </Tooltip>
            <Tooltip title={t("Cancel")} placement="top">
              <IconButton
                key={"cancel"}
                style={{ padding: "10px" }}
                onClick={props.cancel}
              >
                <CancelIcon style={{ color: colors.red[400] }} />
              </IconButton>
            </Tooltip>
          </Box>
        </>
      ) : (
        <Box style={{ display: "flex", justifyContent: "center" }}>
          <Tooltip
            title={props.menutype === "master" ? t("View") : t("Edit")}
            placement="top"
          >
            <IconButton
              key={"edit"}
              disabled={
                props.menutype === "master" ? false : !isInlineEditAllowed
              }
              style={{ padding: "10px" }}
              onClick={props.in_edit}
            >
              {props.menutype === "master" ? (
                <VisibilityIcon
                  style={{ color: "var(--primaryColor)" }}
                  onClick={props.in_edit}
                />
              ) : (
                <EditIcon
                  style={{
                    color: isInlineEditAllowed ? "var(--primaryColor)" : "#ccc",
                  }} /*  onClick={props.in_edit} */
                />
              )}
            </IconButton>
          </Tooltip>
        </Box>
      )}
    </>
  )
}

export default ActionMenu
