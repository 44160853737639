import React from "react"
import * as Adm from "@adm"
import { Grid, Typography, TextField, Tabs } from "@material-ui/core"
import * as Render from "./renderProps"
import { useConfigUI } from "./useConfigUI"
import AvalaraFormFields from "./AvalaraFormFields"
import TaxConnectorTabs from "./TaxConnectorTabs"
import { useTranslation } from "react-i18next"

function TaxConfigUI(props) {
  const { t } = useTranslation()
  const {
    defaultList = {},
    onUpdate = () => "",
    getIsErrorIntiated = false,
    setIsErrorIntiated = () => "",
    type = "",
    crudType = "",
    getAvalaraDetails = () => { },
    getDefaultSampleCode = {},
  } = props || {}
  const stateConfigUIProps = useConfigUI({
    defaultList,
    onUpdate,
    getIsErrorIntiated,
    setIsErrorIntiated,
    getAvalaraDetails,
    getDefaultSampleCode,
  })

  console.log(stateConfigUIProps, props, "stateConfigUIProps")
  return (
    <>
      {type === "avalaraconfig" ?
        <Grid style={{ padding: "20px" }}>
          <Grid style={{ marginBottom: "40px" }}>
            <Typography variant="h3" style={{ fontWeight: 800, fontSize: "24px" }}>
              {crudType === "create" ? t(`Create Avalara Configuration`) : t(`Edit Avalara Configuration`)}</Typography>
          </Grid>
          <AvalaraFormFields stateConfigUIProps={stateConfigUIProps} crudType={crudType} type={type} getAvalaraDetails={getAvalaraDetails} currentTab="" />
        </Grid>
        :
        <Grid Container lg={10} md={12}>
          <TaxConnectorTabs stateConfigUIProps={stateConfigUIProps} type={type} crudType={crudType} getAvalaraDetails={getAvalaraDetails} />
        </Grid>}
    </>
  )
}

export { TaxConfigUI }
export default TaxConfigUI
