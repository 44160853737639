import React from "react"
import IconButton from "@material-ui/core/IconButton"
import {
  EditIcon,
  DeleteIcon,
  SaveIcon,
  CancelIcon,
  VisibilityIcon,
} from "@iconsGallery"
import { colors, Tooltip } from "@material-ui/core"
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined"
import StopRoundedIcon from "@material-ui/icons/StopRounded"
import { useTranslation } from "react-i18next"

function IconMenu(props) {
  const {
    isDisableViewIcon = false,
    isDisableSaveButton = false,
    isDisableEditIcon = true,
    showCopyIcon = false,
    isDisableDeleteButton = false,
    showPauseIcon = false,
    showDeleteIcon = false,
    isInlineEditAllowed = false,
    data = {},
    moduleName = "",
  } = props || {}
  const { t } = useTranslation()

  let isDeleteIcon =
    moduleName === "trainingProd"
      ? (showPauseIcon || showDeleteIcon) &&
        (data?.status === "Stopped" ||
          data?.status === "Failed" ||
          data?.status === "Completed" ||
          (data?.progress <= 100 && data?.progress !== 0))
      : props.isInlineEditAllowed

  return (
    <div style={{ textAlign: "center" }}>
      {props.data.inEdit ? (
        <>
          <IconButton
            color="primary"
            aria-label="Save"
            component="span"
            onClick={(...e) => !props.data.saveBTNdisabled && props.save(e)}
            disabled={isDisableSaveButton}
            style={
              props.data.saveBTNdisabled
                ? {
                    color: "rgba(0, 0, 0, 0.5)",
                    cursor: "not-allowed",
                  }
                : {}
            }
          >
            <SaveIcon
              style={{
                color: isDisableSaveButton ? "grey" : colors.green[600],
              }}
            />
          </IconButton>
          <IconButton
            color="primary"
            aria-label="Cancel changes"
            component="span"
            onClick={props.cancel}
          >
            <CancelIcon style={{ color: colors.red[400] }} />
          </IconButton>
        </>
      ) : (
        <>
          {isDisableViewIcon === false && (
            <Tooltip title={t("View")} placement="top">
              <IconButton
                color="primary"
                aria-label="Visiblity"
                component="span"
                onClick={props.in_edit}
              >
                <VisibilityIcon
                  onClick={props.view}
                  style={{ color: "var(--primaryColor)" }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isDisableEditIcon === true && (
            <IconButton
              color="primary"
              aria-label="Edit"
              component="span"
              onClick={props.in_edit}
              disabled={!props.isInlineEditAllowed}
            >
              <EditIcon
                style={{
                  color: !props.isInlineEditAllowed
                    ? ""
                    : "var(--primaryColor)",
                }}
              />
            </IconButton>
          )}
          {showPauseIcon && showDeleteIcon && (
            <Tooltip title={t("Stop")} placement="top">
              <IconButton
                color="primary"
                aria-label="Delete"
                component="span"
                onClick={props.stop}
                disabled={
                  data?.status === "Stopped" ||
                  data?.status === "Failed" ||
                  data?.progress === 100
                }
              >
                <StopRoundedIcon
                  style={{
                    border:
                      data?.status === "Stopped" ||
                      data?.status === "Failed" ||
                      data?.progress === 100
                        ? "1px solid #bdbdbd"
                        : "1px solid var(--primaryColor)",
                    borderRadius: "50px",
                    color:
                      data?.status === "Stopped" ||
                      data?.status === "Failed" ||
                      data?.progress === 100
                        ? "#bdbdbd"
                        : "var(--primaryColor)",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}
          {isDisableDeleteButton === false && (
            <Tooltip title={t("Delete")} placement="top">
              <IconButton
                color="primary"
                aria-label="Delete"
                component="span"
                onClick={props.delete}
                disabled={isDeleteIcon ? false : true}
              >
                <DeleteIcon
                  style={{
                    color: isDeleteIcon ? "var(--primaryColor)" : "",
                  }}
                />
              </IconButton>
            </Tooltip>
          )}

          {showCopyIcon === true && (
            <IconButton
              color="primary"
              aria-label="Copy"
              component="span"
              onClick={props?.copy}
              // disabled={!props.isInlineEditAllowed}
            >
              <FileCopyOutlinedIcon
                style={{
                  color: !props.isInlineEditAllowed
                    ? ""
                    : "var(--primaryColor)",
                }}
              />
            </IconButton>
          )}
        </>
      )}
    </div>
  )
}
export default IconMenu
