import FrictionlessDashboard from "./FrictionlessDashboard"
import * as IconsGallery from "@iconsGallery"

export const getDefaultList = (stateDashboard, props, t) => {
  const { rolePermAttr = {} } = stateDashboard
  const { home = {}, as = {}, fs = {} } = rolePermAttr
  let getRoleName = "SaaS-Site-Admin"
  let getTenantId = localStorage.getItem("tenantId")
  getRoleName = getRoleName.toLocaleLowerCase()
  const isSassRole = true
  return [
    {
      isHide: true,
      identifer: "H",
      breadcrumbName: "SaaS admin Dashboard",
      qureyName: t("home"),
      name: "Dashboard",
      icon: () => (
        <IconsGallery.DashboardsIcon
          style={{ marginLeft: 8, marginRight: 20 }}
        />
      ),
      component: () => <></>,
    },

    {
      isHide: false,
      identifer: "FS",
      breadcrumbName: "Frictionless Retailer",
      qureyName: t("frictionless"),
      name: "Frictionless Retailers",
      icon: () => (
        <IconsGallery.ArrowWifi style={{ marginLeft: 8, marginRight: 20 }} />
      ),
      component: () => (
        <FrictionlessDashboard mainStateDashboard={{ ...stateDashboard, ...props }} />
      ),
    },
  ]
}

export const triggerSearchParam = (props, stateProps = {}, t) => {
  const { stateDashboard = {} } = props
  const defaultList = getDefaultList(stateDashboard, stateProps, t)
  const getTabIndex = stateDashboard.getTabIdValue()
  stateDashboard?.setCurrentMainTabIndex(getTabIndex)
  if (getTabIndex === -1 || getTabIndex === 2) {
    if (stateProps?.defaultList?.selectedTenantId === "") {
    } else {
      stateDashboard.isLoading = false
    }
  }
}
